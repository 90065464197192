import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Tab from "@material-ui/core/Tab";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import GetApp from "@material-ui/icons/GetApp";
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import {withStyles} from '@material-ui/styles';
import React, {useState} from 'react';

import Button from ".../assets/components/CustomButtons/Button";
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import StyledDropzone from ".../components/features/forms/formEditor/StyledDropzone";
import LxIntField from ".../components/formComponents/LxIntField";
import LxTextField from ".../components/formComponents/LxTextField";

const styles = theme => ({
    itemContainer: {
        padding: '5px',
        height: '100%'
    },
    itemCard: {
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.7)',
        height: '100%',
        width: '100%'
    },
    errorHightlight: {
        boxShadow: `0 1px 8px 0 ${theme.palette.error.main}`
    },
    warningHightlight: {
        boxShadow: `0 1px 8px 0 ${theme.palette.warning.main}`
    },
    removeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    toolTip: {
        top: '20px !important'
    },
    cogButton: {
        padding: "0px",
        height: "20.56px",
        width: "20.56px",
        right: "24.56px",
        top: "20px",
        zIndex: 10
    }
});

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function FormEditorTabsSection(props){
    const {section, renderSection, handleAddSubSection, handleRemoveSubSection, handleUpdateSubSectionName, handleUpdateSubSectionSequence, handleImportSubSection, classes} = props;
    const [value, setValue] = React.useState(0);
    const [editorOpen, setEditorOpen] = React.useState(false);
    const [dropZoneOpen, setDropZoneOpen] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let onFileUploaded = (subSection) => {
        handleImportSubSection(subSection);
    };

    let sortedSubsections = Array.isArray(section.subSections) ? section.subSections.sort((a, b) => a.sequence - b.sequence) : [];
    return(
        <React.Fragment>
            <GridContainer>
                <GridItem xs={1}>
                    <IconButton className={classes.cogButton} onClick={() => setEditorOpen(true)}>
                        <SettingsIcon/>
                    </IconButton>
                </GridItem>
                <GridItem xs={11}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                    >
                        {sortedSubsections.map((subSection, index) => (
                            <Tab
                                label={subSection.sectionDisplayName}
                                {...a11yProps(index)}
                            />

                        ))}
                        <Tab icon={<AddIcon />} onClick={() => handleAddSubSection()}/>
                    </Tabs>
                </GridItem>
            </GridContainer>
            <Dialog open={editorOpen} onClose={() => setEditorOpen(false)}>
                <Card>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Display Name</TableCell>
                                    <TableCell>Sequence</TableCell>
                                    <TableCell>Delete</TableCell>
                                    <TableCell>Export</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedSubsections.map((subSection) => (
                                    <TableRow key={subSection.sectionName}>
                                        <TableCell component="th" scope="row">
                                            <LxTextField
                                                placeholder='Display Name'
                                                value={subSection.sectionDisplayName}
                                                useOnChangeEvent
                                                onChange={(event) => handleUpdateSubSectionName(subSection, event.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <LxIntField
                                                placeholder='Sequence'
                                                value={subSection.sequence}
                                                onChange={(val) => handleUpdateSubSectionSequence(subSection, val)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CloseIcon onClick={() => handleRemoveSubSection(subSection.sectionName)}/>
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(subSection))}`}
                                                download={`${subSection.sectionDisplayName}.json`}
                                                onClick={(e) => e.preventDefault}
                                            >
                                                <GetApp />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key={0}>
                                    <TableCell component="th" scope="row"/>
                                    <TableCell>
                                        <IconButton onClick={() => setDropZoneOpen(true)}>
                                            <PublishIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleAddSubSection()}>
                                            <AddIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Button color="primary" onClick={() => setEditorOpen(false)}>Done</Button>
                    </CardContent>
                </Card>
            </Dialog>
            {sortedSubsections.map((subSection, index) => (
                <TabPanel value={value} index={index}>
                    {renderSection(subSection)}
                </TabPanel>
            ))}
            <Dialog
                open={dropZoneOpen}
                onClose={() => setDropZoneOpen(false)}
            >
                <StyledDropzone onFileUploaded={onFileUploaded}/>
            </Dialog>
        </React.Fragment>);
}

export default withStyles(styles)(FormEditorTabsSection);