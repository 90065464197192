import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';

const styles = theme => ({
    card: {
        marginBottom: '20px',
        '& .ps__rail-y': {
            top: '0px !important'
        }
    },
    cardBody: {
        maxHeight: '300px'
    },
    cardFooter: {
        justifyContent: 'space-around'
    },
    topDivider: {
        marginBottom: '10px'
    },
    botDivider: {
        marginTop: '10px'
    },
    errorTypography: {
        borderRadius: `${theme.spacing(3)}px`,
        border: `3px solid ${theme.palette.error.main}`,
        margin: `${theme.spacing(2)}px`
    },
    warningTypography: {
        borderRadius: `${theme.spacing(3)}px`,
        border: `3px solid ${theme.palette.warning.main}`,
        margin: `${theme.spacing(2)}px`
    }
});

class FormEditorErrorBar extends React.Component {
    fixFormSchema = () => {
        const { setFormSchema, schemaValidation } = this.props;

        if (typeof setFormSchema !== 'undefined' && schemaValidation !== null) {
            setFormSchema(schemaValidation.validatedFormSchema);
        }
    }

    render() {
        const { classes, validationLoading, schemaValidation } = this.props;
        const { updatedFields = [], removedFields = [] } = schemaValidation !== null ? schemaValidation : {};

        if (updatedFields.length === 0 && removedFields.length === 0) {
            return null;
        }

        return (
            <Card className={classes.card}>
                <CardHeader>
                    <Typography>
                        {'Field Validation Errors'}
                    </Typography>
                </CardHeader>
                {validationLoading ?
                    <CardBody>
                        <CircularProgress size={60} thickness={7} />
                    </CardBody>
                    :
                    <React.Fragment>
                        <CardBody className={classes.cardBody}>
                            <Divider className={classes.topDivider} />
                            {removedFields.length === 0 ?
                                null
                                :
                                <Typography className={classes.errorTypography}>
                                    {`
                                        The fields marked in red do not have a valid configuration for the selected Event Table and View.
                                        The fields marked in red will be removed when you click "Fix Form."
                                    `}
                                </Typography>
                            }
                            {updatedFields.length === 0 ?
                                null
                                :
                                <Typography className={classes.warningTypography}>
                                    {`
                                        The fields marked in yellow have a valid configuration for the selected Event Table and View but are not using them.
                                        The fields marked in yellow will be updated when you click "Fix Form."
                                    `}
                                </Typography>
                            }
                            <Divider className={classes.botDivider} />
                        </CardBody>
                        <CardFooter className={classes.cardFooter}>
                            <Button variant='outlined' color='primary' onClick={this.fixFormSchema}>
                                {'Fix Form'}
                            </Button>
                        </CardFooter>
                    </React.Fragment>
                }
            </Card>
        );
    }
}

export default withStyles(styles)(FormEditorErrorBar);