import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';
import LxSelectField from '.../components/formComponents/LxSelectField';
import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 50px)'
    },
    divider: {
        marginTop: '15px'
    },
    cardFooter: {
        display: 'flex',
        bottom: '0px',
        width: '100%',
        alignitems: 'right',
        justifycontent: 'space-between'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    addNumber: {
        flex: '1 0 auto'
    },
    number: {
        flex: '5 0 auto',
        width: 'auto',
        margin: '0px 10px'
    },
    table: {
        flex: '1 0 auto',
        height: '300px' 
    }
});

class StaffNumbers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            staffNumberId: 0,
            changedNumber: '',
            changedNumberType: '',
            staffNumberDef: null,
            staffNumbers: this.props.staffNumbers,
            validNewStaffNumber: true
        };
    }

    componentWillUnmount() {
        const { onStaffNumbersChange } = this.props;
        const { staffNumbers } = this.state;

        if (typeof onStaffNumbersChange === 'function') {
            onStaffNumbersChange(staffNumbers);
        }
    }

    onStaffNumberTypeChange = (changedNumberType) => {
        this.setState({
            changedNumberType: changedNumberType
        });
    }

    onStaffNumberChange = (changedNumber) => {
        this.setState({
            changedNumber: changedNumber
        });
    }

    onStaffNumberAdd = (staffId) => {
        let newStaffNumberList = Array.isArray(this.state.staffNumbers) ? [...this.state.staffNumbers] : [];
        //Set Staff numder ids for newly adding staff numbers with 0 or -1,-2 numbers etc.
        let numberId = 0;
        if (newStaffNumberList.length > 0) {
            let lastElement = newStaffNumberList[newStaffNumberList.length - 1];
            if (lastElement.staffNumberId <= 0) {
                numberId = lastElement.staffNumberId - 1;
            }
        }

        let numberDef = {
            staffNumberId: numberId,
            staffId: staffId,
            idType: this.state.changedNumberType,
            idNumber: this.state.changedNumber
        };

        //Add New Staff Number.
        let staffNumber = numberDef;
        newStaffNumberList.push({
            ...staffNumber
        });

        this.setState({
            staffNumbers: newStaffNumberList
        });
    }

    deleteStaffNumber = (staffNumberId) => {
        if (this.state.staffNumbers !== null) {
            let newStaffNumberList = Array.isArray(this.state.staffNumbers) ? [...this.state.staffNumbers] : [];
            let staffNumberIdIndex = newStaffNumberList.map(staffNumber => staffNumber.staffNumberId).indexOf(staffNumberId);
            newStaffNumberList.splice(staffNumberIdIndex, 1);

            this.setState({
                staffNumbers: newStaffNumberList
            });
        }
    }

    isValidStaffNumbers = (staffNumberType, staffNumber) => {
        const { staffNumbers } = this.state;
        let validStaffNumber = true;

        if (Array.isArray(staffNumbers)) {
            staffNumbers.forEach(number => {
                if ((number.idType === staffNumberType) && (number.idNumber === staffNumber)) {
                    validStaffNumber = false;
                }
            });
        }
        return validStaffNumber;
    }

    render() {
        const { classes, staffId, personnelNumbers } = this.props;
        const { changedNumberType } = this.state;
        //let validNewStaffNumber = this.isValidStaffNumbers(changedNumberType, changedNumber);

        return (
            <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                    <LxSearchableTable
                        className={classes.table}
                        items={this.state.staffNumbers !== null ? this.state.staffNumbers : []}
                        columns={[
                            {
                                title: 'Staff Role Id',
                                key: 'staffNumberId',
                                show: false
                            },
                            {
                                title: 'Staff Id',
                                key: 'staffId',
                                show: false
                            },
                            {
                                title: 'Number Type',
                                key: 'idType',
                                width: 200
                            },
                            {
                                title: 'Staff Number',
                                key: 'idNumber',
                                width: 200
                            },
                            {
                                id: 'delete',
                                width: 65,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Delete',
                                key: (item) => (
                                    <ConfirmationButton
                                        className={classes.button}
                                        showConfirmation
                                        confirmationTitle='Permanently Delete Staff Number?'
                                        confirmationText={[
                                            'Are you sure you want to remove this staff number?',
                                            'You will not be able to get it back.'
                                        ]}
                                        confirmationConfirmText='Delete'
                                        onClick={() => this.deleteStaffNumber(item.staffNumberId)}
                                    >
                                        <DeleteIcon />
                                    </ConfirmationButton>
                                )
                            }
                        ]}
                        searchKeys={[
                            'idType',
                            'idNumber'
                        ]}

                        title={'Available Numbers'}
                    />
                    <Divider />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                    <LxSelectField
                        title={'Number Type'}
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={changedNumberType}
                        nullable={false}
                        onChange={this.onStaffNumberTypeChange}
                        options={personnelNumbers}
                    />
                    <LxTextField
                        title={'Number'}
                        className={classes.number}
                        placeholder='New Staff Number...'
                        onChange={this.onStaffNumberChange}
                        max={30}
                    />
                    <Button
                        className={classes.addNumber}
                        variant='contained'
                        color='primary'
                        onClick={() => this.onStaffNumberAdd(staffId)}
                    >
                        {'Add Number'}
                    </Button>
                </CardFooter>
            </Card>
        );
    }
}
export default withStyles(styles)(StaffNumbers);