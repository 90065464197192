import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxIntField from '.../components/formComponents/LxIntField';
import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    dialog: {
        minWidth: '40vw',
        minHeight: '20vh',
        maxWidth: '80vw',
        maxHeight: '60vw'
    },
    dialogTitle: {
        padding: '8px 24px'
    },
    dialogContent: {
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        container: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    dialogButtons: {
        justifyContent: 'center'
    },
    propertyFields: {
        paddingBottom: '20px'
    }
});

class FormHeaderPropertyEditor extends React.Component {
    state = {
        isOpen: false
    }

    openDialog = () => {
        this.setState({
            isOpen: true
        });
    }

    closeDialog = () => {
        this.setState({
            isOpen: false
        });
    }

    updateFormHeader = (targetPropertyName) => (value) => {
        const { onChange, formHeader } = this.props;

        let newFormHeader = {
            ...formHeader,
            [targetPropertyName]: value
        };

        if (typeof onChange === 'function') {
            onChange(newFormHeader);
        }
    }

    renderDialogContent = () => {
        const { classes, formHeader } = this.props;
        return (
            <div>
                <LxIntField
                    value={formHeader.sequence}
                    className={classes.propertyFields}
                    onChange={this.updateFormHeader("sequence")}
                    title={"Sequence"}
                />
                <LxTextField
                    value={formHeader.selectionExpression}
                    className={classes.propertyFields}
                    onChange={this.updateFormHeader("selectionExpression")}
                    title={"Selection Expression"}
                />
                <LxIntField
                    value={formHeader.selectionSequence}
                    className={classes.propertyFields}
                    onChange={this.updateFormHeader("selectionSequence")}
                    title={"Selection Sequence"}
                />
                <LxTextField
                    value={formHeader.emrExclusionExpression}
                    className={classes.propertyFields}
                    onChange={this.updateFormHeader("emrExclusionExpression")}
                    title={"Emr Integration Exclusion Expression"}
                />
            </div>
        );
    }

    handleMouseDown = (event) => {
        event.stopPropagation();
    }

    render() {
        const { classes, className, formHeader, disabled } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                <Button 
                    variant={'outlined'}
                    color={'primary'}
                    onClick={this.openDialog} 
                    className={className}
                    disabled={disabled}
                >
                    Settings:
                </Button>
                <Dialog
                    classes={{ paper: classes.dialog }}
                    open={isOpen}
                    transitionDuration={0}
                    onMouseDown={this.handleMouseDown}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        Form Header Properties Editor
                    <Divider />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {formHeader ? this.renderDialogContent() : null}
                    </DialogContent>
                    <DialogActions className={classes.dialogButtons}>
                        <Button variant='contained' color='primary' onClick={this.closeDialog}>
                            {'Close'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(FormHeaderPropertyEditor);