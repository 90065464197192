import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    dialog: {
        minWidth: '60vw',
        minHeight: '20vh'
    },
    dialogTitle: {
        padding: '8px 24px'
    },
    dialogContent: {
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center'
    },
    dialogButtons: {
        justifyContent: 'center'
    }
});

class ExpressionEditor extends React.Component {
    state = {
        expression: this.props.expression,
        initialExpression: this.props.expression
    }

    static getDerivedStateFromProps(props, state) {
        if (props.expression !== state.initialExpression) {
            return ({
                expression: props.expression,
                initialExpression: props.expression
            });
        }

        return null;
    }

    handleExpressionChange = (expression) => {
        this.setState({
            expression
        });
    }

    handleConfirm = (expression) => () => {
        const { onConfirm, closeDialog } = this.props;

        if (typeof onConfirm === 'function') {
            onConfirm(expression);
            if (typeof closeDialog === 'function') {
                closeDialog();
            }
        }
    }

    render() {
        const { classes, isOpen, closeDialog, title } = this.props;
        const { expression } = this.state;

        return (
            <Dialog
                classes={{ paper: classes.dialog }}
                open={isOpen}
                transitionDuration={0}
            >
                <DialogTitle className={classes.dialogTitle}>
                    {typeof title !== 'undefined' ? title : 'Expression Editor'}
                    <Divider />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <LxTextField value={expression} onChange={this.handleExpressionChange} />
                </DialogContent>
                <DialogActions className={classes.dialogButtons}>
                    <Button variant='contained' color='primary' onClick={closeDialog}>
                        {'Close'}
                    </Button>
                    <Button
                        onClick={this.handleConfirm(expression)}
                        variant='contained'
                        color='primary'
                    >
                        {'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ExpressionEditor);