import axios from 'axios';

import userManager from '.../utils/userManager';

export default class Request
{
    constructor(baseURL) {
        this.instance = axios.create({
            baseURL
        });
        this.cancelTokens = {};
    }

    getNewCancelToken(url) {
        if (typeof this.cancelTokens[url] !== 'undefined') {
            this.cancelTokens[url].cancel();
        }
        this.cancelTokens[url] = axios.CancelToken.source();
        return this.cancelTokens[url].token;
    }

    statusCodes = {
        200: (res) => res.data, //Success
        201: (res) => res.data, //Created
        204: (res) => null, //No content
        401: (res) => { throw new Error(res.data); }, //Unauthorized
        403: (res) => { throw new Error(res.data); }, //Forbidden
        404: (res) => { throw new Error(res.data); }, //Not found
        500: (res) => { throw new Error(res.data); } //Internal server error
    }

    handleStatus = (res) => {
        let nextFunc = this.statusCodes[res.status];
        if (typeof nextFunc !== 'undefined') {
            return nextFunc(res);
        } else {
            throw new Error(res.data);
        }
    }

    async apiRequest(url, method = 'GET', data, cancel = true, responseType = 'json') {
        return userManager.getUser().then(async (user) => {
            let cancelToken = cancel? this.getNewCancelToken(url) : undefined;
            url += `${url.indexOf('?') > 0 ? '&' : '?'}requestTime=${Date.now()}`;

            let accessToken = user.access_token;
            let headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            };

            let config = {
                url,
                method,
                headers,
                cancelToken,
                responseType
            };

            if (typeof data !== 'undefined') {
                config.data = data;
                config.headers['Content-Type'] = 'application/json';
            }

            return this.instance.request(config)
            .then((res) => {
                return this.handleStatus(res);
            });
        });
    }
}