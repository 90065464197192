import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    backLink: {
        marginLeft: '10px'
    }
});

const Error = (props) =>
{
    let onBackClick = (event) =>{
        event.preventDefault();
      props.history.goBack();
    };

    const parsed = queryString.parse(props.location.search.slice(1)); // TODO: Make sure slice 1 is safe for removing question mark
    return(
        <div>
            <div>
                <h1>Error: {parsed.error}</h1>
                <h2> {parsed.response} </h2>
                <p>Sorry, please try again and if the problem persists contact support.</p>
                <a className={props.classes.backLink} href="" onClick={(event) => onBackClick(event)}>Back</a>
            </div>
        </div>
    );
};

export default withStyles(styles)(withRouter(Error));