import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import getColor from '.../utils/getColor';

const styles = theme => ({
    listItem: {
        border: 'solid 2px white',
        color: theme.palette.primary.contrastText,
        lineHeight: 1
    },
    leftText: {
        marginRight: 'auto',
        marginLeft: theme.spacing(1),
        textAlign: 'left',
        color: 'inherit',
        fontSize: theme.typography.fontSize
    },
    rightText: {
        marginLeft: '8px',
        marginRight: theme.spacing(1),
        textAlign: 'right',
        color: 'inherit',
        fontSize: theme.typography.fontSize
    }
});

const CoefficientItem = (props) => {
    const { coefficient, nestedLevel, classes, isLoading, ...others } = props;

    return (
        <ListItem {...others} dense disableGutters className={classes.listItem} style={{backgroundColor: getColor(coefficient.value)}} >
            <div className={classes.leftText}>
                {coefficient.displayName}
            </div>
            <div className={classes.rightText}>
                {/* TODO
                    Find out whether the value should be truncated and if it should be rounded
                    Currently it rounds upward if large enough and it truncates to 4 decimal places
                 */}
                {isLoading ? '' : (coefficient.value).toFixed(4)}
            </div>
        </ListItem>
    );
};

export default withStyles(styles)(CoefficientItem);