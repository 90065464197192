import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

import { demographicsApi, pushToError } from '.../utils/apiHelper';
import PatientConflictResolver from './PatientConflictResolver';

const MATCH_OPTIONS = {
    path: '/patient/:mrn?'
};

const PatientContext = ({ children, location, patientInfo, clearPatientInfo, onPatientLoad }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [conflictingPatients, setConflictingPatients] = useState(null);
    const [processingMrn, setProcessingMrn] = useState(null);
    const getOneToOnePatient = useCallback((sourcePatientLists) => {
        let validPatient = false;
        let apolloPatient = {
            emrPatients: {}
        };

        let sources = Object.getOwnPropertyNames(sourcePatientLists);
        for (let i = 0; i < sources.length; i++) {
            let source = sources[i];
            if (Array.isArray(sourcePatientLists[source])) {
                if (sourcePatientLists[source].length !== 1) {
                    return null;
                } else if (source.toLowerCase() !== 'apollo') {
                    apolloPatient.emrPatients[source] = sourcePatientLists[source][0].uniqueSourceId;
                } else if (source.toLowerCase() === 'apollo') {
                    validPatient = true;
                    apolloPatient = {
                        ...apolloPatient,
                        ssPatientId: sourcePatientLists[source][0].uniqueSourceId,
                        firstName: sourcePatientLists[source][0].firstName,
                        lastName: sourcePatientLists[source][0].lastName,
                        middleName: sourcePatientLists[source][0].middleName,
                        patientId: sourcePatientLists[source][0].mrn,
                        dateOfBirth: sourcePatientLists[source][0].dateOfBirth
                    };
                }
            }
        }

        return validPatient ? apolloPatient : null;
    }, []);

    let contextMrn = patientInfo !== null ? patientInfo.patientId : null;
    let pathMatch = matchPath(location.pathname, MATCH_OPTIONS);
    let pathMrn = pathMatch !== null ? pathMatch.params.mrn : null;

    useEffect(() => {
        if (pathMrn === null) {
            clearPatientInfo();
            setConflictingPatients(null);
            setProcessingMrn(null);
            setIsLoading(false);
        } else if (pathMrn !== null && pathMrn !== processingMrn && pathMrn !== contextMrn) {
            setProcessingMrn(pathMrn);
            setIsLoading(true);
            clearPatientInfo();
            demographicsApi.getByMrn(pathMrn)
                .then((foundPatients) => {
                    let patient = getOneToOnePatient(foundPatients);

                    if (patient !== null) {
                        onPatientLoad(patient);
                        setProcessingMrn(null);
                        setConflictingPatients(null);
                        setIsLoading(false);
                    } else {
                        setConflictingPatients(foundPatients);
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    clearPatientInfo();
                    pushToError(error);
                    setProcessingMrn(null);
                    setIsLoading(false);
                });
        }
    }, [
        pathMrn,
        contextMrn,
        processingMrn,
        setProcessingMrn,
        setIsLoading,
        onPatientLoad,
        clearPatientInfo,
        setConflictingPatients,
        getOneToOnePatient
    ]);

    if (isLoading) {
        return <CircularProgress size={60} thickness={7} />;
    }

    if (conflictingPatients !== null) {
        return (
            <React.Fragment>
                <PatientConflictResolver
                    mrn={pathMrn}
                    conflictingPatients={conflictingPatients}
                    onPatientSubmit={(patientLists) => {
                        let patient = getOneToOnePatient(patientLists);
                        if (patient !== null) {
                            onPatientLoad(patient);
                            setConflictingPatients(null);
                            setProcessingMrn(null);
                        }
                    }}
                    onPatientCreation={(patient) => {
                        let newConflictingPatients = {};
                        Object.getOwnPropertyNames(conflictingPatients).forEach(source => {
                            newConflictingPatients[source] = conflictingPatients[source];
                            if (source.toLowerCase() === 'apollo') {
                                newConflictingPatients[source].push(patient);
                            }
                        });
                        setConflictingPatients(newConflictingPatients);
                    }}
                />
                {React.Children.only(children)}
            </React.Fragment>
        );
    }

    return (React.Children.only(children));
};

const mapStateToProps = (state) => ({
    patientInfo: state.demographics
});

const mapDispatchToProps = (dispatch) => ({
    onPatientLoad: (demographics) => dispatch({ type: 'SET_DEMOGRAPHICS', demographics }),
    clearPatientInfo: () => dispatch({ type: 'CLEAR_DEMOGRAPHICS' })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientContext));