import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import CompactTablePager from '.../components/features/widgets/CompactTablePager';
import ValidationErrorIcon from '.../components/formComponents/helperComponents/ValidationErrorIcon';
import CollapseWrapper from '../forms/FormSidePanel/CollapseWrapper';

const styles = theme => ({
    truncatedText: {
        width: '220px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '.75rem !important'
    },
    compactDescriptionCell: {
        padding: '0px !important'
    },
    iconCell: {
        padding: '0px',
        paddingLeft: '5px'
    },
    tableWrapper: {
        maxWidth: '100%'
    },
    buttonContainer: {
        marginBottom: '5px'
    },
    errorFilterButton: {
        borderColor: '#e2bdbd'
    },
    warningFilterButton: {
        borderColor: '#e2d9af'
    },
    infoFilterButton: {
        borderColor: '#bdd8e2'
    }
});

class ValidationWidget extends React.Component {
    state = {
        fullView: false,
        hideErrors: false,
        hideWarnings: true,
        hideInfo: true,
        page: 0,
        rowsPerPage: 10
    }

    componentDidUpdate = () => {
        this.props.onResize();
    }

    handleNavigateToValidation = (targetValidation) => () => {
        const { onNavigateToValidation, formData } = this.props;
        let targetTable = formData.tables[targetValidation.tableName];
        if (targetTable) {
            let targetFieldInfo = targetTable.includedFieldInfos[targetValidation.fieldName];
            if (targetFieldInfo) {
                if (Array.isArray(targetFieldInfo.formLocations) && targetFieldInfo.formLocations.length > 0) {
                    //Currently this always navigates to the first element listed in the collection, in the future we may want to add more intelligence or user input to this decision.
                    let targetFormLocation = targetFieldInfo.formLocations[0];
                    if (targetFormLocation) {
                        onNavigateToValidation(targetFormLocation.sectionName, targetFormLocation.navigationKey, targetValidation.tableName, targetValidation.recordKey);
                    }
                }
            }
        }
    }

    renderTableRow = (singleValidation) => {
        const { classes } = this.props;
        const { hideErrors, hideWarnings, hideInfo } = this.state;
        
        if ((singleValidation.severity === 1 && !hideErrors) || (singleValidation.severity === 2 && !hideWarnings) || (singleValidation.severity === 3 && !hideInfo)) {
            return (
                <Tooltip 
                    key={`${singleValidation.tableName}.${singleValidation.fieldName}:${singleValidation.key}`}
                    title={singleValidation.description}
                >
                    <TableRow
                        onClick={this.handleNavigateToValidation(singleValidation)}
                        hover
                        style={{ cursor: 'pointer' }}
                    >
                        <TableCell className={classes.iconCell}>
                            <ValidationErrorIcon
                                error
                                validationErrorSeverity={singleValidation.severity}
                                helperText={singleValidation.description}
                            />
                        </TableCell>
                        <TableCell className={classes.compactDescriptionCell}>
                            <Typography className={classes.truncatedText}>
                                {singleValidation.description}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Tooltip>
            );
        }
    }

    renderTableBody = () => {
        const { formData } = this.props;
        const { page, rowsPerPage, hideErrors, hideWarnings, hideInfo } =  this.state;
        if (formData && formData.validationList) {
            let validationSubPage = formData.validationList
                .filter((singleValidation) => (singleValidation.severity === 1 && !hideErrors) || (singleValidation.severity === 2 && !hideWarnings) || (singleValidation.severity === 3 && !hideInfo))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            return (
                <TableBody>
                    {validationSubPage.map((singleValidation) => this.renderTableRow(singleValidation))}
                </TableBody>
            );
        }
    }

    renderFilterButtons = (errorCount, warningCount, infoCount) => {
        const { formData, classes } = this.props;
        const { hideErrors, hideWarnings, hideInfo } = this.state;

        if (formData && formData.validationList) {
            return (
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.errorFilterButton}
                        variant={hideErrors || errorCount === 0 ? 'outlined' : 'contained'}
                        onClick={() => this.setState({ hideErrors: !hideErrors })}
                    >
                        <ValidationErrorIcon
                            error
                            helperText='Error'
                            validationErrorSeverity={1}
                        />
                        {`${errorCount}`}
                    </Button>
                    <Button
                        className={classes.warningFilterButton}
                        variant={hideWarnings || warningCount === 0 ? 'outlined' : 'contained'}
                        onClick={() => this.setState({ hideWarnings: !hideWarnings })}
                    >
                        <ValidationErrorIcon
                            error
                            helperText='Warning'
                            validationErrorSeverity={2}
                        />
                        {`${warningCount}`}
                    </Button>
                    <Button
                        className={classes.infoFilterButton}
                        variant={hideInfo || infoCount === 0 ? 'outlined' : 'contained'}
                        onClick={() => this.setState({ hideInfo: !hideInfo })}
                    >
                        <ValidationErrorIcon
                            error
                            helperText='Information'
                            validationErrorSeverity={3}
                        />
                        {`${infoCount}`}
                    </Button>
                </div>
            );
        }
    }

    render() {
        const { formData } = this.props;
        const { page, rowsPerPage, hideErrors, hideWarnings, hideInfo } = this.state;

        if (formData && formData.validationList) {
            let errorCount = 0;
            let warningCount = 0;
            let infoCount = 0;
            formData.validationList.forEach((singleValidation) => {
                switch(singleValidation.severity) {
                    case 1:
                        errorCount++;
                        break;
                    case 2:
                        warningCount++;
                        break;
                    case 3:
                        infoCount++;
                        break;
                    default:
                }
            });
            let totalAfterFilter = 0;
            totalAfterFilter = hideErrors ? totalAfterFilter : totalAfterFilter + errorCount;
            totalAfterFilter = hideWarnings ? totalAfterFilter : totalAfterFilter + warningCount;
            totalAfterFilter = hideInfo ? totalAfterFilter : totalAfterFilter + infoCount;

            return (
                <div>
                    {this.renderFilterButtons(errorCount, warningCount, infoCount)}
                    <Table size={'small'}>
                        {this.renderTableBody()}
                    </Table>
                    <CompactTablePager
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalAfterFilter}
                        onChangePage={(newPage) => this.setState({page: newPage})}
                        onChangeRowsPerPage={(newRowsPerPage) => this.setState({rowsPerPage: newRowsPerPage})}
                    />
                </div>
            );
        } else {
            return (
                <React.Fragment/>
            );
        }
    }
}

export default withStyles(styles)(CollapseWrapper(ValidationWidget));