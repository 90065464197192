import withStyles from '@material-ui/styles/withStyles';
import React from 'react';

const styles = theme => ({
    card: {
        width: '80vw',
        height: '115vh'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        display: 'flex'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class ReportViewer extends React.Component {

    render() {
        const { selectedReportID, base64STR } = this.props;
        return (
            <>
                <p>Report: {selectedReportID}.pdf</p>
                <embed src={`data:application/pdf;base64,${base64STR}#toolbar=0`} width="800px" height="500px" />
            </>
        );
    }
}
export default withStyles(styles)(ReportViewer);