import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

import Unauthorized from '.../components/main/Unauthorized';
import userManager from '.../utils/userManager';

const stringToFeatureAndAccessLevel = (authorizationString) => {
    let [feature, accessLevel] = authorizationString.split(':');
    if (typeof accessLevel === 'string') accessLevel = accessLevel.split(',');
    return {
        feature,
        accessLevel
    };
};

const featureAndAccessLevelToString = (featureAndAccessLevel) => {
    let { feature, accessLevel } = featureAndAccessLevel;
    if (Array.isArray(accessLevel)) {
        return `${feature}:${accessLevel.join(',')}`;
    } else if (typeof accessLevel === 'string') {
        return `${feature}:${accessLevel}`;
    } else {
        return feature;
    }
};

const checkAccess = (allowed, callback) => {
    let allowedLevels = [];
    let callbackCalled = false;

    if (typeof allowed === 'string') {
        allowedLevels.push(stringToFeatureAndAccessLevel(allowed));
    } else if (Array.isArray(allowed)) {
        allowedLevels = allowed.map(level => typeof level === 'string' ? stringToFeatureAndAccessLevel(level) : level);
    }

    if (allowedLevels.length === 0) {
        console.warn('Authorization Filter did not recieve any allowed features, component will not render.');
        callback(false);
        return;
    }

    userManager.getUser().then(user => {
        if (typeof user.profile.authorizationlevel === 'string') {
            user.profile.authorizationlevel = [user.profile.authorizationlevel];
        }

        user.profile.authorizationlevel.forEach(claimAuthLevel => {

            claimAuthLevel = stringToFeatureAndAccessLevel(claimAuthLevel);

            allowedLevels.forEach(allowedLevel => {
                let claimFeature = claimAuthLevel.feature.toLowerCase();
                if (claimFeature === allowedLevel.feature.toLowerCase() || claimFeature === 'allfeatures') {

                    if (Array.isArray(allowedLevel.accessLevel) && allowedLevel.accessLevel.length > 0) {

                        if (Array.isArray(claimAuthLevel.accessLevel) && claimAuthLevel.accessLevel.length > 0) {
                            claimAuthLevel.accessLevel.forEach(claimAccessLevel => {
                                if (claimAccessLevel.toLowerCase() === 'administrator') {
                                    callback(true);
                                    callbackCalled = true;
                                } else if(claimFeature !== 'allfeatures') {
                                    allowedLevel.accessLevel.forEach(levelAllowed => {
                                        if (claimAccessLevel.toLowerCase() === levelAllowed.toLowerCase()) {
                                            callback(true);
                                            callbackCalled = true;
                                        }
                                    });
                                }
                            });
                        } else {
                            console.warn(`Authorization filter skipping feature: "${claimFeature}" since it's missing access levels.`);
                        }
                    } else {
                        console.warn(`Authorization filter skipping required feature: "${allowedLevel.feature}" since it wasn't provided access levels.`);
                    }
                }
            });
        });

        if (!callbackCalled) {
            callback(false);
        }
    });
};

class AuthorizationFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allowedAccess: false,
            isLoading: true
        };
    }

    componentDidUpdate(prevProps) {
        const { allowed } = this.props;
        if (JSON.stringify(prevProps.allowed) !== JSON.stringify(allowed)) {
            this.updateAccess();
        }
    }

    componentDidMount() {
        userManager.events.addUserSessionChanged(this.updateAccess);
        this.updateAccess();
    }

    componentWillUnmount() {
        userManager.events.removeUserSessionChanged(this.updateAccess);
    }

    updateAccess = () => checkAccess(this.props.allowed, (allowedAccess) => this.setState({ allowedAccess, isLoading: false }))

    render() {
        const { allowedAccess, isLoading } = this.state;
        const { allowed, showUnauthorized } = this.props;

        if (isLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }

        if (allowedAccess) {
            return this.props.children;
        } else if (typeof showUnauthorized !== 'undefined') {
            let features;

            if (typeof allowed === 'string') {
                features = [featureAndAccessLevelToString(stringToFeatureAndAccessLevel(allowed))];
            } else if (Array.isArray(allowed)) {
                features = allowed
                    .map(level => typeof level === 'string' ? stringToFeatureAndAccessLevel(level) : level)
                    .map(featureAndAccessLevelToString);
            }

            return <Unauthorized features={features} />;
        } else {
            return null;
        }
    }
}

export default AuthorizationFilter;

export const CheckAuthorization = (allowed) => new Promise((resolve) => checkAccess(allowed, resolve));