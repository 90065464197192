// ##############################
// // // RegularForms view styles
// #############################

import { cardTitle } from "../../material-dashboard-pro-react";
import customCheckboxRadioSwitch from "../customCheckboxRadioSwitch";

const regularFormsStyle = {
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        paddingRight: "65px"
    },
    staticFormGroup: {
        marginLeft: "0",
        marginRight: "0",
        paddingBottom: "10px",
        margin: "8px 0 0 0",
        position: "relative",
        "&:before,&:after": {
            display: "table",
            content: '" "'
        },
        "&:after": {
            clear: "both"
        }
    },
    staticFormControl: {
        marginBottom: "0",
        paddingTop: "8px",
        paddingBottom: "8px",
        minHeight: "34px"
    }
};

export default regularFormsStyle;
