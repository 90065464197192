import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
    dialogContent: {
        textAlign: 'center'
    },
    dialogActions: {
        justifyContent: 'center'
    },
    requestStatus: {
        height: 'inherit',
        display: 'table'
    },
    requestIcon: {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: '60px',
        overflow: 'hidden'
    },
    dialogText: {
        marginBottom: '0px'
    },
    errorColor: {
        color: `${theme.palette.error.main} !important`,
        borderColor: `${theme.palette.error.main} !important`
    }
});

class ConfirmationButton extends React.Component {
    state = {
        dialogOpen: false,
        dialogCallback: null,
        dialogIsLoading: false,
        dialogIsDone: false,
        dialogIsError: false
    }

    closeDialog = () => {
        this.setState({
            dialogOpen: false,
            dialogCallback: null,
            dialogIsLoading: false,
            dialogIsDone: false,
            dialogIsError: false
        });
    }

    closeDialogWithStatus = (callback) => () => {
        if (typeof callback === 'function') {
            this.setState({
                dialogIsLoading: true
            });

            Promise.resolve(callback()).then(() => {
                this.setState({
                    dialogIsDone: true
                });
            }).catch(() => {
                this.setState({
                    dialogIsError: true
                });
            }).finally(() => {
                setTimeout(this.closeDialog, 1000);
            });
        }
    }

    closeDialogWithCallback = (callback) => (event) => {
        this.closeDialog();
        if (typeof callback === 'function') {
            callback(event);
        }
    }

    openDialog = (callback, showCallbackStatus) => (event) => {
        event.preventDefault();

        let dialogCallback;

        if (showCallbackStatus) {
            dialogCallback = this.closeDialogWithStatus(callback);
        } else {
            dialogCallback = this.closeDialogWithCallback(callback);
        }

        this.setState({
            dialogOpen: true,
            dialogCallback
        });
    }

    render() {
        const { dialogOpen, dialogCallback, dialogIsLoading, dialogIsDone, dialogIsError } = this.state;
        const {
            className,
            classes,
            error,
            disabled,
            onClick,
            showConfirmation,
            confirmationTitle,
            confirmationText,
            confirmationStatus,
            confirmationConfirmText,
            ...otherProps
        } = this.props;

        const { children, variant, color, ...dialogProps } = otherProps;
        const { dialogContent, dialogActions, requestStatus, requestIcon, dialogText, errorColor, ...otherClasses } = classes;

        const buttonClass = classnames({
            [className]: true,
            [errorColor]: error
        });

        return (
            <React.Fragment>
                <Button
                    {...otherProps}
                    disabled={disabled}
                    className={buttonClass}
                    classes={otherClasses}
                    onClick={showConfirmation ?
                        this.openDialog(onClick, confirmationStatus)
                        :
                        onClick
                    }
                />
                <Dialog
                    onClick={event => event.preventDefault()}
                    open={dialogOpen}
                    transitionDuration={0}
                >
                    {dialogIsLoading ?
                        <DialogContent className={dialogContent}>
                            <div className={requestStatus}>
                                <div className={requestIcon}>
                                    {dialogIsDone ?
                                        <DoneIcon color='primary' fontSize='inherit' />
                                        :
                                        dialogIsError ?
                                            <ErrorIcon color='error' fontSize='inherit' />
                                            :
                                            <CircularProgress size={60} thickness={7} />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        :
                        <React.Fragment>
                            <DialogTitle>{confirmationTitle}</DialogTitle>
                            <DialogContent className={dialogContent}>
                                {typeof confirmationText !== 'undefined' && Array.isArray(confirmationText) ?
                                    confirmationText.map((text, index, array) => (
                                        <DialogContentText key={text} className={index + 1 < array.length ? dialogText : undefined}>
                                            {text}
                                        </DialogContentText>
                                    ))
                                    :
                                    <DialogContentText>
                                        {confirmationText}
                                    </DialogContentText>
                                }
                            </DialogContent>
                            <DialogActions className={dialogActions}>
                                <Button variant='contained' color='primary' onClick={this.closeDialog}>
                                    {"Close"}
                                </Button>
                                <Button
                                    {...dialogProps}
                                    onClick={dialogCallback}
                                    variant='contained'
                                    color='primary'
                                >
                                    {confirmationConfirmText ?
                                        confirmationConfirmText
                                        :
                                        React.Children.toArray(children)
                                    }
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}

ConfirmationButton.propTypes = {
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    showConfirmation: PropTypes.bool,
    confirmationTitle: PropTypes.string,
    confirmationText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    confirmationStatus: PropTypes.bool,
    confirmationConfirmText: PropTypes.node
};

ConfirmationButton.defaultProps = {
    error: false,
    disabled: false,
    showConfirmation: false,
    confirmationTitle: '"confirmationTitle" goes here',
    confirmationText: '"confirmationText" goes here',
    confirmationStatus: false,
    confirmationConfirmText: undefined
};

export default withStyles(styles)(ConfirmationButton);