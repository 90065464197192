import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCheckbox from ".../components/formComponents/LxCheckbox";
import LxCombobox from '.../components/formComponents/LxCombobox';
import LxIntField from '.../components/formComponents/LxIntField';
import LxRadioGroup from '.../components/formComponents/LxRadioGroup';
import { chestPainEvalApi, pushToError } from '.../utils/apiHelper';

const styles = theme => ({
    navPills: {
        marginTop: '10px'
    },
    navPillsWrapper: {
        marginTop: '10px'
    },
    cardBodyBordered: {
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRight: '1px solid lightGray'
    },
    cardBody: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    combobox: {
        height: '50px'
    },
    sectionDivider: {
        margin: `0px ${theme.spacing(2)}px 10px`
    },
    cardDivider: {
        margin: `${theme.spacing(1)}px 0px`
    },
    dividerLineTitle: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    dividerTitle: {
        flex: '0 0 auto',
        marginRight: `${theme.spacing(2)}px`
    },
    dividerLine: {
        flex: '1 0 auto'
    },
    gridContainer: {
        textAlign: 'left'
    },
    selectableItem: {

    },
    checkBoxContainer: {
        marginTop: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(1)}px`,
        paddingLeft: '15px'
    },
    checkBox: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    },
    printingDialog: {
        width: '30vh',
        height: '30vh',
        overflow: 'visible'
    },
    printingStatus: {
        height: 'inherit',
        display: 'table',
        width: '100%'
    },
    printingLoadingIcon: {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: '60px'
    },
    troponinGrid: {
        paddingBottom: '5px!important'
    },
    timeGrid: {
        paddingTop: '0px!important'
    },
    abnormal: {
        backgroundColor: 'rgb(209, 51, 43)'
    },
    indeterminate: {
        backgroundColor: 'rgb(243, 190, 85)'
    },
    ruledOut: {
        backgroundColor: 'rgb(146, 208, 80)'
    },
    noAcuteInjury: {
        backgroundColor: 'rgb(214, 214, 214)'
    },
    dot: {
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        display: 'inline-block',
        margin: 'auto'
    }
});

class ChestPainForm extends React.Component {
    constructor(props) {
        super(props);

        this.tabs = [
            { id: 0, name: '0 Hour', dataKey: 'initialHourEval', validationKey: 'initialHourValidation' },
            { id: 1, name: '1 Hour', dataKey: 'oneHourEval', validationKey: 'oneHourValidation' },
            { id: 2, name: '3 Hour', dataKey: 'threeHourEval', validationKey: 'threeHourValidation' }
        ];

        this.state = {
            isLoading: true,
            printingLoading: false,
            copiedSnackbarOpen: false,
            savedSnackbarOpen: false,
            clipboardValue: ''
        };
    }

    componentDidMount() {
        chestPainEvalApi.getChestPainLabResults(this.props.patientInfo.ssPatientId)
            .then(labResult => {
                this.setState({
                    labResult,
                    isLoading: false
                });
            }).catch(error => pushToError(error, this.props.patientInfo.patientId));
    }

    toggleCopiedSnackbar = (val) => {
        this.setState({
            copiedSnackbarOpen: val
        });
    };

    toggleSavedSnackbar = (val) => {
        this.setState({
            savedSnackbarOpen: val
        });
    };

    constructLowerObjectLayer = (original = {}, keyString, value) => {
        const keyArray = keyString.split('.');
        const key = keyArray.shift();
        return ({
            ...original,
            [key]: keyArray.length > 0 ? this.constructLowerObjectLayer(original[key], keyArray.join('.'), value) : value
        });
    }


    getUpdatedHourFormData = (formData, key, field, value) => {
        //TODO when lab is selected and saved, test date and time upodated on next hour
        const existingHourData = formData[key];
        const newFormData = {
            ...formData,
            [key]: {
                ...existingHourData,
                ...(this.constructLowerObjectLayer(existingHourData, field, value))
            }
        };

        return newFormData;
    }

    updateSharedValue = (field, value, getResult = true) => {
        const { formData } = this.props;
        const newFormData = this.constructLowerObjectLayer(formData, field, value);

        this.props.updateFormData(newFormData);
        if (getResult) this.props.getResult(newFormData);
    }

    updateTroponinLabValue = (riskTab) => (labResult) => {
        const { formData, updateFormData, getResult } = this.props;
        const { testResult, testDate, testTime } = labResult ?? {};

        let newFormData = this.getUpdatedHourFormData(formData, riskTab.dataKey, 'troponin', testResult);
        newFormData = this.getUpdatedHourFormData(newFormData, riskTab.dataKey, 'testDate', testDate);
        newFormData = this.getUpdatedHourFormData(newFormData, riskTab.dataKey, 'testTime', testTime);

        updateFormData(newFormData);
        getResult(newFormData);
    }

    updateTroponinHandEntry = (riskTab) => (troponinValue) => {
        const { formData, updateFormData, getResult } = this.props;

        let newFormData = this.getUpdatedHourFormData(formData, riskTab.dataKey, 'troponin', troponinValue);
        newFormData = this.getUpdatedHourFormData(newFormData, riskTab.dataKey, 'testDate', null);
        newFormData = this.getUpdatedHourFormData(newFormData, riskTab.dataKey, 'testTime', null);

        updateFormData(newFormData);
        getResult(newFormData);
    }

    updateEcgValue = (riskTab) => (ecgValue) => {
        const { formData, updateFormData, getResult } = this.props;

        let newFormData = this.getUpdatedHourFormData(formData, riskTab.dataKey, 'ecg', ecgValue);

        updateFormData(newFormData);
        getResult(newFormData);
    }

    itemToValue = item => {
        if (item === null || typeof item === 'undefined') {
            return '';
        } else if (typeof item === 'string') {
            return item;
        } else {
            return `${item.testResult}`;
        }
    }

    getDotColor = (interpretation, classes) => {
        switch (interpretation.toLowerCase()) {
            case 'abnormal':
            case 'error':
                return classes.abnormal;
            case 'indeterminate':
                return classes.indeterminate;
            case 'ruled out':
                return classes.ruledOut;
            default:
                return classes.noAcuteInjury;
        }
    };

    acceptChanges = () => {
        const { formData, saveToDatabase } = this.props;
        saveToDatabase(formData);
        this.toggleSavedSnackbar(true);
    };

    copyResultToClipboard = () => {
        const { formData, results, resultKey, convertToDisplayDate, convertToDisplayTime } = this.props;

        let content = `
Results---------
hsTn:           ${results[resultKey].interpretation.toLowerCase() === 'error' ? 'Incomplete' : results[resultKey].interpretation}
Interpretation: ${results[resultKey].interpretationMessage === null ? '' : results[resultKey].interpretationMessage}
Recommendation: ${results[resultKey].interpretation.toLowerCase() === 'error' ? 'Incomplete' : results[resultKey].recommendation}

HEART Score:    ${results.heartScore.heartScore === -1 ? '' : results.heartScore.heartScoreInterpretation} ${results.heartScore.heartScore === -1 ? '' : `${'('+results.heartScore.heartScore+')'}`}
Age:            ${results.heartScore.ageScore === -1 ? '' : results.heartScore.ageScore}
Symptoms:       ${results.heartScore.symptomsScore === -1 ? '' : results.heartScore.symptomsScore}
ECG:            ${results.heartScore.ecgScore === -1 ? '' : results.heartScore.ecgScore}
Risk Factors:   ${results.heartScore.riskFactorsScore === -1 ? '' : results.heartScore.riskFactorsScore}
Troponin:       ${results.heartScore.troponinScore === -1 ? '' : results.heartScore.troponinScore}

-------Legend-------       
Low Risk:       0-3 Points
Med Risk:       4-6 Points
High Risk:      7-10 Points

Initial Hour-------
            ${results.initialHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.initialHourResult.interpretation}
Troponin:   ${results.initialHourResult.troponinValue === null ? '' : results.initialHourResult.troponinValue}
Lab Date:   ${convertToDisplayDate(results.initialHourResult.testDate)}
Lab Time:   ${convertToDisplayTime(results.initialHourResult.testTime)}
Delta:      N/A
ECG:        ${formData.initialHourEval.ecg === null ? '' : formData.initialHourEval.ecg}

One Hour-------
            ${results.oneHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.oneHourResult.interpretation}
Troponin:   ${results.oneHourResult.troponinValue === null ? '' : results.oneHourResult.troponinValue}
Lab Date:   ${convertToDisplayDate(results.oneHourResult.testDate)}
Lab Time:   ${convertToDisplayTime(results.oneHourResult.testTime)}
Delta:      ${results.oneHourResult.troponinValue ? results.oneHourResult.troponinValue - (results.initialHourResult.troponinValue === '<6' ? 5 : results.initialHourResult.troponinValue) : 'N/A'}
ECG:        ${formData.oneHourEval.ecg === null ? '' : formData.oneHourEval.ecg}

Three Hour-------
            ${results.threeHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.threeHourResult.interpretation}
Troponin:   ${results.threeHourResult.troponinValue === null ? '' : results.threeHourResult.troponinValue}
Lab Date:   ${convertToDisplayDate(results.threeHourResult.testDate)}
Lab Time:   ${convertToDisplayTime(results.threeHourResult.testTime)}
Delta:      ${results.threeHourResult.troponinValue ? results.threeHourResult.troponinValue - (results.initialHourResult.troponinValue === '<6' ? 5 : results.initialHourResult.troponinValue) : 'N/A'}
ECG:        ${formData.threeHourEval.ecg === null ? '' : formData.threeHourEval.ecg}
        `;

        const area = document.querySelector('#clipboard-area');
        area.textContent = content;
        area.select();
        document.execCommand('copy');

        this.toggleCopiedSnackbar(true);
    };

    renderResults = riskTab => {
        const { classes, results } = this.props;

        switch (riskTab.id) {
            case 0:
                return this.renderInitialInterpretation(results, classes);
            case 1:
                return this.renderOneHourInterpretation(results, classes);
            case 2:
                return this.renderThreeHourInterpretation(results, classes);
            default:
                return <React.Fragment />;
        }
    };

    renderInitialInterpretation = (results, classes) => {
        return <GridContainer>
            <GridItem xs={7}>
                <Typography align="left" color="inherit" variant="body2">
                    Interpretation: <div className={`${classes.dot} ${this.getDotColor(results.initialHourResult.interpretation.toLowerCase(), classes)}`} />
                </Typography>
            </GridItem>
            <GridItem xs={5}>
                <Typography align="right" color="inherit" variant="body2">
                    {results.initialHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.initialHourResult.interpretation}
                </Typography>
            </GridItem>
        </GridContainer>;
    };
    renderOneHourInterpretation = (results, classes) => {
        return ((results.oneHourResult.interpretation.toLowerCase() !== 'error') || (results.initialHourResult.interpretation.toLowerCase() === 'indeterminate'))
            ?
            <GridContainer>
                <GridItem xs={7}>
                    <Typography align="left" color="inherit" variant="body2">
                        Interpretation: <div className={`${classes.dot} ${this.getDotColor(results.oneHourResult.interpretation.toLowerCase(), classes)}`} />
                    </Typography>
                </GridItem>
                <GridItem xs={5}>
                    <Typography align="right" color="inherit" variant="body2">
                        {results.oneHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.oneHourResult.interpretation}
                    </Typography>
                </GridItem>
            </GridContainer>
            : <React.Fragment />;
    };

    renderThreeHourInterpretation = (results, classes) => {
        return ((results.threeHourResult.interpretation.toLowerCase() !== 'error') || (results.oneHourResult.interpretation.toLowerCase() === 'indeterminate'))
            ?
            <GridContainer>
                <GridItem xs={7}>
                    <Typography align="left" color="inherit" variant="body2">
                        Interpretation: <div className={`${classes.dot} ${this.getDotColor(results.threeHourResult.interpretation.toLowerCase(), classes)}`} />
                    </Typography>
                </GridItem>
                <GridItem xs={5}>
                    <Typography align="right" color="inherit" variant="body2">
                        {results.threeHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.threeHourResult.interpretation}
                    </Typography>
                </GridItem>
            </GridContainer>
            : <React.Fragment />;
    };

    renderDelta = riskTab => {
        const { formData } = this.props;

        const initialValue = formData[this.tabs[0].dataKey].troponin === '<6' ? 5 : formData[this.tabs[0].dataKey].troponin;
        let delta = riskTab.id > 0 && formData[riskTab.dataKey].troponin ? formData[riskTab.dataKey].troponin - initialValue : 'N/A';
        return (
            <GridContainer>
                <GridItem xs={6}>
                    <Typography align='left' color='inherit' variant='body2'>
                        {`Delta:`}
                    </Typography>
                </GridItem>
                <GridItem xs={6}>
                    <Typography align='right' color='inherit' variant='body2'>
                        {`${delta}`}
                    </Typography>
                </GridItem>
            </GridContainer>

        );
    };

    isMatchingLabValue = (labVal, hourEval) =>
        hourEval.testDate === labVal.testDate
        && hourEval.testTime === labVal.testTime
        && hourEval.troponin === labVal.testResult;

    isLaterThanInitialLabValue = (labVal) => {
        const { formData } = this.props;

        var date1 = '';
        if (labVal.testDate != null && labVal.testTime != null) {
            const [dt1, tm1] = labVal.testDate.split('T');
            const [dt2, tm2] = labVal.testTime.split('T');
            date1 = `${dt1}T${tm2}`;
        }
        else {
            date1 = null;
        }

        var date2 = '';
        if (formData.initialHourEval.testDate != null && formData.initialHourEval.testTime != null) {
            const [testdt1, testtm1] = formData.initialHourEval.testDate.split('T');
            const [testdt2, testtm2] = formData.initialHourEval.testTime.split('T');
            date2 = `${testdt1}T${testtm2}`;

            if (formData.oneHourEval.testDate != null && formData.oneHourEval.testTime != null) {
                const [oneHourtestdt1, oneHourtesttm1] = formData.oneHourEval.testDate.split('T');
                const [oneHourtestdt2, oneHourtesttm2] = formData.oneHourEval.testTime.split('T');
                date2 = `${oneHourtestdt1}T${oneHourtesttm2}`;
            }

        } else {
            date2 = null;
        }

        return !date1
            || !date2
            || new Date(date1) >= new Date(date2);
    };

    labResultsWithCleanDateTimes = () => {
        const { formData, convertToDisplayDate, convertToDisplayTime } = this.props;
        const { labResult } = this.state;

        return labResult.filter(labVal => {
            if ((!this.isMatchingLabValue(labVal, formData.initialHourEval) && !this.isMatchingLabValue(labVal, formData.oneHourEval) && !this.isMatchingLabValue(labVal, formData.threeHourEval))
                && this.isLaterThanInitialLabValue(labVal)) {
                return labVal;
            }
        }).map(labVal => {

            let result = { ...labVal };
            result.displayTestDate = convertToDisplayDate(result.testDate);
            result.displayTestTime = convertToDisplayTime(result.testTime);
            return result;
        });
    }

    renderCard = riskTab => {
        const { classes, formData, validation, convertToDisplayDate, convertToDisplayTime } = this.props;

        const form = formData[riskTab.dataKey];
        const validationObject = validation[riskTab.validationKey];

        return (
            <React.Fragment>
                <div className={classes.dividerLineTitle} >
                    <Typography align='left' color='inherit' variant='subtitle1' className={classes.dividerTitle} >
                        {`${riskTab.name}`}
                    </Typography>
                    <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
                </div>
                <CardBody className={riskTab.id <= 1 ? classes.cardBodyBordered : classes.cardBody}>
                    <GridContainer spacing={4} className={classes.gridContainer}>
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem style={{ margin: 'auto' }} xs={6} >
                                    <Typography align='left' color='inherit' variant='body2'>
                                        {'Troponin (hsTn):'}
                                    </Typography>
                                </GridItem>
                                <GridItem xs={6} >
                                    <LxCombobox
                                        items={this.labResultsWithCleanDateTimes()}
                                        itemToString={this.itemToValue}
                                        inputDisplayTemplate={this.itemToValue}
                                        inputValue={form.troponin === null ? '' : form.troponin}
                                        onInputValueChange={this.updateTroponinHandEntry(riskTab)}
                                        onChange={this.updateTroponinLabValue(riskTab)}
                                        columns={[
                                            { title: 'Date', key: 'displayTestDate' },
                                            { title: 'Time', key: 'displayTestTime' },
                                            { title: 'Test', key: 'test' },
                                            { title: 'Result', key: 'testResult' },
                                            { title: 'Unit(s)', key: 'resultUnits' }
                                        ]}
                                        searchKeys={['testDate', 'testTime', 'test', 'testResult', 'resultUnits']}
                                        //inital shows error if validation object error, 1h and 3h show error if validation object error AND initial troponin
                                        error={riskTab.id === 0 ? validationObject.troponin !== '' : formData.initialHourEval.troponin && validationObject.troponin !== ''}
                                        helperText={validationObject.troponin}
                                        validationErrorSeverity={validationObject.troponin ? 2 : 0}
                                        //1h and 3h are disabled if no initial troponin. initial never disabled
                                        disabled={riskTab.id !== 0 && !formData.initialHourEval.troponin}
                                        placement={'bottom-start'}
                                        keepInputOnExit
                                        fitTableWidth
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} >
                            <GridContainer>
                                <GridItem xs={5}>
                                    <Typography align='left' color='inherit' variant='body2'>
                                        {`Test Date:`}
                                    </Typography>
                                </GridItem>
                                <GridItem xs={7}>
                                    <Typography align='right' color='inherit' variant='body2'>
                                        {`${convertToDisplayDate(form?.testDate)}`}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={5}>
                                    <Typography align='left' color='inherit' variant='body2'>
                                        {`Test Time:`}
                                    </Typography>
                                </GridItem>
                                <GridItem xs={7}>
                                    <Typography align='right' color='inherit' variant='body2'>
                                        {`${convertToDisplayTime(form?.testTime)}`}
                                    </Typography>
                                </GridItem>
                            </GridContainer>

                            {this.renderDelta(riskTab)}
                            {this.renderResults(riskTab)}
                        </GridItem>
                        <GridItem xs={12} >
                            <LxRadioGroup
                                title={riskTab.id === 0 ? 'Suspicion that ECG is c/w coronary ischemia' : 'ECG'}
                                titleTooltip={'Suspicion that ECG is c/w coronary ischemia'}
                                descriptions={riskTab.id === 0 ? [
                                    { category: "High", description: "Significant ST depression or elevation without BBB, LVH, digoxin, etc.", sequence: 0 },
                                    { category: "Moderate", description: "Non-specific repol abnormality, BBB, or LVH without ischemic ST changes", sequence: 1 },
                                    { category: "Low", description: "Normal ECG without ST changes", sequence: 2 }
                                ] : null}
                                //1h and 3h are disabled if no initial troponin. initial never disabled
                                disabled={riskTab.id !== 0 && !formData.initialHourEval.troponin}
                                error={riskTab.id === 0 ? validationObject.ecg !== '' : formData.initialHourEval.troponin && validationObject.ecg !== ''}
                                helperText={validationObject.ecg}
                                validationErrorSeverity={validationObject.ecg ? 2 : 0}
                                label={validationObject.ecg}
                                value={form.ecg}
                                row={false}
                                onChange={this.updateEcgValue(riskTab)}
                                allowDeselect
                                options={[
                                    {
                                        label: 'High',
                                        value: 'high'
                                    },
                                    {
                                        label: 'Moderate',
                                        value: 'moderate'
                                    },
                                    {
                                        label: 'Low',
                                        value: 'low'
                                    }
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </React.Fragment>);
    };

    render() {
        const { className, classes, formData, triggerEventClear, validation } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }

        return (
            <React.Fragment>
                <Card className={`${className}`}>
                    <GridContainer >
                        <GridItem md={12} lg={4}>
                            {this.renderCard(this.tabs[0])}
                        </GridItem>
                        <GridItem md={12} lg={4}>
                            {this.renderCard(this.tabs[1])}
                        </GridItem>
                        <GridItem md={12} lg={4}>
                            {this.renderCard(this.tabs[2])}
                        </GridItem>
                    </GridContainer>
                    <div className={classes.dividerLineTitle} >
                        <Typography align='left' color='inherit' variant='body2' className={classes.dividerTitle} >
                            {`History`}
                        </Typography>
                        <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
                    </div>
                    <CardBody className={classes.cardBody}>
                        <GridContainer spacing={8} className={classes.gridContainer}>
                            <GridItem sm={12} md={2} >
                                <LxIntField
                                    title='Age'
                                    error={validation.age !== ''}
                                    helperText={validation.age}
                                    validationErrorSeverity={validation.age ? 2 : 0}
                                    min={0}
                                    value={formData.age === null ? '' : formData.age}
                                    onChange={(value) => this.updateSharedValue('age', value, false)}
                                    onBlur={(value) => this.updateSharedValue('age', value)}
                                />
                            </GridItem>
                            <GridItem sm={10} md={4} >
                                <LxRadioGroup
                                    title='Symptom Onset'
                                    titleTooltip='Symptom Onset'
                                    error={validation.symptomOnsetLessThan3hours !== ''}
                                    helperText={validation.symptomOnsetLessThan3hours}
                                    validationErrorSeverity={validation.symptomOnsetLessThan3hours ? 2 : 0}
                                    label={validation.symptomOnsetLessThan3hours}
                                    value={formData.symptomOnsetLessThan3hours === null ? null : formData.symptomOnsetLessThan3hours.toString()}
                                    row={false}
                                    onChange={(value) => this.updateSharedValue('symptomOnsetLessThan3hours', value === 'true')}
                                    allowDeselect
                                    options={[
                                        {
                                            label: '<3 hours before ED arrival',
                                            value: 'true'
                                        },
                                        {
                                            label: '≥3 hours before ED arrival',
                                            value: 'false'
                                        }
                                    ]}
                                />
                            </GridItem>
                            <GridItem sm={12} md={6}>
                                <LxRadioGroup
                                    title='Suspicion that sxs are due to coronary ischemia'
                                    titleTooltip='Suspicion that sxs are due to coronary ischemia'
                                    descriptions={[
                                        { category: "High", description: "Specific signs/sxs of coronary ischemia", sequence: 0 },
                                        { category: "Moderate", description: "History with both specific and nonspecific elements", sequence: 1 },
                                        { category: "Low", description: "Absence of signs/sxs coronary ischemia", sequence: 2 }
                                    ]}
                                    error={validation.sxsHistory !== ''}
                                    helperText={validation.sxsHistory}
                                    validationErrorSeverity={validation.sxsHistory ? 2 : 0}
                                    label={validation.sxsHistory}
                                    value={formData.sxsHistory}
                                    row={false}
                                    onChange={(value) => this.updateSharedValue('sxsHistory', value)}
                                    allowDeselect
                                    options={[
                                        {
                                            label: 'High',
                                            value: 'highly'
                                        },
                                        {
                                            label: 'Moderate',
                                            value: 'moderately'
                                        },
                                        {
                                            label: 'Low',
                                            value: 'slightly'
                                        }
                                    ]}
                                />
                            </GridItem>
                            <GridItem xs={8} >
                                <Typography align='left' color='inherit' variant='body2'>
                                    {'Risk Factors'}
                                </Typography>
                                <GridContainer justify='flex-start' className={classes.checkBoxContainer}>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Atherosclerosis'}
                                            titleTooltip={'Atherosclerosis'}
                                            description={'Atherosclerosis'}
                                            value={formData.cadRiskFactors.atherosclerosis === null ? false : formData.cadRiskFactors.atherosclerosis}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.atherosclerosis', !formData.cadRiskFactors.atherosclerosis)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Diabetes'}
                                            titleTooltip={'Diabetes'}
                                            description={'Diabetes'}
                                            value={formData.cadRiskFactors.diabetes === null ? false : formData.cadRiskFactors.diabetes}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.diabetes', !formData.cadRiskFactors.diabetes)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Family history of premature CAD'}
                                            titleTooltip={'Family history of premature CAD'}
                                            description={'Family history of premature CAD'}
                                            value={formData.cadRiskFactors.familyHistoryOfPrematureCad === null ? false : formData.cadRiskFactors.familyHistoryOfPrematureCad}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.familyHistoryOfPrematureCad', !formData.cadRiskFactors.familyHistoryOfPrematureCad)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Hypercholesterolemia'}
                                            titleTooltip={'Hypercholesterolemia'}
                                            description={'Hypercholesterolemia'}
                                            value={formData.cadRiskFactors.hypercholesterolemia === null ? false : formData.cadRiskFactors.hypercholesterolemia}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.hypercholesterolemia', !formData.cadRiskFactors.hypercholesterolemia)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Hypertension'}
                                            titleTooltip={'Hypertension'}
                                            description={'Hypertension'}
                                            value={formData.cadRiskFactors.hypertension === null ? false : formData.cadRiskFactors.hypertension}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.hypertension', !formData.cadRiskFactors.hypertension)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Obesity'}
                                            titleTooltip={'Obesity'}
                                            description={'Obesity'}
                                            value={formData.cadRiskFactors.obesity === null ? false : formData.cadRiskFactors.obesity}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.obesity', !formData.cadRiskFactors.obesity)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} className={classes.checkBox}>
                                        <LxCheckbox
                                            title={'Tobacco'}
                                            titleTooltip={'Tobacco'}
                                            description={'Tobacco'}
                                            value={formData.cadRiskFactors.tobacco === null ? false : formData.cadRiskFactors.tobacco}
                                            onChange={() => this.updateSharedValue('cadRiskFactors.tobacco', !formData.cadRiskFactors.tobacco)}
                                            threeState={false}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <Divider className={classes.sectionDivider} />
                    <CardFooter>
                        <GridContainer alignItems='baseline' justify='space-between'>
                            <GridItem>
                                <Button color='primary' variant='contained' onClick={() => triggerEventClear()}>
                                    {'BACK'}
                                </Button>
                            </GridItem>
                            <GridItem>
                                <Button color='primary' variant='contained' onClick={() => this.acceptChanges()}>
                                    {'ACCEPT'}
                                </Button>
                            </GridItem>
                            <GridItem>
                                <Button color='primary' variant='contained' onClick={() => this.copyResultToClipboard()}>
                                        {'SUMMARY TO CLIPBOARD'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardFooter>
                    <textarea id="clipboard-area" style={{width: 0, height: 0, maxHeight:0, maxWidth:0, left: 20, top:20, border:0, opacity:0}}/>
                </Card>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={this.state.copiedSnackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => this.toggleCopiedSnackbar(false)}
                    message="Copied!"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={this.state.savedSnackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => this.toggleSavedSnackbar(false)}
                    message="Saved!"
                />
            </React.Fragment>
        );
    }
}

export default compose(
    withStyles(styles),
    connect((state) => ({ patientInfo: state.demographics }))
)(ChestPainForm);