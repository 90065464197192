import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    inputRoot: {
        fontSize: theme.typography.fontSize
    },
    gridItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    inputGridItem: {
        width: '100%'
    }
});

class LxTextField extends React.Component {
    handleBlur = (event) => {
        const { onBlur, useOnChangeEvent, max, value } = this.props;
        let newValue = event.target.value;

        if (typeof onBlur === 'function') {
            if (newValue && newValue.length > max)
            {
                newValue = value;
            }

            return onBlur(useOnChangeEvent ? event : newValue);
        }
    }

    handleChange = (event) => {
        const { onChange, useOnChangeEvent, max, value } = this.props;
        var newValue = event.target.value;

        if (typeof onChange === 'function') {
            if (newValue && newValue.length > max)
            {
                alert('The value length is too big. Maximum number of symbols is -' + max); // TODO show the notification as a message above or below the field. Add a red border around the field.
                newValue = value;
            }

            return onChange(useOnChangeEvent ? event : newValue);
        }
    }

    render() {
        const {
            style,
            borderStyle,
            className,
            classes,
            error,
            disabled,
            title,
            descriptions,
            value,
            valueOptions,
            onBlur,
            defaultValue,
            helperText,
            titleTooltip,
            hasMappings,
            placeholder,
            label,
            onFocus,
            onKeyUp,
            align,
            tabIndex,
            autoFocus,
            forcedFocus,
            validationErrorSeverity,
            inputRef,
            inputOnly,
            multiline,
            fieldInformation,
            fieldInfo,
            rows, metaDataKey, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress,fhirPopupSelector
        } = this.props;

        return (
            <FormControl className={className} disabled={disabled} style={borderStyle}>
                {inputOnly ?
                    null
                    :
                    <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                        <GridItem className={classes.gridItem}>
                            <FormLabel>
                                <Tooltip title={titleTooltip} placement="top">
                                    <Typography align="left" color='inherit' variant='body2'>
                                        {title}
                                    </Typography>
                                </Tooltip>
                            </FormLabel>
                        </GridItem>
                        <GridItem>
                            <FieldHeader
                                value={typeof value === 'undefined' ? defaultValue : value}
                                descriptions={descriptions}
                                valueOptions={valueOptions}
                                onValueSelect={onBlur}
                                hasMappings={hasMappings}
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                                metaDataKey={metaDataKey}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={onUpdateMappingStatus}
                                defaultValue={defaultValue}
                                formLoadProgress={formLoadProgress}
                                fhirPopupSelector={fhirPopupSelector}
                                fieldInformation={fieldInformation}
                                fieldInfo={fieldInfo}
                            />
                        </GridItem>
                    </GridContainer>
                }
                <GridContainer wrap="nowrap" alignItems="center">
                    <GridItem className={classes.inputGridItem}>
                        <TextField
                            value={value === null ? '' : value}
                            disabled={disabled}
                            defaultValue={defaultValue === null ? '' : defaultValue}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            fullWidth
                            placeholder={placeholder}
                            label={label}
                            onFocus={onFocus}
                            multiline={multiline}
                            rows={rows}
                            InputProps={{
                                classes: { root: classes.inputRoot },
                                autoFocus: autoFocus,
                                inputProps: {
                                    tabIndex: tabIndex,
                                    style: style
                                }
                            }}
                            inputRef={textInputRef => {
                                if (forcedFocus && textInputRef) {
                                    textInputRef.focus();
                                }
                                if (typeof inputRef === 'function') {
                                    inputRef(textInputRef);
                                }
                            }}
                            align={align}
                            onKeyUp={onKeyUp}
                        />
                    </GridItem>
                    {inputOnly ?
                        <GridItem>
                            <ValidationErrorIcon
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                            />
                        </GridItem>
                        :
                        null
                    }
                </GridContainer>
                {error && helperText && validationErrorSeverity === 1 ?
                    <FormHelperText error={false} className={classes.colorError}>{helperText}</FormHelperText>
                    :
                    null
                }
            </FormControl>
        );
    }
}

LxTextField.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onFocus: PropTypes.func,
    useOnChangeEvent: PropTypes.bool,
    align: PropTypes.string
};

LxTextField.defaultProps = {
    disabled: false,
    error: false,
    align: 'left'
};

export default withStyles(styles)(LxTextField);