import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment';
import React from 'react';

import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { lookupEditorApi, pushToError } from '.../utils/apiHelper';
import LookupTableSelector from './LookupTableSelector';

const styles = theme => ({
    gridContainer: {
        height: 'inherit',
        overflowY: 'auto',
        padding : '3px'
    },
    gridItem: {
        height: 'inherit'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    buttonCell: {
        padding: '0px !important'
    }
});

class LookupTableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLookupTableData: null,
            data: null,
            columns: null,
            isLoading: false,
            edit: {},
            lookupTableName: null,
            addNewRecord: false,
            lookupTablePrimaryKey: null,
            isIdentity: null,
            dataTypeList: null
        };
    }

    componentDidMount() {
        this.getLookupTablesList();
    }

    getLookupTablesList() {
        lookupEditorApi.getLookupTablesList().then(lookupTablesList1 => {
            this.setState({
                lookupTablesList: lookupTablesList1
            });
        }).catch(error => pushToError(error));
        console.log("List Of Lookup Tables : " + this.state.lookupTablesList);
    }

    getLookupTableData(lookupTableName) {
        this.setState({
            isLoading: true,
            addNewRecord: false
        });
        lookupEditorApi.getLookupTableData(lookupTableName).then(selectedLookupTableData1 => {
            this.setState({
                selectedLookupTableData: selectedLookupTableData1,
                isLoading: false
            }, () => this.onGetSelectedTableData());
        }).catch(error => pushToError(error));
        console.log("Lookup Table Data: " + this.state.selectedLookupTableData);
    }

    getSelectedLookupTableDataType(lookupTableName) {
        this.setState({
            isLoading: true
        });
        lookupEditorApi.getSelectedLookupTableDataType(lookupTableName).then(dataTypeList1 => {
            this.setState({
                dataTypeList: dataTypeList1,
                isLoading: false
            }, () => console.log("Lookup Table Data types: " + this.state.dataTypeList));
        }).catch(error => pushToError(error));
    }

    getLookupTablePrimaryKey(lookupTableName) {
        this.setState({
            isLoading: true
        });

        lookupEditorApi.getLookupTablePrimaryKey(lookupTableName).then(lookupTablePrimaryKey1 => {
            lookupEditorApi.isIdentityColumn(lookupTableName).then(identity => {
                this.setState({
                    isIdentity: identity
                }, () => console.log("Lookup Table Identity Column: " + this.state.isIdentity));
            }).catch(error => pushToError(error));

            this.setState({
                lookupTablePrimaryKey: lookupTablePrimaryKey1,
                isLoading: false
            }, () => console.log("Lookup Table Primary Key: " + this.state.lookupTablePrimaryKey));
        }).catch(error => pushToError(error));

    }

    onGetSelectedTableData = () => {
        this.setState({
            data: this.state.selectedLookupTableData
        });

        this.setState({
            columns: Object.keys(this.state.selectedLookupTableData[0]).map((key, id) => {
                return {
                    Header: key.charAt(0).toUpperCase() + key.slice(1),
                    accessor: key,
                    width: 130,
                    show: (key === 'id' ? false : true),
                    Cell: ({ value, ...props }) =>
                        this.state.edit.id === props.original.id ? (
                            (key.toUpperCase() === this.state.isIdentity.toUpperCase()) ?
                                (value) :
                                this.state.dataTypeList.find(obj => { return obj.key.toUpperCase() === key.toUpperCase() && obj.value === 'System.DateTime'; }) ?
                                    <input style={{ 'padding': '0px' }} type="date"
                                        value={this.state.edit[key] === null || this.state.edit[key] === undefined ? null : moment(this.state.edit[key]).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state.edit,
                                                edit: { ...this.state.edit, [key]: e.target.value }
                                            }, () => console.log("Date type Column :") + key);
                                        }}
                                    /> :
                                    this.state.dataTypeList.find(obj => { return obj.key.toUpperCase() === key.toUpperCase() && obj.value === 'System.Boolean'; }) ?
                                        <input type="checkbox" style={{ 'padding': '0px' }}
                                            color='primary'
                                            checked={this.state.edit[key]}
                                            onChange={(e) => {
                                                this.setState({
                                                    ...this.state.edit,
                                                    edit: { ...this.state.edit, [key]: e.target.checked }
                                                }, () => console.log("Checkbox Type Column :") + key);
                                            }}
                                        />
                                        :
                                        <input style={{ 'padding': '0px' }}
                                            value={this.state.edit[key]}
                                            onChange={(e) => {
                                                this.setState({
                                                    ...this.state.edit,
                                                    edit: { ...this.state.edit, [key]: e.target.value }
                                                }, () => console.log("Edit column :") + key);
                                                this.validateData(key, e.target.value);
                                            }}
                                        />
                        ) : this.state.dataTypeList.find(obj => { return obj.key.toUpperCase() === key.toUpperCase() && obj.value === 'System.Boolean'; }) ?
                            <Checkbox style={{ 'padding': '0px' }} color='primary' checked={value ? 1 : 0} readonly /> : (value)
                };
            })
        });

        this.setState(prevColumns => ({
            columns: [
                ...prevColumns.columns,
                {
                    width: 120,
                    Cell: ({ value, ...props }) =>
                        Object.keys(this.state.edit).length > 0 &&
                            this.state.edit.id === props.original.id ? (
                            <div><button onClick={(e) => { this.saveLookupTableRecord(props.original.id); }}>Save</button>
                                <button onClick={(e) => { this.cancelEditRecord(); }}>Cancel</button></div>) : (
                            <button disabled={props.original.id === null ? "true" : ""} onClick={(e) => this.setState(
                                { ...this.state.edit, edit: props.original })}
                            >Edit</button>
                        )
                }
            ]
        }));

        this.setState(prevColumns => ({
            columns: [
                ...prevColumns.columns,
                {
                    id: 'delete',
                    width: 65,
                    resizable: false,
                    title: 'Delete',
                    Cell: ({ value, ...props }) => (
                        <ConfirmationButton
                            showConfirmation
                            confirmationTitle='Permanently Delete record?'
                            confirmationText={[
                                'Are you sure you want to delete this row?',
                                'You will not be able to get it back.'
                            ]}
                            confirmationConfirmText='Delete'
                            onClick={(e) => {
                                this.setState({
                                    ...this.state.edit, edit: props.original
                                }, () => console.log("this.state.edit :") + this.state.edit);
                                var primaryKeyValue = [], keyValue = {};
                                if ((this.state.isIdentity !== null && this.state.isIdentity !== undefined) && this.state.isIdentity !== '') {
                                    this.deleteLookupTableRecord(primaryKeyValue, props.original[this.state.isIdentity.charAt(0).toLowerCase() + this.state.isIdentity.slice(1)]);
                                }
                                else {
                                    this.state.lookupTablePrimaryKey.forEach(item => {
                                        keyValue = {};
                                        keyValue.Key = item;
                                        keyValue.Value = props.original[item.charAt(0).toLowerCase() + item.slice(1)];

                                        primaryKeyValue.push(keyValue);
                                    });

                                    this.deleteLookupTableRecord(primaryKeyValue, 0);
                                }
                            }}
                        >
                            <DeleteIcon />
                        </ConfirmationButton>
                    )
                }
            ]
        }));
    }

    deleteLookupTableRecord(primaryKeyValue, identityValue) {
        if (this.state.edit !== null && primaryKeyValue !== undefined) {
            lookupEditorApi.deleteSelectedLookupTable(primaryKeyValue, this.state.lookupTableName, identityValue).then(() => {
                this.setState({
                    edit: {},
                    addNewRecord: false
                });
                this.getLookupTableData(this.state.lookupTableName);
            }).catch(error => pushToError(error));
        }
        else {
            this.setState({
                edit: {},
                addNewRecord: false
            });
            this.getLookupTableData(this.state.lookupTableName);
        }
    }

    cancelEditRecord() {
        this.setState({
            edit: {},
            addNewRecord: false
        });
        this.getLookupTableData(this.state.lookupTableName);
    }

    saveLookupTableRecord(isNewRecord) {
        if (this.state.edit !== null) {
            var newRecord = [];
            newRecord.push(this.state.edit);
            if (isNewRecord === undefined) {
                isNewRecord = -1;
            }
            lookupEditorApi.saveSelectedLookupTable(newRecord, this.state.lookupTableName, isNewRecord).then(() => {
                this.setState({
                    edit: {},
                    addNewRecord: false
                });
                this.getLookupTableData(this.state.lookupTableName);
            }).catch(error => pushToError(error));
        }
    }

    onLookupTableChange = (lookupTableName) => {
        this.setState({
            lookupTableName: lookupTableName,
            lookupTablePrimaryKey: null
        });
        this.getLookupTablePrimaryKey(lookupTableName);
        this.getSelectedLookupTableDataType(lookupTableName);
        this.getLookupTableData(lookupTableName);
    }

    onAddNewRecord = () => {
        if (!this.state.addNewRecord) {
            this.setState({
                data: [
                    ...this.state.data,
                    {
                    }
                ],
                addNewRecord: true
            });
        }
    }

    validateData = (columnName, inputValue) => {
        if (inputValue !== null || inputValue !== undefined) {
            if (this.state.dataTypeList.find(obj => { return (obj.key.toUpperCase() === columnName.toUpperCase() && obj.value !== 'System.String'); })) {
                if (this.state.dataTypeList.find(obj => { return (obj.key.toUpperCase() === columnName.toUpperCase() && obj.value !== 'System.DateTime'); })) {
                    if (this.state.dataTypeList.find(obj => { return (obj.key.toUpperCase() === columnName.toUpperCase() && obj.value !== 'System.Boolean'); })) {
                        if (this.state.dataTypeList.find(obj => { return obj.key.toUpperCase() === columnName.toUpperCase() && (obj.value === 'System.Int32' || obj.value === 'System.Int16'); })) {
                            if (Number(inputValue) % 1 !== 0) {
                                this.setState({
                                    ...this.state.edit,
                                    edit: { ...this.state.edit, [columnName]: "" }
                                }, () => { alert('Input string was not in a correct format for : ' + columnName + '. Please enter valid integer data.'); });
                            }
                        }
                        else if (isNaN(inputValue)) {
                            this.setState({
                                ...this.state.edit,
                                edit: { ...this.state.edit, [columnName]: "" }
                            }, () => { alert('Input string was not in a correct format for : ' + columnName + '. Please enter valid data.'); });
                        }
                    }
                }
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { lookupTablesList, selectedLookupTableData, data, columns, isLoading } = this.state;

        return (
            <React.Fragment>
                <GridContainer spacing={4} className={classes.gridContainer}>
                    <GridItem xs={12}>
                        <LookupTableSelector
                            selectedLookupTableData={selectedLookupTableData}
                            lookupTablesList={lookupTablesList}
                            onLookupTableChange={this.onLookupTableChange}
                            onAddNewRecord={this.onAddNewRecord}
                            data={data}
                            columns={columns}
                            isLoading={isLoading}
                        />
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(LookupTableContainer);