import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import CreateNewPatient from '.../components/patientInfo/CreateNewPatient';
import { demographicsApi, pushToError } from '.../utils/apiHelper';
import AuthorizationFilter from '.../utils/AuthorizationFilter';
import PatientWorklist from './PatientWorklist';
import PatientWorklistSearch from './PatientWorklistSearch';

const styles = theme => ({
    container: {
        marginTop: '50px'
    },
    searchComponent: {
        '@media (min-width: 960px)': {
            maxWidth: '50%'
        },
        maxWidth: '100%',
        margin: `${theme.spacing(4)}px auto`
    }
});

class PatientWorklistContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patientWorklist: [],
            isSubmitted: false,
            isResultLoading: false,
            createPatientOpen: false
        };
    }

    onSubmit = (searchCriteria) => {
        const { mrn, firstName, lastName } = searchCriteria;
        this.setState({
            isSubmitted: true,
            isResultLoading: true
        });
        demographicsApi.search(firstName, lastName, mrn)
            .then(res => this.setState({
                patientWorklist: res,
                isResultLoading: false
            }))
            .catch((error) => pushToError(error));

    };

    handlePatientSelect = (patient) => {
        this.props.history.push(`patient/${patient.mrn}?apollo=${patient.uniqueSourceId}`);
    };

    render() {
        const { classes } = this.props;
        const { isSubmitted, patientWorklist, isResultLoading, createPatientOpen } = this.state;

        return (
            <AuthorizationFilter allowed='CommonFeatures:User' showUnauthorized>
                <div className={classes.container} style={{maxHeight: '100%', overflow:'auto'}}>
                    <PatientWorklistSearch className={classes.searchComponent} onSubmit={this.onSubmit} />
                    {isSubmitted ?
                        isResultLoading ?
                            <CircularProgress size={60} thickness={7} />
                            :
                            <PatientWorklist
                                className={classes.searchComponent}
                                title='Search Results'
                                patients={patientWorklist}
                                handlePatientSelect={this.handlePatientSelect}
                                handleCreatePatient={() => this.setState({ createPatientOpen: true })}
                            />
                        :
                        <div />
                    }
                    <CreateNewPatient
                        open={createPatientOpen}
                        handleClose={() => this.setState({ createPatientOpen: false })}
                        failureCallback={pushToError}
                        successfulCallback={this.handlePatientSelect}
                    />
                </div>
            </AuthorizationFilter>
        );
    }
}

export default compose(
    connect(null, (dispatch) => ({ onPatientLoad: (demographics) => dispatch({ type: 'SET_DEMOGRAPHICS', demographics }) })),
    withStyles(styles),
    withRouter
)(PatientWorklistContainer);