import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
    title: {
        marginRight: '8px',
        fontSize: theme.typography.fontSize,
        lineHeight: 1
    },
    procedure: {
        display: 'flex',
        textAlign: 'left',
        margin: '4px 0px',
        alignItems: 'baseline'
    },
    select: {
        width: '142px',
        lineHeight: 1
    },
    selectRoot: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    selectMenu: {
        paddingRight: '8px'
    }
});

const procedureMap = (procedures) => (
    procedures.map(
        (procedure, index) => (
            <MenuItem key={index} value={index}> {procedure.displayName} </MenuItem>
        )
    )
);

const Procedure = (props) => {
    const { procedureIndex, procedures, onProcedureChange, classes } = props;

    return (
        <div>
            <div className={classes.procedure}>
                <div className={classes.title}>
                    {'Procedure'}
                </div>
                <Select
                    value={Array.isArray(procedures) && procedures.length > 0 ? procedureIndex : ''}
                    onChange={onProcedureChange}
                    className={classes.select}
                    classes={{
                        root: classes.selectRoot,
                        selectMenu: classes.selectMenu
                    }}
                >
                    {procedureMap(procedures)}
                </Select>
            </div>
            <Divider />
        </div>
    );
};

export default withStyles(styles)(Procedure);