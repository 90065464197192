import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';

import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import { ElementInitializationContext } from './ElementInitializationContext';
import FormEditorSection from './FormEditorSection';
import FormEditorSectionList from './FormEditorSectionList';
import StyledDropzone from './StyledDropzone';
import Dialog from "@material-ui/core/Dialog";
import SplitButton from ".../assets/components/CustomButtons/SplitButton";

const styles = theme => ({
    addSectionButton: {
        margin: '20px'
    },
    smallDisplaySize: {
        minWidth: '400px',
        width: '400px',
        maxWidth: '400px',
        margin: 'auto'
    },
    mediumDisplaySize: {
        minWidth: '841px', // 800 breakpoint + 40 for padding and 1 to make sure within range
        width: '841px',
        maxWidth: '841px',
        margin: 'auto'
    },
    largeDisplaySize: {
        minWidth: '1141px', // 1100 breakpoint + 40 for padding and 1 to make sure within range
        width: '1141px',
        maxWidth: '1141px',
        margin: 'auto'
    },
    tabbedFormSection: {
        paddingRight: '5px'
    }
});

const ITEM_ROW_HEIGHT = 66;

class FormEditorGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentHeight: {},
            currentSectionIndex: 0,
            dropzoneOpen: false
        };
    }

    static contextType = ElementInitializationContext;

    onFileUploaded = (section) => {
        this.props.onImportSection(section);
        this.onDropzoneClose();
    };

    onSectionSelect = (sectionIndex) => {
        this.setState({
            currentSectionIndex: sectionIndex
        });
    };

    onDropzoneOpen = () => {
        this.setState({
            dropzoneOpen: true
        });
    };

    onDropzoneClose = () => {
        this.setState({
            dropzoneOpen: false
        });
    };

    render() {
        const {
            schema,
            classes,
            onAddSection,
            onRemoveSection,
            updateSectionLayout,
            updateSectionHide,
            //updateSectionDisplayName,
            onUpdateSection,
            schemaValidation,
            validateFormSchema,
            onImportSubSection,
            updateSectionUids
        } = this.props;
        const { currentSectionIndex } = this.state;
        let section = schema.sections[currentSectionIndex];
        let elementInitializationContext = this.context;

        let displaySizeClassName = classnames({
            [classes.smallDisplaySize]: elementInitializationContext.displaySize === 'sm',
            [classes.mediumDisplaySize]: elementInitializationContext.displaySize === 'md',
            [classes.largeDisplaySize]: elementInitializationContext.displaySize === 'lg'
        });

        return (
            <GridContainer>
                <GridItem xs={12} sm={4} md={2} className={classes.tabbedFormSection}>
                    <FormEditorSectionList
                        sections={schema.sections}
                        currentSectionIndex={currentSectionIndex}
                        onClick={this.onSectionSelect}
                        updateSectionLayout={updateSectionLayout}
                        updateSectionHide={updateSectionHide}
                        //updateSectionDisplayName={updateSectionDisplayName}
                    />
                    <SplitButton
                        className={classes.addSectionButton}
                        variant='outlined'
                        options={[
                            {
                                label: 'ADD SECTION',
                                callback: onAddSection,
                                showConfirmation: false
                            },
                            {
                                label: 'IMPORT SECTION',
                                callback: this.onDropzoneOpen,
                                showConfirmation: false
                            }
                        ]}
                    />
                    <Dialog
                        open={this.state.dropzoneOpen}
                        onClose={this.onDropzoneClose}
                    >
                        <StyledDropzone onFileUploaded={this.onFileUploaded} />
                    </Dialog>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={8}
                    md={10}
                    className={classes.tabbedFormSection}
                >
                    <div className={displaySizeClassName}>
                        {section ?
                            <FormEditorSection
                                section={section}
                                key={section.sectionName}
                                tableName={schema.header.eventTable}
                                schemaValidation={schemaValidation}
                                validateFormSchema={validateFormSchema}
                                onRemoveSection={onRemoveSection(currentSectionIndex.toString())}
                                onSchemaUpdate={onUpdateSection}
                                itemRowHeight={ITEM_ROW_HEIGHT}
                                open
                                onImportSubSection={onImportSubSection}
                                updateSectionUids={updateSectionUids}
                            />
                            : null
                        }
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(FormEditorGrid);