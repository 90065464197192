import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { pushToError, staffApi } from '.../utils/apiHelper';
import StaffEditor from './StaffEditor';
import StaffSelector from './StaffSelector';

const styles = theme => ({
    gridContainerInPopup: {
        height: 'inherit',
        width: '1000px'
    },
    gridContainerInAdminConsole: {
        height: 'inherit'
    },
    gridItem: {
        height: 'inherit'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class StaffContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    state = {
        savedSnackbarOpen: false,
        deletedSnackbarOpen: false,
        openDialog: false,
        staffDef: null,
        staffList: null,
        staffRoleList: null,
        staffNumberList: null,
        staffLookupSecurity: null
    }

    componentDidMount() {
        staffApi.getStaffLookupSecurity().then(staffLookupSecurity => {
            this.setState({
                staffLookupSecurity
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Gender').then(genderList => {
            this.setState({
                gender: genderList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Staff_Type').then(staffTypeList => {
            this.setState({
                staffType: staffTypeList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Facility').then(organizationList => {
            this.setState({
                organization: organizationList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Specialty_Area').then(specialtyList => {
            this.setState({
                specialty: specialtyList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Personnel_Department').then(departmentList => {
            this.setState({
                department: departmentList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Role').then(roleList => {
            this.setState({
                role: roleList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('City').then(cityList => {
            this.setState({
                city: cityList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('State_Province').then(stateList => {
            this.setState({
                state: stateList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('County').then(countyList => {
            this.setState({
                county: countyList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Country').then(countryList => {
            this.setState({
                country: countryList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('Personnel_Roles').then(roleList => {
            this.setState({
                personnelRoles: roleList
            });
        }).catch(error => pushToError(error));

        staffApi.getSelectionSetElements('TypeOfPersonnelID').then(numberList => {
            this.setState({
                personnelNumbers: numberList
            });
        }).catch(error => pushToError(error));

        this.getStaffs();
    }

    showSavedSnackbar = () => {
        this.setState({
            savedSnackbarOpen: true
        });
    };

    handleSavedSnackbarClose = () => {
        this.setState({
            savedSnackbarOpen: false
        });
    };

    showDeletedSnackbar = () => {
        this.setState({
            deletedSnackbarOpen: true
        });
    };

    handleDeletedSnackbarClose = () => {
        this.setState({
            deletedSnackbarOpen: false
        });
    };

    getStaffs() {
        this.setState({
            isLoading: true
        });
        staffApi.getStaffs().then(staffList => {
            this.setState({
                staffList,
                isLoading: false
            });
        }).catch(error => pushToError(error));
    }

    openStaffEditor = () => {
        let staffDetails = {
            staffId: 0,
            name: '',
            alternateName: '',
            initials: '',
            upin: '',
            npi: '',
            providerID: '',
            userName: '',
            lastName: '',
            firstName: '',
            middleName: '',
            suffix: '',
            title: '',
            ssn: '',
            gender: '',
            dateOfBirth: null,
            specialty: '',
            role: '',
            staffType: '',
            organization: '',
            department: '',
            practitionerGroup: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            state: '',
            county: '',
            country: '',
            zipCode: '',
            phone1: '',
            phone2: '',
            fax: '',
            pager: '',
            cellPhone: '',
            homePhone: '',
            emailAddress: '',
            active: true,
            activeDate: null,
            inactiveDate: null,
            lastTransactionDateTime: null,
            comments: '',
            tin: '',
            roles: [],
            numbers: []
        };

        this.setState({
            staffDef: staffDetails,
            openDialog: true
        });
    }

    closeStaffEditor = () => {
        this.setState({
            openDialog: false
        });
    }

    handleStaffSelect = (staffDef) => {
        console.log(staffDef);
        this.setState({
            staffDef,
            openDialog: true
        });
    }

    save = () => {
        if (this.state.staffDef !== null) {
            let newStaffList = Array.isArray(this.state.staffList) ? [...this.state.staffList] : [];
            if (this.state.staffDef.staffId !== 0 && this.state.staffList !== null) {
                //Edit existing staff.
                let staffIdIndex = newStaffList.map(staff => staff.staffId).indexOf(this.state.staffDef.staffId);
                newStaffList.splice(staffIdIndex, 1, this.state.staffDef);
            }
            else {
                //Add New Staff.
                let staff = this.state.staffDef;
                newStaffList.push({
                    ...staff
                });
            }
            this.closeStaffEditor();

            staffApi.saveStaff(this.state.staffDef).then(() => {
                this.showSavedSnackbar();
                this.getStaffs();
            }).catch(error => pushToError(error));
        }
    }

    delete = (staffId) => {
        if (staffId !== null) {
            this.setState({
                isLoading: true
            });
            staffApi.deleteStaff(staffId).then(() => {
                this.showDeletedSnackbar();
                this.getStaffs();
            }).catch(error => pushToError(error));
        }
    }

    changeStaff = (newStaff) => {
        this.setState({
            staffDef: newStaff
        });
    }

    handleChange = (item) => {
        const { itemSchema, recordContext, onBlur } = this.props;
        let dataPoints = [];

        if (typeof onBlur === 'function') {
            if (itemSchema.targetTable && itemSchema.targetField) {
                let value;
                let currentKey = recordContext.recordContext[itemSchema.targetTable].key;
                if (item) {
                    value = item["name"];
                } else {
                    value = null;
                }
                dataPoints.push({
                    keyValue: currentKey,
                    tableName: itemSchema.targetTable,
                    FieldName: itemSchema.targetField,
                    value: value
                });

            }
        }
        onBlur(dataPoints);
    }

    render() {
        const { classes, recordContext } = this.props;
        const {
            isLoading,
            savedSnackbarOpen,
            deletedSnackbarOpen,
            openDialog,
            staffDef,
            staffList,
            gender,
            staffType,
            organization,
            specialty,
            department,
            role,
            city,
            state,
            county,
            country,
            personnelRoles,
            personnelNumbers,
            staffLookupSecurity
        } = this.state;

        return (
            <React.Fragment>                
                {
                    recordContext !== undefined ?
                        (
                            <GridContainer spacing={4} className={classes.gridContainerInPopup}>
                                <GridItem xs={12}>
                                    <StaffSelector
                                        onStaffEditorOpen={this.openStaffEditor}
                                        staffDef={staffDef}
                                        onStaffSelect={this.handleStaffSelect}
                                        staffData={staffList}
                                        onDeleteStaff={this.delete}
                                        isLoading={isLoading}
                                        recordContext={recordContext}
                                        onHandleChange={this.handleChange}
                                        staffLookupSecurity={staffLookupSecurity}
                                    />
                                </GridItem>
                            </GridContainer>
                        )
                        :
                        (
                            <GridContainer spacing={4} className={classes.gridContainerInAdminConsole}>
                                <GridItem xs={12}>
                                    <StaffSelector
                                        onStaffEditorOpen={this.openStaffEditor}
                                        staffDef={staffDef}
                                        onStaffSelect={this.handleStaffSelect}
                                        staffData={staffList}
                                        onDeleteStaff={this.delete}
                                        isLoading={isLoading}
                                        recordContext={recordContext}
                                        onHandleChange={this.handleChange}
                                        staffLookupSecurity={staffLookupSecurity}
                                    />
                                </GridItem>
                            </GridContainer>
                        )
                }

                <Dialog open={openDialog} maxWidth="xl" onClose={this.closeStaffEditor}>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeStaffEditor}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Staff Editor'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <StaffEditor
                            populateStaff={staffDef}
                            onStaffChange={this.changeStaff}
                            onSaveStaff={this.saveStaff}
                            gender={gender}
                            staffType={staffType}
                            organization={organization}
                            specialty={specialty}
                            department={department}
                            role={role}
                            city={city}
                            state={state}
                            county={county}
                            country={country}
                            personnelRoles={personnelRoles}
                            personnelNumbers={personnelNumbers}
                        />
                    </DialogContent>
                    <MuiDialogActions>
                        <Button color="primary" variant='contained' onClick={this.save}>
                            Save
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={savedSnackbarOpen}
                    onClose={this.handleSavedSnackbarClose}
                    message="Saved!"
                    autoHideDuration={3000}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={deletedSnackbarOpen}
                    onClose={this.handleDeletedSnackbarClose}
                    message="Deleted!"
                    autoHideDuration={3000}
                />
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(StaffContainer);