import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCombobox from '.../components/formComponents/LxCombobox';
import LxDatePicker from '.../components/formComponents/LxDatePicker';
import TextBox from './TextBox';

const styles = theme => ({
    formRow: {
        display: 'flex'
    },
    formItem: {
        flex: '0 0 33%',
        marginRight: '8px'
    },
    formCheckBoxTitle: {
        display: 'flex',
        flexDirection: 'column'
    },
    formCheckBox: {
        alignSelf: 'center'
    },
    dialog: {
        textAlign: 'left'
    },
    dialogContent: {
        overflow: 'visible'
    }
});

class IPCCCAddEditDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            row: IPCCCAddEditDialog.getInitialValues(props),
            errMsg: null,
            pregFetusOptions: IPCCCAddEditDialog.getPregFetusIdItems(props.patient)
        };
    }

    static getInitialValues = (props) => ({
        fetus: props.includes.indexOf('fetusId') === -1 ? undefined : {
            pregnancyId: null,
            fetusId: null,
            pregnancyGroup: null
        },
        diagnosisCode: props.includes.indexOf('diagnosis') === -1 ? undefined : {
            code: null,
            category: null,
            icd9: null,
            icd10: null,
            diagnosis: null
        },
        qualifier1: props.includes.indexOf('qualifier1') === -1 ? undefined : {
            code: null,
            description: null
        },
        qualifier2: props.includes.indexOf('qualifier2') === -1 ? undefined : {
            code: null,
            description: null
        },
        qualifier3: props.includes.indexOf('qualifier3') === -1 ? undefined : {
            code: null,
            description: null
        },
        code: props.includes.indexOf('diagnosis') === -1 ? undefined : null,
        category: props.includes.indexOf('diagnosis') === -1 ? undefined : null,
        icd9: props.includes.indexOf('diagnosis') === -1 ? undefined : null,
        icd10: props.includes.indexOf('diagnosis') === -1 ? undefined : null,
        qualiCode1: props.includes.indexOf('qualifier1') === -1 ? undefined : null,
        qualiDesc1: props.includes.indexOf('qualifier1') === -1 ? undefined : null,
        qualiCode2: props.includes.indexOf('qualifier2') === -1 ? undefined : null,
        qualiDesc2: props.includes.indexOf('qualifier2') === -1 ? undefined : null,
        qualiCode3: props.includes.indexOf('qualifier3') === -1 ? undefined : null,
        qualiDesc3: props.includes.indexOf('qualifier3') === -1 ? undefined : null,
        diagnosis: props.includes.indexOf('diagnosis') === -1 ? undefined : null,
        ssPatientId: props.patient.ssPatientId,
        dateOfAepc: props.includes.indexOf('dateOfAepc') === -1 ? undefined : new Date().toISOString(),
        archived: props.includes.indexOf('archived') === -1 ? undefined : false,
        archivedDate: props.includes.indexOf('archivedDate') === -1 ? undefined : null,
        archivedReason: props.includes.indexOf('archivedReason') === -1 ? undefined : null,
        displayCodeOnWordReport: props.includes.indexOf('displayCodeOnWordReport') === -1 ? undefined : false,
        pregnancyId: props.includes.indexOf('pregnancyId') === -1 ? undefined : null,
        fetusId: props.includes.indexOf('fetusId') === -1 ? undefined : null,
        pregnancyGroup: props.includes.indexOf('pregnancyId') === -1 ? undefined : null,
        ssdiagnosisId: null
    })

    static getDerivedStateFromProps(props, state) {
        if (props.open === true && state.open === false) {
            let rowId = typeof props.editRowId !== 'undefined' ? props.editRowId : props.archiveRowId;
            let row = typeof rowId !== 'undefined' ? Object.assign({}, props.rows[rowId]) : IPCCCAddEditDialog.getInitialValues(props);
            let pregFetusOptions = IPCCCAddEditDialog.getPregFetusIdItems(props.patient);

            if (props.fetalTab && row.pregnancyId === null && row.fetusId === null && pregFetusOptions.length > 0) {
                let { pregnancyId, fetusId } = JSON.parse(pregFetusOptions[0].props.value);
                row.pregnancyId = pregnancyId;
                row.fetusId = fetusId;
            }

            return ({
                open: true,
                row,
                errMsg: null,
                pregFetusOptions
            });
        } else if (props.open === false && state.open === true) {
            return ({
                open: false
            });
        }

        return null;
    }

    static getPregFetusIdItems = (patient) => {
        let menuItems = [];
        patient.pregnancies.forEach(pregnancy => {
            pregnancy.fetuses.forEach(fetus => {
                menuItems.push(
                    <MenuItem
                        value={JSON.stringify({
                            pregnancyId: pregnancy.pregnancyNumber === null ? null : pregnancy.pregnancyNumber.toString(),
                            fetusId: fetus.fetusId === null ? null : fetus.fetusId.toString()
                        })}
                        key={`${pregnancy.pregnancyNumber} ${fetus.fetusId}`}
                    >
                        {`Pregnancy: ${pregnancy.pregnancyNumber} Fetus: ${fetus.fetusId}`}
                    </MenuItem>
                );
            });
        });
        return menuItems;
    }

    updatePregFetusId = (event) => {
        const { pregnancyId, fetusId } = JSON.parse(event.target.value);
        this.setState({
            row: {
                ...this.state.row,
                fetus: { ...this.state.row.fetus, pregnancyId, fetusId },
                pregnancyId,
                fetusId
            }
        });
    }

    updateDate = (dateOfAepc) => {
        this.setState({
            row: {
                ...this.state.row,
                dateOfAepc: dateOfAepc
            }
        });
    }

    updateShowOnReport = (event, displayCodeOnWordReport) => {
        this.setState({
            row: {
                ...this.state.row,
                displayCodeOnWordReport
            }
        });
    }

    updateArchived = (event, archived) => {
        this.setState({
            row: {
                ...this.state.row,
                archived,
                archivedDate: archived ? new Date().toISOString() : null
            }
        });
    }

    updateArchivedReason = (event) => {
        this.setState({
            row: {
                ...this.state.row,
                archivedReason: event.target.value
            }
        });
    }

    onDiagnosisChange = (selectedItem) => {
        if (selectedItem !== null) {
            const { code, category, diagnosis, icd9, icd10 } = selectedItem;

            this.setState({
                row: {
                    ...this.state.row,
                    diagnosisCode: selectedItem,
                    code,
                    category,
                    diagnosis,
                    icd9,
                    icd10
                }
            });
        }
    };

    onQualifierChange = (selectedItem, qualifierName) => {
        if (selectedItem !== null) {
            const { code, description } = selectedItem;

            const qualifierLookup = {
                qualifier1: {
                    code: 'qualiCode1',
                    description: 'qualiDesc1'
                },
                qualifier2: {
                    code: 'qualiCode2',
                    description: 'qualiDesc2'
                },
                qualifier3: {
                    code: 'qualiCode3',
                    description: 'qualiDesc3'
                }
            };

            let qualifier = {};
            qualifier[qualifierName] = { code, description };
            qualifier[qualifierLookup[qualifierName].code] = code;
            qualifier[qualifierLookup[qualifierName].description] = description;

            this.setState({
                row: {
                    ...this.state.row,
                    ...qualifier
                }
            });
        }
    }

    onEditArchiveSubmit = (event) => {
        event.preventDefault();
        if (this.state.row.archived && (this.state.row.archivedReason === null || this.state.row.archivedReason === '')) {
            this.setState({ errMsg: 'Archived Reason cannot be empty' });
        } else {
            this.props.handleEditArchiveSubmit(this.state.row);
        }
    }

    onAddSubmit = (event) => {
        event.preventDefault();
        this.props.handleAddSubmit(this.state.row);
    }

    render() {
        const { open } = this.state;
        const { handleClose, codeLibrary, itemToString, editRowId, archiveRowId, includes, fetalTab, classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth
                className={classes.dialog}
            >
                <DialogTitle>{typeof editRowId !== 'undefined' ? 'Edit Diagnosis' : typeof archiveRowId !== 'undefined' ? 'Archive Diagnosis' : 'Add New Diagnosis'}</DialogTitle>
                <DialogContent className={classes.dialogContent}>

                    <GridContainer spacing={4}>
                        {(includes.indexOf('pregnancyId') === -1 && includes.indexOf('fetusId') === -1) || this.state.pregFetusOptions.length === 0 ? null :
                            typeof archiveRowId !== 'undefined' ?
                                this.state.row.pregnancyId === null ?
                                    null
                                    :
                                    <GridItem xs={8}>
                                        <h4>Pregnancy and Fetus:</h4>
                                        <Typography variant='subtitle1'>{`Pregnancy ${this.state.row.pregnancyId} Fetus ${this.state.row.fetusId}`}</Typography>
                                    </GridItem>
                                :
                                <GridItem xs={8}>
                                    <h4>Pregnancy and Fetus:</h4>
                                    <Select
                                        value={JSON.stringify({
                                            pregnancyId: this.state.row.pregnancyId === null ? null : this.state.row.pregnancyId.toString(),
                                            fetusId: this.state.row.fetusId === null ? null : this.state.row.fetusId.toString()
                                        })}
                                        onChange={this.updatePregFetusId}
                                        autoWidth
                                        style={{ width: '100%' }}
                                    >
                                        {fetalTab ? null : <MenuItem value={JSON.stringify({ pregnancyId: null, fetusId: null })}> None </MenuItem>}
                                        {this.state.pregFetusOptions}
                                    </Select>
                                </GridItem>
                        }
                        {includes.indexOf('dateOfAepc') === -1 ? null :
                            <GridItem xs={4}>
                                <h4>Date:</h4>
                                {typeof archiveRowId !== 'undefined' ?
                                    <Typography variant='subtitle1'>
                                        {`${new Date(this.state.row.dateOfAepc).toLocaleDateString('en-US', {
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric'
                                        })}`}
                                    </Typography> :
                                    <LxDatePicker
                                        value={this.state.row.dateOfAepc}
                                        onChange={this.updateDate}
                                    />
                                }
                            </GridItem>}
                        {includes.indexOf('displayCodeOnWordReport') === -1 ? null :
                            <GridItem xs={4}>
                                <h4>Show On Report:</h4>
                                <Checkbox color='primary' checked={this.state.row.displayCodeOnWordReport} onChange={this.updateShowOnReport} className={classes.formCheckBox} />
                            </GridItem>}
                    </GridContainer>

                    {includes.indexOf('diagnosis') === -1 ? null :
                        <GridContainer>
                            <GridItem xs={12}>
                                <h4>Diagnosis Code:</h4>
                                {typeof archiveRowId !== 'undefined' ?
                                    <Typography variant='subtitle1'>{this.state.row.diagnosisCode.code === null ?
                                        'None' :
                                        `${this.state.row.diagnosisCode.code} ${this.state.row.diagnosisCode.diagnosis}`}
                                    </Typography> :
                                    <LxCombobox
                                        items={includes.indexOf('pregnancyId') === -1 && includes.indexOf('fetusId') === -1 ?
                                            codeLibrary.aepcSurgAndIntern :
                                            codeLibrary.diagAndSynd}
                                        placeholder={"Start typing to search Codes..."}
                                        itemToString={itemToString}
                                        inputDisplayTemplate={selectedItem => `${selectedItem.code} ${selectedItem.diagnosis}`}
                                        initialSelectedItem={this.state.row.diagnosisCode.code === '' || this.state.row.diagnosisCode.code === null ? null : this.state.row.diagnosisCode}
                                        onChange={(selectedItem) => this.onDiagnosisChange(selectedItem)}
                                        columns={["Code", "Diagnosis", "Category", "ICD9", "ICD10"]}
                                        searchKeys={['code', 'diagnosis', 'description', 'icd9', 'icd10', 'category']}
                                        fitInputWidth
                                    />
                                }
                            </GridItem>
                        </GridContainer>
                    }

                    <GridContainer>
                        {includes.indexOf('qualifier1') === -1 ? null :
                            <GridItem xs={4}>
                                <h4>Qualifier Code 1:</h4>
                                {typeof archiveRowId !== 'undefined' ?
                                    <Typography variant='subtitle1'>{this.state.row.qualifier1.code === null ?
                                        'None' :
                                        `${this.state.row.qualifier1.code} ${this.state.row.qualifier1.description}`}
                                    </Typography> :
                                    <LxCombobox
                                        items={codeLibrary.ipcccQualifiers}
                                        placeholder={"Start typing to search Codes..."}
                                        itemToString={itemToString}
                                        inputDisplayTemplate={selectedItem => `${selectedItem.code} ${selectedItem.description}`}
                                        initialSelectedItem={this.state.row.qualifier1.code === '' || this.state.row.qualifier1.code === null ? null : this.state.row.qualifier1}
                                        onChange={(selectedItem) => this.onQualifierChange(selectedItem, 'qualifier1')}
                                        columns={["Code", "Description"]}
                                        searchKeys={['code', 'diagnosis', 'description', 'icd9', 'icd10', 'category']}
                                        fitInputWidth
                                    />
                                }
                            </GridItem>}
                        {includes.indexOf('qualifier2') === -1 ? null :
                            <GridItem xs={4}>
                                <h4>Qualifier Code 2:</h4>
                                {typeof archiveRowId !== 'undefined' ?
                                    <Typography variant='subtitle1'>{this.state.row.qualifier2.code === null ?
                                        'None' :
                                        `${this.state.row.qualifier2.code} ${this.state.row.qualifier2.description}`}
                                    </Typography> :
                                    <LxCombobox
                                        items={codeLibrary.ipcccQualifiers}
                                        placeholder={"Start typing to search Codes..."}
                                        itemToString={itemToString}
                                        inputDisplayTemplate={selectedItem => `${selectedItem.code} ${selectedItem.description}`}
                                        initialSelectedItem={this.state.row.qualifier2.code === '' || this.state.row.qualifier2.code === null ? null : this.state.row.qualifier2}
                                        onChange={(selectedItem) => this.onQualifierChange(selectedItem, 'qualifier2')}
                                        columns={["Code", "Description"]}
                                        searchKeys={['code', 'diagnosis', 'description', 'icd9', 'icd10', 'category']}
                                        fitInputWidth
                                    />
                                }
                            </GridItem>}
                        {includes.indexOf('qualifier3') === -1 ? null :
                            <GridItem xs={4}>
                                <h4>Qualifier Code 3:</h4>
                                {typeof archiveRowId !== 'undefined' ?
                                    <Typography variant='subtitle1'>
                                        {this.state.row.qualifier3.code === null ?
                                            'None' :
                                            `${this.state.row.qualifier3.code} ${this.state.row.qualifier3.description}`}
                                    </Typography> :
                                    <LxCombobox
                                        items={codeLibrary.ipcccQualifiers}
                                        placeholder={"Start typing to search Codes..."}
                                        itemToString={itemToString}
                                        inputDisplayTemplate={selectedItem => `${selectedItem.code} ${selectedItem.description}`}
                                        initialSelectedItem={this.state.row.qualifier3.code === '' || this.state.row.qualifier3.code === null ? null : this.state.row.qualifier3}
                                        onChange={(selectedItem) => this.onQualifierChange(selectedItem, 'qualifier3')}
                                        columns={["Code", "Description"]}
                                        searchKeys={['code', 'diagnosis', 'description', 'icd9', 'icd10', 'category']}
                                        fitInputWidth
                                    />
                                }
                            </GridItem>}
                    </GridContainer>

                    {typeof archiveRowId === 'undefined' ? null :
                        <GridContainer className={classes.formRow}>
                            {includes.indexOf('archived') === -1 ? null :
                                <GridItem xs={4}>
                                    <h4>Archived:</h4>
                                    <Checkbox color='primary' checked={this.state.row.archived} onChange={this.updateArchived} className={classes.formCheckBox} />
                                </GridItem>}
                            {includes.indexOf('archivedDate') === -1 ? null :
                                <GridItem xs={4}>
                                    <h4>Archived Date:</h4>
                                    <Typography variant='subtitle1'>
                                        {this.state.row.archivedDate !== null ?
                                            `${new Date(this.state.row.archivedDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}` :
                                            null}
                                    </Typography>
                                </GridItem>}
                            {includes.indexOf('archivedReason') === -1 ? null :
                                <GridItem xs={4}>
                                    <h4>Archived Reason:</h4>
                                    <TextBox
                                        name='Archived Reason'
                                        defaultValue={this.state.row.archivedReason}
                                        onBlur={this.updateArchivedReason}
                                        maxLength={50}
                                        multiLine
                                        error={this.state.errMsg !== null}
                                        label={this.state.errMsg}
                                    />
                                </GridItem>}
                        </GridContainer>
                    }
                </DialogContent>

                <DialogActions>
                    <Button color='primary' onClick={handleClose}>Cancel</Button>
                    {typeof archiveRowId !== 'undefined' || typeof editRowId !== 'undefined' ?
                        <Button onClick={this.onEditArchiveSubmit} color='primary' autoFocus>Save</Button>
                        :
                        <Button onClick={this.onAddSubmit} color='primary' autoFocus>Submit</Button>}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(IPCCCAddEditDialog);