import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxCheckbox from '.../components/formComponents/LxCheckbox';

const styles = theme => ({
    content: {
        margin: 'auto',
        overflow: 'hidden',
        float: 'left',
        container: 'flex',
        flexdirection: 'column',
        justifycontent: 'space-between'
    },
    formatButtons: {
        display: 'inline-flex'
        // justifycontent: 'flex-end'
    },
    propertyFields: {
        minWidth: '80px',
        paddingBottom: '20px'
    }
});

class LabelCustomizer extends React.Component {
    state = {
        style: {
            ...this.props.value
        }
    }
    updateStyle = (propertyName) => (value) => {
        const { onChange } = this.props;

        let style = this.state.style;

        if (!style){
            style = {
                'fontWeight': null, 
                'textDecoration' : null,
                'fontStyle' : null, 
                'outline' : null
            };
        }

        let newStyle = {
            ...style
        };

        switch (propertyName) {
            case 'fontWeight':
                newStyle['fontWeight'] = value ? 'bold' : null;
                break;
            case 'textDecoration':
                newStyle['textDecoration'] = value ? 'underline' : null;
                break;
            case 'fontStyle':
                newStyle['fontStyle'] = value ? 'italic' : null; 
                break;
            case 'outline':
                newStyle['outline'] = value ? 'solid black thin' : null;
                break;
            default:
                break;
        }

        this.setState({
            style : newStyle
        });

        onChange(newStyle);
    }

    render() {
        const { classes } = this.props;

        let style = this.state.style;

        let formattingFields = [
            {
                name: "fontWeight",
                label: "Bold"
            },
            {
                name: "textDecoration",
                label: "Underline"
            },
            {
                name: "fontStyle",
                label: "Italicize"
            },
            {
                name: "outline",
                label: "Outline"
            }
        ];

        return (
            <div className={classes.content}>
                <div className={classes.formatButtons}>
                {formattingFields.map((field) => {

                    return(
                        <LxCheckbox
                            style={{'display': 'inline-block'}}
                            className={classes.propertyFields}
                            value={style[field.name]} 
                            onChange={this.updateStyle(field.name)} 
                            title={field.label}
                            disabled={false}
                        />
                    );
                })}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(LabelCustomizer);