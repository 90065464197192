import React from 'react';

import CommandButtonElement from '.../components/formComponents/formElements/CommandButtonElement';
import ImageElement from '.../components/formComponents/formElements/ImageElement';
import LabelElement from '.../components/formComponents/formElements/LabelElement';
import MultiColumnCombo from '.../components/formComponents/formElements/MultiColumnCombo';
import OneToManyElement from '.../components/formComponents/formElements/OneToManyElement';
import LxDynamicFormField from '.../components/formComponents/LxDynamicFormField';

class DynamicFormItem extends React.Component {
    render() {
        const {
            itemSchema, formMetaData, formData, recordContext,
            onChange, onBlur, onAddRecord, onDeleteRecords, setRecordCurrency, executeCommandButton, triggerScroll, viewName,
            settings, autoFocus, highlightedFieldKeyArray, highlightedRecordKey, clearHighlightedField, isLocked, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress
        } = this.props;

        let Element;

        const getRecord = (itemSchema, formData, recordContext) => {
            if (formData && formData.tables && recordContext) {
                return {
                    ...recordContext.getRecord(formData, itemSchema.tableName)
                };
            } else {
                return null;
            }
        };

        let targetRecord = getRecord(itemSchema, formData, recordContext);
        let disabled = (isLocked || (targetRecord?.isLocked !== undefined ? targetRecord?.isLocked : false));

        //TODO Need to implement override on defaultInputType form itemSchema
        switch (itemSchema.$lumedxType) {
            case 'ApolloField':
                Element = LxDynamicFormField;
                break;
            case 'CommandButton':
                Element = CommandButtonElement;
                break;
            case 'Label':
                Element = LabelElement;
                break;
            case 'Image':
                Element = ImageElement;
                break;
            case 'MultiColumnCombo':
                Element = MultiColumnCombo;
                break;
            case 'Grid':
                Element = OneToManyElement;
                break;
            //Do we even want a default here? Element = null causes errors so we should have a blank render element if there is no type.
            default:
                Element = null;
                break;
        }

        return (
            <Element
                itemSchema={itemSchema}
                formMetaData={formMetaData}
                formData={formData}
                executeCommandButton={executeCommandButton}
                recordContext={recordContext}
                settings={settings}
                onChange={onChange}
                onBlur={onBlur}
                onAddRecord={onAddRecord}
                onDeleteRecords={onDeleteRecords}
                setRecordCurrency={setRecordCurrency}
                autoFocus={autoFocus}
                highlightedFieldKeyArray={highlightedFieldKeyArray}
                highlightedRecordKey={highlightedRecordKey}
                clearHighlightedField={clearHighlightedField}
                triggerScroll={triggerScroll}
                disabled={disabled}
                mappingElementsStatus={mappingElementsStatus}
                onUpdateMappingStatus={onUpdateMappingStatus}
                formLoadProgress={formLoadProgress}
                viewName={viewName}
            />
        );
    }
}

export default DynamicFormItem;