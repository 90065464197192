import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

import userManager from '.../utils/userManager';
import CallbackTokenCatcher from './CallbackTokenCatcher';
import Login from './Login';
import { auditApi } from '.../utils/apiHelper';

class LoginManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userLoading: true
        };
    }

    componentDidMount() {
        this.loadUser();
        userManager.events.addUserLoaded(this.loadUser);
    }

    componentWillUnmount() {
        userManager.events.removeUserLoaded(this.loadUser);
    }

    loadUser = () => {
        userManager.getUser().then(user => {
            let currentToken = typeof this.state.user === 'undefined' ? '' : this.state.user.access_token;
            if (user !== null && typeof user !== 'undefined' && user.access_token !== currentToken) {
                this.setState({
                    userLoading: false,
                    user
                });
            } else {
                this.setState({
                    userLoading: false
                });
            }
        });
    }

    render() {
        const { userLoading, user } = this.state;

        if (userLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }
        else {
            if (user !== null && typeof user !== 'undefined'){
                if (!user.expired){
                    auditApi.userlogin();
                }
            }
        }

        return (
            <CallbackTokenCatcher>
                {!user || user.expired ? <Login /> : React.Children.only(this.props.children)}
            </CallbackTokenCatcher>
        );
    }
}

export default LoginManager;
