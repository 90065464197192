import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 75px)'
    },
    divider: {
        marginTop: '15px'
    },
    cardFooter: {
        display: 'flex',
        bottom: '0px',
        width: '100%',
        alignitems: 'right',
        justifycontent: 'space-between'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        padding: '0px 8px !important',
        height: '24px !important',
        display: 'flex'
    },
    editIcon: {
        flex: '0 0 auto'
    }
});

class ReportSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportRecord: null,
            isFinal: false,
            isDeleteConfirm: false
        };
    }

    OpenReport = (item) => (event) => {
        const { onOpenReport } = this.props;

        if (typeof onOpenReport === 'function') {
            onOpenReport(item);
        }
    }

    PrintReport = (item) => (event) => {
        const { onPrintReport } = this.props;

        if (typeof onPrintReport === 'function') {
            onPrintReport(item);
        }
    }

    handleStatusChange = (item) => (event) => {
        const { onStatusChange } = this.props;
        var changedState = event.target.value;
        if (typeof onStatusChange === 'function') {
            onStatusChange(item, changedState);
        }
    }

    handleDeleteReport = (item) => (event) => {
        const { onDeleteReport } = this.props;
        if (typeof onDeleteReport === 'function') {
            this.closeReportDeleteConfirmationDialog();
            onDeleteReport(item);
        }
    }

    onDeletingReport = (item) => (event) => {
        if (item.reportStatus === 'Final') {
            this.setState({
                isFinal: true
            });
        }

        this.setState({
            isDeleteConfirm: true
        });
    }

    closeReportDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirm: false
        });
    }

    reportClick = (report) => {
        this.setState({
            reportRecord: report
        });
    }

    resetGeneratedReportId = () => {
        const { onResetGeneratedReportId } = this.props;
        if (typeof onResetGeneratedReportId === 'function') {
            onResetGeneratedReportId();
        }
    }

    render() {
        const { classes, reports, isExistingReportLoading, reportStatusList, generatedReportId, reportSecurity } = this.props;
        const { reportRecord, isFinal, isDeleteConfirm } = this.state;
        var defaultStatusList = ['Preliminary', 'Pending Approved', 'Approved', 'Final'];
        var statusList = reportStatusList !== undefined ? reportStatusList : defaultStatusList;

        //below condition is written to focus on newly generated report in the grid.
        if (isExistingReportLoading === false) {
            if (generatedReportId > 0) {
                const generatedReport = reports.find(report => {
                    return report.tag.id === generatedReportId;
                });

                if (generatedReport !== undefined) {
                    this.setState({
                        reportRecord: generatedReport
                    }, () => { this.resetGeneratedReportId() });
                }
            }
        }

        return (
            <>
                <Card className={classes.card}>
                    <CardBody className={classes.cardBody}>
                        <LxSearchableTable
                            className={classes.table}
                            items={(reports !== null || reports !== undefined) ? reports : []}
                            columns={[
                                {
                                    id: 'delete',
                                    width: 65,
                                    resizable: false,
                                    className: classes.buttonCell,
                                    title: 'Delete',
                                    key: (item) => (
                                        <div>
                                            {
                                                (reportSecurity !== undefined && reportSecurity.canDelete !== undefined) ? (reportSecurity.canDelete ?
                                                    (
                                                        <Button className={classes.button} onClick={this.onDeletingReport(item)}>
                                                            <DeleteIcon />
                                                        </Button>) : (
                                                        <Button className={classes.button} onClick={this.onDeletingReport(item)} disabled>
                                                            <DeleteIcon />
                                                        </Button>
                                                    )) : (
                                                    <Button className={classes.button} onClick={this.onDeletingReport(item)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )
                                },
                                {
                                    title: 'Name',
                                    key: 'reportName',
                                    show: true
                                },
                                {
                                    title: 'Event Date',
                                    id: 'reportDate',
                                    key: (item) => {
                                        return item.reportDate !== null ? item.reportDate.toString().slice(0, 10) : item.reportDate;
                                    },
                                    show: true
                                },
                                {
                                    title: 'Status',
                                    key: 'reportStatus',
                                    id: 'reportStatus',
                                    width: 200,
                                    Cell: (item) => (
                                        <div>
                                            <select style={{ border: "none" }} defaultValue={item.value} onChange={this.handleStatusChange(item)}>
                                                {
                                                    statusList.map(option => {
                                                        let value = typeof option === 'string' ? option : option.value;
                                                        return (
                                                            <option key={value} value={value}>
                                                                {value}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    )
                                },
                                {
                                    id: 'view',
                                    title: 'View',
                                    width: 50,
                                    resizable: false,
                                    className: classes.buttonCell,
                                    key: (item) => (
                                        <Button className={classes.button} onClick={this.OpenReport(item)}>
                                            <VisibilityIcon />
                                        </Button>
                                    )
                                },
                                {
                                    id: 'print',
                                    title: 'Print',
                                    width: 50,
                                    resizable: false,
                                    className: classes.buttonCell,
                                    key: (item) => (
                                        <ConfirmationButton className={classes.button} onClick={this.PrintReport(item)}>
                                            <PrintIcon />
                                        </ConfirmationButton>
                                    )
                                }
                            ]}
                            onRowClick={this.reportClick}
                            selectedItem={reportRecord}
                            isLoading={isExistingReportLoading}
                            title={'Existing Reports'}
                        />
                    </CardBody>
                    <Dialog open={isDeleteConfirm} maxWidth="xl" onClose={this.closeReportDeleteConfirmationDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Permanently Delete the Report?"}</DialogTitle>
                        <DialogContent>
                            {isFinal === true ? (
                                <DialogContentText id="alert-dialog-description">
                                    This Report is marked as Final. Are you sure you want to delete it now?
                                </DialogContentText>)
                                :
                                (<DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this Report now?
                                </DialogContentText>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeleteReport(reportRecord)} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.closeReportDeleteConfirmationDialog} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            </>
        );
    }
}

export default withStyles(styles)(ReportSelector);