import { withStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/ToolTip';
import Close from '@material-ui/icons/Close';
import classnames from 'classnames';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import DynamicFormItem from '../DynamicFormItem';
import { ElementInitializationContext } from './ElementInitializationContext';
import CommandButtonPropertyEditor from './ElementPropertyEditor/CommandButtonPropertyEditor';
import DynamicFieldPropertyEditor from './ElementPropertyEditor/DynamicFieldPropertyEditor.js';
import GridPropertyEditor from './ElementPropertyEditor/GridPropertyEditor.js';
import LabelPropertyEditor from './ElementPropertyEditor/LabelPropertyEditor.js';
import MultiColumnPropertyEditor from './ElementPropertyEditor/MultiColumnPropertyEditor.js';
import PropertyCog from './ElementPropertyEditor/PropertyCog.js';

const styles = theme => ({
    itemContainer: {
        padding: '5px',
        height: '100%'
    },
    itemCard: {
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.7)',
        height: '100%',
        width: '100%'
    },
    errorHightlight: {
        boxShadow: `0 1px 8px 0 ${theme.palette.error.main}`
    },
    warningHightlight: {
        boxShadow: `0 1px 8px 0 ${theme.palette.warning.main}`
    },
    removeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    toolTip: {
        top: '20px !important'
    },
    cogButton: {
        position: "absolute",
        padding: "0px",
        height: "20.56px",
        width: "20.56px",
        right: "24.56px",
        top: "0px",
        zIndex: 10
    }
});

class FormEditorItem extends React.Component {
    static contextType = ElementInitializationContext;

    determineEditorType = (itemSchema) => {
        let PropertyEditor;
        
        switch (itemSchema?.$lumedxType) {
            case 'ApolloField':
                PropertyEditor = DynamicFieldPropertyEditor;
                break;
            case 'CommandButton':
                PropertyEditor = CommandButtonPropertyEditor;
                break;
            case 'Label':
                PropertyEditor = LabelPropertyEditor;
                break;
            case 'MultiColumnCombo':
                PropertyEditor = MultiColumnPropertyEditor;
                break;
            case 'Grid':
                PropertyEditor = GridPropertyEditor;
                break;
            default:
                PropertyEditor = null;
                break;
        }

        return PropertyEditor;
    }

    onResize = (width, height) => {
        const { itemSchema, updateMinHeights } = this.props;
        updateMinHeights(itemSchema, height);

        //This event needs to be raised otherwise the ResponsiveReactGridLayout will not change breakpoints on when the width changes. For example, for the Form View with an embedded FormSection requries this event.
        let event;
        if (typeof Event === 'function') {
            event = new Event('resize');
        } else {
            event = document.createEvent('Event');
            event.initEvent('resize', true, true);
        }
        window.dispatchEvent(event);
    }

    render() {
        const { classes, onRemoveItem, error, warning, itemSchema, onItemPropertiesChanged } = this.props;
        
        let elementInitializationContext = this.context;
        let formMetaData = elementInitializationContext.formMetaData;

        const cardClass = classnames({
            [classes.itemCard]: true,
            [classes.errorHightlight]: error,
            [classes.warningHightlight]: warning
        });
        
        let itemMetaData = formMetaData[itemSchema.metaDataKey];
        
        return (
            <ToolTip classes={{ popper: classes.toolTip }} title={`${itemSchema.metaDataKey}`} placement='top'>
                <div className={classes.itemContainer}>
                    <Card
                        classes={{
                            card: cardClass
                        }}
                    >
                        <CardBody>
                            <PropertyCog
                                itemSchema={itemSchema}
                                itemMetaData={itemMetaData}
                                formMetaData={formMetaData}
                                onItemPropertiesChanged={onItemPropertiesChanged}
                                determineEditorType={this.determineEditorType}
                                cogButtonCss={classes.cogButton}
                            />
                            <Close
                                className={classes.removeIcon}
                                onClick={onRemoveItem}
                            />
                            <div>
                                <ReactResizeDetector
                                    handleHeight
                                    handleWidth
                                    skipOnMount
                                    onResize={this.onResize}
                                    refreshMode='throttle'
                                    refreshRate={100}
                                    refreshOptions={{ leading: true }}
                                />
                                <DynamicFormItem
                                    itemSchema={itemSchema}
                                    formMetaData={formMetaData}
                                    itemValidation={{
                                        ChildDisabled: true,
                                        ErrorDescription: null
                                    }}
                                    itemValue={{
                                        value: ''
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </ToolTip>
        );
    }
}

export default withStyles(styles)(FormEditorItem);