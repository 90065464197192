import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    page: {
        textAlign: 'center'
    }
});

const Unauthorized = (props) =>
{
    let onBackClick = (event) =>{
        event.preventDefault();
        props.history.goBack();
    };

    const { features } = props;

    return(
        <div>
            <div className={props.classes.page}>
                <h1>Unauthorized</h1>
                <p>Sorry, you do not have access to this page</p>
                {
                    Array.isArray(features) && features.length > 0 ?
                        <div>
                            <p>You need access to one of the following features:</p>
                            {features.map(level =>
                                <p key={level}>{level}</p>
                            )}
                        </div>
                        :
                        null
                }
                <p>Contact your system administrator to gain access</p>
                <a href="" onClick={(event) => onBackClick(event)}>Back</a>
            </div>
        </div>
    );
};

export default withStyles(styles)(withRouter(Unauthorized));