import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from './PropertyEditor';
import LabelCustomizer from './SubEditors/LabelCustomizer';

const styles = theme => ({

});

class LabelPropertyEditor extends React.Component {

    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                title="Label Property Editor"
                properties={[
                    { 
                        name: "text", 
                        label: "Text", 
                        type: "text"
                    },
                    {
                        name: "style",
                        CustomRender: LabelCustomizer
                    }
                ]}
            />
        );
    }
}

export default withStyles(styles)(LabelPropertyEditor);