import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { Button, Divider, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Check, Error, Refresh } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../assets/components/Card/Card';
import { pushToError, settingsApi } from '.../utils/apiHelper';

const styles = theme => ({
    cardStyle: {
        maxWidth: '550px',
        margin: 'auto'
    },
    buttonStyle: {
        border: `1px solid ${theme.palette.lxGreen.main}`,
        backgroundColor: theme.palette.lxGreen.light,
        color: 'white',
        margin: 'auto',
        marginBottom: '25px'
    },
    statusIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        height: '30px',
        width: '30px'
    },
    successIcon: {
        color: theme.palette.lxGreen.main
    },
    failureIcon: {
        color: theme.palette.error.main
    },
    progressCircle: {
        color: 'white'
    },
    bodyText: {
        marginLeft: '25px',
        marginRight: '25px',
        textAlign: 'justify'
    }
});

const SUCCESS_STATUS = "success";
const FAILURE_STATUS = "fail";
const IN_PROGRESS_STATUS = "pending";

function ResetAppContextContainer(props) {
    const { classes } = props;
    const [refreshStatus, setRefreshStaus] = React.useState(null);
    let patientId = props.patientInfo === null ? undefined : props.patientInfo.patientId;

    let handleResetRequest = () => {
        setRefreshStaus(IN_PROGRESS_STATUS);
        settingsApi.resetAppContext().then(() => {
            setRefreshStaus(SUCCESS_STATUS);
        }).catch(error => {
            setRefreshStaus(FAILURE_STATUS);
            pushToError(error, patientId);
        });
    };

    return (
        <Card className={classes.cardStyle}>
            <Typography variant="h4">
                Reset Cached System Data
            </Typography>
            {refreshStatus === SUCCESS_STATUS ?
                <Check className={`${classes.statusIcon} ${classes.successIcon}`} />
                : refreshStatus === FAILURE_STATUS ?
                    <Error className={`${classes.statusIcon} ${classes.failureIcon}`}/>
                    : refreshStatus === IN_PROGRESS_STATUS ?
                        <CircularProgress size={30} thickness={7} className={classes.statusIcon}/>
                        : undefined
            }
            <Divider />
            <Typography variant="body1" className={classes.bodyText}>
                This button will clear out data that has been saved on the server for optimization reasons so that it can be refetched from the database. This is similar in effect to restarting the server.
            </Typography>
            <br />
            <Typography variant="body1" className={classes.bodyText}>
                The Refresh is safe to click, the only downside is that the first time content is accessed after the refresh it may take longer since the 
                app will have to go back to the database to get data. For some items, the difference in speed may not even be noticable.
            </Typography>
            <br />
            <Typography variant="body1" className={classes.bodyText}>
                This should be used to update the application if changes were made to system data directly through the backend, like if a new module was installed or a Selection Set was modified in SQL.
            </Typography>
            <br />
            <Button onClick={handleResetRequest} className={classes.buttonStyle} >
                {refreshStatus === IN_PROGRESS_STATUS ?
                    <CircularProgress size={20} thickness={7} className={classes.progressCircle}/>
                    : <Refresh />
                }
                <Typography variant='h6'>
                    Refresh
                </Typography>
            </Button>
        </Card>
    );
} 

export default withStyles(styles)(withRouter(connect((state) => ({ patientInfo: state.demographics }))(ResetAppContextContainer)));