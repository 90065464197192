import { ListItemIcon, ListItemSecondaryAction } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Event from '@material-ui/icons/Event';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    listItem: {
        margin: 'auto'
    },
    avatar : {
        margin: 'auto',
        backgroundColor: 'transparent'
    },
    listItemText: {
        color: '#000000',
        margin: 'auto'
    },
    formLinkText: {
        color: '#000000' 
    },
    defaultFormLinkText: {
        color: '#000000',
        fontWeight: 'bold'
    },
    formSelectorList: {
        maxHeight: '75vh',
        overflowY: 'auto'
    }
});

//Commented out sections of code in this file are for a "More Options" button on each record. Currently, our only additional action is deleting the record, which doesn't warrent a whole dropdown for one item. 
//Instead, I've disabled it, but if we want to add new items we just need to uncomment all the commented code in this class.
const PatientEventsItem = (props) => {
    const { classes, record, formTable, deleteRecord, toggleRecordLock, routeUrl } = props;
    const [formSelectorOpen, setFormSelectorOpen] = useState(false);
    // const [actionSelectorOpen, setActionSelectorOpen] = useState(false);
    const [optimisticLockState, setOptimisticLockState] = useState(record.locked);
    const [formListAnchorEl, setFormListAnchorEl] = useState(null);
    // const [actionListAnchorEl, setActionListAnchorEl] = useState(null);
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);

    let openFormSelector = (event) => {
        event.preventDefault();
        setFormSelectorOpen(true);
    };

    let closeFormSelector = () => {
        setFormSelectorOpen(false);
    };

    // let openActionSelector = (event) => {
    //     event.preventDefault();
    //     setActionSelectorOpen(true);
    // };

    // let closeActionSelector = () => {
    //     setActionSelectorOpen(false);
    // };

    let handleToggleClick = (tableName, eventId) => (event) => {
        //This logic fixes a bug with double clicking on the lock icon getting it stuck in a permanently loading state. 
        //What happened was the first call would toggle state, and the optimistic update would assume the opposite of record.locked would be the result. But the second click, record.locked was still the old value, 
        //so it would expect to get the opposite of the original record.locked from the backend but that would never come because the second click toggles it back to the original record.locked value.
        setOptimisticLockState(record.locked !== optimisticLockState ? !optimisticLockState : !record.locked);
        toggleRecordLock(tableName, eventId, event);
    };

    let openDeleteWarning = (event) => {
        event.preventDefault();
        setDeleteWarningOpen(true);
    };

    let closeDeleteWarning = (shouldDelete) => (event) => {
        setDeleteWarningOpen(false);
        if (shouldDelete) {
            deleteRecord(formTable.tableName, record.eventId, event);
        }
    };

    let renderDirectFormLink = (header) => {
        let isDefaultForm = record.defaultFormName === header.formName;
        return (
            <Link key={header.formName} className={classes.formLink} to={`${routeUrl}/${formTable.tableName}/${record.eventId}/${header.formName}`}>
                <ListItem key={header.formName} dense button>
                    <ListItemText
                        primaryTypographyProps={{
                            className: isDefaultForm ? classes.defaultFormLinkText : classes.formLinkText
                        }}
                        primary={header.formDisplayName}
                    />
                    {isDefaultForm ?
                        <ListItemSecondaryAction>
                            <Tooltip title="This is the default form">
                                <BeenhereOutlinedIcon color='secondary' />
                            </Tooltip>
                        </ListItemSecondaryAction>
                        :
                        null
                    }
                </ListItem>
            </Link>
        );
    };

    let allowLockUnlock = (record.locked && formTable.moduleSecurity?.allowUnlock) || (!record.locked && formTable.moduleSecurity?.allowLock);
    let RecordIcon = record.locked !== optimisticLockState ? <CircularProgress size={20} /> : record.locked ? <LockIcon color={allowLockUnlock ? "secondary" : undefined} /> : <LockOpenIcon color={allowLockUnlock ? "secondary" : undefined} />;
    if (!formTable.supportsLocking) {
        RecordIcon = <Event/>;
    }

    return (
        <div>
            <Link to={`${routeUrl}/${formTable.tableName}/${record.eventId}/${record.defaultFormName}`}>
                <ListItem className={classes.listItem} button>
                    <Tooltip title={record.locked ? "Unlock Record" : "Lock Record"} placement="bottom">
                        <IconButton
                            onClick={handleToggleClick(formTable.tableName, record.eventId)}
                            disabled={!formTable.supportsLocking || !allowLockUnlock}
                        >
                            {RecordIcon}
                        </IconButton>
                    </Tooltip>
                    <ListItemText
                        className={classes.listItemText}
                        primary={record.viewString}
                    />
                    {formTable.formHeaders.length > 1 ?
                        <Tooltip title="Select a Form" placement="bottom">
                            <IconButton
                                onClick={openFormSelector}
                                color={formSelectorOpen ? 'primary' : 'secondary'}
                                ref={setFormListAnchorEl}
                            >
                                <ViewCarouselIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        null
                    }
                    <Tooltip title="Delete Record" placement="bottom">
                        <IconButton
                            onClick={openDeleteWarning}
                            color={'secondary'}
                            disabled={record.locked || record.locked !== optimisticLockState || !formTable.moduleSecurity.allowDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="More Options..." placement="bottom">
                        <IconButton
                            onClick={openActionSelector}
                            color={'secondary'}
                            ref={setActionListAnchorEl}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip> */}
                </ListItem>
            </Link>
            <Divider />
            {/* Form Selector Menus */}
            <Popper
                open={formSelectorOpen}
                anchorEl={formListAnchorEl}
                placement="right-start"
                modifiers={{
                    flip: {
                        enabled: true
                    }
                }}
            >
                <ClickAwayListener onClickAway={closeFormSelector}>
                    <Paper>
                        <Typography
                            variant={'h6'}
                        >
                            {"Forms"}
                        </Typography>
                        <Divider />
                        <List className={classes.formSelectorList}>
                            {formTable.formHeaders.map((header) => {
                                return (renderDirectFormLink(header));
                            })}
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            {/* Action Selector Menu */}
            {/* <Popper
                open={actionSelectorOpen}
                anchorEl={actionListAnchorEl}
                placement="right-start"
                modifiers={{
                    flip: {
                        enabled: true
                    }
                }}
            >
                <ClickAwayListener onClickAway={closeActionSelector}>
                    <Paper>
                        {/* <Typography
                            variant={'h6'}
                        >
                            {"Forms"}
                        </Typography>
                        <Divider />
                        <List onClick={() => setActionSelectorOpen(false)} className={classes.formSelectorList}>
                            <ListItem onClick={handleToggleClick(formTable.tableName, record.eventId)} button>
                                <ListItemIcon color={'secondary'}>
                                    {record.locked !== optimisticLockState ? <CircularProgress size={20} /> : record.locked ? <LockIcon /> : <LockOpenIcon />}
                                </ListItemIcon>
                                <ListItemText primary={record.locked ? "Unlock Record" : "Lock Record"} />
                            </ListItem>
                            <ListItem onClick={openDeleteWarning} button disabled={record.locked || record.locked !== optimisticLockState}>
                                <ListItemIcon color={'secondary'}>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Delete Record"} />
                            </ListItem>
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper> */}
            {/* Delete Warning Prompt */}
            <Dialog open={deleteWarningOpen} onClose={closeDeleteWarning(false)}>
                <DialogTitle>Permanently Delete Record?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you continue, the record will be permanently deleted. Ensure this is the record you want to delete before confirming.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteWarning(false)}>
                        Cancel
                    </Button>
                    <Button onClick={closeDeleteWarning(true)}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(PatientEventsItem);