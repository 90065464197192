import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCheckbox from '.../components/formComponents/LxCheckbox';
import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 50px)',
        width: '100%'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        display: 'flex'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class SelectionSetEditor extends React.Component {

    renderSelectionSetEditor = () => {
        const { classes, populateSelectionSet } = this.props;

        return (
            <GridContainer className={classes.flex} spacing={5}>
                <GridItem xs={6} >
                    <LxTextField
                        align='center'
                        title='Selection Set'
                        value={populateSelectionSet?.selectionSet}
                        max={255}
                        onChange={this.onSelectionSetNameChange}
                    />
                </GridItem>
                <GridItem xs={6} />
                <GridItem xs={6}>
                    <LxCheckbox
                        title={'Locked'}
                        titleTooltip={'Form Lock'}
                        description={'Form Lock'}
                        value={populateSelectionSet.formLock === null ? false : populateSelectionSet.formLock}
                        threeState={false}
                        onChange={this.onFormLockChange}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <LxCheckbox
                        title={'Limit To List'}
                        titleTooltip={'User Lock'}
                        description={'User Lock'}
                        value={populateSelectionSet.userLock === null ? false : populateSelectionSet.userLock}
                        threeState={false}
                        onChange={this.onUserLockChange}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <LxCheckbox
                        title={'Entry Label'}
                        titleTooltip={'Clinical Doc'}
                        description={'Clinical Doc'}
                        value={populateSelectionSet.clinicalDoc === null ? false : populateSelectionSet.clinicalDoc}
                        threeState={false}
                        onChange={this.onClinicalDocChange}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <LxCheckbox
                        title={'Allow Favorites'}
                        titleTooltip={'Attribute'}
                        description={'Attribute'}
                        value={populateSelectionSet.attributes === 0 ? false : true}
                        threeState={false}
                        onChange={this.onAttributeChange}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    onSelectionSetNameChange = (newSelectionSetName) => {
        this.setState({
            newSelectionSetName
        });

        const { populateSelectionSet, onSelectionSetChange } = this.props;
        if (populateSelectionSet?.selectionSet !== newSelectionSetName) {
            let newSelectionSet = {
                ...populateSelectionSet,
                selectionSet: newSelectionSetName
            };
            onSelectionSetChange(newSelectionSet, populateSelectionSet.selectionSet);
        }
    }

    onFormLockChange = (newFormLock) => {
        this.setState({
            newFormLock
        });

        const { populateSelectionSet, onSelectionSetChange } = this.props;
        if (populateSelectionSet?.formLock !== newFormLock) {
            let newSelectionSet = {
                ...populateSelectionSet,
                formLock: newFormLock
            };
            onSelectionSetChange(newSelectionSet, populateSelectionSet.formLock);
        }
    }

    onUserLockChange = (newUserLock) => {
        this.setState({
            newUserLock
        });

        const { populateSelectionSet, onSelectionSetChange } = this.props;
        if (populateSelectionSet?.userLock !== newUserLock) {
            let newSelectionSet = {
                ...populateSelectionSet,
                userLock: newUserLock
            };
            onSelectionSetChange(newSelectionSet, populateSelectionSet.userLock);
        }
    }

    onClinicalDocChange = (newClinicalDoc) => {
        this.setState({
            newClinicalDoc
        });

        const { populateSelectionSet, onSelectionSetChange } = this.props;
        if (populateSelectionSet?.clinicalDoc !== newClinicalDoc) {
            let newSelectionSet = {
                ...populateSelectionSet,
                clinicalDoc: newClinicalDoc
            };
            onSelectionSetChange(newSelectionSet, populateSelectionSet.clinicalDoc);
        }
    }

    onAttributeChange = (newAttributes) => {
        this.setState({
            newAttributes
        });

        const { populateSelectionSet, onSelectionSetChange } = this.props;
        var attributeValue = populateSelectionSet?.attributes === 0 ? false : true;
        if (attributeValue !== newAttributes) {
            let newSelectionSet = {
                ...populateSelectionSet,
                attributes: (newAttributes === false ? 0 : 1)
            };
            onSelectionSetChange(newSelectionSet, populateSelectionSet.attributes);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Card className={classes.card}>
                    <CardBody>
                        {this.renderSelectionSetEditor()}
                    </CardBody>
                </Card>
            </>
        );
    }
}
export default withStyles(styles)(SelectionSetEditor);