import React from 'react';

import { formApi } from '.../utils/apiHelper';

class ElementInfoObject {
    constructor (onUpdateMethod) {
        if (typeof onUpdateMethod === 'function'){
            this.onUpdate = onUpdateMethod;
        }
    }

    validFields = {}
    registeredTables = []
    displaySize = 'lg'
    formMetaData = {}
    viewName = ''
    onUpdate;

    setViewName = (newViewName) => {
        this.viewName = newViewName;
        this.registeredTables.forEach((tableName) => {
            this.loadValidFields(tableName);
        });
    }

    loadValidFields = (tableName) => {
        this.validFields[tableName] = this.validFields[tableName] ? this.validFields[tableName] : {
            loadingFields: false,
            metaDataList: null
        };
        let validFieldObject = this.validFields[tableName];
        validFieldObject.viewName = this.viewName;

        if (tableName) {
            validFieldObject.loadingFields = true;

            formApi.getEventTableFields(tableName, this.viewName).then(formItems => {
                validFieldObject.loadingFields = false;
                validFieldObject.metaDataList = formItems;
                this.onUpdate(this);
            });
        }
        this.onUpdate(this);
    }

    registerTable = (tableName) => {
        if (!this.registeredTables.includes(tableName)) {
            this.registeredTables.push(tableName);
            this.loadValidFields(tableName);
        }
    }

    addMetaData = (newMetaData) => {
        this.formMetaData[newMetaData.uniqueKey] = newMetaData;
        this.onUpdate(this);
    }
}

export default ElementInfoObject;

export const ElementInitializationContext = React.createContext({
    validFields: {}
});