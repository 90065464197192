import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { measurementsApi, pushToError, trendsApi } from '.../utils/apiHelper';
import AuthorizationFilter from '.../utils/AuthorizationFilter';
import TrendsCharts from './TrendsCharts';
import TrendsWizardContainer from './TrendWizard/TrendsWizardContainer';

class TrendsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dialogOpen: false
        };
    }

    componentDidMount() {
        Promise.all([
            trendsApi.getTrendsCharts(this.props.patientInfo.ssPatientId),
            measurementsApi.getAdultEchoMeasurement()
        ]).then(([charts, measurements]) => this.setState({
            charts: charts.sort((a, b) => {
                return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
            }),
            measurements,
            ssPatientId: parseInt(this.props.patientInfo.ssPatientId, 10),
            isLoading: false
        })).catch((error) => pushToError(error, this.props.match.params.mrn));
    }

    updateChartsList = (reRender) => {
        trendsApi.getTrendsCharts(this.state.ssPatientId)
            .then((charts) => this.setState({
                charts: charts.sort((a, b) => {
                    return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
                }),
                reRender
            })).catch((error) => pushToError(error, this.props.match.params.mrn));
    }

    turnOffChartReRender = () => {
        this.setState({
            reRender: undefined
        });
    }

    toggleDialog = () => {
        this.setState({
            dialogOpen: !this.state.dialogOpen
        });
    }

    render() {
        const { isLoading, charts, measurements, ssPatientId, dialogOpen, reRender } = this.state;
        return (
            isLoading ?
                <CircularProgress size={60} thickness={7} />
                :
                <div style={{marginTop: '42px'}}>
                    <GridContainer justify='space-between' spacing={4}>
                        <AuthorizationFilter allowed='Trends:Administrator'>
                            <GridItem>
                                <Button color='primary' variant='contained' onClick={this.toggleDialog}>
                                    {"Manage Trends"}
                                </Button>
                            </GridItem>
                        </AuthorizationFilter>
                    </GridContainer>
                    <GridContainer spacing={4} justify='center'>
                        {charts.map((chart, index) =>
                            <GridItem key={`chart-${chart.ssCopilotChartId}`} sm={12} lg={6}>
                                <TrendsCharts chartId={chart.ssCopilotChartId} ssPatientId={ssPatientId} reRender={reRender} turnOffChartReRender={this.turnOffChartReRender} />
                            </GridItem>
                        )}
                    </GridContainer>
                    <TrendsWizardContainer updateChartsList={this.updateChartsList} dialogOpen={dialogOpen} toggleDialog={this.toggleDialog} charts={charts} measurements={measurements} ssPatientId={ssPatientId} />
                </div>
        );
    }
}

export default connect((state) => ({ patientInfo: state.demographics }))(withRouter(TrendsContainer));