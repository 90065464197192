import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxSearchableTable from './LxSearchableTable';

const styles = theme => ({
    gridContainer: {
        height: '100%'
    },
    gridItemColumn: {
        maxWidth: '100%',
        maxHeight: '50%'
    },
    gridItemRow: {
        maxWidth: '50%',
        maxHeight: '100%'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        '& svg': {
            backgroundColor: '#fff',
            borderRadius: '12px'
        }
    }
});

class LxItemSelectionTables extends React.Component {
    filterPoolItems = (selectedItems) => (poolItem) => {
        const { itemToString } = this.props;
        const selectedItemsStrings = selectedItems.map(item => itemToString(item));

        if (selectedItemsStrings.indexOf(itemToString(poolItem)) >= 0) {
            return false;
        } else {
            return true;
        }
    }

    removeItem = (item) => (event) => {
        const { selectedItems, poolItems, onStateChange } = this.props;
        let newSelectedItems = [...selectedItems];

        event.preventDefault();

        let itemIndex = selectedItems.map(item => JSON.stringify(item)).indexOf(JSON.stringify(item));

        newSelectedItems.splice(itemIndex, 1);

        let newPoolItems = poolItems.filter(this.filterPoolItems(newSelectedItems));

        if (typeof onStateChange === 'function') {
            onStateChange({
                selectedItems: newSelectedItems,
                poolItems: newPoolItems
            });
        }
    }

    addItem = (item) => (event) => {
        const { selectedItems, poolItems, onStateChange } = this.props;
        let newSelectedItems = [...selectedItems];

        event.preventDefault();

        newSelectedItems.push(item);

        let newPoolItems = poolItems.filter(this.filterPoolItems(newSelectedItems));

        if (typeof onStateChange === 'function') {
            onStateChange({
                selectedItems: newSelectedItems,
                poolItems: newPoolItems
            });
        }
    }

    render() {
        const {
            selectedItem,
            selectedItems,
            selectedItemsLoading,
            selectedColumns,
            selectedSearchKeys,
            selectedRowClick,
            selectedTitle,
            selectedPlaceholder,
            selectedLabel,
            poolItems,
            poolItemsLoading,
            poolColumns,
            poolSearchKeys,
            poolRowClick,
            poolTitle,
            poolPlaceholder,
            poolLabel,
            gridDirection,
            filterPoolItems,
            className,
            classes
        } = this.props;

        const gridItemClass = classNames({
            [classes.gridItemColumn]: gridDirection.indexOf('column') >= 0,
            [classes.gridItemRow]: gridDirection.indexOf('row') >= 0
        });

        return (
            <GridContainer
                className={`${className ? className + ' ' : ''}${classes.gridContainer}`}
                spacing={4}
                direction={gridDirection}
            >
                <GridItem sm={6} className={gridItemClass}>
                    <LxSearchableTable
                        items={selectedItems}
                        selectedItem={selectedItem}
                        isLoading={selectedItemsLoading}
                        columns={[
                            {
                                id: 'remove',
                                width: 75,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Remove',
                                key: (item) => (
                                    <Button className={classes.button} onClick={this.removeItem(item)}>
                                        <RemoveIcon />
                                    </Button>
                                )
                            },
                            ...selectedColumns
                        ]}
                        searchKeys={selectedSearchKeys}
                        onRowClick={selectedRowClick}
                        title={selectedTitle}
                        placeholder={selectedPlaceholder}
                        label={selectedLabel}
                    />
                </GridItem>
                <GridItem sm={6} className={gridItemClass}>
                    <LxSearchableTable
                        items={filterPoolItems ?
                            poolItems.filter(this.filterPoolItems(selectedItems))
                            :
                            poolItems
                        }
                        selectedItem={selectedItem}
                        isLoading={poolItemsLoading}
                        columns={[
                            {
                                id: 'add',
                                width: 75,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Add',
                                key: (item) => (
                                    <Button className={classes.button} onClick={this.addItem(item)}>
                                        <AddIcon />
                                    </Button>
                                )
                            },
                            ...poolColumns
                        ]}
                        searchKeys={poolSearchKeys}
                        onRowClick={poolRowClick}
                        title={poolTitle}
                        placeholder={poolPlaceholder}
                        label={poolLabel}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

LxItemSelectionTables.propTypes = {
    gridDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    filterPoolItems: PropTypes.bool,
    onStateChange: PropTypes.func,
    itemToString: PropTypes.func,
    selectedItem: PropTypes.object,
    selectedItems: LxSearchableTable.Naked.propTypes.items,
    selectedItemsLoading: LxSearchableTable.Naked.propTypes.isLoading,
    selectedColumns: LxSearchableTable.Naked.propTypes.columns,
    selectedSearchKeys: LxSearchableTable.Naked.propTypes.searchKeys,
    selectedRowClick: LxSearchableTable.Naked.propTypes.onRowClick,
    selectedTitle: LxSearchableTable.Naked.propTypes.title,
    selectedPlaceholder: LxSearchableTable.Naked.propTypes.placeholder,
    selectedLabel: LxSearchableTable.Naked.propTypes.label,
    poolItems: LxSearchableTable.Naked.propTypes.items,
    poolItemsLoading: LxSearchableTable.Naked.propTypes.isLoading,
    poolColumns: LxSearchableTable.Naked.propTypes.columns,
    poolSearchKeys: LxSearchableTable.Naked.propTypes.searchKeys,
    poolRowClick: LxSearchableTable.Naked.propTypes.onRowClick,
    poolTitle: LxSearchableTable.Naked.propTypes.title,
    poolPlaceholder: LxSearchableTable.Naked.propTypes.placeholder,
    poolLabel: LxSearchableTable.Naked.propTypes.label
};

LxItemSelectionTables.defaultProps = {
    gridDirection: 'row',
    filterPoolItems: true,
    itemToString: JSON.stringify
};

export default withStyles(styles)(LxItemSelectionTables);