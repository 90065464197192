import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from "react-router-dom";

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import regularFormsStyle from ".../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import PatientEventsItem from './PatientEventsItem';

const useStyles = makeStyles((theme) => ({
    ...regularFormsStyle,
    cardSection:{
        marginBottom: '5px',
        marginTop: '20px'
    },
    scrollBar:{
        maxHeight: '35vh',
        position: 'relative',
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    }
}));

const PatientEvents = (props) => {
    const { formTable, routeUrl, toggleRecordLock, deleteRecord } = props;
    const classes = useStyles();
    let renderListItem = (record) => <PatientEventsItem key={record.eventId} routeUrl={routeUrl} record={record} formTable={formTable} toggleRecordLock={toggleRecordLock} deleteRecord={deleteRecord}/>;

    return (
            <Card>
                <CardHeader icon className={classes.cardSection}>
                    <h4 className={classes.cardTitle}>{formTable.description}</h4>
                </CardHeader>
                <CardBody>
                    <div className={classes.scrollBar}>
                        <Divider />
                        {(formTable.recordList.length > 0) ?
                            formTable.recordList.map((record) => renderListItem(record))
                            :
                            <div>
                                {'No records found, please create one.'}
                                <Divider />
                            </div>
                        }
                    </div>
                    <Link to={`${routeUrl}/${formTable.tableName}/0/${formTable.formHeaders[0].formName}`} >
                        <Button variant='contained' color='primary' className={classes.cardSection}>
                            {'Add New Event'}
                        </Button>
                    </Link>
                </CardBody>
            </Card>
    );
};

PatientEvents.propTypes = {
    formTable: PropTypes.object.isRequired
};

export default PatientEvents;
