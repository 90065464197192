import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from '../PropertyEditor';

const styles = theme => ({

});

function QueryParameterSubEditor(props) {
    const { classes, isOpen, onConfirm, closeDialog, itemSchema } = props;

    return (
        <PropertyEditor
            classes={classes}
            isOpen={isOpen}
            closeDialog={closeDialog}
            onConfirm={onConfirm}
            itemSchema={itemSchema}
            title="Dynamic Query Parameter Editor"
            properties={[
                {
                    name: "placeholder",
                    label: "Placeholder",
                    type: "text",
                    description: "The placeholder is the case insensitive text that will be used in the query to denote where you want the value to be inserted."
                        + " You do not need to surround the placeholder with quotes if it is a varchar or text field. Make sure the placeholder is not used anywhere"
                        + " else in your query, e.g. using a placeholder of 'Date' when you have a field that is 'Surgery_Date' will cause problems."
                },
                {
                    name: "inputExpression",
                    label: "Input Expression",
                    type: "text",
                    description: "This is the expression that will be evaluated against the current record. I'ts result will replace the Placeholder in the query."
                }
            ]}
        />
    );

}

export default withStyles(styles)(QueryParameterSubEditor);