import "core-js/stable";
import "regenerator-runtime/runtime";
import 'typeface-roboto';

import ".../assets/scss/material-dashboard-pro-react.css";

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
