import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from './PropertyEditor';

const styles = theme => ({

});

class DynamicFieldPropertyEditor extends React.Component {
    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema, itemMetaData } = this.props;

        let properties = [
            {
                name: "displayName",
                label: "Display Name",
                type: "text"
            },
            {
                name: "tabIndex",
                label: "Tab sequence",
                type: "int"
            },
            {
                name: "readOnly",
                label: "Read Only",
                type: "checkBox"
            }
        ];

        if(itemMetaData.defaultInputType?.toLowerCase() === "radiogroup"){
            properties.push({
                    name: "labelPosition",
                    label: "Label position",
                    type: "radioGroup",
                    options: ["top", "left"]
                });
        }
        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                title="Field Property Editor"
                properties={properties}
            />
        );
    }
}

export default withStyles(styles)(DynamicFieldPropertyEditor);