import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccessTime from '@material-ui/icons/AccessTime';
import { KeyboardTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { USE_MILITARY_TIME } from '.../components/settings/SettingKeys';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    inputRoot: {
        fontSize: theme.typography.fontSize
    },
    dialog: {
        '& .MuiPickersTimePickerToolbar-ampmSelection': {
            marginRight: '0px'
        },
        '& .MuiPickersToolbarButton-toolbarBtn': {
            height: 'auto !important',
            padding: '0px'
        },
        '& .MuiPickersTimePickerToolbar-separator': {
            marginBottom: '8px'
        },
        '& .MuiPickersModal-dialogRoot': {
            maxWidth: '415px',
            minWidth: '340px'
        },
        '& .MuiPickersBasePicker-pickerView': {
            maxWidth: '415px',
            minWidth: '340px'
        }
    },
    gridItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    inputGridItem: {
        width: '100%'
    }
});

const addZeroCharPadding = (num) => ((`${num}`).length === 1 ? `0${num}` : num);

const convertTimeToISO8601 = (date) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
        let hour = addZeroCharPadding(date.getHours());
        let min = addZeroCharPadding(date.getMinutes());
        let sec = addZeroCharPadding(date.getSeconds());
        return `1899-12-30T${hour}:${min}:${sec}.000`;
    } else {
        return null;
    }
};

function LxTimePicker(props) {
    const {
        value,
        defaultValue,
        borderStyle,
        classes,
        error,
        disabled,
        title,
        descriptions,
        titleTooltip,
        valueOptions,
        settings,
        onBlur,
        helperText,
        hasMappings,
        tabIndex,
        autoFocus,
        forcedFocus,
        validationErrorSeverity,
        inputRef,
        inputOnly,
        fieldInformation,
        fieldInfo
    } = props;
    const [invalidDate, setInvalidDate] = React.useState(null);
    const [internalValue, setInternalValue] = React.useState(defaultValue);

    //If value prop passed to this object is undefined, use defaultValue instead
    const useInternalValue = typeof value === 'undefined';
    let calculatedValue = useInternalValue ? internalValue : value;
    let pickerValue;
    if (invalidDate !== null) {
        pickerValue = invalidDate;
    } else if (calculatedValue) {
        pickerValue = new Date(calculatedValue);
    } else {
        pickerValue = null;
    }

    let timeFormat = settings && settings[USE_MILITARY_TIME] === true ?
        {
            mask: "__:__",
            placeholder: "00:00",
            ampm: false
        }
        :
        {
            mask: "__:__ _M",
            placeholder: "12:00 AM",
            ampm: true
        };

    let handleChange = (date) => {
        const { onChange, onBlur } = props;
        let dateString = convertTimeToISO8601(date);
        if (date !== null && date.toString() === 'Invalid Date') {
            setInvalidDate(date);
            return;
        } else {
            setInvalidDate(null);
            if (useInternalValue) {
                setInternalValue(date);
            }
        }
        if (typeof onChange === 'function') {
            onChange(dateString);
        }
        if (typeof onBlur === 'function') {
            onBlur(dateString);
        }
    };

    let handleKeyDown = (event) => {
        //Insert-Key keycode = 45
        if (event.keyCode === 45 && event.ctrlKey) {
            event.preventDefault();
            let newDate = new Date();
            handleChange(newDate);
        }
    };

    return (
        <FormControl disabled={disabled} style={borderStyle}>
            {inputOnly ?
                null
                :
                <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                    <GridItem className={classes.gridItem}>
                        <FormLabel>
                            <Tooltip title={titleTooltip} placement="top">
                                <Typography align="left" color='inherit' variant='body2'>
                                    {title}
                                </Typography>
                            </Tooltip>
                        </FormLabel>
                    </GridItem>
                    <GridItem>
                        <FieldHeader
                            value={calculatedValue}
                            descriptions={descriptions}
                            valueOptions={valueOptions}
                            onValueSelect={onBlur}
                            hasMappings={hasMappings}
                            error={error}
                            validationErrorSeverity={validationErrorSeverity}
                            helperText={helperText}
                            fieldInformation={fieldInformation}
                            fieldInfo={fieldInfo}
                        />
                    </GridItem>
                </GridContainer>
            }
            <GridContainer wrap="nowrap" alignItems="center">
                <GridItem className={classes.inputGridItem}>
                    <KeyboardTimePicker
                        className={classes.inputGridItem}
                        clearable
                        value={pickerValue}
                        onChange={handleChange}
                        ampm={timeFormat.ampm}
                        mask={timeFormat.mask}
                        placeholder={timeFormat.placeholder}
                        keyboardIcon={<AccessTime />}
                        views={["hours", "minutes"]}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot
                            },
                            inputProps: {
                                tabIndex: tabIndex,
                                autoFocus: autoFocus
                            }
                        }}
                        inputRef={fieldInputRef => {
                            if (forcedFocus && fieldInputRef) {
                                fieldInputRef.focus();
                            }
                            if (typeof inputRef === 'function') {
                                inputRef(fieldInputRef);
                            }
                        }}
                        DialogProps={{
                            className: `nonDraggableSection ${classes.dialog}`
                        }}
                        disabled={disabled}
                    />
                    {error && helperText && validationErrorSeverity === 1 ?
                        <FormHelperText error={false} className={classes.colorError}>{helperText}</FormHelperText>
                        :
                        null
                    }
                </GridItem>
                {inputOnly ?
                    <GridItem>
                        <ValidationErrorIcon
                            error={error}
                            validationErrorSeverity={validationErrorSeverity}
                            helperText={helperText}
                        />
                    </GridItem>
                    :
                    null
                }
            </GridContainer>
        </FormControl>
    );
}

LxTimePicker.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool
};

LxTimePicker.defaultProps = {
    disablePast: false,
    disableFuture: false,
    disabled: false,
    error: false
};

export default withStyles(styles)(LxTimePicker);