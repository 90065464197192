import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { withRouter } from 'react-router-dom';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import modalStyle from '.../assets/jss/material-dashboard-pro-react/modalStyle';
import { pushToError, trendsApi } from '.../utils/apiHelper';
import mediaQueries from '.../utils/mediaQueries';
import Step1_SelectChart from './Steps/Step1_SelectChart';
import Step2_ChartTypeSelection from './Steps/Step2_ChartTypeSelection';
import Step3_XAxisSelection from './Steps/Step3_XAxisSelection';
import Step4_YAxisSelection from './Steps/Step4_YAxisSelection';
import Step5_FinalizeSelection from './Steps/Step5_FinalizeSelection';

const styles = theme => ({
    ...modalStyle(theme),
    dialog: {
        width: '60vw',
        overflow: 'visible'
    },
    dialogMobile: {
        width: '100vh',
        overflow: 'visible'
    },
    dialogContent: {
        height: '65vh',
        overflow: 'visible',
        overflowY: 'auto',
        paddingTop: '0px'
    },
    tabContainer: {
        marginLeft: '-1px'
    },
    tabButtons: {
        width: '100%',
        borderRadius: '0px'
    },
    buttons: {
        minWidth: '95px'
    }
});

class TrendsWizardContainer extends React.Component {
    constructor(props) {
        super(props);

        this.steps = [
            { stepName: "Select Chart", stepComponent: Step1_SelectChart, stepId: 'selectchart' },
            { stepName: "Chart Type", stepComponent: Step2_ChartTypeSelection, stepId: 'charttype' },
            { stepName: "X Axis", stepComponent: Step3_XAxisSelection, stepId: 'xaxis' },
            { stepName: "Y Axis", stepComponent: Step4_YAxisSelection, stepId: 'yaxis' },
            { stepName: "Finalize", stepComponent: Step5_FinalizeSelection, stepId: 'finalize' }
        ];

        this.state = {
            currentStep: 0,
            windowWidth: window.innerWidth
        };
    }

    componentDidMount = () => window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}))

    componentDidUpdate(prevProps) {
        if (prevProps.dialogOpen === false && this.props.dialogOpen === true) {
            this.setState({
                currentStep: 0,
                selectedChart: undefined,
                selectedChartId: undefined
            });
        }
    }

    componentWillUnmount = () => window.removeEventListener('resize', () => this.setState({windowWidth: window.innerWidth}))

    saveChart = (selectedChart) => {
        const { selectedChartId } = this.state;

        if (typeof selectedChart === 'undefined') {
            selectedChart = this.state.selectedChart;
        }

        for (let i = 0; i < this.steps.length; i++) {
            let component = this[this.steps[i].stepId];
            if (!component.isValidated(selectedChart)) {
                this.setState({
                    currentStep: i
                });
                return;
            }
        }
        if (typeof selectedChartId === 'undefined') {
            this.props.toggleDialog();
            trendsApi.createTrendsChart(selectedChart)
                .then(() => {
                    this.props.updateChartsList();
                }).catch(error => pushToError(error, this.props.match.params.mrn));
        } else {
            this.props.toggleDialog();
            trendsApi.updateTrendsChart(selectedChart)
                .then(() => {
                    this.props.updateChartsList(selectedChart.ssCopilotChartId);
                }).catch(error => pushToError(error, this.props.match.params.mrn));
        }
    }

    navigationStepChange = (key, selectedChart) => {
        const { currentStep } = this.state;

        if (typeof selectedChart === 'undefined') {
            selectedChart = this.state.selectedChart;
        }

        let gotoStep = key;
        if (key > currentStep) {
            for (let i = currentStep; i < key; i++) {
                let component = this[this.steps[i].stepId];
                if (!component.isValidated(selectedChart)) {
                    gotoStep = i;
                    break;
                }
            }
        }

        this.setState({
            currentStep: gotoStep
        });
    }

    navigatePreviousStep = () => {
        const { currentStep } = this.state;
        if (currentStep > 0) {
            this.navigationStepChange(currentStep - 1);
        }
    }

    navigateNextStep = (selectedChart) => {
        const { currentStep } = this.state;
        if (currentStep < (this.steps.length - 1)) {
            this.navigationStepChange(currentStep + 1, selectedChart);
        } else if (currentStep === (this.steps.length - 1)) {
            this.saveChart(selectedChart);
        }
    }

    setSelectedChart = (selectedChart, selectedChartId) => {
        this.setState({ selectedChart, selectedChartId });
    }

    getMeasurementItemDisplayTemplate = (item, cols, handleOnClick) => (
        <TableRow onClick={handleOnClick} hover key={`${cols.map(col => item[col.toLowerCase()]).join('-')}`} >
            {cols.map((col, index) => <TableCell key={index} >{item[col.toLowerCase()]}</TableCell>)}
        </TableRow>
    )

    getMeasurementItemToString = (item) => {
        return (item ? `${item.name} ${item.label} ${item.units} ${item.table} ${item.field}` : '');
    }

    render() {
        const { classes, dialogOpen, toggleDialog, charts, measurements, ssPatientId, updateChartsList } = this.props;
        const { currentStep, selectedChart, selectedChartId, windowWidth } = this.state;

        const mobileSize = windowWidth <= mediaQueries.breakpointLargePixels;

        return (
            <Dialog classes={{ paper: mobileSize ? classes.dialogMobile : classes.dialog }} open={dialogOpen} onClose={toggleDialog} maxWidth={false} fullWidth={mobileSize} fullScreen={mobileSize}>
                <DialogTitle disableTypography className={classes.modalHeader}>
                    <Button className={classes.modalCloseButton} onClick={toggleDialog}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{'Manage Trend Charts'}</h4>
                </DialogTitle>
                <GridContainer justify='center' className={classes.tabContainer}>
                    {this.steps.map((step, key) => (
                        <GridItem
                            className={classes.steps}
                            key={key}
                            style={{ width: `${100 / this.steps.length}%` }}
                        >
                            <Button
                                variant='contained'
                                color={currentStep === key ? 'primary' : 'inherit'}
                                onClick={() => this.navigationStepChange(key)}
                                className={classes.tabButtons}
                            >
                                {step.stepName}
                            </Button>
                        </GridItem>
                    ))}
                </GridContainer>
                <DialogContent className={`${classes.modalBody} ${classes.dialogContent}`}>
                    {this.steps.map((step, key) => (
                        <Hidden implementation='css' xsUp={key !== currentStep} key={key} >
                            <step.stepComponent
                                innerRef={ref => this[step.stepId] = ref}
                                charts={charts}
                                measurements={measurements}
                                getMeasurementItemDisplayTemplate={this.getMeasurementItemDisplayTemplate}
                                getMeasurementItemToString={this.getMeasurementItemToString}
                                previousButtonClick={this.navigatePreviousStep}
                                nextButtonClick={this.navigateNextStep}
                                setSelectedChart={this.setSelectedChart}
                                selectedChart={selectedChart}
                                selectedChartId={selectedChartId}
                                ssPatientId={ssPatientId}
                                updateChartsList={updateChartsList}
                            />
                        </Hidden>
                    ))}
                </DialogContent>
                <DialogActions>
                    <GridContainer spacing={4} justify='space-between' alignItems='baseline'>
                        <GridItem>
                            {currentStep !== 0 ?
                                <Button className={classes.buttons} variant='contained' color='primary' onClick={this.navigatePreviousStep}>
                                    {'Previous'}
                                </Button>
                                :
                                null
                            }
                        </GridItem>
                        <GridItem>
                            <Button className={classes.buttons} variant='contained' color='primary' onClick={() => this.navigateNextStep()}>
                                {currentStep !== (this.steps.length - 1) ? 'Next' : 'Save'}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withRouter(withStyles(styles)(TrendsWizardContainer));