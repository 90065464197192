import React from 'react';

import AuthorizationFilter from '.../utils/AuthorizationFilter';
import userManager from '.../utils/userManager';

class DevInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            access_token: null,
            windowSize: null
        };
    }

    componentDidMount() {
        userManager.events.addUserLoaded(this.loadUser);
        window.addEventListener('resize', this.getWindowSize);

        this.loadUser();
        this.getWindowSize();
    }

    componentWillUnmount() {
        userManager.events.removeUserLoaded(this.loadUser);
        window.removeEventListener('resize', this.getWindowSize);
    }

    getWindowSize = () => {
        this.setState({
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        });
    }

    loadUser = () => {
        userManager.getUser().then(user => {
            this.setState({ access_token: user.access_token });
        });
    }

    render() {
        const { access_token, windowSize } = this.state;

        return (
            <AuthorizationFilter allowed='Platform:Administrator' showUnauthorized>
                <div>
                    {`access_token: ${JSON.stringify(access_token, null, 2)}`}
                </div>
                <div>
                    {`window: ${JSON.stringify(windowSize, null, 2)}`}
                </div>
            </AuthorizationFilter>
        );
    }
}

export default DevInfo;