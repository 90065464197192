import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { imagesApi, pushToError } from '.../utils/apiHelper';
import mediaQueries from '.../utils/mediaQueries';
import Cornerstone from './CornerstoneReact';
import Thumbnails from './Thumbnails';
import Tools from './Tools';

const styles = theme => ({
});

class WebpacsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studies: [],
            studiesLoading: true,
            selectedThumbnail: 0,
            selectedTool: 'pan',
            width: window.innerWidth
        };
    }

    componentDidMount() {
        let ssDicomStudyId = this.props.match.params.ssDicomStudyId;
        imagesApi.getBySsDicomStudyId(ssDicomStudyId)
            .then((studies) => {
                let filteredStudies = studies; // studies.filter(image => image.numberOfFrames > 1);
                this.setState({
                    studies: filteredStudies,
                    studiesLoading: false
                });
            }).catch((error) => pushToError(error, this.props.match.params.mrn));

        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.handleWindowResize);

    handleWindowResize = () => this.setState({ width: window.innerWidth });

    handlePrevThumbnail = () => {
        const { selectedThumbnail } = this.state;
        if (selectedThumbnail > 0) {
            this.setState({ selectedThumbnail: selectedThumbnail - 1 });
        }
    }

    handleNextThumbnail = () => {
        const { selectedThumbnail, studies } = this.state;
        if (selectedThumbnail < studies.length - 1) {
            this.setState({ selectedThumbnail: selectedThumbnail + 1 });
        }
    }

    handleThumbnailSelect = (selectedThumbnail) => this.setState({ selectedThumbnail });

    handleToolSelect = (selectedTool) => this.setState({ selectedTool });

    render() {
        const { width, studies, studiesLoading, selectedThumbnail, selectedTool } = this.state;

        const mobileLayout = width <= mediaQueries.breakpointLargePixels;

        if (studiesLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }

        if (mobileLayout && window.innerWidth < window.innerHeight) { // Mobile layout Portrait
            return (
                <GridContainer spacing={4} direction='column' alignItems='stretch'>
                    <GridItem xs={12}>
                        <Thumbnails key='thumbnails' studies={studies} selectedThumbnail={selectedThumbnail} onThumbnailSelect={this.handleThumbnailSelect} />
                    </GridItem>
                    <GridItem xs={12}>
                        <Cornerstone key='cornerstone' preCacheImages imageInfo={studies[selectedThumbnail]} tool={selectedTool} handlePrevThumbnail={this.handlePrevThumbnail} handleNextThumbnail={this.handleNextThumbnail} />
                    </GridItem>
                </GridContainer>
            );
        } else if (mobileLayout) { // Mobile layout Horizontal
            return (
                <GridContainer spacing={4}>
                    <GridItem xs={2}>
                        <Thumbnails studies={studies} selectedThumbnail={selectedThumbnail} onThumbnailSelect={this.handleThumbnailSelect} />
                    </GridItem>
                    <GridItem xs={10}>
                        <Cornerstone key='cornerstone' preCacheImages imageInfo={studies[selectedThumbnail]} tool={selectedTool} handlePrevThumbnail={this.handlePrevThumbnail} handleNextThumbnail={this.handleNextThumbnail} />
                    </GridItem>
                </GridContainer>
            );
        }

        return (
            <GridContainer spacing={4} alignItems='stretch'>
                <GridItem xs={2}>
                    <Thumbnails key='thumbnails' studies={studies} selectedThumbnail={selectedThumbnail} onThumbnailSelect={this.handleThumbnailSelect} />
                </GridItem>
                <GridItem xs={8}>
                    <Cornerstone key='cornerstone' preCacheImages imageInfo={studies[selectedThumbnail]} tool={selectedTool} handlePrevThumbnail={this.handlePrevThumbnail} handleNextThumbnail={this.handleNextThumbnail} />
                </GridItem>
                <GridItem xs={2}>
                    <Tools selectedTool={selectedTool} onToolSelect={this.handleToolSelect} />
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(WebpacsContainer);