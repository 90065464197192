import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 75px)'
    },
    divider: {
        marginTop: '15px'
    },
    cardFooter: {
        display: 'flex',
        bottom: '0px',
        width: '100%',
        alignitems: 'right',
        justifycontent: 'space-between'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        '& svg': {
            backgroundColor: '#fff',
            borderRadius: '12px'
        }
    }
});

class ReportTemplateSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportTemplateRecord: null,
            validateReportGeneration: false
        };
    }

    createReport = (item) => (event) => {
        const { onCreateReport } = this.props;

        if (typeof onCreateReport === 'function') {
            onCreateReport(item);
        }
    }

    generateReport = (item) => (event) => {
        const { onGenerateReport } = this.props;

        if (typeof onGenerateReport === 'function') {
            this.closeReportExistsDialog();
            onGenerateReport(item);
        }
    }

    reportTemplateClick = (reportTemplate) => {
        this.setState({
            reportTemplateRecord: reportTemplate
        });
    }

    closeReportExistsDialog = () => {
        const { closeReportExistsDialog } = this.props;

        if (typeof closeReportExistsDialog === 'function') {
            closeReportExistsDialog();
        }
    }

    render() {
        const { classes, reportTemplates, isReportExists, isExistingReportLoading } = this.props;
        const { reportTemplateRecord } = this.state;

        return (
            <>
                <Card className={classes.card}>
                    <CardBody className={classes.cardBody}>
                        <LxSearchableTable
                            className={classes.table}
                            items={reportTemplates}
                            columns={[
                                {
                                    id: 'create',
                                    width: 75,
                                    resizable: false,
                                    className: classes.buttonCell,
                                    title: 'Create',
                                    key: (item) => (
                                        isExistingReportLoading === true ? <CircularProgress size={10} thickness={10}/> :
                                            <Button className={classes.button} onClick={this.createReport(item)}>
                                                <AddIcon />
                                            </Button>
                                    )
                                },
                                {
                                    title: 'Name',
                                    key: 'reportName',
                                    show: true
                                }
                            ]}
                            onRowClick={this.reportTemplateClick}
                            selectedItem={reportTemplateRecord}
                            title={'New Report'}
                        />
                    </CardBody>
                </Card>
                <Dialog open={isReportExists} maxWidth="xl" onClose={this.closeReportExistsDialog}>
                    <DialogTitle id="alert-dialog-title">{"Report Exists"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This report already exists. Do you want to overwrite this report now?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.generateReport(reportTemplateRecord)} color="primary">
                            Yes
                        </Button>
                        <Button onClick={this.closeReportExistsDialog} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(ReportTemplateSelector);