import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Downshift from "downshift";
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';
import React, { Component } from "react";
import MdClear from "react-icons/lib/md/clear";
import MdExpandLess from "react-icons/lib/md/expand-less";
import MdExpandMore from "react-icons/lib/md/expand-more";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuidv4 } from 'uuid';

import Card from '.../assets/components/Card/Card';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import LxTextField from "../formComponents/LxTextField";

let styles = (theme) => ({
    frame: {
        flexGrow: 1,
        minHeight: "10px",
        margin: 0,
        height: "100%"
    },
    secondaryAction: {
        flex: '0 1 auto',
        padding: '0px !important',
        width: '30px',
        minWidth: '30px',
        '& svg': {
            height: '55%',
            width: '55%'
        }
    },
    inputFrame: {
        position: "relative",
        display: 'flex'
    },
    card: {
        boxShadow: '0 2px 10px 0 rgba(0,0,0, .7)',
        width: '100%',
        height: "300px",
        margin: '0px',
        backgroundColor: theme.palette.background.paper,
        '& .ps__rail-y': {
            top: '0px !important'
        }
    },
    menuAnchor: {
        height: '100%',
        width: '100%'
    },
    popper: {
        zIndex: 2050,
        maxWidth: '95vw'
    },
    textField: {
        flex: '1 1 auto'
    },
    perfectScrollbar: {
        width: '100%'
    },
    tableCell: {
        wordBreak: 'break-all',
        wordWrap: 'break-word'
    },
    highlighted: {
        outline: 'solid 1px #0f8067',
        backgroundColor: 'rgba(37, 165, 137, 0.3)'
    }
});

class DropdownMenu extends Component {
    constructor(props) {
        super(props);
        this.rowsPerPage = typeof props.rowsPerPage === 'undefined' ? 100 : props.rowsPerPage;
        this.state = {
            page: 0,
            inputWidth: 'fit-content',
            tableWidth: 'fit-content',
            selectedItemIndex: 0,
            filteredItems: props.passedProps.items,
            items: props.passedProps.items,
            isOpen: props.options.isOpen,
            searchLoading: false
        };
        this.perfectScrollRef = null;
        this.inputRef = null;
        this.tableRef = null;
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.passedProps.keepInputOnExit && props.options.isOpen !== state.isOpen) {
            return ({
                isOpen: props.options.isOpen,
                filteredItems: props.passedProps.items,
                searchLoading: false
            });
        } else if (JSON.stringify(props.passedProps.items) !== JSON.stringify(state.items)) {
            const { items, searchKeys, columns } = props.passedProps;
            const { inputValue } = props.options;
            return ({
                filteredItems: DropdownMenu.searchItems(items, inputValue, searchKeys, columns, false),
                items: items
            });
        }

        return null;
    }

    static searchItems = (library, value, searchKeys, columns, usePromise = true) => {
        let keys;
        if (Array.isArray(searchKeys)) {
            keys = searchKeys;
        } else {
            keys = columns.map(col => (
                typeof col === 'string' ? col.toLowerCase() : col.key
            ));
        }

        return usePromise ? window.LxSearchWorker(library, value, keys) : matchSorter(library, value, { keys });
    }

    handleKeypressSelection = (event) => {
        const { selectItem } = this.props.options;
        const { filteredItems, selectedItemIndex } = this.state;

        if (typeof selectItem === 'function') {
            let index = selectedItemIndex;
            if (event.key === 'ArrowDown')
            {
                if (index === -1 || index === (filteredItems.length - 1))
                {
                    index = 0;
                }
                else
                {
                    index = index + 1;
                }
            }
            if (event.key === 'ArrowUp')
            {
                if (index === -1 || index === 0)
                {
                    index = filteredItems.length - 1;
                }
                else
                {
                    index = index - 1;
                }
            }
            if (event.key === 'Enter') {
                if (index !== -1 && filteredItems[index]){
                    selectItem(filteredItems[index]);
                    index = -1;
                }
            }
            this.setState({
                selectedItemIndex : index
            });
        }
    }

    disableBodyScroll = () => {
        // This is to stop scrolling to somewhere else on the page when you reach the top or bottom of the table
        document.body.style.overflow = 'hidden';
    }

    enableBodyScroll = () => {
        // This is to continue scrolling the page when your mouse leaves the popper
        document.body.style.overflow = 'auto';
    }

    handleBlur = (event) => {
        const { selectedItem } = this.props.options;
        const { onBlur } = this.props.passedProps;

        if (typeof onBlur === 'function') {
            onBlur(event, selectedItem);
        }
    }

    updateInputValue = event => {
        const { onChangeInput, items, searchKeys, columns } = this.props.passedProps;
        const { page, searchLoading } = this.state;
        event.persist();

        DropdownMenu.searchItems(items, event.target.value, searchKeys, columns)
            .then(filteredItems => {
                const { inputValue } = this.props.options;
                if (inputValue === event.target.value) {
                    this.setState({
                        filteredItems,
                        searchLoading: false,
                        selectedItemIndex : 0
                    });
                }
            });

        if (typeof onChangeInput === 'function') {
            onChangeInput(event);
        }

        if (!searchLoading) {
            this.setState({
                searchLoading: true
            });
        }

        if (page !== 0) {
            this.setState({
                page: 0
            });
        }
    };

    onChangePage = (event, page) => {
        this.setState({
            page
        });

        if (this.perfectScrollRef !== null) {
            this.perfectScrollRef.scrollTop = 0;
        }
    }

    updatePerfectScrollRef = (ref) => {
        if (ref !== null) {
            this.perfectScrollRef = ref;
        }
    }

    updateInputRef = (ref) => {
        if (ref !== null) {
            this.setState({
                inputWidth: `${ref.clientWidth}px`
            });
            this.inputRef = ref;
        }
    }

    updateTableRef = (ref) => {
        if (ref !== null && this.tableRef === null) {
            this.setState({
                tableWidth: `${ref.clientWidth}px`
            });
        } else if (ref === null) {
            this.setState({
                tableWidth: 'fit-content'
            });
        }
        this.tableRef = ref;
    }

    displayTemplate = (item, cols, selected) => {
        const getItem = (col) => typeof col === 'string' ? item[col.toLowerCase()] : item[col.key];

        return (
            <TableRow
                key={`${cols.map(col => getItem(col)).join('-')}-${uuidv4()}`}
                onClick={this.onRowClick(item)}
                hover
                selected={selected}
            >
                {cols.map((col, index) =>
                    <TableCell key={index} className={this.props.passedProps.classes.tableCell}>
                        {getItem(col)}
                    </TableCell>
                )}
            </TableRow>
        );
    }

    onRowClick = (item) => () => {
        const { selectItem, closeMenu } = this.props.options;
        const { selectedItem, onChange } = this.props.passedProps;

        if (typeof selectedItem === 'undefined') {
            selectItem(item);
        } else {
            onChange(item);
            closeMenu();
        }

        this.enableBodyScroll();
    }

    onClear = () => {
        const { clearSelection } = this.props.options;
        const { onChange, items } = this.props.passedProps;

        if (typeof onChange === 'function') {
            onChange(null);
        }
        clearSelection();
        this.enableBodyScroll();

        this.setState({
            filteredItems: items,
            searchLoading: false
        });
    }

    handleOpen = (event) => {
        const { openMenu } = this.props.options;
        const { clearFilterOnOpen, items } = this.props.passedProps;
        if (typeof openMenu === 'function') {
            openMenu(event);
            if (clearFilterOnOpen || event.target.value === '') {
                this.setState({
                    filteredItems: items,
                    searchLoading: false
                });
            }
        }
    }

    render() {
        const {
            getInputProps,
            inputValue,
            isOpen,
            closeMenu,
            selectedItem
        } = this.props.options;

        const {
            itemsLoading,
            title,
            label,
            placeholder,
            inputDisplayTemplate,
            columns,
            classes,
            className,
            helperText,
            hasMappings,
            fitInputWidth,
            fitTableWidth,
            error,
            disabled,
            showConfirmationOnClear,
            confirmationTitleOnClear,
            confirmationTextOnClear,
            confirmationConfirmTextOnClear,
            placement,
            description,
            validationErrorSeverity,
            tabIndex,
            autoFocus,
            forcedFocus,
            highlighted,
            titleTooltip
        } = this.props.passedProps;

        const {
            tableWidth,
            inputWidth,
            page,
            filteredItems,
            selectedItemIndex,
            searchLoading
        } = this.state;

        let _value = inputValue || "";

        if (_value !== '' && selectedItem !== null && typeof selectedItem === "object") {
            _value = inputDisplayTemplate(selectedItem);
        }

        let secondaryActionItem = (inputValue ?? '') !== '' ? <MdClear /> : isOpen ? <MdExpandLess /> : <MdExpandMore />;
        let secondaryActionEvent = (inputValue ?? '') !== '' ? this.onClear : isOpen ? closeMenu : this.handleOpen;

        if (!isOpen) {
            this.enableBodyScroll();
        }
        let classNameString = `${classes.frame} ${className} `;
        classNameString = classNameString + (highlighted ? ` ${classes.highlighted}` : '');

        return (
            <div className={classNameString} ref={this.props.downshiftRef}>
                <Popper
                    className={classes.popper}
                    style={{ width: fitTableWidth ? tableWidth : fitInputWidth ? inputWidth : 'fit-content' }}
                    placement={placement !== null ? placement : 'bottom'}
                    //The open prop used to check to make sure SelectedItem was null, but there is a bug that causes there to be a selected item even when the field is emtpy, 
                    //making the arrow not open the menu. I can't figure out how a selectedItem isn't getting cleared properly, but removing this also minimizes the number of
                    //clicks a user needs to make to change a selection from one value to another.
                    open={(isOpen)}
                    anchorEl={this.inputRef}
                    transition
                    onMouseOver={this.disableBodyScroll}
                    onMouseOut={this.enableBodyScroll}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                            <Card className={classes.card}>
                                <PerfectScrollbar
                                    className={classes.perfectScrollbar}
                                    containerRef={this.updatePerfectScrollRef}
                                    options={{
                                        suppressScrollX: true,
                                        suppressScrollY: false
                                    }}
                                >
                                    <div className={classes.menuAnchor} ref={this.props.menuRef}>
                                        {itemsLoading || searchLoading ?
                                            <CircularProgress size={60} thickness={7} />
                                            :
                                            <Table ref={this.updateTableRef}>
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((col) => (
                                                            <TableCell key={typeof col === 'string' ? col : col.title}>
                                                                {typeof col === 'string' ? col : col.title}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredItems ?
                                                        filteredItems.slice(page * this.rowsPerPage, (page + 1) * this.rowsPerPage)
                                                            .map((item, index) => this.displayTemplate(item, columns, index === selectedItemIndex)) : ''}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[this.rowsPerPage]}
                                                            rowsPerPage={this.rowsPerPage}
                                                            count={filteredItems ? filteredItems.length : 0}
                                                            page={page}
                                                            onChangePage={this.onChangePage}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>}
                                    </div>
                                </PerfectScrollbar>
                            </Card>
                        </Fade>
                    )}
                </Popper>
                <div className={classes.inputFrame} ref={this.updateInputRef}>
                    <LxTextField
                        {...getInputProps({
                            className: classes.textField,
                            onFocus: this.handleOpen,
                            fullWidth: true,
                            helperText: helperText,
                            hasMappings: hasMappings,
                            error: error,
                            disabled: disabled,
                            placeholder: placeholder,
                            titleTooltip: titleTooltip,
                            title: title,
                            label: label,
                            description: description,
                            value: _value,
                            onChange: this.updateInputValue,
                            onBlur: this.handleBlur,
                            useOnChangeEvent: true,
                            onKeyUp: this.handleKeypressSelection,
                            validationErrorSeverity: validationErrorSeverity,
                            tabIndex: tabIndex,
                            autoFocus: autoFocus,
                            forcedFocus: forcedFocus
                        })}
                    />
                    <ConfirmationButton
                        disabled={disabled}
                        className={classes.secondaryAction}
                        onClick={secondaryActionEvent}
                        variant='text'
                        showConfirmation={
                            inputValue !== ''
                            && selectedItem !== null
                            && showConfirmationOnClear
                        }
                        confirmationTitle={confirmationTitleOnClear}
                        confirmationText={confirmationTextOnClear}
                        confirmationConfirmText={confirmationConfirmTextOnClear}
                    >
                        {secondaryActionItem}
                    </ConfirmationButton>
                </div>
            </div>
        );
    }
}

class LxCombobox extends Component {
    autoCompleteContents = (options) => {
        return (
            <DropdownMenu
                {...options.getRootProps({ refKey: 'downshiftRef' })}
                {...options.getMenuProps({ refKey: 'menuRef' }, { suppressRefError: true })}
                options={options}
                passedProps={this.props}
                onSelectionKeypress={this.handleKeypressSelection}
            />
        );
    }

    stateReducer = (state, changes) => {
        if (changes.type === Downshift.stateChangeTypes.mouseUp && this.props.keepInputOnExit) {
            const { inputValue, ...otherChanges } = changes;
            return otherChanges;
        } else if (changes.type === Downshift.stateChangeTypes.changeInput && state.selectedItem !== null) {
            changes.selectedItem = null;
            return changes;
        } else {
            return changes;
        }
    }

    render() {
        const { downshiftRef, ...otherProps } = this.props;

        return (
            <Downshift
                stateReducer={this.stateReducer}
                {...(typeof downshiftRef === 'function' ? { ref: downshiftRef } : {})}
                {...otherProps}
            >
                {this.autoCompleteContents}
            </Downshift>
        );
    }
}

LxCombobox.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape),
    itemsLoading: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    inputValue: PropTypes.string,
    selectedItem: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    tabIndex: PropTypes.number,
    autoFocus: PropTypes.bool,
    itemToString: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    searchKeys: PropTypes.arrayOf(PropTypes.string),
    inputDisplayTemplate: PropTypes.func,
    columns: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            key: PropTypes.string
        }))
    ]).isRequired,
    fitInputWidth: PropTypes.bool,
    fitTableWidth: PropTypes.bool,
    downshiftRef: PropTypes.func,
    showConfirmationOnClear: PropTypes.bool,
    confirmationTitleOnClear: PropTypes.string,
    confirmationTextOnClear: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    confirmationConfirmTextOnClear: PropTypes.node,
    keepInputOnExit: PropTypes.bool,
    //This property will cause the filter to clear itself whenever the menu is opened, because 
    //MultiColumnCombos leave the previous filter applied even when the input value is cleared
    clearFilterOnOpen: PropTypes.bool
};

LxCombobox.defaultProps = {
    itemsLoading: false,
    fitInputWidth: false,
    fitTableWidth: false,
    error: false,
    disabled: false
};

export default withStyles(styles)(LxCombobox);