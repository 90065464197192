import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import NavPills from ".../assets/components/NavPills/NavPills";
import { ipcccApi, pushToError } from '.../utils/apiHelper';
import IPCCCCells from './IPCCCCell';
import IPCCCTable from './IPCCCTable';

const styles = theme => ({
    tabContent: {
        maxHeight: 'calc(100vh - 90px)',
        overflow: 'hidden'
    }
});

class IPCCCContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            noUser: false,
            tab: 0
        };

        this.columnOptions = {
            category: {
                Header: 'Category',
                accessor: row => row.category === null ? 'None' : row.category,
                id: 'category',
                filterable: false,
                PivotValue: row => `Category: ${row.value}`
            },
            dateOfAepc: {
                Header: 'Date',
                accessor: "dateOfAepc",
                filterable: false,
                minWidth: 80,
                Cell: row => <IPCCCCells row={row} variant='date' />
            },
            diagnosis: {
                Header: 'Diagnosis',
                accessor: row => row.code === null || row.diagnosis === null ? null : `${row.code} ${row.diagnosis}`,
                id: "diagnosis",
                filterable: false,
                minWidth: 300,
                Cell: row => <IPCCCCells row={row} variant='tooltip' />
            },
            qualifier1: {
                Header: 'Qualifier 1',
                accessor: row => row.qualiCode1 === null || row.qualiDesc1 === null ? null : `${row.qualiCode1} ${row.qualiDesc1}`,
                id: "qualifier1",
                filterable: false,
                minWidth: 150,
                Cell: row => <IPCCCCells row={row} variant='tooltip' />
            },
            qualifier2: {
                Header: 'Qualifier 2',
                accessor: row => row.qualiCode2 === null || row.qualiDesc2 === null ? null : `${row.qualiCode2} ${row.qualiDesc2}`,
                id: "qualifier2",
                filterable: false,
                minWidth: 150,
                Cell: row => <IPCCCCells row={row} variant='tooltip' />
            },
            qualifier3: {
                Header: 'Qualifier 3',
                accessor: row => row.qualiCode3 === null || row.qualiDesc3 === null ? null : `${row.qualiCode3} ${row.qualiDesc3}`,
                id: "qualifier3",
                filterable: false,
                minWidth: 150,
                Cell: row => <IPCCCCells row={row} variant='tooltip' />
            },
            archived: {
                Header: 'Archived',
                accessor: "archived",
                filterable: false,
                resizable: false,
                width: 95,
                Cell: row => <IPCCCCells row={row} variant='checkbox' />
            },
            archivedDate: {
                Header: 'Archived Date',
                accessor: "archivedDate",
                filterable: false,
                width: 80,
                Cell: row => <IPCCCCells row={row} variant='date' />
            },
            archivedReason: {
                Header: 'Archived Reason',
                accessor: "archivedReason",
                filterable: false,
                Cell: row => <IPCCCCells row={row} variant='tooltip' />
            },
            pregnancyId: {
                Header: 'Pregnancy ID',
                accessor: row => row.pregnancyId,
                id: 'pregnancyId',
                filterable: false,
                Cell: row => <IPCCCCells row={row} />,
                PivotValue: row => `Pregnancy ID: ${row.value}`
            },
            fetusId: {
                Header: 'Fetus ID',
                accessor: row => row.fetusId,
                id: 'fetusId',
                filterable: false,
                Cell: row => <IPCCCCells row={row} />,
                PivotValue: row => `Fetus ID: ${row.value}`
            }
        };
    }

    componentDidMount() {
        Promise.all([
            ipcccApi.getPatientDiagAndSynd(this.props.patientInfo.ssPatientId),
            ipcccApi.getCodeLibrary()
        ]).then(([patient, codeLibrary]) => (this.setState({
            patient,
            codeLibrary,
            isLoading: false,
            ssPatientId: this.props.patientInfo.ssPatientId
        }))).catch((error) => pushToError(error, this.props.match.params.mrn));
    }

    itemToString = item => item ? `${item.code} ${item.diagnosis} ${item.category} ${item.icd9} ${item.icd10}` : '';

    setPatientDiagnosis = (newDiagnoses) => {
        this.setState({
            patient: {
                ...this.state.patient,
                diagnoses: newDiagnoses.filter((diagnosis) => diagnosis.pregnancyId === null),
                fetalDiagnoses: newDiagnoses.filter((diagnosis) => diagnosis.pregnancyId !== null)
            }
        });
    };

    setPatientInterventions = (newInterventions) => {
        this.setState({
            patient: { ...this.state.patient, interventions: newInterventions }
        });
    };

    setFetalDiagnosis = (newDiagnoses) => {
        this.setState({
            patient: { ...this.state.patient, fetalDiagnoses: newDiagnoses }
        });
    };

    render() {
        const { classes } = this.props;
        const { isLoading, noUser, patient, codeLibrary } = this.state;
        const { mrn } = this.props.match.params;

        if (isLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }

        if (noUser) {
            return (
                <Typography align='center' variant='subtitle1'>
                    {`Could not find a patient with MRN: ${mrn}`}
                </Typography>
            );
        }

        let tabs = [];
        tabs.push({
            tabButton: "Diagnoses and Syndromes",
            tabContent:
                <IPCCCTable
                    className={classes.tabContent}
                    rows={patient.diagnoses.concat(patient.fetalDiagnoses)}
                    patient={patient}
                    codeLibrary={codeLibrary}
                    itemToString={this.itemToString}
                    setPatientDiagnosis={this.setPatientDiagnosis}
                    addRowApi={ipcccApi.createDiagAndSynd}
                    editRowApi={ipcccApi.updateDiagAndSynd}
                    deleteRowApi={ipcccApi.deleteDiagAndSynd}
                    columns={[
                        this.columnOptions.category,
                        this.columnOptions.dateOfAepc,
                        this.columnOptions.diagnosis,
                        this.columnOptions.qualifier1,
                        this.columnOptions.qualifier2,
                        this.columnOptions.qualifier3,
                        this.columnOptions.archived,
                        this.columnOptions.archivedDate,
                        this.columnOptions.archivedReason,
                        this.columnOptions.pregnancyId,
                        this.columnOptions.fetusId
                    ]}
                    pivotBy={['category']}
                />
        });

        tabs.push({
            tabButton: "Surgeries, Interventions and Complications",
            tabContent:
                <IPCCCTable
                    className={classes.tabContent}
                    rows={patient.interventions}
                    patient={patient}
                    codeLibrary={codeLibrary}
                    itemToString={this.itemToString}
                    setPatientInterventions={this.setPatientInterventions}
                    addRowApi={ipcccApi.createSurgAndIntern}
                    editRowApi={ipcccApi.updateSurgAndIntern}
                    deleteRowApi={ipcccApi.deleteSurgAndIntern}
                    columns={[
                        this.columnOptions.category,
                        this.columnOptions.dateOfAepc,
                        this.columnOptions.diagnosis,
                        this.columnOptions.qualifier1,
                        this.columnOptions.qualifier2,
                        this.columnOptions.qualifier3,
                        this.columnOptions.archived,
                        this.columnOptions.archivedDate,
                        this.columnOptions.archivedReason
                    ]}
                    pivotBy={['category']}
                />
        });

        if (patient.fetalDiagnoses.length > 0) {
            tabs.push({
                tabButton: "Fetal Diagnoses and Syndromes",
                tabContent:
                    <IPCCCTable
                        className={classes.tabContent}
                        rows={patient.fetalDiagnoses}
                        patient={patient}
                        codeLibrary={codeLibrary}
                        itemToString={this.itemToString}
                        setFetalDiagnosis={this.setFetalDiagnosis}
                        addRowApi={ipcccApi.createDiagAndSynd}
                        editRowApi={ipcccApi.updateDiagAndSynd}
                        deleteRowApi={ipcccApi.deleteDiagAndSynd}
                        columns={[
                            this.columnOptions.pregnancyId,
                            this.columnOptions.fetusId,
                            this.columnOptions.category,
                            this.columnOptions.dateOfAepc,
                            this.columnOptions.diagnosis,
                            this.columnOptions.qualifier1,
                            this.columnOptions.qualifier2,
                            this.columnOptions.qualifier3,
                            this.columnOptions.archived,
                            this.columnOptions.archivedDate,
                            this.columnOptions.archivedReason
                        ]}
                        pivotBy={['pregnancyId', 'fetusId', 'category']}
                    />
            });
        }

        return (
            <NavPills
                color="primary"
                tabs={tabs}
            />
        );
    }
}

export default compose(
    withStyles(styles),
    connect((state) => ({ patientInfo: state.demographics })),
    withRouter
)(IPCCCContainer);