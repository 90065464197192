import { compose, createStore } from 'redux';

import Reducer from './reducer/Reducer';

// create the middleware with the userManager
// const oidcMiddleware = createOidcMiddleware(userManager);

/*
 const loggerMiddleware = (store) => (next) => (action) => {
     console.log("Action type:", action.type);
     console.log("Action payload:", action.payload);
     console.log("State before:", store.getState());
     next(action);
     console.log("State after:", store.getState());
 };
 */

const initialState = {};

const createStoreWithMiddleware = compose(
    // applyMiddleware(loggerMiddleware)
)(createStore);

// window.__REDUX_DEVTOOLS_EXTENSION__ is used for activating the Redux viewer extension in Chrome
const store = createStoreWithMiddleware(Reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
