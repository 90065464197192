import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router';

import { powerbiApi, pushToError } from '.../utils/apiHelper';
import PowerBI from './PowerBI';

const styles = theme => ({
    container: {
        height: 'calc(100vh - 30px)',
        overflow: 'hidden'
    }
});

class HvaContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        const { module, feature } = this.props.match.params;
        let { id, rls } = this.props.moduleFeatureMap[module][feature];
        this.setFeature(id, rls);
    }

    componentDidUpdate() {
        const { module, feature } = this.props.match.params;
        let { id, rls } = this.props.moduleFeatureMap[module][feature];
        if (!this.state.isLoading && id !== this.state.featureId) {
            this.setFeature(id, rls);
        }
    }

    setFeature = (featureId, rls) => {
        this.setState({ isLoading: true });
        powerbiApi.getFeature(featureId, rls)
            .then(featuresData => (this.setState({
                embedInfo: {
                    embedClassName: 'powerbi-embed',
                    id: featuresData.reportGuid,
                    embedUrl: featuresData.embeddedURL,
                    onEmbedded: (report) => report.iframe.frameBorder = 'none',
                    accessToken: featuresData.accessToken
                },
                isLoading: false,
                featureId
            }))).catch((error) => pushToError(error, this.props.match.params.mrn));
    }

    render() {
        const { classes } = this.props;
        const { isLoading, embedInfo } = this.state;
        return (
                isLoading ? <CircularProgress size={60} thickness={7} />
                :
                <div className={classes.container}>
                    <PowerBI {...embedInfo} />
                </div>
        );
    }
}

export default withStyles(styles)(withRouter(HvaContainer));