import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

import EmrOptionRow from './EmrOptionRow';

const styles = theme => ({
    headerRow: {
        background: theme.palette.background.default
    }
});

class EmrValueList extends React.Component {
    state = {
        elementViewerOpen: false
    }

    handleClick = (option) => () => {
        const { onValueSelect } = this.props;
        onValueSelect(option.value);
    }

    render() {
        const { options, classes } = this.props;

        return (
            <Table size="small">
                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell>Value</TableCell>
                        <TableCell>Mapping</TableCell>
                        <TableCell>SourceType</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {options.map((option, index) => (
                        <EmrOptionRow key={index} handleRowClick={this.handleClick(option)} option={option}/>
                    ))}
                </TableBody>
            </Table>
        );
    }
}

export default withStyles(styles)(EmrValueList);