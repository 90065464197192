import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

const dateLongFormat = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
};

const dateShortFormat = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
};

const styles = theme => ({
    cell: {
        textAlign: 'left',
        width: '100%',
        overflow: 'inherit',
        textOverflow: 'ellipsis',
        margin: 'auto 8px',
        maxHeight: '24px',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    archiveButton: {
        width: '100%',
        lineHeight: '.5',
        fontSize: '100%',
        margin: 'auto'
    }
});

const Cells = props => {
    const { classes, row, variant } = props;
    const cells = {
        date: row => (
            <div className={classes.cell}>
                {row.value === null ?
                    null
                    :
                    `${new Date(row.value).toLocaleDateString('en-US', row.width >= 120 ? dateLongFormat : dateShortFormat)}`
                }
            </div>
        ),
        checkbox: row => <Checkbox disabled checked={row.value} className={classes.cell} />,
        tooltip: row => (
            <Tooltip title={row.value === null ? '' : row.value} placement="right">
                <div className={classes.cell}>
                    {row.value}
                </div>
            </Tooltip>
        ),
        default: row => (
            <div className={classes.cell}>
                {row.value}
            </div>
        )
    };

    const lowerVariant = typeof variant !== 'string' ? variant : variant.toLowerCase();

    if (typeof lowerVariant === 'undefined') {
        return cells.default(row);
    } else if (typeof cells[lowerVariant] === 'function') {
        return cells[lowerVariant](row);
    } else {
        console.error(`Expected cell variant of (${Object.keys(cells)}) but received ${lowerVariant}. Using 'default' instead.`);
        return cells.default(row);
    }
};

export default withStyles(styles)(Cells);