import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from '../PropertyEditor';

const styles = theme => ({

});

class CriteriaOneToManyEditor extends React.Component {
    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                title="Criteria Property Editor"
                properties={[
                    {
                        name: "sourceField",
                        label: "Source Field",
                        type: "text",
                        description: "The Source Field dictates what field from the Main Source Table will be used to compare against the comparison value"
                    },
                    {
                        name: "comparisonValue",
                        label: "Comparison Value",
                        type: "text",
                        description: "The results of the Multi Column Combo will be filtered down to only display results where the value in all the source fields match the Comparison Value."
                    },
                    {
                        name: "activeDataComparison",
                        label: "Use Active Data?",
                        type: "checkBox",
                        description: "If this checkbox is true, the comparison value will try to pull a value from the current patient record. The dropdown will then only display results where there is a match.\n" 
                        + "To use, input a [TableName].[FieldName] into the Comparison Value. The value from the Source Field will be compared against the value in the [TableName].[FieldName] from the current record."
                    }
                ]}
            />
        );
    }
}

export default withStyles(styles)(CriteriaOneToManyEditor);