import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import FormEditorSection from '.../components/features/forms/formEditor/FormEditorSection.js';

const styles = theme => ({
    smallDialogContent: {
        minWidth: '600px',
        width: '600px',
        maxWidth: '600px',
        margin: 'auto'
    },
    mediumDialogContent: {
        minWidth: '841px', // 800 breakpoint + 40 for padding and 1 to make sure within range
        width: '841px',
        maxWidth: '841px',
        margin: 'auto'
    },
    largeDialogContent: {
        minWidth: '1141px', // 1100 breakpoint + 40 for padding and 1 to make sure within range
        width: '1141px',
        maxWidth: '1141px',
        margin: 'auto'
    },
    buttonFlexBox: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '20px'
    }
});

const ITEM_ROW_HEIGHT = 66;

class SubSectionEditor extends React.Component {
    state = {
        internalSectionSchema: this.props.value ? 
        this.props.value 
        : 
        {
            sectionDisplayName: '',
            elements: []
        },
        isOpen: false
    }

    //LayoutSizeString needs to match up to a breakpoint in the ReactResizeableGrid, like sm or lg
    handleOpenDialog = (layoutSizeString) => () => {
        this.setState({
            isOpen: true,
            layoutSize: layoutSizeString
        });
    }

    handleCloseDialog = () => {
        this.setState({
            isOpen: false
        });
    }

    handleConfirm = () => {
        const { onChange } = this.props;
        const { internalSectionSchema } = this.state;
        if (typeof onChange === 'function') {
            onChange(internalSectionSchema);
        }
        this.handleCloseDialog();
    }

    handleSectionSchemaChange = (newSectionSchema) => {
        this.setState({
            internalSectionSchema: newSectionSchema
        });
    }

    render() {
        const { classes, contextTableName } = this.props;
        const { internalSectionSchema, isOpen, layoutSize } = this.state;
        let displaySizeClass;
        switch (layoutSize) {
            case 'sm':
                displaySizeClass = classes.smallDialogContent;
                break;
            case 'md':
                displaySizeClass = classes.mediumDialogContent;
                break;
            case 'lg':
                displaySizeClass = classes.largeDialogContent;
                break;
            default:
                displaySizeClass = classes.largeDialogContent;
                break;
        }
        
        return (
            <>
            <div className={classes.buttonFlexBox}>
                <Button
                    onClick={this.handleOpenDialog('sm')}
                    variant='outlined'
                    color='primary'
                >
                    Edit Small Form View
                </Button>
                <Button
                    onClick={this.handleOpenDialog('lg')}
                    variant='outlined'
                    color='primary'
                >
                    Edit Large Form View
                </Button>
            </div>
                <Dialog
                    className={displaySizeClass}
                    classes={{ paper: displaySizeClass}}
                    open={isOpen}
                    transitionDuration={0}
                    onMouseDown={this.handleMouseDown}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        Form View Editor
                    <Divider />
                    </DialogTitle>
                    <DialogContent className={displaySizeClass}>
                        <FormEditorSection
                            section={internalSectionSchema}
                            tableName={contextTableName}
                            validateFormSchema={() => { }}
                            onSchemaUpdate={this.handleSectionSchemaChange}
                            blockSectionNameUpdate
                            itemRowHeight={ITEM_ROW_HEIGHT}
                            open
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogButtons}>
                        <Button variant='contained' color='primary' onClick={this.handleCloseDialog}>
                            {'Close'}
                        </Button>
                        <Button
                            onClick={this.handleConfirm}
                            variant='contained'
                            color='primary'
                        >
                            {'Confirm'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(SubSectionEditor);