import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const styles = theme => ({
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        zIndex: '998'
    },
    sectionTitle: {
        margin: '10px',
        textAlign: 'center'
    },
    toolList: {
        height: '85vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    toolButton: {
        flex: '0 1 auto',
        margin: '8px'
    }
});

class Tools extends React.Component {
    constructor(props) {
        super(props);

        this.toolCollection = {
            pan: 'Pan',
            zoom: 'Zoom',
            wwwc: 'Color Balance',
            probe: 'Probe',
            length: 'Length',
            ellipticalRoi: 'Elliptical ROI',
            rectangleRoi: 'Rectangle ROI',
            freehand: 'Freehand ROI',
            angle: 'Angle',
            highlight: 'Highlight',
            magnify: 'Magnify'
        };

        this.state = {
            toolButtons: this.constructToolButtons(props)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedTool !== this.props.selectedTool) {
            this.setState({ toolButtons: this.constructToolButtons(this.props) });
        }
    }

    constructToolButtons = (props) => {
        const { selectedTool, onToolSelect, classes } = typeof props !== 'undefined' ? props : this.props;
        return (
            Object.keys(this.toolCollection).map((tool, index) => (
                <Button
                    color={selectedTool === tool ? 'primary' : 'default'}
                    variant='contained'
                    onClick={() => onToolSelect(tool)}
                    className={classes.toolButton}
                    key={index}
                >
                    {this.toolCollection[tool]}
                </Button>
            ))
        );
    }

    render() {
        const { classes } = this.props;
        const { toolButtons } = this.state;

        return (
            <Paper className={classes.sideBar} >
                <Typography className={classes.sectionTitle} variant="subtitle1">
                    {`TOOLS`}
                </Typography>
                <Divider />
                <div className={classes.toolList}>
                    {toolButtons}
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(Tools);