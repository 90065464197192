const DemographicsReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_DEMOGRAPHICS':
            return action.demographics;
        case 'CLEAR_DEMOGRAPHICS':
            return null;
        default:
            return state;
    }
};

export default DemographicsReducer;