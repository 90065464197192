import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withRouter } from 'react-router-dom';

import PhysicianSummaryMetric from './PhysicianSummaryMetric';

const styles = theme => ({
    container: {
        minWidth: '300px'
    },
    departmentMetric: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    departmentMetricValues: {
        margin: theme.spacing(1),
        flex: '1 1 auto'
    },
    secondaryMetric: {
        margin: 'auto',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-evenly'
    },
    list: {
        padding: '0px'
    },
    link: {
        textDecoration: 'none'
    },
    linkRow: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    linkTitle: {
        textDecoration: 'underline'
    },
    linkDate: {
        textDecoration: 'none'
    },
    cardioTable: {
        maxWidth: '700px',
        margin: 'auto'
    },
    centeredCell: {
        textAlign: 'center',
        border: `1px solid ${theme.palette.divider}`,
        padding: '4px',
        minWidth: '50px'
    }
});

const PhysicianSummaryForm = (props) => {
    const { classes, summary, className } = props;

    return (
        <div className={className}>
            <Card className={classes.container}>
                <CardContent>
                    <Typography variant='h5' align='left'>
                        {"Weekly Status Report"}
                    </Typography>
                </CardContent>
                <div>
                    <CardContent>
                        <Typography variant='h6' align='left'>
                            {"Surgical Department"}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <div className={classes.departmentMetric}>
                            {summary.departmentMetrics.map((metric, index) => (
                                <div className={classes.departmentMetricValues} key={index}>
                                    <Typography variant='subtitle1'>
                                        {metric.title}
                                    </Typography>
                                    <Typography variant='h5'>
                                        {metric.value}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </div>
                <div>
                    <CardContent>
                        <Typography variant='h6' align='left'>
                            {"Top Movers This Week"}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <div className={classes.secondaryMetric}>
                            <PhysicianSummaryMetric sectionName='Better than Expected' metrics={summary.betterThanExpectedMetrics} />
                            <PhysicianSummaryMetric sectionName='Worse than Expected' metrics={summary.worseThanExpectedMetrics} />
                            <PhysicianSummaryMetric sectionName='Better than Peers' metrics={summary.betterThanPeersMetrics} />
                            <PhysicianSummaryMetric sectionName='Worse than Peers' metrics={summary.worseThanPeersMetrics} />
                        </div>
                    </CardContent>
                </div>
                <div>
                    <CardContent>
                        <Typography variant='h6' align='left'>
                            {"Latest Portfolio News"}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <List className={classes.list}>
                            {summary.newsArticles.map((article, index) => (
                                <a href={article.link} key={index} className={classes.link}>
                                    <ListItem button disableRipple className={classes.linkRow}>
                                        <Typography variant='body2' align='center' className={classes.linkTitle}>
                                            {article.title}
                                        </Typography>
                                        <Typography variant='subtitle1' align='center' className={classes.linkDate}>
                                            {article.date}
                                        </Typography>
                                    </ListItem>
                                </a>
                            ))}
                        </List>
                    </CardContent>
                </div>
                <div>
                    <CardContent>
                        <Typography variant='h6' align='left'>
                            {"Cardiovascular Performance"}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Table className={classes.cardioTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.centeredCell} padding='none' />
                                    {summary.cardiovascularPerformance.length <= 0 ?
                                        null
                                        :
                                        summary.cardiovascularPerformance[0].cells.map((column, index) => (
                                            <TableCell className={classes.centeredCell} padding='none' key={index}>
                                                <strong> {column.title} </strong>
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summary.cardiovascularPerformance.map((row, rowIndex) => (
                                    <TableRow hover key={rowIndex}>
                                        <TableCell className={classes.centeredCell} padding='none'>
                                            <strong> {row.title} </strong>
                                        </TableCell>
                                        {row.cells.map((cell, cellIndex) => (
                                            <TableCell className={classes.centeredCell} padding='none' key={cellIndex}>
                                                {cell.value}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </div>
            </Card>
        </div>
    );
};

export default withRouter(withStyles(styles)(PhysicianSummaryForm));