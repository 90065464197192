import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import Button from '.../assets/components/CustomButtons/Button';

const styles = theme => ({
    leftAlign: {
        flexDirection: 'row'
    },
    rightAlign: {
        flexDirection: 'row-reverse'
    },
    topAlign: {
        flexDirection: 'column-reverse'
    },
    bottomAlign: {
        flexDirection: 'column'
    },
    iconButton: {
        width: '24px',
        height: '24px',
        padding: '0px'
    }
});

class CommandButtonElement extends React.Component {

    handleClick = () => {
        const { itemSchema, executeCommandButton } = this.props;

        if (typeof executeCommandButton === 'function') {
            executeCommandButton(itemSchema);
        }
    }

    // This class allows us to see validation errors from the backend, this would only really happen when the database is in a bad state.
    // Checkboxes will appear unchecked since the database didn't send a true/false value but something else.
    // If you want it truly unchecked you have to check the errored checkbox, then uncheck the box to clear the error.
    // We probably need to make the backend handle some cleaning of the data before it's sent forward for instances like this.
    render() {
        const { itemSchema, autoFocus, className, classes, formData, disabled } = this.props;

        let calculatedDisabled = disabled || itemSchema.readOnly || !formData || Object.keys(formData).length === 0;
        return (
            <FormControl className={className}>
                <Button
                    disabled={calculatedDisabled}
                    onClick={() => this.handleClick()}
                    //classes={{ root: classes.iconButton }}
                    title={itemSchema.description}
                    color='primary'
                    //inputProps={{ tabIndex: itemSchema.tabIndex, autoFocus }}
                >
                    {itemSchema.displayName}
                </Button>
            </FormControl>
        );
    }
}

export default withStyles(styles)(CommandButtonElement);