import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import NavPills from ".../assets/components/NavPills/NavPills";
import { cardTitle } from ".../assets/jss/material-dashboard-pro-react";
import PhysicianRanking from './PhysicianRankingPage';
import SabermedicsContainer from './SabermedicsContainer';
import PhysicianSummaryContainer from './summary/PhysicianSummaryContainer';

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};

class Panels extends React.Component {
    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12}>
                        <NavPills
                            color="primary"
                            tabs={[
                                {
                                    tabButton: "O/E Ratio",
                                    tabContent: (
                                        <SabermedicsContainer />
                                    )
                                },
                                {
                                    tabButton: "Ranking",
                                    tabContent: (
                                        <PhysicianRanking />
                                    )
                                },
                                {
                                    tabButton: "Summary",
                                    tabContent: (
                                        <PhysicianSummaryContainer />
                                    )
                                }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(Panels);