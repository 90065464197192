import { withStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/ToolTip';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCheckbox from '.../components/formComponents/LxCheckbox';

const styles = theme => ({
    gridContainer: {
        margin: `0px`,
        width: 'inherit'
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    }
});

class FormControlBar extends React.Component {
    getComponent = (componentOptions) => {
        const { type, label, toolTip, ...otherProps } = componentOptions;
        const toolTipProvided = typeof toolTip !== 'undefined' && toolTip !== null;

        switch (type) {
            case 'button':
                return (
                    <ToolTip
                        title={toolTipProvided ? toolTip : ''}
                        disableFocusListener={!toolTipProvided}
                        disableHoverListener={!toolTipProvided}
                        disableTouchListener={!toolTipProvided}
                    >
                        <ConfirmationButton variant='contained' color='primary' {...otherProps}>
                            {label}
                        </ConfirmationButton>
                    </ToolTip>
                );
            case 'checkBox':
                return (
                    <LxCheckbox
                        title={label}
                        {...otherProps}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const { classes, components, ...gridContainerProps } = this.props;

        return (
            <GridContainer className={classes.gridContainer} {...gridContainerProps} justify={"flex-start"} spacing={4}>
                {components.map((componentOptions, index) => (
                    <GridItem className={classes.gridItem} key={index}>
                        {this.getComponent(componentOptions)}
                    </GridItem>
                ))}
            </GridContainer>
        );
    }
}

FormControlBar.propTypes = {
    components: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
            type: PropTypes.oneOf(['button']).isRequired,
            label: PropTypes.node.isRequired,
            toolTip: PropTypes.node,
            ...ConfirmationButton.Naked.propTypes
        }),
        PropTypes.shape({
            type: PropTypes.oneOf(['checkBox']).isRequired,
            label: PropTypes.string,
            ...LxCheckbox.Naked.propTypes
        })
    ])).isRequired
};

FormControlBar.defaultProps = {
    justify: 'space-between'
};

export default withStyles(styles)(FormControlBar);