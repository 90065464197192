import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import userManager from '.../utils/userManager';

const styles = theme => ({
    container: {
        textAlign: 'center'
    }
});

class CallbackTokenCatcher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            urlToken: props.location.hash.indexOf('#id_token') >= 0
        };
        this.tokenProcessing = false;
        const managedWindow = queryString.parse(this.props.location.search).managedWindow;
        if(managedWindow){
            sessionStorage.setItem("managedWindow", "true");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.hash !== this.props.location.hash) {
            let urlToken = this.props.location.hash.indexOf('#id_token') >= 0;
            if (urlToken !== this.state.urlToken) {
                this.tokenProcessing = false;
                this.setState({
                    urlToken
                });
            }
        }
    }

    onSuccessCallback = () => {
        let redirectUrl = localStorage.getItem('redirectUrl');
        localStorage.removeItem('redirectUrl');

        if (redirectUrl !== null) {
            this.props.history.replace(redirectUrl);
        } else {
            this.props.history.replace('/');
        }
    }

    render() {
        const { classes } = this.props;
        const { urlToken } = this.state;

        if (urlToken && !this.tokenProcessing) {
            this.tokenProcessing = true;
            userManager.signinRedirectCallback().then(this.onSuccessCallback);
        }

        if (urlToken) {
            return (
                <div className={classes.container}>
                    <CircularProgress size={60} thickness={7} />
                </div>
            );
        } else {
            return (React.Children.only(this.props.children));
        }
    }
}

export default withStyles(styles)(withRouter(CallbackTokenCatcher));