import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import React from 'react';

import OneToManyFormView from '.../components/formComponents/formElements/OneToManyFormView';
import OneToManyGridView from '.../components/formComponents/formElements/OneToManyGridView';

const styles = theme => ({
    toggleButtonWrapper: {
        top: '5px',
        left: '10px',
        position: 'absolute',
        margin: '2px'
    },
    wrapper: {
        height: '100%',
        width: '100%',
        position: 'relative'
    }
});

const GRID_VIEW = 'GridView';
const FORM_VIEW = 'FormView';

class OneToManyElement extends React.Component {
    state = {
        viewMode: this.props.itemSchema.defaultViewMode ? this.props.itemSchema.defaultViewMode : GRID_VIEW 
    }

    handleSetFormView = () => {
        const { clearHighlightedField } = this.props;
        if (typeof clearHighlightedField === 'function') {
            clearHighlightedField();
        }
        this.setState({
            viewMode:FORM_VIEW
        });
    }

    handleSetGridView = () => {
        const { clearHighlightedField } = this.props;
        if (typeof clearHighlightedField === 'function') {
            clearHighlightedField();
        }
        this.setState({
            viewMode:GRID_VIEW
        });
    }

    renderToggleButtons = (toggleAvailable) => {
        const { classes } = this.props;
        const { viewMode } = this.state;
        if (toggleAvailable) {
            return (
                <div className={classes.toggleButtonWrapper}>
                    <Button
                        onClick={this.handleSetGridView}
                        variant={viewMode === GRID_VIEW ? "contained" : "outlined"}
                        color="primary"
                    >
                        <BorderAllIcon />
                    </Button>
                    <Button
                        onClick={this.handleSetFormView}
                        variant={viewMode === FORM_VIEW ? "contained" : "outlined"}
                        color="primary"
                    >
                        <ViewQuiltIcon />
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const {
            classes,
            itemSchema,
            formMetaData,
            formData,
            recordContext,
            settings,
            onChange,
            onBlur,
            onAddRecord,
            onDeleteRecords,
            setRecordCurrency,
            autoFocus,
            highlightedFieldKeyArray,
            highlightedRecordKey,
            clearHighlightedField,
            triggerScroll,
            disabled
        } = this.props;
        const { viewMode } = this.state;

        let newFieldKeyArray;
        if (Array.isArray(highlightedFieldKeyArray) && highlightedFieldKeyArray.length > 1) {
            newFieldKeyArray = [...highlightedFieldKeyArray];
            let firstKey = newFieldKeyArray.shift();
            if (firstKey === itemSchema.uniqueKey) {
                let secondKey = newFieldKeyArray[0];
                if (secondKey === ":column:" && viewMode !== GRID_VIEW) {
                    this.setState({
                        viewMode: GRID_VIEW
                    });
                } else if (secondKey !== ":column:" && viewMode !== FORM_VIEW) {
                    this.setState({
                        viewMode: FORM_VIEW
                    });
                }
            } else {
                newFieldKeyArray = [];
            }
        }


        let calculatedViewMode = viewMode;
        if (!itemSchema.formViewEnabled && viewMode === FORM_VIEW) {
            calculatedViewMode = GRID_VIEW;
        } else if (!itemSchema.gridViewEnabled && viewMode === GRID_VIEW) {
            calculatedViewMode = FORM_VIEW;
        }
        
        let toggleAvailable = (itemSchema.formViewEnabled && calculatedViewMode !== FORM_VIEW) || (itemSchema.gridViewEnabled && calculatedViewMode !== GRID_VIEW);
        let Element = (calculatedViewMode === FORM_VIEW) ? OneToManyFormView : OneToManyGridView;
        return (
            <div className={classes.wrapper}>
                {this.renderToggleButtons(toggleAvailable)}
                <Element
                    itemSchema={itemSchema}
                    formMetaData={formMetaData}
                    formData={formData}
                    recordContext={recordContext}
                    settings={settings}
                    onChange={onChange}
                    onBlur={onBlur}
                    onAddRecord={onAddRecord}
                    onDeleteRecords={onDeleteRecords}
                    autoFocus={autoFocus}
                    setRecordCurrency={setRecordCurrency}
                    setFormView={this.handleSetFormView}
                    setGridView={this.handleSetGridView}
                    toggleAvailable={toggleAvailable}
                    highlightedFieldKeyArray={newFieldKeyArray}
                    highlightedRecordKey={highlightedRecordKey}
                    clearHighlightedField={clearHighlightedField}
                    triggerScroll={triggerScroll}
                    disabled={disabled}
                />
            </div>
        );
    }
}

export default withStyles(styles)(OneToManyElement);