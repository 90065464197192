import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import CriteriaOneToManyEditor from './CriteriaOneToManyEditor.js';
import OneToManyEditor from './OneToManyEditor';

const styles = theme => ({

});

class WhereCriteriaOneToManyEditor extends React.Component {
    createNewCriteria = () => {
        return {};
    }

    determineEditorType = () => {return CriteriaOneToManyEditor;}

    itemToDisplayString = (item) => {
        if ((item.sourceField ?? null) !== null) {
            if ((item.comparisonValue ?? null) !== null) {
                return `${item.sourceField} = ${item.comparisonValue}`;
            }else{
                return item.sourceField;
            }
        } else{
            return "New Column"; 
        }
    }

    render() {
        const { value, onChange } = this.props;

        return (
            <OneToManyEditor
                items={value ? value : []}
                displayName={"Filter Criteria"}
                itemToDisplayString={this.itemToDisplayString}
                onChange={onChange}
                newItem={this.createNewCriteria()}
                determineEditorType={this.determineEditorType}
            />
        );
    }
}

export default withStyles(styles)(WhereCriteriaOneToManyEditor);