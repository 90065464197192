import React from 'react';
import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 75px)'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        padding: '0px 8px !important',
        height: '24px !important',
        display: 'flex'
    },
    editIcon: {
        flex: '0 0 auto'
    }
});

class SelectionSetSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionSetRecord: null
        };
    }

    deleteSelectionSet = (sS_Selection_Sets_ID) => {
        const { onDeleteSelectionSet } = this.props;
        onDeleteSelectionSet(sS_Selection_Sets_ID);
    }

    selectSelectionSet = (selectionSet) => {
        const { onSelectionSetEdit } = this.props;
        onSelectionSetEdit(selectionSet);
    }

    handleChange = (selectionSet) => {
        const { onHandleChange } = this.props;
        onHandleChange(selectionSet);
    }

    selectionSetRowClick = (selectionSet) => {
        const { onSelectionSetSelect } = this.props;
        this.setState({
            selectionSetRecord: selectionSet
        });
        onSelectionSetSelect(selectionSet);
    }

    render() {
        const { classes, selectionSetList, onSelectionSetEditorOpen, isLoading } = this.props;
        const { selectionSetRecord } = this.state;

        return (
            <Card className={classes.card}>
                <CardHeader>
                    <Typography className={classes.title} align='center' variant='h6'>
                        {'SELECTION SET EDITOR'}
                    </Typography>
                    <Divider />
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <LxSearchableTable
                        items={selectionSetList !== null ? selectionSetList : []}
                        columns={[
                            {
                                title: 'sS_Selection_Sets_ID',
                                key: 'sS_Selection_Sets_ID',
                                show: false
                            },
                            {
                                title: 'Selection Set',
                                key: 'selectionSet',
                                id: 'selectionSet',
                                width: 300
                            },
                            {
                                title: 'Locked',
                                key: 'formLock',
                                id: 'formLock',
                                width: 150,
                                accessor: item => item.formLock.toString()
                            },
                            {
                                title: 'Limit To List',
                                key: 'userLock',
                                id: 'userLock',
                                width: 140,
                                accessor: item => item.userLock.toString()
                            },
                            {
                                title: 'Entry Label',
                                key: 'clinicalDoc',
                                id: 'clinicalDoc',
                                width: 140,
                                accessor: item => item.clinicalDoc.toString()
                            },
                            {
                                title: 'Allow Favorites',
                                key: 'attributes',
                                id: 'attributes',
                                width: 140,
                            },
                            {
                                id: 'edit',
                                width: 80,
                                title: 'Edit',
                                className: classes.buttonCell,
                                resizable: false,
                                key: (item) => (
                                    <ConfirmationButton
                                        onClick={() => this.selectSelectionSet(item)}
                                        className={classes.button}
                                    >
                                        <EditIcon className={classes.editIcon} />
                                    </ConfirmationButton>
                                )
                            },
                            {
                                id: 'delete',
                                width: 80,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Delete',
                                key: (item) => (
                                    <ConfirmationButton
                                        showConfirmation
                                        confirmationTitle='Permanently Delete selection set?'
                                        confirmationText={[
                                            'Are you sure you want to remove this selection set?',
                                            'You will not be able to get it back.'
                                        ]}
                                        confirmationConfirmText='Delete'
                                        onClick={() => this.deleteSelectionSet(item.sS_Selection_Sets_ID)}
                                    >
                                        <DeleteIcon />
                                    </ConfirmationButton>
                                )
                            }
                        ]}
                        searchKeys={[
                            'selectionSet',
                            'formLock',
                            'userLock',
                            'clinicalDoc',
                            'attributes'
                        ]}
                        onRowClick={this.selectionSetRowClick}
                        selectedItem={selectionSetRecord}
                        isLoading={isLoading}
                        title={'Available Selection Sets'}
                    />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onSelectionSetEditorOpen}
                        disabled={isLoading}
                    >
                        {'Add New Selection Set'}
                    </Button>
                </CardFooter>
            </Card>
        );
    }
}
export default withStyles(styles)(SelectionSetSelector);