import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCombobox from '.../components/formComponents/LxCombobox';

const styles = theme => ({
    header: {
        margin: `${theme.spacing(2)}px 0px`
    },
    lxComboboxMenuAnchor: {
        height: '500px'
    }
});

class XAxisSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: true
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.selectedChart) !== JSON.stringify(this.props.selectedChart)) {
            this.isValidated(this.props.selectedChart);
        }
    }

    isValidated = (chart) => {
        const checkedChart = typeof chart !== 'undefined' ? chart : this.props.selectedChart;
        let validated = typeof checkedChart !== 'undefined';

        validated = validated && ((checkedChart.chartType === 'line') || (checkedChart.xAxisFieldName !== '' && checkedChart.xAxisTableName !== ''));

        this.setState({ validated });
        return validated;
    }

    handleXAxisTableChange = (xAxisTableName) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            xAxisTableName: xAxisTableName
        }, this.props.selectedChartId);
    }

    handleXAxisFieldChange = (xAxisFieldName) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            xAxisFieldName: xAxisFieldName
        }, this.props.selectedChartId);
    }

    handleXAxisUnitsChange = (units) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            units: units
        }, this.props.selectedChartId);
    }

    handleXAxisMeasurementLabelChange = (measurementLabel) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            measurementLabel: measurementLabel
        }, this.props.selectedChartId);
    }

    handleItemSelect = (item) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            xAxisTableName: item !== null && typeof item !== 'undefined' ? item.table : '',
            xAxisFieldName: item !== null && typeof item !== 'undefined' ? item.field : '',
            units: item !== null && typeof item !== 'undefined' ? item.units : '',
            measurementLabel: item !== null && typeof item !== 'undefined' ? item.label : ''
        }, this.props.selectedChartId);
    }

    getSelectedMeasurement = (measurements) => {
        const { selectedChart } = this.props;

        for (let i = 0; i < measurements.length; i++){
            if (measurements[i].table === selectedChart.xAxisTableName && measurements[i].field === selectedChart.xAxisFieldName){
                return measurements[i];
            }
        }
    }

    render() {
        const { selectedChart, classes, measurements, getMeasurementItemToString } = this.props;
        const { validated } = this.state;

        const validChart = typeof selectedChart !== 'undefined';
        const lineChart = validChart ? selectedChart.chartType === 'line' : false;

        return (!validChart ? null :
            <div>
                <GridContainer className={classes.header} justify='space-between' alignItems='baseline'>
                    <GridItem>
                        <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                            {lineChart ? 'Line chart X axis is over time, nothing to show here' : 'Select an X axis option from the search box'}
                        </Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={4} justify='center' alignContent='center'>
                    {/*
                        <GridItem sm={12}>
                            <TextField
                                helperText='X Axis Table Name'
                                placeholder='X Axis Table Name'
                                fullWidth
                                disabled={lineChart}
                                error={!validated && selectedChart.xAxisTableName === ''}
                                value={selectedChart.xAxisTableName === null ? '' : selectedChart.xAxisTableName}
                                onChange={(event) => this.handleXAxisTableChange(event.target.value)}
                            />
                        </GridItem>
                        <GridItem sm={12}>
                            <TextField
                                helperText='X Axis Field Name'
                                placeholder='X Axis Field Name'
                                fullWidth
                                disabled={lineChart}
                                error={!validated && selectedChart.xAxisFieldName === ''}
                                value={selectedChart.xAxisFieldName === null ? '' : selectedChart.xAxisFieldName}
                                onChange={(event) => this.handleXAxisFieldChange(event.target.value)}
                            />
                        </GridItem>
                        <GridItem sm={12}>
                            <TextField
                                helperText='X Axis Units'
                                placeholder='X Axis Units'
                                fullWidth
                                disabled={lineChart}
                                value={selectedChart.units === null ? '' : selectedChart.units}
                                onChange={(event) => this.handleXAxisUnitsChange(event.target.value)}
                            />
                        </GridItem>
                        <GridItem sm={12}>
                        <TextField
                                helperText='X Axis Measurement Label'
                                placeholder='X Axis Measurement Label'
                                fullWidth
                                disabled={lineChart}
                                value={selectedChart.measurementLabel === null ? '' : selectedChart.measurementLabel}
                                onChange={(event) => this.handleXAxisMeasurementLabelChange(event.target.value)}
                            />
                        </GridItem>
                    */}
                    {lineChart ?
                        null
                        :
                        <GridItem sm={12}>
                            <LxCombobox
                                key={selectedChart.title}
                                items={measurements}
                                placeholder={"Start typing to search measurement definitions..."}
                                itemToString={getMeasurementItemToString}
                                inputDisplayTemplate={item => `${item.label} ${item.units}`}
                                selectedItem={measurements ? this.getSelectedMeasurement(measurements)
                                                             : ''}
                                onChange={this.handleItemSelect}
                                columns={["Label", "Units", "Table", "Field"]}
                                searchKeys={['name', 'label', 'units', 'table', 'field']}
                                classes={{ menuAnchor: classes.lxComboboxMenuAnchor }}
                                fitTableWidth
                            />
                        </GridItem>
                    }
                </GridContainer>
            </div>
        );
    }
}

XAxisSelection.propTypes = {
    selectedChart: PropTypes.object,
    selectedChartId: PropTypes.number,
    setSelectedChart: PropTypes.func.isRequired
};

export default withStyles(styles)(XAxisSelection);