import Avatar from '@material-ui/core/Avatar';
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import cx from "classnames";
import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import sidebarStyle from "../../jss/material-dashboard-pro-react/components/sidebarStyle";
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { auditApi } from '.../utils/apiHelper';
import { sessionStorageHelper } from '.../utils/sessionStorageHelper';
import userManager from '.../utils/userManager';

class SidebarUserItem extends React.Component {
    state = {
        openAvatar: false,
        isIframe: window.self !== window.top || window.CefSharp
    }

    componentDidMount() {
        userManager.getUser().then((user) => {
            let loginName = '';
            let initialLetters = '';
            if (typeof user.profile.given_name === 'undefined' || typeof user.profile.family_name === 'undefined') {
                loginName = typeof user.profile.name === 'undefined' ? 'undefined user name' : `${user.profile.name}`;
                // TODO: WE may need to revisit this code after we support other providers. This code works fin for the case profile.name is in format of domain\userId
                initialLetters = typeof user.profile.name === 'undefined' ? '?' : `${user.profile.name.toUpperCase()[user.profile.name.indexOf('\\') + 1]}`;
            }
            else {
                loginName = `${user.profile.given_name} ${user.profile.family_name}`;
                initialLetters = `${user.profile.given_name.toUpperCase()[0]}${user.profile.family_name.toUpperCase()[0]}`;
            }
            this.setState({
                loginName,
                initialLetters
            });
        });
    }

    onLogoutButtonClicked = (event) => {
        event.preventDefault();
        //set isSigningOut cookie to true to disable auto redirect
        sessionStorageHelper.setIsSigningOut(true);
        userManager.getUser().then((user) => {
            userManager.signoutRedirect({ 'id_token_hint': user.id_token });
            userManager.removeUser(); // removes the user data from sessionStorage
        });

        auditApi.userlogout();
    }

    render() {
        const { classes, bgColor, rtlActive, miniActive } = this.props;
        const { initialLetters, loginName, openAvatar, isIframe } = this.state;

        const itemText = cx({
            [classes.itemText]: true,
            [classes.itemTextMini]: miniActive,
            [classes.itemTextMiniRTL]: rtlActive && miniActive,
            [classes.itemTextRTL]: rtlActive
        });

        const collapseItemText = cx({
            [classes.collapseItemText]: true,
            [classes.collapseItemTextMini]: miniActive,
            [classes.collapseItemTextMiniRTL]: rtlActive && miniActive,
            [classes.collapseItemTextRTL]: rtlActive
        });

        const userWrapperClass = cx({
            [classes.user]: true,
            [classes.whiteAfter]: bgColor === "white"
        });

        const caret = cx({
            [classes.caret]: true,
            [classes.caretRTL]: rtlActive
        });

        const collapseItemMini = cx({
            [classes.collapseItemMini]: true,
            [classes.collapseItemMiniRTL]: rtlActive
        });

        return (
            <div className={userWrapperClass}>
                <List className={classes.list}>
                    <ListItem className={classes.userItem} onClick={() => this.setState({ openAvatar: !openAvatar })}>
                        <Avatar className={classes.avatarImg}>
                            {initialLetters}
                        </Avatar>
                        <ListItemText
                            primary={
                                <GridContainer wrap='nowrap'>
                                    <GridItem className={classes.userNameGridItem}>
                                        <Tooltip placement='top' title={typeof loginName !== 'undefined' ? loginName : ''}>
                                            <Typography className={classes.userName} noWrap variant='caption'>
                                                {loginName}
                                            </Typography>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem>
                                        <b
                                            className={
                                                caret +
                                                " " +
                                                classes.userCaret +
                                                " " +
                                                (openAvatar ? classes.caretActive : "")
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>
                            }
                            disableTypography
                            className={itemText + " " + classes.userItemText}
                        />
                    </ListItem>
                </List>
                <Collapse in={openAvatar} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                        {isIframe ?
                            null :
                            <ListItem className={classes.collapseItem}>
                                <NavLink
                                    to="#"
                                    className={
                                        classes.itemLink + " " + classes.userCollapseLinks
                                    }
                                    onClick={e => this.onLogoutButtonClicked(e)}
                                >
                                    <span className={collapseItemMini}>
                                        {"LO"}
                                    </span>
                                    <ListItemText
                                        primary={"LOGOUT"}
                                        disableTypography
                                        className={collapseItemText}
                                    />
                                </NavLink>

                            </ListItem>
                        }
                    </List>
                </Collapse>
            </div>
        );
    }
}

export default withStyles(sidebarStyle)(withRouter(SidebarUserItem));
