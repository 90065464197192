import 'react-table/react-table.css';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactTable from 'react-table';

const styles = (theme) => ({
    reactTable: {
        textAlign: 'left',
        '& .rt-thead': {
            '& .rt-th:last-child': {
                textAlign: 'left'
            }
        }
    },
    trendLine: {
        '& svg': {
            maxHeight: '30px'
        }
    }
});

class OeRatio extends React.Component {
    constructor(props) {
        super(props);

        let columns = [
            {
                Header: 'Risk',
                id: 'risk',
                columns: [
                    {
                        Header: 'EndPoint',
                        accessor: 'endpoint',
                        minWidth: 120
                    }
                ]
            },
            {
                Header: 'Observed vs Expected',
                id: 'observedVsExpected',
                columns: [
                    {
                        Header: 'Observed',
                        accessor: 'observedVsExpected.observed',
                        minWidth: 50
                    },
                    {
                        Header: 'Total',
                        accessor: 'observedVsExpected.total',
                        minWidth: 50
                    },
                    {
                        Header: 'Expected',
                        accessor: 'observedVsExpected.expected',
                        minWidth: 80
                    }
                ]
            },
            {
                Header: 'O/E Ratio',
                id: 'oeRatio',
                columns: [
                    {
                        Header: 'You',
                        accessor: 'oeRatio.you',
                        minWidth: 80
                    },
                    {
                        Header: 'Peers',
                        accessor: 'oeRatio.peers',
                        minWidth: 80
                    },
                    {
                        Header: 'Difference',
                        accessor: 'oeRatio.difference',
                        minWidth: 80
                    }
                ]
            },
            {
                Header: 'Trends',
                id: 'trends',
                minWidth: 100,
                columns: [
                    {
                        Header: 'You',
                        id: 'trendsYou',
                        minWidth: 150,
                        accessor: row => row.trend.split(/\s*\|\s*/),
                        Cell: row => (
                            <div className={props.classes.trendLine}>
                                <Sparklines data={row.value}>
                                    <SparklinesLine />
                                    <SparklinesSpots />
                                </Sparklines>
                            </div>
                        )
                    }
                ]
            }
        ];

        columns = columns.map(column => ({
            ...column,
            Header: typeof column.Header !== 'string' ?
                column.Header
                :
                <Tooltip title={column.Header} placement='top'>
                    <span>{column.Header}</span>
                </Tooltip>,
            columns: column.columns.map((col) => ({
                ...col,
                Header: typeof col.Header !== 'string' ?
                    col.Header
                    :
                    <Tooltip title={col.Header} placement='top'>
                        <span>{col.Header}</span>
                    </Tooltip>
            }))
        }));

        this.state = {
            columns
        };
    }

    render() {
        const { classes, oeRatios, ...others } = this.props;
        const { columns } = this.state;

        return (
            <ReactTable
                data={oeRatios}
                className={"-striped -highlight " + classes.reactTable}
                showPaginationBottom={false}
                minRows={0}
                columns={columns}
                {...others}
            />
        );
    }
}

export default withStyles(styles)(OeRatio);