import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
    selectBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    leftSelectItem: {
        flex: '1 1 33%',
        margin: `${theme.spacing(1)}px`,
        marginLeft: '0px'
    },
    centerSelectItem: {
        flex: '1 1 33%',
        margin: `${theme.spacing(1)}px`
    },
    rightSelectItem: {
        flex: '1 1 33%',
        margin: `${theme.spacing(1)}px`,
        marginRight: '0px'
    }
});

const OeRatioFilters = (props) => {
    return (
        <div className={props.className}>
            <div className={props.classes.selectBar}>
                {typeof props.selected.surgeon === 'undefined' ?
                    null
                    :
                    <FormControl className={props.classes.leftSelectItem}>
                        <InputLabel>{"Surgeon"}</InputLabel>
                        <Select value={props.selected.surgeon} onChange={(event) => props.onChange({ ...props.selected, surgeon: event.target.value })}>
                            {props.filterOptions.surgeons.map((surgeon, index) => (
                                <MenuItem value={surgeon} key={index}>{surgeon}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                <FormControl className={props.classes.centerSelectItem}>
                    <InputLabel>{"Procedure"}</InputLabel>
                    <Select value={props.selected.procedure} onChange={(event) => props.onChange({ ...props.selected, procedure: event.target.value })}>
                        {props.filterOptions.procedures.map((procedure, index) => (
                            <MenuItem value={procedure.id} key={index}>{procedure.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={props.classes.rightSelectItem}>
                    <InputLabel>{"Number of Months"}</InputLabel>
                    <Select value={props.selected.month} onChange={(event) => props.onChange({ ...props.selected, month: event.target.value })}>
                        {props.filterOptions.numberOfMonths.map((month, index) => (
                            <MenuItem value={month} key={index}>{month}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default withStyles(styles)(OeRatioFilters);