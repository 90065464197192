import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ReactTable from 'react-table';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxSelectField from '.../components/formComponents/LxSelectField';

const styles = theme => ({
    gridContainer: {
        height: 'inherit'
    },
    gridItem: {
        height: 'inherit'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: '100%'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        padding: '0px 8px !important',
        height: '24px !important',
        display: 'flex'
    },
    editIcon: {
        flex: '0 0 auto'
    },
    reactTable: {
        '& .rt-thead': {
            '& .rt-th': {
                margin: '0px',
                maxHeight: '30px',
                lineHeight: '1 !important',
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily
            },
            '& .rt-th:last-child': {
                textAlign: 'left'
            }
        },
        '& .rt-td': {
            fontSize: theme.typography.fontSize,
            margin: '0px',
            padding: '3px 5px',
            height: '24px',
            display: 'flex',
            flexDirection: 'column',
            '& div': {
                textAlign: 'center',
                width: 'inherit',
                margin: 'auto',
                '& span': {
                    margin: '0px 8px'
                }
            }
        },
        '& .rt-tr': {
            minWidth: 'fit-content',
            textAlign: 'left'
        },
        '& .rt-table': {
            flex: '1 1 auto',
            overflow: 'auto'
        },
        '& .pagination-bottom': {
            flex: '0 0 auto',
            '& .-previous': {
                flex: '0 0 65px',
                '& button': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                    padding: '0px'
                }
            },
            '& .-center': {
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily,
                '& .-pageInfo': {
                    marginTop: '0px',
                    marginBottom: '0px',
                    '& .-pageJump': {
                        '& input': {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            height: '18px',
                            backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#D2D2D2, #D2D2D2)`
                        }
                    }
                },
                '& .-pageSizeOptions': {
                    marginTop: '0px',
                    marginBottom: '0px',
                    '& select': {
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        height: '18px',
                        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#D2D2D2, #D2D2D2)`
                    }
                }
            },
            '& .-next': {
                flex: '0 0 65px',
                '& button': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                    padding: '0px'
                }
            },
            '& .-pagination': {
                boxShadow: 'none'
            }
        },
        minHeight: '170px',
        flex: '1 1 auto'
    }
});

class LookupTableSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lookupTableName: ''
        };
    }

    onLookupTableChange = (lookupTableName) => {
        const { onLookupTableChange } = this.props;
        onLookupTableChange(lookupTableName);
        this.setState({
            lookupTableName: lookupTableName
        });
    }

    render() {
        const { classes, lookupTablesList, data, columns, isLoading, onAddNewRecord } = this.props;

        return (
            <Card>
                <CardHeader>
                    <Typography className={classes.title} align='center' variant='h6'>
                        {'LOOKUP TABLE EDITOR'}
                    </Typography>
                    <Divider />
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <GridContainer spacing={4} className={classes.gridContainer}>
                        <GridItem xs={3}>
                            <LxSelectField
                                title='Lookup Tables'
                                classes={{
                                    dropdownRoot: classes.dropdownRoot,
                                    dropdownPaper: classes.dropdownPaper
                                }}
                                value={lookupTablesList}
                                options={lookupTablesList}
                                nullable={false}
                                onChange={this.onLookupTableChange}
                            />
                        </GridItem>
                        <GridItem xs={3} />
                        <GridItem xs={3} />
                        <GridItem xs={3}>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={this.state.lookupTableName ? "" : "true"}
                                onClick={onAddNewRecord}
                            >
                                {'Add New Record'}
                            </Button>
                        </GridItem>
                        <GridItem xs={12} >
                            <ReactTable
                                className={`-striped -highlight ${classes.reactTable}`}
                                data={data !== null ? data : []}
                                filterable
                                columns={columns !== null ? columns : []}
                                searchKeys={[columns !== null ? columns : []]}
                                isLoading={isLoading}
                                defaultSorted={[{ id: "id" }]}
                                defaultPageSize={25}
                                showPaginationBottom
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}
export default withStyles(styles)(LookupTableSelector);