import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import Radium from 'radium';
import React from 'react';

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import CardIcon from ".../assets/components/Card/CardIcon";
import CustomInput from ".../assets/components/CustomInput/CustomInput";
import regularFormsStyle from ".../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = (theme) => ({
    ...regularFormsStyle
});

class PatientWorklistSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mrn: '',
            firstName: '',
            lastName: '',
            snackbarOpen: false
        };

        this.onSubmit = props.onSubmit;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value.trim().replace(/[^0-9a-z]/gi, '')
        });
    };

    handleValidation = (event) => {
        event.preventDefault();
        const { mrn, firstName, lastName } = this.state;

        if (mrn === '' && firstName === '' && lastName === '') {
            this.setState({
                snackbarOpen: true
            });
            return;
        }

        this.onSubmit({ mrn, firstName, lastName });
    };

    handleRequestClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    render() {
        const { className, classes } = this.props;

        return (
            <Card className={className}>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary" style={{ zIndex: 2000 }}>
                        <Search />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Patient Search</h4>
                </CardHeader>
                <CardBody>
                    <form onSubmit={(event) => { this.handleValidation(event); }}>
                        <CustomInput
                            value={this.state.mrn}
                            labelText="MRN"
                            id="mrn"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "mrn",
                                onChange: this.handleInputChange
                            }}
                        />
                        <CustomInput
                            value={this.state.firstName}
                            labelText="First Name"
                            id="firstName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "firstName",
                                onChange: this.handleInputChange
                            }}
                        />
                        <CustomInput
                            value={this.state.lastName}
                            onChange={this.handleInputChange}
                            labelText="Last Name"
                            id="lastName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "lastName",
                                onChange: this.handleInputChange
                            }}
                        />

                        <Button type='submit' color='primary' variant='contained' >Submit</Button>
                        <Snackbar
                            open={this.state.snackbarOpen}
                            message='You must fill in at least one search criteria'
                            autoHideDuration={4000}
                            onClose={this.handleRequestClose}
                        />
                    </form>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(Radium(PatientWorklistSearch));