import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCheckbox from '.../components/formComponents/LxCheckbox';
import LxDatePicker from '.../components/formComponents/LxDatePicker';
import LxSelectField from '.../components/formComponents/LxSelectField';
import LxTextField from '.../components/formComponents/LxTextField';
import StaffNumbers from './StaffNumbers';
import StaffRoles from './StaffRoles';

const styles = theme => ({
    card: {
        width: '80vw',
        height: '115vh'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        display: 'flex'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class StaffEditor extends React.Component {
    state = {
        newFirstName: '',
        newMiddleName: '',
        newLastName: '',
        newActiveStaffState: false,
        dateOfBirth: null,
        openRoleDialog: false,
        openNumberDialog: false
    }

    openStaffRoles = () => {
        this.setState({
            openRoleDialog: true
        });
    }

    closeStaffRoles = () => {
        this.setState({
            openRoleDialog: false
        });
    }

    openStaffNumbers = () => {
        this.setState({
            openNumberDialog: true
        });
    }

    closeStaffNumbers = () => {
        this.setState({
            openNumberDialog: false
        });
    }

    renderStaffEditor = () => {
        const { classes, populateStaff, gender, staffType, organization, specialty, department, role, city, state, county, country } = this.props;

        return (
            <GridContainer className={classes.flex} spacing={5}>
                <GridItem xs={3} className={classes.checkBox}>
                    <LxCheckbox
                        title={'Active'}
                        titleTooltip={'Active'}
                        description={'Active'}
                        value={populateStaff.active === null ? false : populateStaff.active}
                        threeState={false}
                        onChange={this.onActiveStaffChange}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <LxTextField
                        align='center'
                        title='Display Name'
                        value={populateStaff?.name}
                        max={40}
                    />
                </GridItem>
                <GridItem xs={3} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button color="primary" variant='outlined' onClick={this.onGenerateName}>
                        {'Generate Name'}
                    </Button>
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='First Name'
                        value={populateStaff?.firstName}
                        onChange={this.onFirstNameChange}
                        max={50}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Middle Name'
                        value={populateStaff?.middleName}
                        onChange={this.onMiddleNameChange}
                        max={40}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Last Name'
                        value={populateStaff?.lastName}
                        onChange={this.onLastNameChange}
                        max={50}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Initials'
                        value={populateStaff?.initials}
                        onChange={this.onInitialsChange}
                        max={10}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Suffix'
                        value={populateStaff?.suffix}
                        onChange={this.onSuffixChange}
                        max={20}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Title'
                        value={populateStaff?.title}
                        onChange={this.onTitleChange}
                        max={20}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='User Name'
                        value={populateStaff?.userName}
                        onChange={this.onUserNameChange}
                        max={20}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Alternate Name'
                        value={populateStaff?.alternateName}
                        onChange={this.onAlternateNameChange}
                        max={50}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='UPIN'
                        value={populateStaff?.upin}
                        onChange={this.onUPINChange}
                        max={20}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='SSN'
                        value={populateStaff?.ssn}
                        onChange={this.onSSNChange}
                        max={15}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxDatePicker
                        title='Date of Birth'
                        value={populateStaff?.dateOfBirth === null ? null : populateStaff.dateOfBirth}
                        onChange={this.onBirthDateChange}
                        className={classes.formItem}
                        disableFuture
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Gender'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.gender}
                        onChange={this.onGenderChange}
                        options={gender}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Provider ID'
                        value={populateStaff?.providerID}
                        onChange={this.onProviderIDChange}
                        max={60}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Staff Type'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.staffType}
                        onChange={this.onStaffTypeChange}
                        options={staffType}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Organization'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.organization}
                        onChange={this.onOrganizationChange}
                        options={organization}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Practitioner Group'
                        value={populateStaff?.practitionerGroup}
                        onChange={this.onPractitionerGroupChange}
                        max={25}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Specialty'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.specialty}
                        onChange={this.onSpecialtyChange}
                        options={specialty}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Department'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.department}
                        onChange={this.onDepartmentChange}
                        options={department}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Primary Role'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.role}
                        options={role}
                        onChange={this.onPrimaryRoleChange}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <Divider />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='NPI'
                        value={populateStaff?.npi}
                        onChange={this.onNPIChange}
                        max={10}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='TIN'
                        value={populateStaff?.tin}
                        onChange={this.onTinChange}
                        max={20}
                    />
                </GridItem>
                <GridItem xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" variant='outlined' onClick={this.openStaffRoles}>
                        {'Roles'}
                    </Button>
                </GridItem>
                <GridItem xs={3} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button color="primary" variant='outlined' onClick={this.openStaffNumbers}>
                        {'Staff Numbers'}
                    </Button>
                </GridItem>
                <GridItem xs={12}>
                    <Divider />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Address'
                        value={populateStaff?.address1}
                        onChange={this.onAddress1Change}
                        max={120}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Address #2'
                        value={populateStaff?.address2}
                        onChange={this.onAddress2Change}
                        max={80}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Address #3'
                        value={populateStaff?.address3}
                        onChange={this.onAddress3Change}
                        max={80}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Address #4'
                        value={populateStaff?.address4}
                        onChange={this.onAddress4Change}
                        max={80}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='City'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.city}
                        options={city}
                        onChange={this.onCityChange}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='State'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.state}
                        options={state}
                        onChange={this.onStateChange}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Zip Code'
                        value={populateStaff?.zipCode}
                        onChange={this.onZipCodeChange}
                        max={10}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='County'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.county}
                        options={county}
                        onChange={this.onCountyChange}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Country'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={populateStaff?.country}
                        options={country}
                        onChange={this.onCountryChange}
                        nullable={false}
                    />
                </GridItem>
                <GridItem xs={3} />
                <GridItem xs={3} />
                <GridItem xs={3} />
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Phone #1'
                        value={populateStaff?.phone1}
                        onChange={this.onPhone1Change}
                        max={25}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Phone #2'
                        value={populateStaff?.phone2}
                        onChange={this.onPhone2Change}
                        max={25}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Fax'
                        value={populateStaff?.fax}
                        onChange={this.onFaxChange}
                        max={15}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Pager'
                        value={populateStaff?.pager}
                        onChange={this.onPagerChange}
                        max={15}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Home Phone'
                        value={populateStaff?.homePhone}
                        onChange={this.onHomePhoneChange}
                        max={15}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Cellular Phone'
                        value={populateStaff?.cellPhone}
                        onChange={this.onCellPhoneChange}
                        max={15}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        align='center'
                        title='Email'
                        value={populateStaff?.emailAddress}
                        onChange={this.onEmailAddressChange}
                        max={40}
                    />
                </GridItem>
                <GridItem xs={9}>
                    <LxTextField
                        align='center'
                        title='Comments'
                        multiline
                        rows={4}
                        value={populateStaff?.comments}
                        onChange={this.onCommentsChange}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    onGenerateName = () => {
        const { populateStaff } = this.props;
        let value = populateStaff?.firstName;

        if (value !== '') {
            value = populateStaff?.firstName + ' ';
        }
        if (populateStaff?.middleName !== '') {
            value = value + populateStaff?.middleName + ' ';
        }
        if (populateStaff?.lastName !== '') {
            value = value + populateStaff?.lastName + ' ';
        }
        if (populateStaff?.suffix !== '') {
            value = value + ',' + populateStaff?.suffix;
        }

        this.setState({
            value
        });

        const { onStaffChange } = this.props;
        if (populateStaff?.name !== value) {
            let newStaff = {
                ...populateStaff,
                name: value
            };
            onStaffChange(newStaff, populateStaff.name);
        }
    }

    onActiveStaffChange = (newActiveStaffState) => {
        this.setState({
            newActiveStaffState
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.active !== newActiveStaffState) {
            let newStaff = {
                ...populateStaff,
                active: newActiveStaffState
            };
            onStaffChange(newStaff, populateStaff.active);
        }
    }

    onFirstNameChange = (newFirstName) => {
        this.setState({
            newFirstName
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.firstName !== newFirstName) {
            let newStaff = {
                ...populateStaff,
                firstName: newFirstName
            };
            onStaffChange(newStaff, populateStaff.firstName);
        }
    }

    onMiddleNameChange = (newMiddleName) => {
        this.setState({
            newMiddleName
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.middleName !== newMiddleName) {
            let newStaff = {
                ...populateStaff,
                middleName: newMiddleName
            };
            onStaffChange(newStaff, populateStaff.middleName);
        }
    }

    onLastNameChange = (newLastName) => {
        this.setState({
            newLastName
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.lastName !== newLastName) {
            let newStaff = {
                ...populateStaff,
                lastName: newLastName
            };
            onStaffChange(newStaff, populateStaff.lastName);
        }
    }

    onGenderChange = (newGender) => {
        this.setState({
            newGender
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.gender !== newGender) {
            let newStaff = {
                ...populateStaff,
                gender: newGender
            };
            onStaffChange(newStaff, populateStaff.gender);
        }
    }

    onBirthDateChange = (newBirthDate) => {
        this.setState({
            newBirthDate
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.dateOfBirth !== newBirthDate) {
            let newStaff = {
                ...populateStaff,
                dateOfBirth: newBirthDate
            };
            onStaffChange(newStaff, populateStaff.dateOfBirth);
        }
    }

    onStaffTypeChange = (newStaffType) => {
        this.setState({
            newStaffType
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.staffType !== newStaffType) {
            let newStaff = {
                ...populateStaff,
                staffType: newStaffType
            };
            onStaffChange(newStaff, populateStaff.staffType);
        }
    }

    onOrganizationChange = (newOrganization) => {
        this.setState({
            newOrganization
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.organization !== newOrganization) {
            let newStaff = {
                ...populateStaff,
                organization: newOrganization
            };
            onStaffChange(newStaff, populateStaff.organization);
        }
    }

    onPrimaryRoleChange = (newRole) => {
        this.setState({
            newRole
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.role !== newRole) {
            let newStaff = {
                ...populateStaff,
                role: newRole
            };
            onStaffChange(newStaff, populateStaff.role);
        }
    }

    onInitialsChange = (newInitials) => {
        this.setState({
            newInitials
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.initials !== newInitials) {
            let newStaff = {
                ...populateStaff,
                initials: newInitials
            };
            onStaffChange(newStaff, populateStaff.initials);
        }
    }

    onSuffixChange = (newSuffix) => {
        this.setState({
            newSuffix
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.suffix !== newSuffix) {
            let newStaff = {
                ...populateStaff,
                suffix: newSuffix
            };
            onStaffChange(newStaff, populateStaff.suffix);
        }
    }

    onTitleChange = (newTitle) => {
        this.setState({
            newTitle
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.title !== newTitle) {
            let newStaff = {
                ...populateStaff,
                title: newTitle
            };
            onStaffChange(newStaff, populateStaff.title);
        }
    }

    onUserNameChange = (newUserName) => {
        this.setState({
            newUserName
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.userName !== newUserName) {
            let newStaff = {
                ...populateStaff,
                userName: newUserName
            };
            onStaffChange(newStaff, populateStaff.userName);
        }
    }

    onNPIChange = (newNPI) => {
        this.setState({
            newNPI
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.npi !== newNPI) {
            let newStaff = {
                ...populateStaff,
                npi: newNPI
            };
            onStaffChange(newStaff, populateStaff.npi);
        }
    }

    onAlternateNameChange = (newAlternateName) => {
        this.setState({
            newAlternateName
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.alternateName !== newAlternateName) {
            let newStaff = {
                ...populateStaff,
                alternateName: newAlternateName
            };
            onStaffChange(newStaff, populateStaff.alternateName);
        }
    }

    onSpecialtyChange = (newSpecialty) => {
        this.setState({
            newSpecialty
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.specialty !== newSpecialty) {
            let newStaff = {
                ...populateStaff,
                specialty: newSpecialty
            };
            onStaffChange(newStaff, populateStaff.specialty);
        }
    }

    onProviderIDChange = (newProviderID) => {
        this.setState({
            newProviderID
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.providerID !== newProviderID) {
            let newStaff = {
                ...populateStaff,
                providerID: newProviderID
            };
            onStaffChange(newStaff, populateStaff.providerID);
        }
    }

    onDepartmentChange = (newDepartment) => {
        this.setState({
            newDepartment
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.department !== newDepartment) {
            let newStaff = {
                ...populateStaff,
                department: newDepartment
            };
            onStaffChange(newStaff, populateStaff.department);
        }
    }

    onTinChange = (newTin) => {
        this.setState({
            newTin
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.tin !== newTin) {
            let newStaff = {
                ...populateStaff,
                tin: newTin
            };
            onStaffChange(newStaff, populateStaff.tin);
        }
    }

    onUPINChange = (newUPIN) => {
        this.setState({
            newUPIN
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.upin !== newUPIN) {
            let newStaff = {
                ...populateStaff,
                upin: newUPIN
            };
            onStaffChange(newStaff, populateStaff.upin);
        }
    }

    onSSNChange = (newSSN) => {
        this.setState({
            newSSN
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.ssn !== newSSN) {
            let newStaff = {
                ...populateStaff,
                ssn: newSSN
            };
            onStaffChange(newStaff, populateStaff.ssn);
        }
    }

    onPractitionerGroupChange = (newPractitionerGroup) => {
        this.setState({
            newPractitionerGroup
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.practitionerGroup !== newPractitionerGroup) {
            let newStaff = {
                ...populateStaff,
                practitionerGroup: newPractitionerGroup
            };
            onStaffChange(newStaff, populateStaff.practitionerGroup);
        }
    }

    onAddress1Change = (newAddress1) => {
        this.setState({
            newAddress1
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.address1 !== newAddress1) {
            let newStaff = {
                ...populateStaff,
                address1: newAddress1
            };
            onStaffChange(newStaff, populateStaff.address1);
        }
    }

    onAddress2Change = (newAddress2) => {
        this.setState({
            newAddress2
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.address2 !== newAddress2) {
            let newStaff = {
                ...populateStaff,
                address2: newAddress2
            };
            onStaffChange(newStaff, populateStaff.address2);
        }
    }

    onAddress3Change = (newAddress3) => {
        this.setState({
            newAddress3
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.address3 !== newAddress3) {
            let newStaff = {
                ...populateStaff,
                address3: newAddress3
            };
            onStaffChange(newStaff, populateStaff.address3);
        }
    }

    onAddress4Change = (newAddress4) => {
        this.setState({
            newAddress4
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.address4 !== newAddress4) {
            let newStaff = {
                ...populateStaff,
                address4: newAddress4
            };
            onStaffChange(newStaff, populateStaff.address4);
        }
    }

    onCityChange = (newCity) => {
        this.setState({
            newCity
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.city !== newCity) {
            let newStaff = {
                ...populateStaff,
                city: newCity
            };
            onStaffChange(newStaff, populateStaff.city);
        }
    }

    onStateChange = (newState) => {
        this.setState({
            newState
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.state !== newState) {
            let newStaff = {
                ...populateStaff,
                state: newState
            };
            onStaffChange(newStaff, populateStaff.state);
        }
    }

    onZipCodeChange = (newZipCode) => {
        this.setState({
            newZipCode
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.zipCode !== newZipCode) {
            let newStaff = {
                ...populateStaff,
                zipCode: newZipCode
            };
            onStaffChange(newStaff, populateStaff.zipCode);
        }
    }

    onCountyChange = (newCounty) => {
        this.setState({
            newCounty
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.county !== newCounty) {
            let newStaff = {
                ...populateStaff,
                county: newCounty
            };
            onStaffChange(newStaff, populateStaff.county);
        }
    }

    onCountryChange = (newCountry) => {
        this.setState({
            newCountry
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.country !== newCountry) {
            let newStaff = {
                ...populateStaff,
                country: newCountry
            };
            onStaffChange(newStaff, populateStaff.country);
        }
    }

    onPhone1Change = (newPhone1) => {
        this.setState({
            newPhone1
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.phone1 !== newPhone1) {
            let newStaff = {
                ...populateStaff,
                phone1: newPhone1
            };
            onStaffChange(newStaff, populateStaff.phone1);
        }
    }

    onPhone2Change = (newPhone2) => {
        this.setState({
            newPhone2
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.phone2 !== newPhone2) {
            let newStaff = {
                ...populateStaff,
                phone2: newPhone2
            };
            onStaffChange(newStaff, populateStaff.phone2);
        }
    }

    onFaxChange = (newFax) => {
        this.setState({
            newFax
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.fax !== newFax) {
            let newStaff = {
                ...populateStaff,
                fax: newFax
            };
            onStaffChange(newStaff, populateStaff.fax);
        }
    }

    onPagerChange = (newPager) => {
        this.setState({
            newPager
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.pager !== newPager) {
            let newStaff = {
                ...populateStaff,
                pager: newPager
            };
            onStaffChange(newStaff, populateStaff.pager);
        }
    }

    onHomePhoneChange = (newHomePhone) => {
        this.setState({
            newHomePhone
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.homePhone !== newHomePhone) {
            let newStaff = {
                ...populateStaff,
                homePhone: newHomePhone
            };
            onStaffChange(newStaff, populateStaff.homePhone);
        }
    }

    onCellPhoneChange = (newCellPhone) => {
        this.setState({
            newCellPhone
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.cellPhone !== newCellPhone) {
            let newStaff = {
                ...populateStaff,
                cellPhone: newCellPhone
            };
            onStaffChange(newStaff, populateStaff.cellPhone);
        }
    }

    onEmailAddressChange = (newEmailAddress) => {
        this.setState({
            newEmailAddress
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.emailAddress !== newEmailAddress) {
            let newStaff = {
                ...populateStaff,
                emailAddress: newEmailAddress
            };
            onStaffChange(newStaff, populateStaff.emailAddress);
        }
    }

    onCommentsChange = (newComments) => {
        this.setState({
            newComments
        });

        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.comments !== newComments) {
            let newStaff = {
                ...populateStaff,
                comments: newComments
            };
            onStaffChange(newStaff, populateStaff.comments);
        }
    }

    onStaffRolesChange = (newRoles) => {
        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.roles !== newRoles) {
            let newStaff = {
                ...populateStaff,
                roles: newRoles
            };
            onStaffChange(newStaff, populateStaff.roles);
        }
    }

    onStaffNumbersChange = (newNumbers) => {
        const { populateStaff, onStaffChange } = this.props;
        if (populateStaff?.numbers !== newNumbers) {
            let newStaff = {
                ...populateStaff,
                numbers: newNumbers
            };
            onStaffChange(newStaff, populateStaff.numbers);
        }
    }

    render() {
        const { classes, populateStaff, personnelRoles, personnelNumbers } = this.props;
        const { openRoleDialog, openNumberDialog } = this.state;
        return (
            <>
                <Card className={classes.card}>
                    <CardBody>
                        {this.renderStaffEditor()}
                    </CardBody>
                </Card>
                <Dialog open={openRoleDialog}>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeStaffRoles}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Staff Roles'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <StaffRoles
                            staffId={populateStaff.staffId}
                            personnelRoles={personnelRoles}
                            staffRoles={populateStaff.roles}
                            onStaffRolesChange={this.onStaffRolesChange}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog open={openNumberDialog}>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeStaffNumbers}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Staff Numbers'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <StaffNumbers
                            staffId={populateStaff.staffId}
                            personnelNumbers={personnelNumbers}    
                            staffNumbers ={populateStaff.numbers}
                            onStaffNumbersChange ={this.onStaffNumbersChange}                                                
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
export default withStyles(styles)(StaffEditor);