import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const styles = (theme) => ({
    leftText: {
        marginRight: 'auto'
    },
    rightText: {
        marginLeft: '16px'
    },
    selected: {
        backgroundColor: theme.palette.background.selected
    },
    listItem: {
        padding: '2px 0px'
    },
    '@keyframes placeHolderShimmer': {
        '0%': {
        backgroundPosition: '-468px 0'
        },
        '100%': {
        backgroundPosition: '468px 0'
        }
    },
    animatedBackground: {
        animationDuration: '4s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: '$placeHolderShimmer',
        animationTimingFunction: 'linear',
        background: 'linear-gradient(to right, #FFFFFF 8%, #F0F0F0 18%, #FFFFFF 33%)',
        position: 'relative'
    }
});

const DECIMAL_PRECISION = 2;

const ResultItem = (props) => {
    const { risk, index, onRiskChange, riskIndex, classes, isLoading } = props;

    let roundedRiskValue = Number((Math.round(risk.value * 100 + 'e' + DECIMAL_PRECISION)) + 'e' + -DECIMAL_PRECISION);
    return (
        <div>
            <Hidden xsUp={!isLoading}>
                <ListItem dense disableGutters className={`${classes.animatedBackground} ${classes.listItem} ${index === riskIndex ? classes.selected : ''}`} button divider value={index} onClick={() => (onRiskChange(index))}>
                    <Typography align='left' color='inherit' variant='body2' className={classes.leftText} >
                        {risk.displayName}
                    </Typography>
                </ListItem>
            </Hidden>
            <Hidden xsUp={isLoading}>
                <ListItem dense disableGutters className={`${classes.listItem} ${index === riskIndex ? classes.selected : ''}`} button divider value={index} onClick={() => (onRiskChange(index))}>
                    <Typography align='left' color='inherit' variant='body2' className={classes.leftText} >
                        {risk.displayName}
                    </Typography>
                    <Typography align='right' color='inherit' variant='body2' className={classes.rightText} >
                        {/* TODO
                        Find out whether the value should be truncated and if it should be rounded
                        Currently it rounds upward if large enough and it truncates to 2 decimal places
                    */}
                        {`${roundedRiskValue}%`}
                    </Typography>
                </ListItem>
            </Hidden>
        </div>
    );
};

export default withStyles(styles)(ResultItem);