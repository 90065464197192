import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    multiColumnComboBox: {
        display: 'flex',
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`
        }
    },
    addMultiColumnComboButton: {
        whiteSpace: 'nowrap',
        minWidth: '250px'
    },
    inputField: {
        marginRight: '8px'
    }
});

class MultiColumnComboSelector extends React.Component {
    state = {
        tableName: null,
        fieldName: null
    }

    updateTable = (newValue) => {
        this.setState({
            tableName: newValue
        });
    }

    updateField = (newValue) => {
        this.setState({
            fieldName: newValue
        });
    }

    addFormSelection = () => {
        const { onAddItem } = this.props;
        const { tableName, fieldName } = this.state;

        if ((tableName ?? '') !== ''
            || (fieldName ?? '') !== ''
        ) {
            let newItem = {
                tableName: tableName,
                fieldName: fieldName,
                metaDataKey: `${tableName}.${fieldName}`,
                $lumedxType: "MultiColumnCombo"
            };
            onAddItem(newItem);
            this.setState({
                tableName: null,
                fieldName: null
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { tableName, fieldName } = this.state;
        return (
            <div className={classes.multiColumnComboBox}>
                <LxTextField className={classes.inputField} value={tableName} title={"Table Name"} placeholder='Enter Table Name...' onChange={this.updateTable}/>
                <LxTextField className={classes.inputField} value={fieldName} title={"Field Name"} placeholder='Enter Field Name...' onChange={this.updateField}/>
                <Button className={classes.addMultiColumnComboButton} color='primary' variant='outlined' onClick={this.addFormSelection}>
                    {'ADD MultiColumnCombo'}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(MultiColumnComboSelector);