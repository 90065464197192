import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import AccessTime from '@material-ui/icons/AccessTime';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar, Line, Scatter } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';
import dashboardStyle from '.../assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { trendsApi } from '.../utils/apiHelper';

const styles = theme => ({
    ...dashboardStyle,
    circularProgress: {
        margin: 'auto'
    },
    card: {
        margin: '0px'
    }
});

const legend = {
    position: 'top',
    labels: {
        boxWidth: 30
    }
};

class TrendsCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            redraw: false
        };
    }

    componentDidMount() {
        if (typeof this.props.chart === 'undefined') {
            this.getChartData(this.props.ssPatientId, this.props.chartId);
        } else {
            let newChart = this.mapToChart(this.props.chart);
            this.setState({
                chart: newChart,
                renderedChart: this.getChart(newChart),
                redraw: true,
                isLoading: false
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (typeof this.props.chart !== 'undefined' && JSON.stringify(prevProps.chart) !== JSON.stringify(this.props.chart)) {
            let newChart = this.mapToChart(this.props.chart);
            this.setState({
                chart: newChart,
                renderedChart: this.getChart(newChart),
                redraw: true,
                isLoading: false
            });
        } else if (typeof this.props.ssPatientId !== 'undefined' && typeof this.props.chartId !== 'undefined') {
            if (prevProps.ssPatientId !== this.props.ssPatientId || prevProps.chartId !== this.props.chartId) {
                this.setState({ redraw: true, isLoading: true });
                this.getChartData(this.props.ssPatientId, this.props.chartId);
            } else if (this.props.reRender === this.props.chartId) {
                this.setState({ redraw: true, isLoading: true });
                this.getChartData(this.props.ssPatientId, this.props.chartId);
                if (typeof this.props.turnOffChartReRender !== 'undefined') {
                    this.props.turnOffChartReRender();
                }
            }
        }
    }

    shouldComponentUpdate(newProps, newState) {
        if (this.state.isLoading !== newState.isLoading || (typeof newProps.reRender !== 'undefined' && newProps.reRender === newProps.chartId) ||
            newProps.ssPatientId !== this.props.ssPatientId || newProps.chartId !== this.props.chartId ||
            (typeof newProps.chart !== 'undefined' && (newProps.chart.title !== this.props.chart.title || newProps.chart.description !== this.props.chart.description))) {
            if (typeof newProps.turnOffChartReRender !== 'undefined') {
                newProps.turnOffChartReRender();
            }
            return true;
        }
        return false;
    }

    getChartData = (ssPatientId, chartId) => {
        trendsApi.getTrendsChartData(ssPatientId, chartId)
            .then(chart => {
                let newChart = this.mapToChart(chart);
                this.setState({
                    chart: newChart,
                    renderedChart: this.getChart(newChart),
                    isLoading: false,
                    errorMessage: undefined
                });
            }).catch((error) => {
                this.setState({
                    errorMessage: typeof error.response !== 'undefined' ? error.response.data : error.message,
                    isLoading: false
                });
            });
    }

    mapToChart = (chart) => ({
        ...chart,
        data: {
            datasets: chart.data.datasets.map((dataset, index) => {
                let color = this.getRandomColor(index);
                let currentPatient = dataset.label !== null && dataset.label.toLowerCase().trim() === 'current patient';
                let otherPatient = chart.type.toLowerCase().trim() === 'scatter' && !currentPatient;
                return {
                    ...dataset,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: color,
                    borderColor: color,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: color,
                    pointBorderWidth: currentPatient ? 2 : 1,
                    pointHoverRadius: currentPatient ? 15 : 10,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    pointHoverBorderWidth: currentPatient ? 4 : 2,
                    pointRadius: currentPatient ? 10 : 5,
                    pointHitRadius: otherPatient ? -5 : 10
                };
            })
        },
        options: {
            ...chart.options,
            tooltips: {
                callbacks: {
                    title: () => '',
                    label: this.mapOptionsToLabel(chart.options, (point, data, options) => {
                        let yMeasurement = data.datasets[point.datasetIndex].label;
                        let yValue = point.yLabel;
                        let axisOptions = options.scales.yAxes.filter(axis => axis.id === data.datasets[point.datasetIndex].yAxisID);
                        let yUnits = axisOptions.length === 1 ? axisOptions[0].scaleLabel.labelString : 'unit(s)';
                        let xUnits = options.scales.xAxes.length === 1 ? options.scales.xAxes[0].scaleLabel.labelString : 'unit(s)';
                        let xValue = point.xLabel;
                        let xDateCheck = DateTime.fromISO(xValue);
                        if (xDateCheck.isValid) {
                            xValue = xDateCheck.toLocaleString();
                            xUnits = '';
                        }
                        return `${yMeasurement}: (${xValue} ${xUnits}, ${yValue} ${yUnits})`;
                    })
                }
            }
        }
    })

    mapOptionsToLabel = (options, callback) => ((point, data) => callback(point, data, options))

    getRandomColor = (index) => {
        const colorOptions = ['#F26419', '#337ab7', '#004825', '#5E5C6C'];
        return colorOptions[index % colorOptions.length];
    }

    getChart = (chart) => {
        const { redraw } = this.state;
        this.setState({ redraw: false });
        switch (chart.type) {
            case "line":
                return <Line legend={legend} data={chart.data} options={chart.options} redraw={redraw} />;
            case "scatter":
                return <Scatter legend={legend} data={chart.data} options={chart.options} redraw={redraw} />;
            case "bar":
                return <Bar legend={legend} data={chart.data} options={chart.options} redraw={redraw} />;
            default:
                break;
        }
    }

    render() {
        const { classes } = this.props;
        const { isLoading, chart, renderedChart, errorMessage } = this.state;

        return (
            <Card chart className={classes.card}>
                {
                    isLoading ?
                        <CircularProgress size={60} thickness={7} className={classes.circularProgress} />
                        :
                        typeof errorMessage !== 'undefined' ?
                            <div>
                                <CardBody>
                                    {errorMessage}
                                </CardBody>
                            </div>
                            :
                            <div>
                                <CardHeader className={classes.cardHeaderHover}>
                                    <h4 className={classes.cardTitle}>{chart.title}</h4>
                                    <p className={classes.cardCategory}>
                                        {chart.description}
                                    </p>
                                </CardHeader>
                                <CardBody>
                                    {renderedChart}
                                </CardBody>
                                <CardFooter chart>
                                    <div className={classes.stats}>
                                        {typeof chart.lastUpdated === 'undefined' ? null :
                                            <div>
                                                <AccessTime /> last updated {new Date(chart.lastUpdated).toLocaleDateString('en-US')}
                                            </div>
                                        }
                                    </div>
                                </CardFooter>
                            </div>
                }
            </Card>
        );
    }
}

const checkRequiredProps = (props, propName, componentName) => {
    if (!props.chart && !props.chartId && !props.ssPatientId) {
        return new Error(`${componentName} needs a chart or a chartId with a ssPatientId`);
    } else if (!props.chart && !props.chartId && props.ssPatientId) {
        return new Error(`${componentName} needs a chartId along with a ssPatientId`);
    } else if (!props.chart && !props.ssPatientId && props.chartId) {
        return new Error(`${componentName} needs a ssPatientId along with a chartId`);
    }
};

TrendsCharts.propTypes = {
    charts: checkRequiredProps,
    chartId: checkRequiredProps,
    ssPatientId: checkRequiredProps,
    reRender: PropTypes.number
};

export default withStyles(styles)(withRouter(TrendsCharts));