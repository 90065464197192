import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import {
    cardTitle,
    successColor,
    tooltip
} from ".../assets/jss/material-dashboard-pro-react";
import hoverCardStyle from ".../assets/jss/material-dashboard-pro-react/hoverCardStyle";
import FeatureInformation from './FeatureInformation';

const styles = theme => ({
    ...hoverCardStyle,
    tooltip,
    cardHover: {
        ...hoverCardStyle.cardHover,
        height: '90%'
    },
    cardItem: {
        zIndex: 1050,
        height: '400px'
    },
    cardImage: {
        height: '200px'
    },
    cardIcon: {
        height: '200px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '6px',
        '& svg': {
            width: '25%',
            height: 'inherit'
        }
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
        textAlign: "center"
    },
    cardCategory: {
        color: "#999999",
        fontSize: "14px",
        paddingTop: "10px",
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardProductDesciprion: {
        textAlign: "center",
        color: "#999999"
    },
    stats: {
        color: "#999999",
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& i": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
    productStats: {
        paddingTop: "7px",
        paddingBottom: "7px",
        margin: "0"
    },
    successText: {
        color: successColor
    },
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    underChartIcons: {
        width: "17px",
        height: "17px"
    },
    price: {
        color: "inherit",
        "& h4": {
            marginBottom: "0px",
            marginTop: "0px"
        }
    },
    container: {
        marginTop: '42px'
    }
});

class Dashboard extends React.Component {
    handleClick = (route) => {
        const { history, location, patientInfo } = this.props;
        history.push(
            location.pathname.replace('/dashboard', '')
            + '/'
            + route.replace(':mrn', patientInfo !== null ? patientInfo.patientId : '')
                .replace(/\s/g, '')
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <GridContainer className={classes.container} spacing={4}>
                {this.props.modules.map((mod, index) => (
                    <GridItem key={index} xs={12} sm={6} md={4} lg={3} className={classes.cardItem}>
                        <a onClick={() => this.handleClick(FeatureInformation[mod].path)}>
                            <Card product className={classes.cardHover}>
                                <CardHeader image className={classes.cardHeaderHover}>
                                    {typeof FeatureInformation[mod].image !== 'undefined' ?
                                        <img src={FeatureInformation[mod].image} alt="..." className={classes.cardImage} />
                                        :
                                        <div className={classes.cardIcon}>
                                            {FeatureInformation[mod].icon}
                                        </div>
                                    }
                                </CardHeader>
                                <CardBody>
                                    <h4 className={classes.cardProductTitle}>
                                        {FeatureInformation[mod].displayName.toUpperCase()}
                                    </h4>
                                    <p className={classes.cardProductDesciprion}>
                                        {FeatureInformation[mod].description}
                                    </p>
                                </CardBody>
                            </Card>
                        </a>
                    </GridItem>
                ))}
            </GridContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    patientInfo: state.demographics
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(Dashboard)));