import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import Coefficients from './Coefficients';
import Disclaimer from './Disclaimer';
import Procedure from './Procedure';
import Result from './Result';

const styles = theme => ({
    sidebar: {
        display: 'flex',
        flexDirection: 'column'
    },
    card: {
        margin: '0px'
    },
    loadingIndicator: {
        margin: '32px auto 32px auto'
    },
    procedure: {
        flex: '0 1 auto'
    },
    risk: {
        flex: '0 1 auto',
        margin: '0px',
        paddingBottom: '8px'
    },
    coefficients: {
        flex: '0 1 auto'
    },
    patientInfoCard: {
        marginBottom: '16px'
    },
    cardHeader: {
        paddingBottom: '0px'
    },
    cardBody: {
        paddingTop: '0px'
    },
    cardDivider: {
        margin: `${theme.spacing(1)}px 0px`
    },
    dividerLineTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '-10px'
    },
    dividerTitle: {
        flex: '0 0 auto',
        marginRight: `${theme.spacing(2)}px`
    },
    dividerLine: {
        flex: '1 0 auto'
    }
});

class ResultContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            procedureIndex: 0,
            riskIndex: 0
        };
    }

    onProcedureChange = (event) => {
        this.setState({
            procedureIndex: event.target.value
        });
    };

    onRiskChange = (riskIndex) => {
        this.setState({
            riskIndex: riskIndex
        });
    }

    render() {
        const { procedureIndex, riskIndex } = this.state;
        const { resultCalculating, riskResult, classes } = this.props;

        return (
            <div className={classes.sidebar}>
                <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}>
                        <div className={classes.dividerLineTitle} >
                            <Typography align='left' variant='body1' className={classes.dividerTitle} >
                                {'RESULTS'}
                            </Typography>
                            <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
                        </div>
                        <Disclaimer />
                        <Divider />
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                        <div className={classes.procedure}>
                            <Procedure
                                procedureIndex={procedureIndex}
                                procedures={riskResult === null ? [] : riskResult}
                                onProcedureChange={this.onProcedureChange}
                            />
                        </div>
                        <div className={classes.risk}>
                            <Result
                                isLoading={resultCalculating}
                                result={riskResult?.length > 0 ? riskResult[procedureIndex].risks : []}

                                riskIndex={riskIndex}
                                onRiskChange={this.onRiskChange}
                            />
                        </div>
                        <div className={classes.coefficients}>
                            <Coefficients
                                isLoading={resultCalculating}
                                coefficients={riskResult?.length > 0 ? riskResult[procedureIndex].risks[riskIndex].coefficients : []}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

ResultContainer.propTypes = {
    riskResult: PropTypes.arrayOf(PropTypes.shape({
        propName: PropTypes.string,
        displayName: PropTypes.string,
        risks: PropTypes.arrayOf(PropTypes.shape({
            propName: PropTypes.string,
            displayName: PropTypes.string,
            value: PropTypes.number,
            coefficients: PropTypes.arrayOf(PropTypes.shape({
                propName: PropTypes.string,
                displayName: PropTypes.string,
                value: PropTypes.number
            }))
        }))
    }))
};

export default withStyles(styles)(ResultContainer);