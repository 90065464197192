import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from './PropertyEditor';

const styles = theme => ({

});

class CommandButtonPropertyEditor extends React.Component {
    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;        

        let properties = [
            {
                name: "displayName",
                label: "Display Name",
                type: "text"
            },
            {
                name: "fileName",
                label: "File Name",
                type: "text"
            },
            {
                name: "className",
                label: "Class Name",
                type: "text"
            },
            {
                name: "methodName",
                label: "Method Name",
                type: "text"
            },
            {
                name: "description",
                label: "Description",
                type: "text"
            },
            {
                name: "tabIndex",
                label: "Tab sequence",
                type: "int"
            },
            {
                name: "readOnly",
                label: "Read Only",
                type: "checkBox"
            },
            {
                name: "lookupName",
                label: "Lookup Name",
                type: "text"
            },
            {
                name: "targetTable",
                label: "Target Table",
                type: "text"
            },
            {
                name: "targetField",
                label: "Target Field",
                type: "text"
            },
            {
                name: "popUpFormName",
                label: "Popup Form Name",
                type: "text"
            }
        ];

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                title="Command Button Editor"
                properties={properties}
            />
        );
    }
}

export default withStyles(styles)(CommandButtonPropertyEditor);