import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import modalStyle from '.../assets/jss/material-dashboard-pro-react/modalStyle';
import { pushToError, trendsApi } from '.../utils/apiHelper';

const styles = theme => ({
    ...modalStyle(theme),
    list: {
        overflowY: 'auto'
    },
    header: {
        margin: `${theme.spacing(2)}px 0px`
    }
});

class SelectChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: true,
            confirmDeleteOpen: false
        };
    }

    isValidated = (chart) => {
        const checkedChart = typeof chart !== 'undefined' ? chart : this.props.selectedChart;
        let validated = typeof checkedChart !== 'undefined';
        this.setState({ validated });
        return validated;
    }

    handleChartSelection = (index) => () => {
        this.setState({
            validated: true
        });

        this.props.setSelectedChart(this.props.charts[index], index);
    }

    handleNewChartSelection = () => {
        this.setState({
            validated: true
        });

        let newChart = {
            ssPatientId: this.props.ssPatientId,
            chartType: '',
            title: '',
            description: '',
            units: '',
            xAxisTableName: '',
            xAxisFieldName: '',
            ssCopilotChartDataSets: [
                {
                    yAxisTableName: '',
                    yAxisFieldName: '',
                    axisLeftRight: '',
                    units: ''
                }
            ]
        };

        this.props.setSelectedChart(newChart);

        this.props.nextButtonClick(newChart);
    }

    handleCloneChart = (key) => {
        const { charts, updateChartsList } = this.props;
        let chartClone = {
            ...charts[key],
            title: `${charts[key].title} - clone`,
            ssCopilotChartId: 0,
            ssCopilotChartDataSets: charts[key].ssCopilotChartDataSets.map((chart) => (
                {
                    ...chart,
                    ssCopilotChartDataSetsId: 0
                }
            ))
        };
        trendsApi.createTrendsChart(chartClone)
            .then(() => updateChartsList())
            .catch(error => pushToError(error, this.props.match.params.mrn));
    }

    toggleConfirmDelete = (key) => {
        this.setState({
            confirmDeleteOpen: !this.state.confirmDeleteOpen,
            readyForDeletion: key
        });
    }

    deleteChart = () => {
        const { charts, updateChartsList } = this.props;
        const { readyForDeletion } = this.state;
        let chartForDeletion = charts[readyForDeletion];
        trendsApi.deleteTrendsChart(chartForDeletion)
            .then(() => {
                updateChartsList(chartForDeletion.ssCopilotChartId);
                this.setState({
                    readyForDeletion: undefined,
                    confirmDeleteOpen: false
                });
            })
            .catch(error => pushToError(error, this.props.match.params.mrn));
    }

    render() {
        const { classes, charts, selectedChartId } = this.props;
        const { validated, confirmDeleteOpen } = this.state;
        return (
            <div>
                <GridContainer className={classes.header} justify='space-between' alignItems='baseline'>
                    <GridItem>
                        <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                            {'Select a chart below or create a new one'}
                        </Typography>
                    </GridItem>
                    <GridItem>
                        <Button variant='contained' color='primary' onClick={this.handleNewChartSelection}>
                            {'New Chart'}
                        </Button>
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={4} justify='center'>
                    <GridItem sm={12}>
                        <List className={classes.list}>
                            {charts.map((chart, index) =>
                                <ListItem key={index} dense button onClick={this.handleChartSelection(index)}>
                                    <Radio color='primary' checked={index === selectedChartId} />
                                    <ListItemText primary={chart.title} secondary={chart.description} />
                                    <ListItemSecondaryAction>
                                        <GridContainer>
                                            <Tooltip title="Copy">
                                                <IconButton onClick={() => this.handleCloneChart(index)}>
                                                    <FileCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => this.toggleConfirmDelete(index)}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </GridContainer>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </GridItem>
                </GridContainer>
                <Dialog classes={{ paper: classes.dialog }} open={confirmDeleteOpen} onClose={this.toggleConfirmDelete}>
                    <DialogTitle disableTypography className={classes.modalHeader}>
                        <Button className={classes.modalCloseButton} onClick={this.toggleConfirmDelete}>
                            <Close className={classes.modalClose} />
                        </Button>
                        <h4 className={classes.modalTitle}>{'Delete Chart'}</h4>
                    </DialogTitle>
                    <DialogContent className={`${classes.modalBody}`}>
                        <DialogContentText>
                            {'Are you sure you want to delete that chart?'}
                        </DialogContentText>
                        <DialogContentText>
                            {'You will not be able to get it back'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <GridContainer spacing={4} justify='space-between' alignItems='baseline'>
                            <GridItem>
                                <Button color='primary' onClick={this.toggleConfirmDelete}>
                                    {'Cancel'}
                                </Button>
                            </GridItem>
                            <GridItem>
                                <Button color='primary' onClick={this.deleteChart}>
                                    {'Delete'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

SelectChart.defaultProps = {
    charts: []
};

SelectChart.propTypes = {
    classes: PropTypes.object.isRequired,
    charts: PropTypes.arrayOf(PropTypes.object).isRequired,
    nextButtonClick: PropTypes.func.isRequired,
    setSelectedChart: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(SelectChart));