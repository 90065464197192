import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import TrendsCharts from '../../TrendsCharts';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';

const styles = theme => ({
    chart: {
        margin: 'auto',
        width: '45vmin'
    },
    header: {
        margin: `${theme.spacing(2)}px 0px`
    }
});

class FinalizeSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: true
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.selectedChart) !== JSON.stringify(this.props.selectedChart)) {
            this.isValidated(this.props.selectedChart);
        }
    }

    isValidated = (chart) => {
        const checkedChart = typeof chart !== 'undefined' ? chart : this.props.selectedChart;
        let validated = typeof checkedChart !== 'undefined' && checkedChart.title !== '' && checkedChart.description !== '';
        this.setState({ validated });
        return validated;
    }

    handleTitleChange = (event) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            title: event.target.value
        }, this.props.selectedChartId);
    }

    handleDescriptionChange = (event) => {
        this.props.setSelectedChart({
            ...this.props.selectedChart,
            description: event.target.value
        }, this.props.selectedChartId);
    }

    handlePatientToggle = () => {
        const { selectedChart } = this.props;
        if (selectedChart.ssPatientId === null) {
            this.handleSetPatient();
        } else {
            this.handleRemovePatient();
        }
    }

    handleSetPatient = () => {
        const { selectedChart, setSelectedChart, ssPatientId, selectedChartId } = this.props;
        setSelectedChart({
            ...selectedChart,
            ssPatientId: ssPatientId
        }, selectedChartId);
    }

    handleRemovePatient = () => {
        const { selectedChart, setSelectedChart, selectedChartId } = this.props;
        setSelectedChart({
            ...selectedChart,
            ssPatientId: null
        }, selectedChartId);
    }

    mapChart = (chart) => {
        return ({
            title: chart.title,
            description: chart.description,
            type: chart.chartType,
            data: {
                datasets: chart.ssCopilotChartDataSets.map(dataset => ({
                    label: 'value',
                    yAxisID: `${dataset.axisLeftRight.toLowerCase()}${dataset.units.toLowerCase()}`,
                    data: [
                        { x: 0, y: 3 },
                        { x: 1, y: 2 },
                        { x: 2, y: 5 },
                        { x: 3, y: 4 },
                        { x: 4, y: 1 },
                        { x: 5, y: 0 }
                    ]
                }))
            },
            options: {
                scales: {
                    yAxes: chart.ssCopilotChartDataSets.map(dataset => ({
                        id: `${dataset.axisLeftRight.toLowerCase()}${dataset.units.toLowerCase()}`,
                        position: dataset.axisLeftRight,
                        type: 'linear',
                        time: {
                            unit: 'year'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: dataset.units
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    })),
                    xAxes: [{
                        id: 'xAxis',
                        position: 'bottom',
                        type: 'linear',
                        time: {
                            units: 'year'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: chart.units
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    render() {
        const { selectedChart, classes } = this.props;
        const { validated } = this.state;

        const validChart = typeof selectedChart !== 'undefined';

        return (!validChart ? null :
            <div>
                <GridContainer className={classes.header} justify='space-between' alignItems='baseline'>
                    <GridItem>
                        <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                            {'Finalize the chart below'}
                        </Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={4} justify='space-between'>
                    <GridItem sm={12}>
                        <TextField
                            helperText='Chart Title'
                            placeholder='Chart Title'
                            fullWidth
                            error={!validated && selectedChart.title === ''}
                            value={selectedChart.title}
                            onChange={this.handleTitleChange}
                        />
                    </GridItem>
                    <GridItem sm={12}>
                        <TextField
                            helperText='Chart Description'
                            placeholder='Chart Description'
                            fullWidth
                            error={!validated && selectedChart.description === ''}
                            value={selectedChart.description}
                            onChange={this.handleDescriptionChange}
                        />
                    </GridItem>
                    <GridItem sm={6} container alignItems='flex-start'>
                        <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                            {'Save chart for:'}
                        </Typography>
                        <GridContainer>
                            <GridItem xs={6} container alignItems='center' onClick={this.handleSetPatient}>
                                <Radio color='primary' checked={selectedChart.ssPatientId !== null} />
                                <Typography align='left'>{'THIS patient'}</Typography>
                            </GridItem>
                            <GridItem xs={6} container alignItems='center' onClick={this.handleRemovePatient}>
                                <Radio color='primary' checked={selectedChart.ssPatientId === null} />
                                <Typography align='left'>{'ALL patients'}</Typography>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem sm={12}>
                        <div className={classes.chart}>
                            <TrendsCharts chart={this.mapChart(selectedChart)} reRender={0} chartId={0} />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

FinalizeSelection.propTypes = {
    selectedChart: PropTypes.object,
    selectedChartId: PropTypes.number,
    setSelectedChart: PropTypes.func.isRequired
};

export default withStyles(styles)(FinalizeSelection);