const RiskFormConfigurations = {
    TimiStemiConfiguration: {
        riskFactors: [
            {
                name: 'Age',
                criteria: [
                    { name: '< 65 years', value: 0 },
                    { name: '65-74 years', value: 2 },
                    { name: '>= 75 years', value: 3 }
                ]
            },
            {
                name: 'DM or Hypertension or Angina',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Systolic BP < 100 mmHg',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 3 }
                ]
            },
            {
                name: 'Heart Rate > 100',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 2 }
                ]
            },
            {
                name: 'Killip Class II-IV',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 2 }
                ]
            },
            {
                name: 'Weight < 67kg (147.7 lbs)',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Anterio ST Elevation or LBBB',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Time to Treatment > 4 hours',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            }
        ],
        riskOfMortality: [0.8, 1.6, 2.2, 4.4, 7.3, 12.4, 16.1, 23.4, 26.8, 35.9, 35.9, 35.9, 35.9, 35.9, 35.9]
    },
    TimiUaNstemiConfiguration: {
        riskFactors: [
            {
                name: 'Age >= 65 years',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: '>= 3 CAD Risk Factors (Hypertension, hypercholesterolemia, diabetes, family history of CAD, or current smoker)',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Known CAD (Stenosis >= 50%)',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'ASA use in past 7 days',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Severe Angina (>= 2 episodes in 24 hrs)',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'EKG ST changes >= 0.5 mm',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Positive Cardiac Marker',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            }
        ],
        riskOfMortality: [0, 5.0, 8.0, 13.0, 20.0, 26.0, 41.0, 41.0]
    },
    ChadsvascConfiguration: {
        riskFactors: [
            {
                name: 'Age',
                criteria: [
                    { name: '< 65 year', value: 0 },
                    { name: '65-74 year', value: 1 },
                    { name: '>= 75 year', value: 2 }
                ]
            },
            {
                name: 'Sex',
                criteria: [
                    { name: 'Male', value: 0 },
                    { name: 'Female', value: 1 }
                ]
            },
            {
                name: 'CHF history',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Hypertension history',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Stroke / TIA / Thromboembolism history',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 2 }
                ]
            },
            {
                name: 'Vascular disease history',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            },
            {
                name: 'Diabetes history',
                criteria: [
                    { name: 'No', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            }
        ],
        riskOfMortality: [0.2, 0.6, 2.2, 3.2, 4.8, 7.2, 9.7, 11.2, 10.8, 12.2]
    }
};

export default RiskFormConfigurations;