import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { pushToError, sabermedicsApi } from '.../utils/apiHelper';
import PhysicianSummaryForm from './PhysicianSummaryForm';

class PhysicianSummaryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        sabermedicsApi.getPhysicianSummary()
            .then(summary =>
                this.setState({
                    summary,
                    isLoading: false
                })
            ).catch((error) => pushToError(error, this.props.match.params.mrn));
    }

    render() {
        const { isLoading, summary } = this.state;

        return (
            isLoading ?
                <CircularProgress size={60} thickness={7} />
                :
                <PhysicianSummaryForm summary={summary} />
        );
    }
}

export default withRouter(PhysicianSummaryContainer);