import Link from '@material-ui/core/Link';
import Paper from "@material-ui/core/Paper";
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';
import React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import { v4 as uuidv4 } from 'uuid';

import LxTextField from '../../../formComponents/LxTextField';
import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';
import { ElementInitializationContext } from './ElementInitializationContext';
import FormEditorItem from './FormEditorItem';
import FormEditorTabsSection from "./FormEditorTabsSection";
import CommandButtonSelector from './FormItemSelector/CommandButtonSelector';
import FieldSelector from './FormItemSelector/FieldSelector';
import GridSelector from './FormItemSelector/GridSelector';
import ImageSelector from './FormItemSelector/ImageSelector';
import ItemSelectorContainer from './FormItemSelector/ItemSelectorContainer';
import LabelSelector from './FormItemSelector/LabelSelector';
import MultiColumnComboSelector from './FormItemSelector/MultiColumnComboSelector';

const styles = theme => ({
    card: {
        height: '100%'
    },
    removeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    exportIcon: {
        position: "absolute",
        right: "20px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    itemSelector: {
        width: '100%'
    },
    headerFlexContainer: {
        display: "flex",
        flexWrap: "nowrap",
        paddingBottom: "3px"
    },
    sectionNameInput: {
        width: "80%"
    },
    editButton: {
        marginRight: "8px!important"
    },
    editorSection:{
        padding: '10px'
    },
    cardBody: {
        padding: '0px'
    }
});

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class FormEditorSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            minHeights: {
                sm: {},
                md: {},
                lg: {}
            },
            internalSectionSchema: props.section
        };

    }

    componentDidMount () {
        const {tableName } = this.props;
        let elementInitializationContext = this.context;
        elementInitializationContext.registerTable(tableName);
    }

    static contextType = ElementInitializationContext;

    downshiftRef = null

    stopPropagation = (layout, oldItem, newItem, placeholder, event, element) => {
        event.stopPropagation();
    }

    handleUpdateItemLayout = (currentLayout, allLayouts, section) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({
                ...item,
                fieldLayoutMatrix: { ...item.fieldLayoutMatrix }
            }))
        };

        Object.getOwnPropertyNames(allLayouts).forEach((layoutBreakpoint, breakpointIndex) => {
            allLayouts[layoutBreakpoint].forEach((layout) => {
                const { i, x, y, w, h } = layout;
                let keyedElements = section.elements.map(item => item.uniqueKey);
                let fieldIndex = keyedElements.indexOf(i);

                if (breakpointIndex === 0) {
                    section.elements[fieldIndex].fieldLayoutMatrix = {};
                }
                section.elements[fieldIndex].fieldLayoutMatrix[layoutBreakpoint] = { x, y, w, h };
            });
        });

        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });
        this.updateSection(newSectionSchema, section);
        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    }

    updateSection = (newSectionSchema, section) =>{
        //if section is the root section, just set it and return
        if(newSectionSchema.sectionName === section.sectionName){
            //need to set all the properties https://stackoverflow.com/questions/6605640/javascript-by-reference-vs-by-value
            newSectionSchema.elements = section.elements;
            newSectionSchema.numberOfItems = section.numberOfItems;
            newSectionSchema.sectionDisplayName = section.sectionDisplayName;
            newSectionSchema.hide = section.hide;
            newSectionSchema.sequence = section.sequence;
            newSectionSchema.subSections = section.subSections;
            return;
        } else {
            let foundSection = newSectionSchema.subSections.find(s => s.sectionName === section.sectionName);
            //if section is found in the subsections then set it in place
            if(foundSection){
                newSectionSchema.subSections[foundSection] = section;
                return;
            } else {
                //if section is not found, recursively check subsections
                newSectionSchema.subSections.forEach(s => {
                    return this.updateSection(s, section);
                });
            }
            return;
        }
    }

    handleAddItem = (formItem, itemMetaData, section) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let elementInitializationContext = this.context;
        if (itemMetaData) {
            elementInitializationContext.addMetaData(itemMetaData);
        }

        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({ ...item }))
        };

        let smMaxY = 0;
        let mdMaxY = 0;
        let lgMaxY = 0;

        section.elements.forEach(item => {
            if ((item.fieldLayoutMatrix?.sm?.y + item.fieldLayoutMatrix?.sm?.h) > smMaxY) {
                smMaxY = (item.fieldLayoutMatrix.sm.y + item.fieldLayoutMatrix.sm.h);
            }

            if ((item.fieldLayoutMatrix?.md?.y + item.fieldLayoutMatrix?.md?.h) > mdMaxY) {
                mdMaxY = (item.fieldLayoutMatrix.md.y + item.fieldLayoutMatrix.md.h);
            }

            if ((item.fieldLayoutMatrix?.lg?.y + item.fieldLayoutMatrix?.lg?.h) > lgMaxY) {
                lgMaxY = (item.fieldLayoutMatrix.lg.y + item.fieldLayoutMatrix.lg.h);
            }
        });

        section.elements.push({
            ...formItem,
            uniqueKey: uuidv4(),
            fieldLayoutMatrix: {
                sm: {
                    x: 0,
                    y: smMaxY,
                    w: 8,
                    h: 1
                },
                md: {
                    x: 0,
                    y: mdMaxY,
                    w: 8,
                    h: 1
                },
                lg: {
                    x: 0,
                    y: lgMaxY,
                    w: 8,
                    h: 1
                }
            }
        });

        section.numberOfItems = section.elements.length;
        this.updateSection(newSectionSchema, section);
        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }

        this.setState({
            internalSectionSchema: newSectionSchema
        });
    }

    handleRemoveItem = (i, section) => () => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({ ...item }))
        };

        let fieldIndex = section.elements.map(item => item.uniqueKey).indexOf(i);

        section.elements.splice(fieldIndex, 1);
        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });

        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    };

    updateSectionUids = (section) => {
        section.sectionName = uuidv4();
        section.subSections.map(subSection => this.updateSectionUids(subSection));
        return section;
    };

    handleImportSubSection = (parentSection, subSection) => {
        const { onImportSubSection, updateSectionUids } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({ ...item }))
        };

        subSection.sectionName = uuidv4();
        subSection.sequence = parentSection.subSections.length;
        updateSectionUids(subSection);

        parentSection.subSections.push({
            ...subSection
        });

        this.updateSection(newSectionSchema, parentSection);

        if ( typeof onImportSubSection === 'function') {
            onImportSubSection(newSectionSchema);
        }
    };

    handleAddSubSection = (section) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({ ...item }))
        };

        section.subSections.push({
            sectionName: uuidv4(),
            sectionDisplayName: `Section #${section.subSections.length + 1}`,
            sequence: section.subSections.length,
            numberOfItems: 0,
            elements: [],
            subSections: []
        });


        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });

        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    };

    handleRemoveSubSection = (section, subSectionName) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({ ...item }))
        };

        let subSectionIndex = section.subSections.map(s => s.sectionName).indexOf(subSectionName);

        section.subSections.splice(subSectionIndex, 1);
        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });

        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    };

    handleItemPropertiesChange = (newItemSchema, section) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let itemKey = newItemSchema.uniqueKey;

        let newSectionSchema = {
            ...internalSectionSchema,
            elements: internalSectionSchema.elements.map(item => ({ ...item }))
        };

        section.elements.forEach((item, index) => {
            if (item.uniqueKey === itemKey) {
                section.elements.splice(index, 1, newItemSchema);
            }
        });
        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });

        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    }

    updateSectionName = (section, sectionDisplayName) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema
        };

        section.sectionDisplayName = sectionDisplayName;
        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });

        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    }

    updateSectionSequence = (section, sectionSequence) => {
        const { onSchemaUpdate } = this.props;
        const { internalSectionSchema } = this.state;
        let newSectionSchema = {
            ...internalSectionSchema
        };

        section.sequence = sectionSequence;
        this.updateSection(newSectionSchema, section);
        this.setState({
            internalSectionSchema: newSectionSchema
        });

        if ( typeof onSchemaUpdate === 'function') {
            onSchemaUpdate(newSectionSchema);
        }
    }

    updateMinHeights = (itemSchema, height) => {
        const { itemRowHeight } = this.props;
        const { minHeights } = this.state;
        let adjustedHeight = (height + 50) / itemRowHeight; //Added 50 to offset padding
        let elementInitializationContext = this.context;
        
        if (minHeights[elementInitializationContext.displaySize][itemSchema.uniqueKey] !== adjustedHeight) {
            this.setState({
                minHeights: {
                    ...minHeights,
                    [elementInitializationContext.displaySize]: {
                        ...minHeights[elementInitializationContext.displaySize],
                        [itemSchema.uniqueKey]: adjustedHeight
                    }
                }
            });
        }
    }

    renderField = (itemSchema, section) => {
        const { schemaValidation } = this.props;
        let removedFields = [];
        let updatedFields = [];
        let fieldKey = itemSchema.uniqueKey;
        let metaDataKey = itemSchema.metaDataKey;

        if (schemaValidation) {
            removedFields = schemaValidation.removedFields;
            updatedFields = schemaValidation.updatedFields;
        }

        return (
            <div key={fieldKey}>
                <FormEditorItem
                    itemSchema={itemSchema}
                    onRemoveItem={this.handleRemoveItem(fieldKey, section)}
                    updateMinHeights={this.updateMinHeights}
                    error={removedFields.indexOf(metaDataKey) >= 0}
                    warning={updatedFields.indexOf(metaDataKey) >= 0}
                    onItemPropertiesChanged={(newItemSchema) => this.handleItemPropertiesChange(newItemSchema, section)}
                />
            </div>
        );
    };

    handleResize = (width, height) => {
        let event;
        if (typeof Event === 'function') {
            event = new Event('resize');
        } else {
            event = document.createEvent('Event');
            event.initEvent('resize', true, true);
        }
        window.dispatchEvent(event);
    };

    generateLayout = (section, minHeights) => {
        let layouts = {
            sm: [],
            md: [],
            lg: []
        };

        section.elements.forEach(item => {
            Object.getOwnPropertyNames(item.fieldLayoutMatrix).forEach(breakpoint => {
                if (typeof layouts[breakpoint] === 'undefined') {
                    layouts[breakpoint] = [];
                }

                let minH = 1;

                if (typeof minHeights[breakpoint] !== 'undefined'
                    && typeof minHeights[breakpoint][item.uniqueKey] !== 'undefined'
                ) {
                    minH = Math.round(minHeights[breakpoint][item.uniqueKey]);
                }

                layouts[breakpoint].push({
                    ...item.fieldLayoutMatrix[breakpoint],
                    h: item.fieldLayoutMatrix[breakpoint].h < minH ? minH : item.fieldLayoutMatrix[breakpoint].h,
                    i: item.uniqueKey,
                    minW: 4,
                    maxW: 32,
                    minH
                });
            });
        });

        return layouts;
    }

    renderSection = (section) => {
        const {
            tableName,
            itemRowHeight,
            classes,
            open
        } = this.props;

        let elementInitializationContext = this.context;
        //TODO ensure validFields always exists
        let validFieldsObject = elementInitializationContext?.validFields[tableName];

        if (open) {
            const { minHeights } = this.state;

            let layouts = this.generateLayout(section, minHeights);

            return (
                <>
                    <CardBody className={classes.cardBody}>

                            {/* Is the resize detector actually being used for anything? Disabling the onResize doesn't seem to do anything */}
                            {/* <ReactResizeDetector
                                handleHeight
                                handleWidth
                                onResize={this.handleResize}
                            /> */}
                        <Paper elevation={1} className={classes.editorSection}>
                            <ResponsiveReactGridLayout
                                breakpoints={{
                                    sm: 0,
                                    lg: 600
                                }}
                                cols={{
                                    sm: 8,
                                    lg: 32
                                }}
                                layouts={layouts}
                                margin={[0, 0]}
                                rowHeight={itemRowHeight}
                                onDragStart={this.stopPropagation}
                                onLayoutChange={(currentLayout, allLayouts) => this.handleUpdateItemLayout(currentLayout, allLayouts, section)}
                                compactType={null}
                            >
                                {section.elements.map((e) => this.renderField(e, section))}
                            </ResponsiveReactGridLayout>
                        <ItemSelectorContainer
                            className={classes.itemSelector}
                            onAddItem={(formItem, itemMetaData) => this.handleAddItem(formItem, itemMetaData, section)}
                            tabs={[
                                {
                                    text: 'Form Fields',
                                    component: FieldSelector,
                                    props: {
                                        formItems: validFieldsObject ? validFieldsObject.metaDataList : null,
                                        formItemsLoading: validFieldsObject ? validFieldsObject.loadingFields : true
                                    }
                                },
                                {
                                    text: 'Multi Column Combo',
                                    component: MultiColumnComboSelector
                                },
                                {
                                    text: 'One To Many Grid',
                                    component: GridSelector
                                },
                                {
                                    text: 'Form Label',
                                    component: LabelSelector
                                },
                                {
                                    text: 'Command button',
                                    component: CommandButtonSelector
                                },
                                {
                                    text: 'Images',
                                    component: ImageSelector
                                }
                            ]}
                        />
                            </Paper>
                        <FormEditorTabsSection
                            section={section}
                            renderSection={(s) => this.renderSection(s)}
                            handleAddSubSection={() => this.handleAddSubSection(section)}
                            handleRemoveSubSection={(subSectionName) => this.handleRemoveSubSection(section, subSectionName)}
                            handleUpdateSubSectionName={(subSection, subSectionDisplayName) => this.updateSectionName(subSection, subSectionDisplayName)}
                            handleUpdateSubSectionSequence={(subSection, sectionSequence) => this.updateSectionSequence(subSection, sectionSequence)}
                            handleImportSubSection={(subSection) => this.handleImportSubSection(section, subSection)}
                        />
                    </CardBody>
                    <CardFooter className={`nonDraggableSection`} />
                </>
            );
        }
        else {
            return (
                <React.Fragment />
            );
        }
    };

    render() {
        const { internalSectionSchema } = this.state;
        const {
            schemaValidation,
            validateFormSchema,
            onRemoveSection,
            blockSectionNameUpdate,
            classes
        } = this.props;

        let sectionNameError = (internalSectionSchema.sectionDisplayName === '')
            || (schemaValidation && schemaValidation.sectionDisplayNames.map(name => name.toLowerCase()).indexOf(internalSectionSchema.sectionDisplayName.toLowerCase()) >= 0);
        let sectionNameErrorMsg = '';
        if (internalSectionSchema.sectionDisplayName === '') {
            sectionNameErrorMsg = 'Section name can not be empty';
        } else if (schemaValidation && schemaValidation.sectionDisplayNames.map(name => name.toLowerCase()).indexOf(internalSectionSchema.sectionDisplayName.toLowerCase()) >= 0) {
            sectionNameErrorMsg = 'Section name already exists, pick a unique name';
        }
        
        return (
            <div>
                <Card classes={{ card: classes.card }}>
                    <CardHeader className='nonDraggableSection'>
                        {!blockSectionNameUpdate ?
                            <div className={classes.headerFlexContainer}>
                                <LxTextField
                                    error={sectionNameError}
                                    helperText={sectionNameErrorMsg}
                                    placeholder='Section Name'
                                    onBlur={validateFormSchema}
                                    value={internalSectionSchema.sectionDisplayName}
                                    useOnChangeEvent
                                    onChange={(event) => this.updateSectionName(internalSectionSchema, event.target.value)}
                                />
                            </div>
                            :
                            null
                        }
                        <Link
                            className={classes.exportIcon}
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(internalSectionSchema))}`}
                            download={`${internalSectionSchema.sectionDisplayName}.json`}
                            onClick={(e) => e.preventDefault}
                        >
                            <GetApp />
                        </Link>
                        {onRemoveSection ?
                            <Close onClick={onRemoveSection} className={classes.removeIcon} />
                            :
                            null
                        }
                    </CardHeader>
                    {this.renderSection(internalSectionSchema)}
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(FormEditorSection);