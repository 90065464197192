import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    backLink: {
        marginLeft: '10px'
    }
});

const NotFound = (props) =>
{
    let onBackClick = (event) =>{
        event.preventDefault();
        props.history.goBack();
    };

    const wrongUrl = props.location.pathname; // TODO: Make sure slice 1 is safe for removing question mark
    return(
        <div>
            <div>
                <h1>Page Not Found</h1>
                <p>Sorry, the page {wrongUrl} could not be found</p>
                <a className={props.classes.backLink} href="" onClick={(event) => onBackClick(event)}>Back</a>
            </div>
        </div>
    );
};

export default withStyles(styles)(withRouter(NotFound));