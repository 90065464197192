import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

const MATCH_OPTIONS = {
    path: '/patient/:mrn?/forms/:recordTable/:recordId'
};

const RecordContext = ({ children, location, recordInfo, onRecordLoad, clearRecord }) => {
    let pathMatch = matchPath(location.pathname, MATCH_OPTIONS);
    let pathRecordTable = pathMatch !== null ? pathMatch.params.recordTable : null;
    let pathRecordId = pathMatch !== null ? pathMatch.params.recordId : null;

    useEffect(() => {
        if(pathRecordTable && pathRecordId){
            let record = {
                recordTable: pathRecordTable,
                recordId: pathRecordId
            };
            onRecordLoad(record);
        } else {
            clearRecord();
        }
    }, [pathRecordTable, pathRecordId, onRecordLoad, clearRecord]);

    return (React.Children.only(children));
};

const mapStateToProps = (state) => ({
    recordInfo: state.recordInfo
});

const mapDispatchToProps = (dispatch) => ({
    onRecordLoad: (recordInfo) => dispatch({ type: 'SET_RECORDINFO', recordInfo }),
    clearRecord: () => dispatch({ type: 'CLEAR_RECORDINFO' })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordContext));