import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import { pushToError, sabermedicsApi } from '.../utils/apiHelper';
import OeRatio from './OeRatio';
import OeRatioFilters from './OeRatioFilters';

const styles = theme => ({
    oeRatio: {
        minWidth: '600px',
        maxWidth: '1000px',
        margin: 'auto'
    }
});

class SabermedicsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filtersLoading: true,
            resultLoading: true
        };
    }
    componentDidMount() {
        sabermedicsApi.getOeRatioFilterOptions().then((filters) => {
            if (filters.surgeons.length === 0 || filters.numberOfMonths.length === 0 || filters.procedures.length === 0) {
                throw new Error("Sabermedics was missing filter options");
            }
            sabermedicsApi.getOeRatioResult(filters.surgeons[0], filters.procedures[0].id, filters.numberOfMonths[0]).then((oeRatios) => {
                this.setState({
                    filtersLoading: false,
                    resultLoading: false,
                    filters: filters,
                    selected: {
                        surgeon: filters.surgeons[0],
                        month: filters.numberOfMonths[0],
                        procedure: filters.procedures[0].id
                    },
                    oeRatios: oeRatios
                });
            }).catch(pushToError);
        }).catch(pushToError);
    }

    onFilterChange = (selected) => {
        this.setState({ selected, resultLoading: true });
        sabermedicsApi.getOeRatioResult(selected.surgeon, selected.procedure, selected.month).then((oeRatios) => {
            this.setState({
                oeRatios,
                resultLoading: false
            });
        }).catch((error) => pushToError(error, this.props.match.params.mrn));
    }

    render() {
        const { filtersLoading, resultLoading, oeRatios, selected, filters } = this.state;
        const { classes } = this.props;

        return (filtersLoading ?
            <CircularProgress size={60} thickness={7} />
            :
            <Card className={classes.oeRatio}>
                <CardBody>
                    <OeRatioFilters selected={selected} filterOptions={filters} onChange={this.onFilterChange} />
                    <OeRatio oeRatios={oeRatios} loading={resultLoading} />
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(SabermedicsContainer);