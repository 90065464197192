import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import Images from '../Images';

const styles = theme => ({
    image: {
        height: '100%',
        width: '100%'
    },
    header: {
        margin: `${theme.spacing(2)}px 0px`
    },
    button: {
        height: '100% !important'
    }
});

class ChartTypeSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: true
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.selectedChart) !== JSON.stringify(this.props.selectedChart)) {
            this.isValidated(this.props.selectedChart);
        }
    }

    isValidated = (chart) => {
        const checkedChart = typeof chart !== 'undefined' ? chart : this.props.selectedChart;
        let validated = typeof checkedChart !== 'undefined' && checkedChart.chartType !== '';
        this.setState({ validated });
        return validated;
    }

    handleChartTypeSelection = (chartType) => {
        let newChart = {
            ...this.props.selectedChart,
            chartType
        };

        if (chartType === 'line') {
            newChart = {
                ...newChart,
                units: 'Date',
                measurementLabel: 'Year',
                xAxisTableName: null,
                xAxisFieldName: null
            };
        } else if (chartType === 'scatter') {
            newChart = {
                ...newChart,
                units: '',
                measurementLabel: '',
                xAxisTableName: '',
                xAxisFieldName: ''
            };
        }

        this.props.setSelectedChart(newChart, this.props.selectedChartId);
    }

    render() {
        const { selectedChart, classes } = this.props;
        const { validated } = this.state;

        const validChart = typeof selectedChart !== 'undefined';

        return (!validChart ? null :
            <div>
                <GridContainer className={classes.header} justify='space-between' alignItems='baseline'>
                    <GridItem>
                        <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                            {'Select a chart type below'}
                        </Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={4} justify='center' alignContent='center'>
                    <GridItem sm={4}>
                        <Button
                            variant='contained'
                            color={selectedChart.chartType === 'line' ? 'primary' : 'inherit'}
                            onClick={() => this.handleChartTypeSelection('line')}
                            className={classes.button}
                        >
                            <img alt='...' src={Images.LineChart} style={{ height: '100%', width: '100%' }} />
                        </Button>
                        {'Line chart over time'}
                    </GridItem>
                    <GridItem sm={4}>
                        <Button
                            variant='contained'
                            color={selectedChart.chartType === 'scatter' ? 'primary' : 'inherit'}
                            onClick={() => this.handleChartTypeSelection('scatter')}
                            className={classes.button}
                        >
                            <img alt='...' src={Images.ScatterChart} className={classes.image} />
                        </Button>
                        {'Scatter plot of values compared'}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

ChartTypeSelection.propTypes = {
    selectedChart: PropTypes.object,
    selectedChartId: PropTypes.number,
    setSelectedChart: PropTypes.func.isRequired
};

export default withStyles(styles)(ChartTypeSelection);