import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    select: {
        width: '100%',
        fontSize: theme.typography.fontSize,
        marginTop: '0px !important',
        textAlign: 'left'
    },
    gridItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    menuItem: {
        minHeight: '32px'
    },
    dropdownPaper: {},
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    inputGridItem: {
        width: '100%'
    }
});

function LxSelectField(props){
    const {
        className,
        classes,
        borderStyle,
        error,
        disabled,
        nullable,
        title,
        titleTooltip,
        descriptions,
        value,
        defaultValue,
        valueOptions,
        onBlur,
        helperText,
        hasMappings,
        options,
        tabIndex,
        validationErrorSeverity,
        inputRef,
        inputOnly,
        fieldInformation,
        fieldInfo,
        onChange, metaDataKey, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress, fhirPopupSelector
    } = props;
    let selectRef = React.useRef(null);

    let handleChange = (event) => {
        if (typeof onChange === 'function') {
            onChange(event.target.value);
        }
        if (typeof onBlur === 'function') {
            onBlur(event.target.value);
        }
    };

    React.useEffect(() => {
        if (typeof inputRef === 'function' && selectRef.current) {
            inputRef(selectRef.current.node);
        }
    }, [ inputRef, selectRef ]);

    return (
        <FormControl
            className={className}
            disabled={disabled}
            style={borderStyle}
        >
            {inputOnly ?
                null
                :
                <div>
                    <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                        <GridItem className={classes.gridItem}>
                            <FormLabel>
                                 {typeof titleTooltip === 'undefined' ?
                                    (<Typography align="left" color='inherit' variant='body2'>
                                        {title}
                                    </Typography>) :
                                    (<Tooltip title={titleTooltip} placement="top">
                                        <Typography align="left" color='inherit' variant='body2'>
                                            {title}
                                        </Typography>
                                    </Tooltip>)
                                } 
                            </FormLabel>
                        </GridItem>
                        <GridItem>
                            <FieldHeader
                                value={typeof value === 'undefined' ? defaultValue : value}
                                descriptions={descriptions}
                                valueOptions={valueOptions}
                                onValueSelect={onBlur}
                                hasMappings={hasMappings}
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                                metaDataKey={metaDataKey}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={onUpdateMappingStatus}
                                defaultValue={defaultValue}
                                formLoadProgress={formLoadProgress}
                                fhirPopupSelector={fhirPopupSelector}
                                fieldInformation={fieldInformation}
                                fieldInfo={fieldInfo}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            }
            <GridContainer wrap="nowrap" alignItems="center">
                <GridItem className={classes.inputGridItem}>
                    {disabled ?
                        <TextField disabled={disabled} value={value} defaultValue={defaultValue} fullWidth />
                        :
                        <Select
                            value={value === null ? '' : value}
                            defaultValue={defaultValue ? defaultValue : ''}
                            className={classes.select}
                            onChange={handleChange}
                            autoWidth
                            ref={selectRef}
                            inputProps={{
                                //For some reason, having a non-null tabIndex will cause the component to not be fully disabled.
                                //Without this conditional, you can still click on the component to gain focus and spacebar will open the popup.
                                tabIndex: disabled ? null : tabIndex
                            }}
                            MenuProps={{
                                className: classes.dropdownRoot,
                                classes: {
                                    paper: classes.dropdownPaper
                                },
                                anchorOrigin: {
                                    horizontal: 'left',
                                    vertical: 'bottom'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            {nullable && !disabled ?
                                //In IE 11, even if the FormControl is disabled, you can still focus the dropdown and use space to open it up and select a value. Removing all the values keeps IE users from selecting values for readonly fields.
                                <MenuItem className={classes.menuItem} value='' />
                                :
                                null
                            }
                            {options.map(option => {
                                if (!disabled) {
                                    //In IE 11, even if the FormControl is disabled, you can still focus the dropdown and use space to open it up and select a value. Removing all the values keeps IE users from selecting values for readonly fields.
                                    let value = typeof option === 'string' ? option : option.value;
                                    return (
                                        <MenuItem className={classes.menuItem} key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Select>}
                </GridItem>
                {inputOnly ?
                    <GridItem>
                        <ValidationErrorIcon
                            error={error}
                            validationErrorSeverity={validationErrorSeverity}
                            helperText={helperText}
                        />
                    </GridItem>
                    :
                    null
                }
            </GridContainer>
            {error && helperText && validationErrorSeverity === 1 ?
                <FormHelperText error={false} className={classes.colorError}>{helperText}</FormHelperText>
                :
                null
            }
        </FormControl>
    );
}

LxSelectField.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    nullable: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]))
};

LxSelectField.defaultProps = {
    disabled: false,
    error: false,
    nullable: true,
    options: []
};

export default withStyles(styles)(LxSelectField);