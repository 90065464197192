import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';

import Button from ".../assets/components/CustomButtons/Button";
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import LxTextField from "../../../formComponents/LxTextField";
import LxCheckbox from "../../../formComponents/LxCheckbox";

const styles = theme => ({
    itemContainer: {
        padding: '5px',
        height: '100%'
    },
    itemCard: {
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.7)',
        height: '100%',
        width: '100%'
    },
    errorHightlight: {
        boxShadow: `0 1px 8px 0 ${theme.palette.error.main}`
    },
    warningHightlight: {
        boxShadow: `0 1px 8px 0 ${theme.palette.warning.main}`
    },
    removeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    toolTip: {
        top: '20px !important'
    },
    cogButton: {
        position: "absolute",
        padding: "5px",
        height: "20.56px",
        width: "20.56px",
        right: "24.56px",
        top: "0px",
        zIndex: 10
    },

    propertyFields: {
        paddingBottom: '20px'
    }
});

function FormEditorSectionPropertyEditor(props) {
    const { section, handleUpdateSectionHide, classes } = props;
    const [editorOpen, setEditorOpen] = React.useState(false);
    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={1}>
                    <IconButton className={classes.cogButton} onClick={() => setEditorOpen(true)}>
                        <SettingsIcon />
                    </IconButton>
                </GridItem>
            </GridContainer>
            <Dialog open={editorOpen} onClose={() => setEditorOpen(false)}>
                <DialogTitle className={classes.dialogTitle}>
                    Section Properties Editor
                    <Divider />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <LxTextField
                        value={section.sectionDisplayName}
                        className={classes.propertyFields}
                        //TODO : Need to add onChange event to handle the update section display name from the section property editor
                        //onChange={(event) => handleUpdateSectionDisplayName(section, event.target.value)}
                        title={"Section Display Name"}
                    />
                    <LxTextField
                        value={section.sectionName}
                        className={classes.propertyFields}
                        title={"Section Name"}
                    />
                    <LxCheckbox
                        value={section.hide === false ? false : true}
                        className={classes.propertyFields}
                        threeState={false}
                        onChange={(val) => handleUpdateSectionHide(section, val)}
                        title={"Hide"}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogButtons}>
                    <Button color="primary" onClick={() => setEditorOpen(false)}>Done</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>);
}

export default withStyles(styles)(FormEditorSectionPropertyEditor);