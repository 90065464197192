import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import regularFormsStyle from ".../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import PatientWorklistItem from './PatientWorklistItem';

const styles = theme => ({
    ...regularFormsStyle,
    card: {
        userSelect: 'none'
    },
    newPatientButton: {
        position: "absolute",
        left: '22px',
        top: '22px',
        zIndex: 10,
        cursor: "pointer"
    },
    expanderButton: {
        position: "absolute",
        right: '22px',
        top: '14px',
        zIndex: 10,
        cursor: "pointer"
    },
    expander: {
        marginLeft: theme.spacing(2),
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expanderOpen: {
        transform: 'rotate(180deg)'
    },
    noPatientsFound: {
        margin: theme.spacing(4)
    }
});

const PatientWorklist = (props) => {
    const { className, classes, patients, selectedPatient, handlePatientSelect, handleCreatePatient, title, showUniqueId, collapsible } = props;
    const [collapsed, setCollapsed] = useState(true);

    let renderListItem = (patient, index) => (
        <PatientWorklistItem
            key={`${patient.uniqueSourceId} ${index}`}
            selected={selectedPatient.uniqueSourceId === patient.uniqueSourceId}
            patient={patient}
            showUniqueId={showUniqueId}
            handlePatientSelect={handlePatientSelect}
        />
    );

    return (
        <Card className={`${classes.card} ${className}`}>
            <CardHeader>
                {typeof handleCreatePatient === 'function' ?
                    <Button
                        className={classes.newPatientButton}
                        variant='outlined'
                        color='primary'
                        onClick={handleCreatePatient}
                    >
                        {'Create Patient'}
                    </Button>
                    :
                    null
                }
                <h4 className={classes.cardTitle}>
                    {title}
                </h4>
                {collapsible ?
                    <IconButton
                        className={classes.expanderButton}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {Array.isArray(patients) ? patients.length : 0}
                        <ExpandMoreIcon
                            className={
                                `${classes.expander} ${!collapsed ? classes.expanderOpen : ''}`
                            }
                        />
                    </IconButton>
                    :
                    null
                }
            </CardHeader>
            <CardBody>
                <Collapse in={!(collapsed && collapsible)} timeout="auto">
                    <Divider />
                    {Array.isArray(patients) && patients.length > 0 ?
                        patients.map(renderListItem)
                        :
                        <Typography variant='subtitle1' className={classes.noPatientsFound}>
                            {`No ${title} found`}
                        </Typography>
                    }
                </Collapse>
            </CardBody>
        </Card>
    );
};

PatientWorklist.propTypes = {
    className: PropTypes.string,
    patients: PropTypes.arrayOf(PropTypes.object),
    selectedPatient: PropTypes.object,
    handlePatientSelect: PropTypes.func.isRequired,
    handleCreatePatient: PropTypes.func,
    title: PropTypes.string,
    showUniqueId: PropTypes.bool,
    collapsible: PropTypes.bool
};

PatientWorklist.defaultProps = {
    patients: [],
    selectedPatient: {},
    handlePatientSelect: patient => console.log(patient),
    title: 'Search Results',
    showUniqueId: false,
    collapsible: false
};

export default withStyles(styles)(PatientWorklist);
