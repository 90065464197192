import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Event from '@material-ui/icons/Event';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    inputRoot: {
        fontSize: theme.typography.fontSize,
        width: '100%'
    },
    dialog: {
        '& .MuiPickersToolbarButton-toolbarBtn': {
            height: 'unset !important'
        }
    },
    gridItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    inputGridItem: {
        width: '100%'
    }
});

class LxDatePicker extends React.Component {
    state = {
        internalValue: typeof this.props.value === 'undefined' ? this.props.defaultValue : this.props.value,
        invalidDate: null
    }

    handleChange = (date) => {
        const { onChange, onBlur } = this.props;

        let dateString = this.convertDateToISO8601(date);
        if (date !== null && date.toString() === 'Invalid Date') {
            this.setState({
                internalValue: null,
                invalidDate: date
            });

            return;
        } else {
            this.setState({
                internalValue: date,
                invalidDate: null
            });
        }

        if (typeof onChange === 'function') {
            onChange(dateString);
        }

        if (typeof onBlur === 'function') {
            onBlur(dateString);
        }
    }

    handleKeyDown = (event) => {
        //Insert-Key keycode = 45
        if (event.keyCode === 45 && event.ctrlKey) {
            event.preventDefault();
            let newDate = new Date();
            this.handleChange(newDate);
        }
    };

    addZeroCharPadding = (num) => ((`${num}`).length === 1 ? `0${num}` : num)

    convertDateToISO8601 = (date) => {
        if (date instanceof Date && date.toString() !== 'Invalid Date') {
            // getMonth returns month index so Jan => 0, add 1 to correct for that
            let month = this.addZeroCharPadding(date.getMonth() + 1);
            let day = this.addZeroCharPadding(date.getDate());
            return `${date.getFullYear()}-${month}-${day}T00:00:00.000`;
        } else {
            return null;
        }
    }

    render() {
        const {
            classes,
            borderStyle,
            error,
            disabled,
            title,
            titleTooltip,
            descriptions,
            valueOptions,
            onBlur,
            helperText,
            hasMappings,
            disablePast,
            disableFuture,
            tabIndex,
            autoFocus,
            forcedFocus,
            validationErrorSeverity,
            inputRef,
            inputOnly,
            fieldInformation,
            fieldInfo
        } = this.props;

        const { invalidDate } = this.state;

        let calculatedValue = typeof this.props.value === 'undefined' ? this.props.defaultValue : this.props.value;

        let pickerValue;
        if (invalidDate !== null) {
            pickerValue = invalidDate;
        } else if (calculatedValue) {
            pickerValue = new Date(calculatedValue);
        } else {
            pickerValue = null;
        }

        return (
            <FormControl disabled={disabled} style={borderStyle}>
                {inputOnly ?
                    null
                    :
                    <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                        <GridItem className={classes.gridItem}>
                            <FormLabel>
                                <Tooltip title={titleTooltip} placement="top">
                                    <Typography align="left" color='inherit' variant='body2'>
                                        {title}
                                    </Typography>
                                </Tooltip>
                            </FormLabel>
                        </GridItem>
                        <GridItem>
                            <FieldHeader
                                value={calculatedValue}
                                descriptions={descriptions}
                                valueOptions={valueOptions}
                                onValueSelect={onBlur}
                                hasMappings={hasMappings}
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                                fieldInformation={fieldInformation}
                                fieldInfo={fieldInfo}
                            />
                        </GridItem>
                    </GridContainer>
                }
                <GridContainer wrap="nowrap" alignItems="center">
                    <GridItem className={classes.inputGridItem}>
                        <KeyboardDatePicker
                            className={classes.inputGridItem}
                            clearable
                            value={pickerValue}
                            onChange={this.handleChange}
                            format='MM/dd/yyyy'
                            mask='__/__/____'
                            rightArrowIcon={<NavigateNext />}
                            leftArrowIcon={<NavigateBefore />}
                            keyboardIcon={<Event />}
                            placeholder='mm/dd/yyyy'
                            onKeyDown={this.handleKeyDown}
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot
                                },
                                inputProps: {
                                    tabIndex: tabIndex,
                                    autoFocus: autoFocus
                                }
                            }}
                            inputRef={fieldInputRef => {
                                if (forcedFocus && fieldInputRef) {
                                    fieldInputRef.focus();
                                }
                                if (typeof inputRef === 'function') {
                                    inputRef(fieldInputRef);
                                }
                            }}
                            KeyboardButtonProps={{ tabIndex: -1 }}
                            DialogProps={{
                                className: `nonDraggableSection ${classes.dialog}`
                            }}
                            disabled={disabled}
                            disablePast={disablePast}
                            disableFuture={disableFuture}
                            maxDateMessage='Date can not be in the future'
                            minDateMessage='Date can not be in the past'
                            error={false}
                        />
                    </GridItem>
                    {inputOnly ?
                        <GridItem>
                            <ValidationErrorIcon
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                            />
                        </GridItem>
                        :
                        null
                    }
                </GridContainer>
                {error && helperText  && validationErrorSeverity === 1?
                    <FormHelperText error={false} className={classes.colorError}>{helperText}</FormHelperText>
                    :
                    null
                }
            </FormControl>
        );
    }
}

LxDatePicker.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool
};

LxDatePicker.defaultProps = {
    disablePast: false,
    disableFuture: false,
    disabled: false,
    error: false
};

export default withStyles(styles)(LxDatePicker);