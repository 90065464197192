import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { powerbiApi, pushToError } from '../../../utils/apiHelper';
import HvaContainer from './HvaContainer';

class HvaRoutes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        powerbiApi.getUserModules()
            .then(userModules => {
                let moduleFeatureMap = {};
                let firstModule = '';
                let firstFeature = '';

                userModules.forEach((module, moduleIndex) => {

                    if (moduleIndex === 0) {
                        firstModule = module.productName.toLowerCase();
                    }

                    moduleFeatureMap[module.productName.toLowerCase()] = {};
                    module.features.forEach((feature, featureIndex) => {

                        if (moduleIndex === 0 && featureIndex === 0) {
                            firstFeature = feature.featureName.toLowerCase();
                        }

                        moduleFeatureMap[module.productName.toLowerCase()][feature.featureName.toLowerCase()] = {
                            id: feature.featureId,
                            rls: feature.properties.rls
                        };
                    });
                });

                this.setState({
                    moduleFeatureMap,
                    firstModule,
                    firstFeature,
                    isLoading: false
                });
            })
            .catch(error => pushToError(error, this.props.match.params.mrn));
    }

    render() {
        const { isLoading, moduleFeatureMap, firstModule, firstFeature } = this.state;
        return (
            isLoading ?
                <CircularProgress size={60} thickness={7} />
                :
                <Switch>
                    <Route exact path="/patient/:mrn/hva" render={(props) => <Redirect {...props} to={`${this.props.location.pathname}/${firstModule}/${firstFeature}`} />} />
                    <Route exact path="/hva" render={(props) => <Redirect {...props} to={`${this.props.location.pathname}/${firstModule}/${firstFeature}`} />} />
                    <Route exact path="/hva/:module/:feature" render={(props) => <HvaContainer {...props} moduleFeatureMap={moduleFeatureMap} />} />
                    <Route exact path="/patient/:mrn/hva/:module/:feature" render={(props) => <HvaContainer {...props} moduleFeatureMap={moduleFeatureMap} />} />
                </Switch>
        );
    }
}

export default withRouter(HvaRoutes);