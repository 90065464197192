import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from "@material-ui/core/styles/withStyles";
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { chestPainEvalApi, pushToError } from '.../utils/apiHelper';
import ChestPainEventList from './ChestPainEventList';
import ChestPainForm from './ChestPainForm';
import ChestPainResult from './ChestPainResult';
import moment from "moment";

const styles = theme => ({
    container: {
        margin: '0px'
    },
    result: {
        flex: '0 0 340px'
    },
    formContainer: {
        marginRight: `${theme.spacing(2)}px`
    },
    form: {
        height: '100%'
    }
});

class ChestPainContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            resultLoading: false,
            tab: 0,
            chestPainFormData: null,
            confirmEventClear: false
        };
    }

    handleEventSelect = (eventId) => {
        this.setState({
            isLoading: true
        });

        if (eventId === 0) {
            chestPainEvalApi.createEvent({
                ssPatientId: this.props.patientInfo.ssPatientId,
                eventDate: moment().format('YYYY-MM-DDTHH:mm:ss')
            })
                .then(this.loadEvent)
                .catch(error => pushToError(error, this.props.patientInfo.patientId));
        } else {
            this.loadEvent(eventId);
        }
    };

    loadEvent = (eventId) => {
        chestPainEvalApi.getChestPainDataAndResult(eventId).then((chestPainDataAndResult) => {
            //Set active tab to the latest tab with troponin value
            const tab = chestPainDataAndResult.chestPainObject.chestPainResult.threeHourResult.troponinValue
                ? 2
                : chestPainDataAndResult.chestPainObject.chestPainResult.oneHourResult.troponinValue
                    ? 1
                    : 0;
            this.setState({
                chestPainFormData: chestPainDataAndResult.chestPainObject.chestPainData,
                chestPainEvalResults: chestPainDataAndResult.chestPainObject.chestPainResult,
                chestPainValidation: chestPainDataAndResult.validationResult,
                resultLoading: false,
                isLoading: false,
                tab: tab

            });
        }).catch(error => pushToError(error, this.props.patientInfo.patientId));
    };

    triggerEventClear = () => {
        this.setState({
            chestPainFormData: null,
            tab: 0
        });
    };

    calculateAge = (dob) => {
        const diffMs = Date.now() - new Date(dob).getTime();
        const ageDate = new Date(diffMs);

        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    populateForward = (firstObject, secondObject, keysToSkip = []) => {
        const firstObjectKeys = Object.getOwnPropertyNames(firstObject);

        let newObject = { ...secondObject };

        firstObjectKeys.forEach(key => {
            if (keysToSkip.indexOf(key) < 0) {
                if (firstObject[key] !== null && typeof firstObject[key] === 'object') {
                    newObject[key] = this.populateForward(firstObject[key], newObject[key]);
                } else if (newObject[key] === null) {
                    newObject[key] = firstObject[key];
                }
            }
        });

        return newObject;
    };

    saveToDatabase = (formData) => {
        const newFirstHourEval = this.populateForward(formData.initialHourEval, formData.oneHourEval, ['troponin', 'testDate', 'testTime', 'ecg']);
        const newThirdHourEval = this.populateForward(formData.oneHourEval, formData.threeHourEval, ['troponin', 'testDate', 'testTime', 'ecg']);
        const newFormData = {
            ...formData,
            oneHourEval: newFirstHourEval,
            threeHourEval: newThirdHourEval
        };
        this.updateFormData(newFormData);

        chestPainEvalApi.getResult(newFormData, true).then().catch(error => pushToError(error, this.props.match.params.mrn));
    };

    updateFormData = (formData) => this.setState({ chestPainFormData: formData });

    getResult = (formData, save = false) => {
        this.setState({
            resultLoading: true
        });
        chestPainEvalApi.getResult(formData, save).then(evalResults => {
            this.setState({
                resultLoading: false,
                chestPainEvalResults: evalResults.chestPainResult,
                chestPainValidation: evalResults.validationResult
            });
        }).catch(error => pushToError(error, this.props.match.params.mrn));
    };

    switchTab = (tab) => this.setState({ tab });

    setReportRef = (reportRef) => this.setState({ reportRef });

    convertToDisplayDate = (dateValue) => {
        let date = new Date(dateValue);

        if (dateValue === null || date.toString() === 'Invalid Date') {
            return 'N/A';
        } else {
            return moment(dateValue).format('MM/DD/YYYY');
        }
    };

    convertToDisplayTime = (timeValue) => {
        let time = new Date(timeValue);

        if (timeValue === null || time.toString() === 'Invalid Date') {
            return 'N/A';
        } else {
            return moment(timeValue).format('HH:mm');
        }
    };


    render() {
        const { classes } = this.props;
        const { isLoading, resultLoading, chestPainFormData, chestPainEvalResults, tab, chestPainValidation } = this.state;

        if (isLoading) {
            return <CircularProgress size={60} thickness={7} />;
        } else if (chestPainFormData === null) {
            return <ChestPainEventList handleEventSelect={this.handleEventSelect} />;
        } else {
            return (
                <React.Fragment>
                    <GridContainer spacing={0} className={classes.container} alignItems='stretch'>

                        <GridItem xs className={classes.formContainer}>
                            <ChestPainForm
                                className={classes.form}
                                tab={tab}
                                switchTab={this.switchTab}
                                formData={chestPainFormData}
                                validation={chestPainValidation}
                                results={chestPainEvalResults}
                                resultKey={chestPainEvalResults.threeHourResult.troponinValue ? 'threeHourResult' : chestPainEvalResults.oneHourResult.troponinValue ? 'oneHourResult' : 'initialHourResult'}
                                getResult={this.getResult}
                                updateFormData={this.updateFormData}
                                saveToDatabase={this.saveToDatabase}
                                triggerEventClear={this.triggerEventClear}
                                convertToDisplayDate={this.convertToDisplayDate}
                                convertToDisplayTime={this.convertToDisplayTime}
                            />
                        </GridItem>
                        <ChestPainResult
                            className={classes.result}
                            tab={tab}
                            resultKey={chestPainEvalResults.threeHourResult.troponinValue ? 'threeHourResult' : chestPainEvalResults.oneHourResult.troponinValue ? 'oneHourResult' : 'initialHourResult'}
                            resultLoading={resultLoading}
                            results={chestPainEvalResults}
                            formData={chestPainFormData}
                            convertToDisplayDate={this.convertToDisplayDate}
                            convertToDisplayTime={this.convertToDisplayTime}
                        />
                    </GridContainer>
                </React.Fragment>
            );
        }
    }
}

export default connect((state) => ({ patientInfo: state.demographics }))(withRouter(withStyles(styles)(ChestPainContainer)));