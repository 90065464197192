import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import StudyWorklist from './StudyWorklist';
import WebpacsContainer from './WebpacsContainer';

const WebpacsRoutes = () => (
    <Switch>
        <Route exact path="/patient/:mrn/webpacs" component={StudyWorklist} />
        <Route exact path="/patient/:mrn/webpacs/:ssDicomStudyId" component={WebpacsContainer}/>
    </Switch>
);

export default withRouter(WebpacsRoutes);