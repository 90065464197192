import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxCheckbox from '.../components/formComponents/LxCheckbox';
import LxDatePicker from '.../components/formComponents/LxDatePicker';
import LxFloatField from '.../components/formComponents/LxFloatField';
import LxIntField from '.../components/formComponents/LxIntField';
import LxRadioGroup from '.../components/formComponents/LxRadioGroup';
import LxSelectField from '.../components/formComponents/LxSelectField';
import LxTextField from '.../components/formComponents/LxTextField';
import LxTimePicker from '.../components/formComponents/LxTimePicker';

const styles = theme => ({
    dialog: {
        minWidth: '40vw',
        minHeight: '20vh',
        maxWidth: '80vw',
        maxHeight: '90vh'
    },
    dialogTitle: {
        padding: '8px 24px'
    },
    dialogContent: {
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        container: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    dialogButtons: {
        justifyContent: 'center'
    },
    propertyFields: {
        paddingBottom: '20px'
    }
});

class PropertyEditor extends React.Component {
    state = {
        newItemSchema: {
            ...this.props.itemSchema
        }
    }

    handleConfirm = () => () => {
        const { onConfirm, closeDialog } = this.props;
        const { newItemSchema } = this.state;

        if (typeof onConfirm === 'function') {
            onConfirm(newItemSchema);
            if (typeof closeDialog === 'function') {
                closeDialog();
            }
        }
    }

    renderPropertyField = (incomingProp, index) => {
        const { classes } = this.props;
        const { newItemSchema } = this.state;
        const { name, label, type, hidden, options, description, CustomRender, customProps } = incomingProp;
        let value = newItemSchema[name];

        //This custom render allows you to render more complex components for editing properties. The only thing it requires is a new react component 
        //that will be the custom editor, and an onChange method that will be called whenever the property changes, passing the new property value as 
        //it's only argument. See SourceColumnOneToManyEditor as an example of this implementation.
        if (hidden) {
            return;
        }

        if ((CustomRender ?? null) !== null) {
            return (
                <CustomRender
                    key={index}
                    value={value}
                    onChange={this.updateItemSchema(name)}
                    title={label}
                    {...customProps}
                />
            );
        } else {
            let InputField = this.determinePropertyInputType(type);
            return (
                <InputField
                    key={index}
                    className={classes.propertyFields}
                    style={newItemSchema.style}
                    value={value}
                    onChange={this.updateItemSchema(name)}
                    onBlur={this.updateItemSchema(name)}
                    title={label}
                    options={options}
                    description={((description ?? '') !== '') ? description : null}
                    threeState={false}
                    forceBooleanFalse
                />
            );
        }
    }

    updateItemSchema = (propertyName) => (value) => {
        const { itemSchema, handleUpdatedPropertyBindings } = this.props;
        const { newItemSchema } = this.state;

        let tempItemSchema = {
            ...newItemSchema,
            [propertyName]: value,
            //The newItemSchema fieldLayoutMatrix does not update when a user drags it around, so the newItemSchema fieldLayoutMatrix cannot be trusted.
            fieldLayoutMatrix: itemSchema?.fieldLayoutMatrix
        };

        if (typeof handleUpdatedPropertyBindings === 'function') {
            handleUpdatedPropertyBindings(propertyName, value);
        }
        
        this.setState({
            newItemSchema: tempItemSchema
        });
    }

    handleMouseDown = (event) => {
        event.stopPropagation();
    }

    determinePropertyInputType = (typeString) => {
        let inputField;
        switch (typeString) {
            case 'text':
                inputField = LxTextField;
                break;
            case 'checkBox':
                inputField = LxCheckbox;
                break;
            case 'selectionSet':
                inputField = LxSelectField;
                break;
            case 'radioGroup':
                inputField = LxRadioGroup;
                break;
            case 'datePicker':
                inputField = LxDatePicker;
                break;
            case 'timePicker':
                inputField = LxTimePicker;
                break;
            case 'float':
                inputField = LxFloatField;
                break;
            case 'int':
                inputField = LxIntField;
                break;
            default:
                inputField = LxTextField;
                break;
        }

        return inputField;
    }

    render() {
        const { classes, isOpen, closeDialog, title, properties } = this.props;

        return (
            <Dialog
                classes={{ paper: classes.dialog }}
                open={isOpen}
                transitionDuration={0}
                onMouseDown={this.handleMouseDown}
            >
                <DialogTitle className={classes.dialogTitle}>
                    {typeof title !== 'undefined' ? title : 'Label Properties'}
                    <Divider />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {properties.map((prop, index) => this.renderPropertyField(prop, index))}
                </DialogContent>
                <DialogActions className={classes.dialogButtons}>
                    <Button variant='contained' color='primary' onClick={closeDialog}>
                        {'Close'}
                    </Button>
                    <Button
                        onClick={this.handleConfirm()}
                        variant='contained'
                        color='primary'
                    >
                        {'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(PropertyEditor);