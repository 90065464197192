import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { pushToError } from '.../utils/apiHelper';

const styles = theme => {
    let isIE = window.IeVersion().IsIE;
    return ({
        imageLoading: {
            margin: 'auto'
        },
        imageWidth: {
            width: isIE ? '100%' : 'fill-available',
            height: 'auto'
        },
        imageHeight: {
            height: isIE ? '100%' : 'fill-available',
            width: 'auto'
        }
    });
};

class ImageLoader extends React.Component {
    constructor(props) {
        super(props);

        let fit = '';
        if (typeof props.fit === 'undefined' || props.fit === 'width') {
            fit = props.classes.imageWidth;
        } else if (props.fit === 'height') {
            fit = props.classes.imageHeight;
        } else {
            console.warn('Invalid "fit" option provided to ImageLoader, defaulting to width');
            fit = props.classes.imageWidth;
        }

        this.state = {
            isLoading: true,
            fit
        };
    }

    componentDidMount() {
        this.handleImagePromise(this.props.imagePromise);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fit !== this.props.fit) {
            let fit = '';

            if (typeof this.props.fit === 'undefined' || this.props.fit === 'width') {
                fit = this.props.classes.imageWidth;
            } else if (this.props.fit === 'height') {
                fit = this.props.classes.imageHeight;
            } else {
                console.warn('Invalid "fit" option provided to ImageLoader, defaulting to width');
                fit = this.props.classes.imageWidth;
            }

            this.setState({
                fit
            });
        }
    }

    handleImagePromise = (imagePromise) => {
        imagePromise.then(image => {
            this.setState({
                image: this.imageEncode(image),
                isLoading: false
            });
        }).catch(error => pushToError(error, this.props.match.params.mrn));
    }

    imageEncode = (arrayBuffer) => {
        let image = btoa(
            new Uint8Array(arrayBuffer)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return `data:image/jpeg;base64,${image}`;
    }

    render() {
        const { classes } = this.props;
        const { isLoading, image, fit } = this.state;

        if (isLoading) {
            return (<CircularProgress className={classes.imageLoading} size={60} thickness={7} />);
        }

        return <img className={fit} src={image} alt='...' />;
    }
}

export default withStyles(styles)(withRouter(ImageLoader));