import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from ".../assets/components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles({
    okButton: {
        width: '25%',
        margin: 'auto',
        marginBottom: '5px'
    }
});

export default function FormsIllegalDataDialog(props){
    const classes = useStyles();
    const { onClose, open, illegalFields } = props;

    return(
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Illegal Data</DialogTitle>
            <Typography>
                There are validation errors in the current workflow. You must resolve these before saving your changes.
            </Typography>
            <Divider/>
            <List>
                {illegalFields.map((illegalField, index) => (
                    <div key={index}>
                        <ListItem>
                            <Typography>
                                {illegalField.description}
                            </Typography>
                        </ListItem>
                        <Divider/>
                    </div>
                ))}
            </List>
            <Button variant='contained' color='primary' className={classes.okButton} onClick={onClose}>
                <Typography>
                    OK
                </Typography>
            </Button>
        </Dialog>
    );
}