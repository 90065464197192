import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import DeleteForever from '@material-ui/icons/DeleteForever';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import PropertyCog from '../PropertyCog.js';

const styles = theme => ({
    buttonColumn: {
        width: '40px',
        height: "24px",
        padding: "0px"
    },
    headerRow: {
        background: theme.palette.lxGreen.main
    },
    tableCell: {
        height: "24px",
        padding: "0px"
    },
    cogButton: {
        height: "24px",
        width: "24px",
        padding: "0px"
    },
    typography: {
        align: "center"
    },
    reorderButtonContainer: {
        flexDirection: 'column',
        height: '30px',
        width: '30px',
        margin: 'auto'
    },
    reorderButton: {
        height: '15px!important',
        minHeight: '15px!important',
        width: '30px!important',
        padding: '0px!important'
    },
    reorderButtonCell: {
        width: '40px',
        height: "24px",
        padding: "0px"
    },
    scrollableTableWrapper: {
        maxHeight: '250px',
        //this padding keeps a scrollbar from appearing when the internal content is sized the same as the wrapper (e.g. all the time)
        paddingBottom: '1px'
    },
    tableDivMaxWidth: {
        width: '100%'
    },
    addButton: {
        margin: '6px'
    }
});

class OneToManyEditor extends React.Component {
    handleAdd = () => {
        const { items, onChange, newItem } = this.props;
        let newItems = items.slice();
        newItems.push(newItem);

        if (typeof onChange === 'function') {
            onChange(newItems);
        }
    }

    handleDelete = (index) => () => {
        const { items, onChange } = this.props;
        let newItems = items.slice();
        newItems.splice(index, 1);

        if (typeof onChange === 'function') {
            onChange(newItems);
        }
    }

    handleItemPropertiesChanged = (index) => (newItem) => {
        const { items, onChange } = this.props;
        let newItems = items.slice();
        newItems.splice(index, 1, newItem);

        if (typeof onChange === 'function') {
            onChange(newItems);
        }
    }

    changeElementOrder = (index, newIndex) => () => {
        const { items, onChange } = this.props;

        if (index <= items.length - 1 && index >= 0
            && newIndex <= items.length - 1 && newIndex >= 0) {
            let newItems = items.slice();
            let targetItem = items[index];
            newItems.splice(index, 1);
            newItems.splice(newIndex, 0, targetItem);

            if (typeof onChange === 'function') {
                onChange(newItems);
            }
        }
    }

    renderReorderButtons = (index) => {
        const { items, classes } = this.props;

        return (
            <GridContainer className={classes.reorderButtonContainer}>
                <GridItem className={classes.reorderButton}>
                    <IconButton className={classes.reorderButton} onClick={this.changeElementOrder(index, index - 1)} disabled={index === 0}>
                        <KeyboardArrowUpIcon className={classes.reorderButton} />
                    </IconButton>
                </GridItem>
                <GridItem className={classes.reorderButton}>
                    <IconButton className={classes.reorderButton} onClick={this.changeElementOrder(index, index + 1)} disabled={index === items.length - 1}>
                        <KeyboardArrowDownIcon className={classes.reorderButton} />
                    </IconButton>
                </GridItem>
            </GridContainer>
        );
    }

    render() {
        const { classes, items, displayName, itemToDisplayString, determineEditorType } = this.props;

        return (
            <div className={`${classes.propertyFields} ${classes.tableDivMaxWidth}`}>
                <TableContainer className={classes.scrollableTableWrapper}>
                    <Table stickyHeader>
                        <TableHead className={classes.headerRow}>
                            <TableRow className={classes.headerRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography className={classes.typography}>
                                        {displayName}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography className={classes.typography}>
                                        Edit
                                </Typography>
                                </TableCell>
                                <TableCell className={classes.buttonColummn}>
                                    <Typography className={classes.typography} align={"center"}>
                                        Sort
                                </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography className={classes.typography} align={"center"}>
                                        Delete
                                </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => {
                                if (!item.reactArrayUniqueKey) {
                                    item.reactArrayUniqueKey = uuidv4();
                                }
                                return (
                                    <TableRow key={item.reactArrayUniqueKey}>
                                        <TableCell className={classes.tableCell}>
                                            {itemToDisplayString(item)}
                                        </TableCell>
                                        <TableCell className={classes.buttonColumn}>
                                            <PropertyCog
                                                itemSchema={item}
                                                determineEditorType={determineEditorType}
                                                onItemPropertiesChanged={this.handleItemPropertiesChanged(index)}
                                                cogButtonCss={classes.cogButton}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.reorderButtonCell}>
                                            {this.renderReorderButtons(index)}
                                        </TableCell>
                                        <TableCell className={classes.buttonColumn}>
                                            <Button
                                                className={classes.cogButton}
                                                onClick={this.handleDelete(index)}
                                            >
                                                <DeleteForever />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    className={classes.addButton}
                    onClick={this.handleAdd}
                    variant="outlined"
                >
                    Add New
                    <Add />
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(OneToManyEditor);