import React from 'react';
import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';

const styles = theme => ({
    card: {
        height: 'calc(100% + 40px)',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 40px)'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        padding: '0px 8px !important',
        height: '24px !important',
        display: 'flex'
    },
    editIcon: {
        flex: '0 0 auto'
    },
    table: {
        flex: '1 0 auto',
        height: '50px'
    }
});


class SelectionSetElementSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionSetElementRecord: null
        };
    }

    deleteSelectionSetElement = (SSSelectionSetID) => {
        const { onDeleteSelectionSetElement } = this.props;
        onDeleteSelectionSetElement(SSSelectionSetID);
    }

    selectSelectionSetElement = (selectionSetElement) => {
        const { onSelectionSetElementEdit } = this.props;
        onSelectionSetElementEdit(selectionSetElement);
    }

    handleChange = (selectionSetElement) => {
        const { onHandleChange } = this.props;
        onHandleChange(selectionSetElement);
    }   

    render() {
        const { classes, selectionSetElementList,isLoadingElements,onSelectionSetElementEditorOpen } = this.props;
        const { selectionSetElementRecord } = this.state;

        return (
            <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                    <LxSearchableTable
                    className={classes.table} 
                        items={selectionSetElementList !== null ? selectionSetElementList : []}
                        columns={[
                            {
                                title: 'ssSelectionSetID',
                                key: 'ssSelectionSetID',
                                show: false
                            },
                            {
                                title: 'Element Text',
                                key: 'elementText',
                                width: 300
                            },
                            {
                                title: 'Order',
                                key: 'elementOrder',
                                width: 150
                            },
                            {
                                id: 'edit',
                                width: 100,
                                title: 'Edit',
                                className: classes.buttonCell,
                                resizable: false,
                                key: (item) => (
                                    <ConfirmationButton
                                        onClick={() => this.selectSelectionSetElement(item)}
                                        className={classes.button}
                                    >
                                        <EditIcon className={classes.editIcon} />
                                    </ConfirmationButton>
                                )
                            },
                            {
                                id: 'delete',
                                width: 100,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Delete',
                                key: (item) => (
                                    <ConfirmationButton
                                        showConfirmation
                                        confirmationTitle='Permanently Delete selection set element?'
                                        confirmationText={[
                                            'Are you sure you want to remove this selection set element?',
                                            'You will not be able to get it back.'
                                        ]}
                                        confirmationConfirmText='Delete'
                                        onClick={() => this.deleteSelectionSetElement(item.ssSelectionSetID)}
                                    >
                                        <DeleteIcon />
                                    </ConfirmationButton>
                                )
                            }
                        ]}
                        searchKeys={[
                            'elementText',
                            'elementOrder'
                        ]}
                        selectedItem={selectionSetElementRecord}
                        isLoading={isLoadingElements}
                        title={'Available Selection Set Elements'}
                    />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onSelectionSetElementEditorOpen}
                        disabled={isLoadingElements}
                    >
                        {'Add New Selection Set Element'}
                    </Button>
                </CardFooter>
            </Card>
        );
    }
}
export default withStyles(styles)(SelectionSetElementSelector);
