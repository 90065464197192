import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const styles = theme => ({
    sectionContainer: {
        margin: theme.spacing(2),
        flex: '0 0 45%'
    },
    section: {
        minWidth: '300px',
        maxWidth: '500px',
        margin: 'auto'
    },
    list: {
        padding: '0px'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
});

const PhysicianSummaryMetric = (props) => (
    <div className={props.classes.sectionContainer}>
        <div className={props.classes.section}>
            <Typography gutterBottom variant='subtitle1' align='left'>
                {props.sectionName}
            </Typography>
            <Divider />
            <List className={props.classes.list}>
                {props.metrics.map((metric, index) => (
                    <ListItem dense divider button disableRipple key={index}>
                        <div className={props.classes.row}>
                            <Typography variant='body2' align='left' className={props.classes.secondaryMetricName}>
                                {metric.title}
                            </Typography>
                            <Typography variant='body2' align='right' className={props.classes.secondaryMetricValue}>
                                {metric.value}
                            </Typography>
                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    </div>
);

export default withStyles(styles)(PhysicianSummaryMetric);