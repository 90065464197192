import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import LxItemSelectionTables from '.../components/formComponents/LxItemSelectionTables';
import LxSelectField from '.../components/formComponents/LxSelectField';
import LxTextField from '.../components/formComponents/LxTextField';
import ExpressionEditor from './ExpressionEditor';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 45px)'
    },
    divider: {
        marginTop: '15px'
    },
    cardFooter: {
        display: 'flex',
        position: 'absolute',
        bottom: '0px',
        width: '100%'
    },
    mappingName: {
        flex: '20 0 auto',
        width: 'auto'
    },
    mappingResource: {
        flex: '5 0 auto',
        width: 'auto',
        margin: '0px 10px'
    },
    addMapping: {
        flex: '1 0 auto'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        padding: '0px 8px !important',
        height: '24px !important',
        display: 'flex'
    },
    expressionText: {
        flex: '1 1 auto',
        width: 'auto !important',
        textAlign: 'left !important',
        paddingRight: '12px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'none'
    },
    expressionIcon: {
        flex: '0 0 auto'
    }
});

const VALUE_EXPRESSION = "expression";
const MISSING_EXPRESSION = "missingExpression";

class MappingSelector extends React.Component {
    state = {
        newMappingCount: 0,
        newMappingName: '',
        newMappingSourceType: '',
        newMappingSource: '',
        validNewMappingName: true,
        expressionEditorMapping: null,
        expressionEditorOpen: false,
        expressionType: null,
        expressionText: ""
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.newMappingName !== this.state.newMappingName) {
            //Only re-render when validation state changes, keeps table from re-rendering
            if (this.isValidMappingName(nextState.newMappingName) !== this.isValidMappingName(this.state.newMappingName)) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }

    onMappingNameChange = (newMappingName) => {
        this.setState({
            newMappingName
        });
    }

    handleTablesStateChange = (stateChanges) => {
        const { onFieldMappingChange } = this.props;
        const { selectedItems } = stateChanges;

        selectedItems.forEach(item => {
            if (typeof item.expression === 'undefined') {
                item.expression = '';
            }
            if (typeof item.missingExpression === 'undefined') {
                item.missingExpression = '';
            }
        });

        if (typeof onFieldMappingChange === 'function') {
            onFieldMappingChange(selectedItems);
        }
    }

    handleEditExpression = (expressionEditorMapping, expressionType) => (event) => {
        event.preventDefault();

        this.setState({
            expressionEditorMapping,
            expressionEditorOpen: true,
            expressionType
        });
    }

    closeExpressionEditor = () => {
        this.setState({
            expressionEditorMapping: null,
            expressionEditorOpen: false,
            expressionType: null
        });
    }

    updateMappingExpression = (expression) => {
        const { expressionEditorMapping, expressionType } = this.state;
        const { selectedField, onFieldMappingChange } = this.props;
        const { emrMappings } = selectedField;

        let newMappings = [...emrMappings];
        let updatedMapping = {
            ...expressionEditorMapping,
            [expressionType]: expression
        };

        let oldMappingIndex = newMappings.map(mapping => mapping.name).indexOf(expressionEditorMapping.name);

        newMappings.splice(oldMappingIndex, 1, updatedMapping);

        if (typeof onFieldMappingChange === 'function') {
            onFieldMappingChange(newMappings);
        }

        this.closeExpressionEditor();
    }

    onMappingSourceTypeChange = (newSourceType) => {
        this.setState({
            newMappingSourceType: newSourceType
        });
    }

    onMappingSourceChange = (newSource) => {
        this.setState({
            newMappingSource: newSource
        });
    }

    createMapping = () => {
        const { createMapping } = this.props;
        const { newMappingCount, newMappingName, newMappingSource, newMappingSourceType } = this.state;

        if (this.validateNewMappingValues() && typeof createMapping === 'function') {
            createMapping(newMappingName, newMappingSourceType, newMappingSource);
            this.setState({
                newMappingCount: newMappingCount + 1,
                newMappingName: '',
                newMappingSourceType: '',
                newMappingSource: ''
            });
        }
    }

    isValidMappingName = (mappingName) => {
        const { emrMappingPool } = this.props;
        let validMappingName = true;

        if (Array.isArray(emrMappingPool)) {
            emrMappingPool.forEach(mapping => {
                if (mapping.name === mappingName) {
                    validMappingName = false;
                }
            });
        }

        return validMappingName;
    }

    validateNewMappingValues = () => {
        const { newMappingName, newMappingSource, newMappingSourceType} = this.state;

        let valid = false;

        if ( this.isValidMappingName(newMappingName) && (newMappingSourceType ?? '') !== '' && (newMappingSource ?? '') !== '') {
            valid = true;
        }
        return valid;
    }

    render() {
        const {
            classes,
            selectedField,
            selectedFieldsLoading,
            emrMappingPool,
            emrMappingPoolLoading,
            selectedMapping,
            onMappingSelect,
            deleteMapping,
            sourceTypes,
            sources
        } = this.props;

        const {
            newMappingCount,
            newMappingSourceType,
            newMappingSource,
            newMappingName,
            expressionEditorMapping,
            expressionType,
            expressionEditorOpen
        } = this.state;

        let sourceOptions = sources?.[newMappingSourceType.toLowerCase()] ?? [];
        let validNewMappingName = this.isValidMappingName(newMappingName);
        let newMappingValid = this.validateNewMappingValues();
        let expressionEditorTitle;

        switch (expressionType) {
            case VALUE_EXPRESSION:
                expressionEditorTitle = 'Value Expression Editor';
                break;
            case MISSING_EXPRESSION:
                expressionEditorTitle = 'Missing Expression Editor';
                break;
            default:
                expressionEditorTitle = 'Expression Editor';
                break;
        }

        return (
            <React.Fragment>
                <Card className={classes.card}>
                    <CardBody className={classes.cardBody}>
                        <LxItemSelectionTables
                            gridDirection='column'
                            selectedItem={selectedMapping}
                            selectedItems={selectedField !== null ? selectedField.emrMappings : []}
                            selectedItemsLoading={selectedFieldsLoading}
                            selectedRowClick={onMappingSelect}
                            selectedColumns={[
                                {
                                    title: 'Mapping Name',
                                    key: 'name',
                                    width: 250
                                },
                                {
                                    title: 'Source Type',
                                    id: 'sourceType',
                                    key: (item) => {
                                        return item.emrSource.sourceType;
                                    }
                                },
                                {
                                    title: 'Source',
                                    id: 'source',
                                    key: (item) => {
                                        return item.emrSource.source;
                                    }
                                },
                                {
                                    id: 'expression',
                                    width: 150,
                                    title: 'Value Expression',
                                    className: classes.buttonCell,
                                    key: (item) => (
                                        <ConfirmationButton
                                            onClick={this.handleEditExpression(item, VALUE_EXPRESSION)}
                                            className={classes.button}
                                            error={
                                                typeof item.expression === 'undefined'
                                                || item.expression === ''
                                                || item.expression === null
                                            }
                                        >
                                            <div className={classes.expressionText}>
                                                {`${item.expression}`}
                                            </div>
                                            <EditIcon className={classes.expressionIcon} />
                                        </ConfirmationButton>
                                    )
                                },
                                {
                                    id: 'missing expression',
                                    width: 150,
                                    title: 'Missing Expression',
                                    className: classes.buttonCell,
                                    key: (item) => (
                                        <ConfirmationButton
                                            onClick={this.handleEditExpression(item, MISSING_EXPRESSION)}
                                            className={classes.button}
                                        >
                                            <div className={classes.expressionText}>
                                                {`${item.missingExpression}`}
                                            </div>
                                            <EditIcon className={classes.expressionIcon} />
                                        </ConfirmationButton>
                                    )
                                }
                            ]}
                            selectedSearchKeys={[
                                'name',
                                'sourceType',
                                'source',
                                'expression',
                                'missingExpression'
                            ]}
                            selectedTitle='Applied Mappings'
                            poolItems={emrMappingPool !== null ? emrMappingPool : []}
                            poolRowClick={onMappingSelect}
                            poolItemsLoading={emrMappingPoolLoading}
                            poolColumns={[
                                {
                                    title: 'Mapping Name',
                                    key: 'name'
                                },
                                {
                                    title: 'Source Type',
                                    id: 'sourceType',
                                    key: (item) => {
                                        return item.emrSource.sourceType;
                                    },
                                    width: 115
                                },
                                {
                                    title: 'Source',
                                    id: 'source',
                                    key: (item) => {
                                        return item.emrSource.source;
                                    },
                                    width: 165
                                },
                                {
                                    id: 'delete',
                                    width: 75,
                                    resizable: false,
                                    className: classes.buttonCell,
                                    title: 'Delete',
                                    key: (item) => (
                                        <ConfirmationButton
                                            className={classes.button}
                                            showConfirmation
                                            confirmationTitle='Permanently Delete Mapping?'
                                            confirmationText={[
                                                'Are you sure you want to remove this mapping?',
                                                'You will not be able to get it back.'
                                            ]}
                                            confirmationConfirmText='Delete'
                                            onClick={() => deleteMapping(item.name)}
                                        >
                                            <DeleteIcon />
                                        </ConfirmationButton>
                                    )
                                }
                            ]}
                            poolSearchKeys={[
                                'name',
                                'sourceType',
                                'source'
                            ]}
                            poolTitle='Available Mappings'
                            itemToString={item => item.name}
                            onStateChange={this.handleTablesStateChange}
                        />
                        <Divider className={classes.divider} />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                        <LxTextField
                            title={'Mapping Name'}
                            key={`MappingName-${newMappingCount}`}
                            className={classes.mappingName}
                            placeholder='New Mapping Name...'
                            onChange={this.onMappingNameChange}
                            error={!validNewMappingName}
                            helperText={!validNewMappingName ?
                                'That mapping name is already in use'
                                :
                                ''
                            }
                        />
                        <LxSelectField
                            title={'Source Type'}
                            key={`MappingSourceType-${newMappingCount}`}
                            className={classes.mappingResource}
                            value={newMappingSourceType}
                            nullable={false}
                            onChange={this.onMappingSourceTypeChange}
                            options={sourceTypes}
                        />
                        <LxSelectField
                            title={'Source'}
                            key={`MappingSource-${newMappingCount}`}
                            className={classes.mappingResource}
                            value={newMappingSource}
                            nullable={false}
                            onChange={this.onMappingSourceChange}
                            options={sourceOptions}
                            disabled={(newMappingSourceType ?? '') === ''}
                        />
                        <Button
                            className={classes.addMapping}
                            variant='outlined'
                            color='primary'
                            onClick={this.createMapping}
                            disabled={!newMappingValid}
                        >
                            {'Add New Mapping'}
                        </Button>
                    </CardFooter>
                </Card>
                <ExpressionEditor
                    isOpen={expressionEditorOpen}
                    closeDialog={this.closeExpressionEditor}
                    title={expressionEditorTitle}
                    expression={expressionEditorMapping !== null ?
                        expressionEditorMapping[expressionType]
                        :
                        ''
                    }
                    onConfirm={this.updateMappingExpression}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(MappingSelector);