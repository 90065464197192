import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from '../PropertyEditor';
import QueryParametersOneToManyEditor from './QueryParametersOneToManyEditor';
import SourceColumnOneToManyEditor from './SourceColumnOneToManyEditor';
import SqlQueryEditor from './SqlQueryEditor';
import WhereCriteriaOneToManyEditor from './WhereCriteriaOneToManyEditor';

const styles = theme => ({

});

const TABLE_FIELD_STRING = 'TableField';
const MULTI_COLUMN_STRING = 'MultiColumn';
const MULTI_COLUMN_ELEMENT_STRING = 'MultiColumnCombo';

class GridColumnPropertyEditor extends React.Component {
    state = {
        columnType: this.props.itemSchema.$lumedxType
    }

    handlePropertyBinding = (name, value) => {
        if (name === '$lumedxType') {
            this.setState({
                columnType: value
            });
        }
        if (name === "sqlQuery") {
            this.setState({
                sqlQueryEnabled: value.useSql
            });
        }
    }

    handleConfirm = (newColumnSchema) => {
        if (this.state.columnType === MULTI_COLUMN_STRING) {
            newColumnSchema.$lumedxType = MULTI_COLUMN_ELEMENT_STRING;
        }
    }

    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;
        const { columnType } = this.state;

        let isMultiColumn = columnType === MULTI_COLUMN_STRING;
        
        let propertyArray = isMultiColumn ?
            //Properties for the MultiColumnCombo column editor
            [
                {
                    name: "$lumedxType",
                    CustomRender: ConvertTypeButton
                },
                {
                    name: "tableName",
                    label: "Table Name",
                    type: "text"
                },
                {
                    name: "fieldName",
                    label: "Field Name",
                    type: "text"
                },
                {
                    name: "displayName",
                    label: "Display Name",
                    type: "text"
                },
                {
                    name: "mainSourceTableName",
                    label: "Source Table Name",
                    type: "text",
                    hidden: this.state.sqlQueryEnabled,
                    description: "This field refers to the table that data will be pulled from to populate the dropdown. The specific columns are configured in the SourceColumns collection."
                },
                {
                    name: "sourceColumns",
                    CustomRender: SourceColumnOneToManyEditor
                },
                {
                    name: "whereCriteria",
                    hidden: this.state.sqlQueryEnabled,
                    CustomRender: WhereCriteriaOneToManyEditor
                },
                {
                    name: "sqlQuery",
                    label: "Use Sql Query",
                    CustomRender: SqlQueryEditor
                },
                {
                    name: "parameters",
                    hidden: !this.state.sqlQueryEnabled,
                    CustomRender: QueryParametersOneToManyEditor
                }
            ]
            : 
            //Properties for the TableField column editor
            [
                {
                    name: "$lumedxType",
                    CustomRender: ConvertTypeButton
                },
                {
                    name: "tableName",
                    label: "Table Name",
                    type: "text"
                },
                {
                    name: "fieldName",
                    label: "Field Name",
                    type: "text"
                },
                {
                    name: "displayName",
                    label: "Column Display Name (optional)",
                    type: "text"
                }
            ];

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                handleUpdatedPropertyBindings={this.handlePropertyBinding}
                title="Grid Column Property Editor"
                properties={propertyArray}
            />
        );
    }
}

function ConvertTypeButton(props) {
    const { value, onChange } = props;
    const isMultiColumn = value === MULTI_COLUMN_STRING;
    const isTableField = value === TABLE_FIELD_STRING;
    const buttonText = isMultiColumn ? 'Convert to Table Field Reference' : 'Convert to Multi Column Combo';

    let handleValueChange = () => {
        if (isMultiColumn) {
            onChange(TABLE_FIELD_STRING);
        } else if (isTableField) {
            onChange(MULTI_COLUMN_STRING);
        }
    };

    return (
        <Button onClick={handleValueChange}>
            {buttonText}
        </Button>
    );
}

export default withStyles(styles)(GridColumnPropertyEditor);