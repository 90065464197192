const RecordReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_RECORDINFO':
            return action.recordInfo;
        case 'CLEAR_RECORDINFO':
            return null;
        default:
            return state;
    }
};

export default RecordReducer;