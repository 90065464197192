import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import GridColumnPropertyEditor from './GridColumnPropertyEditor.js';
import OneToManyEditor from './OneToManyEditor';

const styles = theme => ({

});

class GridColumnOneToManyEditor extends React.Component {
    createNewSourceColumn = () => {
        return {
            $lumedxType: 'TableField'
        };
    }

    determineEditorType = () => {return GridColumnPropertyEditor;}

    itemToDisplayString = (item) => {
        if (item.displayName){
            return item.displayName;
        } else if (item.fieldName) {
            return item.fieldName;
        } else{
            return "New Column"; 
        }
    }

    render() {
        const { value, onChange, classes } = this.props;

        return (
            <OneToManyEditor
                classes={classes}
                items={value ? value : []}
                displayName={"Columns"}
                itemToDisplayString={this.itemToDisplayString}
                onChange={onChange}
                newItem={this.createNewSourceColumn()}
                determineEditorType={this.determineEditorType}
            />
        );
    }
}

export default withStyles(styles)(GridColumnOneToManyEditor);