import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Link from '@material-ui/icons/Link';
import { MappedMissingIcon, ConflictingConfirmedIcon, NonConflictingConfirmedIcon, ConflictingUnconfirmedIcon, NonConflictingUnconfirmedIcon, MappingErrorIcon } from './EmrIcons/Icons';
import EmrValueList from './EmrValueList';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    iconButton: {
        padding: "0px !important",
        height: '18px !important',
        borderRadius: "4px",
        minWidth: "24px"
    },
    map: {
        height: '16px',
        width: '22px',
        float: "left",
        color: theme.palette.text.secondary
    },
    container: {
        background: theme.palette.background.selected,
        height: "18px",
        position: 'relative',
        '& .icon': {
            height: '16px',
            width: '22px'
        },
        "& .agreeingButton": {
            backgroundColor: theme.palette.lxGreen.light,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.secondary
            }
        },
        "& .agreeingButton:hover": {
            backgroundColor: theme.palette.lxGreen.dark,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.contrast
            }
        },
        "& .mismatchButton": {
            backgroundColor: theme.palette.warning.light,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.secondary
            }
        },
        "& .mismatchButton:hover": {
            backgroundColor: theme.palette.warning.dark,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.contrast
            }
        }
    },
    popper: {
        zIndex: "500000"
    }
});

class EmrValueSelector extends React.Component {
    constructor(props) {
        super(props);
        this.selectorOpenFromPanel = true
        this.state = {
            valueSelectorOpen: false,
            valueSelectorOpenFromPanel: true,
            anchorEl: null
        };
    }

    isEpicDataConflicting = () => {
        let valueOptions = this.newValueOptions();
        let conflicting = false;
        if (Array.isArray(valueOptions)) {
            if (valueOptions.length > 0) {
                let initialValue = valueOptions[0].value;
                valueOptions.forEach(option => {
                    if (initialValue !== option.value) {
                        conflicting = true;
                    }
                });
            }
            else {
                conflicting = false;
            }
        }
        return conflicting;
    }

    isApolloDataConflicting = (valueFromEpicWindow) => {
        const { value } = this.props;
        let tempValue;
        if (valueFromEpicWindow !== null) {
            tempValue = valueFromEpicWindow;
        }
        else {
            tempValue = value
        }
        let valueOptions = this.newValueOptions();
        let conflicting = false;
        if (Array.isArray(valueOptions)) {
            if (!this.isValueNothing() && valueOptions.length > 0) {
                valueOptions.forEach(option => {
                    let apolloValue;
                    if (typeof option.value === 'number') {
                        apolloValue = Number(tempValue);
                    }
                    else {
                        apolloValue = value;
                    }
                    if (apolloValue !== option.value) {
                        conflicting = true;
                    }
                });
            }
            else {
                conflicting = false;
            }
        }
        return conflicting;
    }

    setAnchorRef = (anchorElement) => {
        this.setState({
            anchorEl: anchorElement
        });
    }

    openValueSelector = () => {
        this.setState({
            valueSelectorOpen: true
        });
        this.selectorOpenFromPanel = true;
        window.addEventListener('scroll', this.closeValueSelector);
    }

    closeValueSelector = () => {
        this.setState({
            valueSelectorOpen: false
        });
        this.selectorOpenFromPanel = true;
        window.removeEventListener('scroll', this.closeValueSelector);
    }

    closeValueSelectorFromPanel = () => {
        //Here setting the dummy state to execute the redner again. This was needed when the EMR value selector popup is open from the FHIR panel.
        this.selectorOpenFromPanel = false;
        this.setState({
            valueSelectorOpenFromPanel: false,
            valueSelectorOpen: false
        });
        window.removeEventListener('scroll', this.closeValueSelectorFromPanel);
        this.selectorOpenFromPanel = true;
    }

    handleValueSelected = (value) => {
        const { onValueSelect } = this.props;

        this.closeValueSelector();
        this.closeValueSelectorFromPanel();
        onValueSelect(value);

        let isConflicting = this.isEpicDataConflicting() || this.isApolloDataConflicting(value);
        this.updateMappingStatus(isConflicting, 'Confirmed', 'Post-Initialization');
    }

    updateMappingStatus = (isConflicting, status, remark) => {
        const { metaDataKey, onUpdateMappingStatus } = this.props;
        onUpdateMappingStatus(metaDataKey, isConflicting, status, remark)
    }

    isValueNothing = () => {
        const { value } = this.props;
        if ((value === undefined || value === null || value === '')) {
            return true;
        }
        return false;
    }

    isDefaultValueNothing = () => {
        let fieldElement = this.getElementFromMappingElements();
        if (fieldElement !== undefined && (fieldElement.remark === "Initialization" || fieldElement.remark === "Post-Initialization")) {
            if ((fieldElement.elementDefaultValue === undefined || fieldElement.elementDefaultValue === null || fieldElement.elementDefaultValue === '')) {
                return true;
            }
        }
        return false;
    }

    isNoEmrOptionsForEveryElement = (newMappingElementsStatus) => {
        let result = newMappingElementsStatus.every(element => {
            if (element.emrOptions.length === 0) {
                return true;
            }
        });
        return result;
    };

    renderMap = (hasMappings, classes) => {
        const { mappingElementsStatus } = this.props;
        if (hasMappings) {
            let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
            let isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
            let fieldElement = this.getElementFromMappingElements();
            if (fieldElement !== undefined && fieldElement.mappedStatus === "Mapping-Error" && !isNoEmrOptionsForAllelements) {
                return (
                    <Tooltip title={fieldElement.mappingErrorDesc}>
                        <Button className={classes.iconButton} tabIndex="-1">
                            <MappingErrorIcon />
                        </Button>
                    </Tooltip>
                );
            } else {
                return (
                    <Button className={classes.iconButton} tabIndex="-1">
                        <Link className={classes.map} />
                    </Button>
                );
            }
        }
    };

    getApolloValueOption = (elementDefaultValue) => {
        let valueOption = {
            value: elementDefaultValue,
            mapping: {
                emrSource: {
                    sourceType: 'Database',
                    source: 'Apollo Database',
                    sourceConfig: ''
                },
                expression: '',
                mappingElements: [],
                missingExpression: '',
                name: ''
            }
        };
        return valueOption;
    }

    renderIcon = (valueOptions, classes) => {
        const { value, mappingElementsStatus, hasMappings } = this.props;

        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        if (newMappingElementsStatus.length === 0) {
            if (hasMappings) {
                return (
                    <Button className={classes.iconButton} tabIndex="-1">
                        <Link className={classes.map} />
                    </Button>
                );
            }
        } else {
            if (valueOptions && valueOptions.length > 0) {
                let fieldElement = this.getElementFromMappingElements();
                if (fieldElement !== undefined && fieldElement.remark === "Initialization") {
                    if ((value === fieldElement.elementDefaultValue)) {
                        if (fieldElement.conflictStatus === "Nonconflicting data" && fieldElement.status === "Confirmed") {
                            return (
                                <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                                    <NonConflictingConfirmedIcon />
                                </Button>
                            );
                        } else if (fieldElement.conflictStatus === "Conflicting data" && fieldElement.status === "Confirmed") {
                            return (
                                <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                                    <ConflictingConfirmedIcon />
                                </Button>
                            );
                        } else if (fieldElement.conflictStatus === "Nonconflicting data" && fieldElement.status === "Unconfirmed") {
                            return (
                                <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                                    <NonConflictingUnconfirmedIcon />
                                </Button>
                            );
                        } else if (fieldElement.conflictStatus === "Conflicting data" && fieldElement.status === "Unconfirmed") {
                            return (
                                <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                                    <ConflictingUnconfirmedIcon />
                                </Button>
                            );
                        }
                    }
                    else {
                        return this.changeEpicIndicatorsOnUserClick(classes);
                    }
                }
                else {
                    return this.changeEpicIndicatorsOnUserClick(classes);
                }
            }
        }
    };

    changeEpicIndicatorsOnUserClick = (classes) => {
        let isConflicting = this.isEpicDataConflicting() || this.isApolloDataConflicting(null);
        if (this.isDefaultValueNothing()) {
            if (this.isValueNothing()) {
                this.updateMappingStatus(isConflicting, 'Unconfirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <ConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <NonConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
            } else {
                this.updateMappingStatus(isConflicting, 'Confirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <ConflictingConfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <NonConflictingConfirmedIcon />
                        </Button>
                    );
                }
            }
        } else {
            if (this.isValueNothing()) {
                this.updateMappingStatus(isConflicting, 'Unconfirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <ConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <NonConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
            } else {
                this.updateMappingStatus(isConflicting, 'Confirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <ConflictingConfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1">
                            <NonConflictingConfirmedIcon />
                        </Button>
                    );
                }
            }
        }
    }

    renderMappedMissing = (hasMappings, valueOptions, classes) => {
        if (hasMappings) {
            if (valueOptions && valueOptions.length === 0) {
                return (
                    <Button className={classes.iconButton} tabIndex="-1">
                        <MappedMissingIcon />
                    </Button>
                );
            }
        }
    };


    newValueOptions = () => {
        const { valueOptions, mappingElementsStatus, metaDataKey } = this.props;

        let valOptions = [];
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        if (newMappingElementsStatus.length > 0) {
            let fieldElement = newMappingElementsStatus.find(elementStatus => elementStatus.element === metaDataKey);
            let emrOptions = Array.isArray(fieldElement.emrOptions) ? [...fieldElement.emrOptions] : [];
            if (emrOptions.length > 0) {
                emrOptions.forEach(option => {
                    let valueOption = {
                        value: option.value,
                        mapping: {
                            emrSource: {
                                sourceType: option.sourceType,
                                source: option.source,
                                sourceConfig: ''
                            },
                            expression: '',
                            mappingElements: option.emrOptionElements,
                            missingExpression: '',
                            name: option.mapping
                        }
                    };
                    valOptions.push(valueOption);
                });

                if (fieldElement.apolloOption !== '') {
                    let apolloOption = this.getApolloValueOption(fieldElement.elementDefaultValue);
                    valOptions.push(apolloOption);
                }
            }
            else {
                try {
                    valOptions = [...valueOptions];
                    if (valueOptions && valueOptions.length > 0) {
                        let fieldElement = this.getElementFromMappingElements();
                        if (fieldElement !== undefined && fieldElement.apolloOption !== '') {
                            let apolloOption = this.getApolloValueOption(fieldElement.elementDefaultValue);
                            valOptions.push(apolloOption);
                        }
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }
        }

        return valOptions;
    }

    getElementFromMappingElements = () => {
        const { mappingElementsStatus, metaDataKey } = this.props;
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let fieldElement = newMappingElementsStatus.find(elementStatus => elementStatus.element === metaDataKey);
        return fieldElement;
    }

    render() {
        const { className, classes, hasMappings, valueOptions, fhirPopupSelector } = this.props;
        const { valueSelectorOpen, anchorEl } = this.state;
        let valOptions = this.newValueOptions();

        return (
            <React.Fragment>
                <ButtonGroup variant="outlined" className={`${classes.container} ${className}`}>
                    {hasMappings && (valueOptions && valueOptions.length > 0) ?
                        this.renderIcon(valueOptions, classes) :
                        this.renderMap(hasMappings, classes)}
                </ButtonGroup>
                <Popper
                    open={valueSelectorOpen}
                    anchorEl={anchorEl}
                    placement="top"
                    className={`${classes.popper} nonDraggableSection`}
                >
                    <ClickAwayListener onClickAway={this.closeValueSelectorFromPanel}>
                        <Paper>
                            <EmrValueList options={valOptions} onValueSelect={this.handleValueSelected} />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EmrValueSelector);