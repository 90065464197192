import CircularProgress from "@material-ui/core/CircularProgress";
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose} from "redux";

import FormsContainerSignalR from ".../components/features/forms/FormsContainerSignalR";
import StsSurgicalRiskContainerSignalR
    from ".../components/features/risk/webre/STSSurgicalRisk/StsSurgicalRiskContainerSignalR";
import {ALLOW_ILLEGAL_DATA, USE_MILITARY_TIME} from ".../components/settings/SettingKeys";
import {
    DataTypeStrings,
    SettingKeyTypes,
    SettingRequestIdentifiers
} from ".../components/settings/SettingRequestIdentifiers";
import {getSettingsPromise} from ".../components/settings/SettingsAccessor";
import { formApi, pushToError } from '.../utils/apiHelper';

function FormsRoutesContainer(props){
    const {patientInfo, match, recordInfo, triggerScroll} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [fullFormSchema, setFullFormSchema] = useState(null);
    const [settings, setSettings] = useState(null);

    useEffect( () => {
        let settingRequest = new SettingRequestIdentifiers();
        settingRequest.addSettingToRequest(
            USE_MILITARY_TIME,
            SettingKeyTypes.application,
            DataTypeStrings.boolean,
            true,
            true
        );
        settingRequest.addSettingToRequest(
            ALLOW_ILLEGAL_DATA,
            SettingKeyTypes.application,
            DataTypeStrings.boolean,
            false,
            true
        );
        getSettingsPromise(settingRequest.settingGroups).then((result) => {
                setSettings(result);
            }
        );
    },[]);

    useEffect(() => {
        if(patientInfo){
            formApi.getFormSchema(match.params.formName).then(fullFormSchema => {
                setFullFormSchema(fullFormSchema);
                setIsLoading(false);
            }).catch(error => pushToError(error, patientInfo.patientId));
        }
    }, [match.params.formName, patientInfo]);

    let isRiskForm = match.params.formName === "STSRiskForm2.9" || match.params.formName === "STSRiskForm4.20";
    return(
        isLoading
            ? <CircularProgress size={60} thickness={7} />
            : isRiskForm
            ? <StsSurgicalRiskContainerSignalR patientId={patientInfo.patientId} patientInfo={patientInfo} formName={match.params.formName} recordId={recordInfo.recordId} formSchema={fullFormSchema?.schema} formMetaData={fullFormSchema?.metaData} sectionList={fullFormSchema?.sectionList} settings={settings} triggerScroll={triggerScroll}/>
            : <FormsContainerSignalR patientId={patientInfo.patientId} patientInfo={patientInfo} formName={match.params.formName} recordId={recordInfo.recordId} formSchema={fullFormSchema?.schema} formMetaData={fullFormSchema?.metaData} sectionList={fullFormSchema?.sectionList} settings={settings} triggerScroll={triggerScroll}/>
    );
}

export default compose(
    connect((state) => ({ patientInfo: state.demographics, recordInfo: state.recordInfo })),
    withRouter
)(FormsRoutesContainer);