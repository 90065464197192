import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';

const styles = theme => ({
    buttonGroup: {
        width: '100%'
    },
    actionButton: {
        width: '100%'
    },
    popper: {
        zIndex: 1000,
        width: 'inherit'
    },
    menuButton: {
        padding: 'inherit !important'
    },
    menuItem: {
        minHeight: 'auto'
    }
});

class SplitButton extends React.Component {
    state = {
        open: false,
        selectedIndex: 0
    }

    anchorRef = React.createRef()

    setOpen = (open) => {
        this.setState({
            open
        });
    }

    setSelectedIndex = (selectedIndex) => {
        this.setState({
            selectedIndex
        });
    }

    handleMenuItemClick = (event, index) => {
        this.setSelectedIndex(index);
        this.setOpen(false);
    }

    handleToggle = () => {
        const { open } = this.state;
        this.setOpen(!open);
    }

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setOpen(false);
    }

    render() {
        const { open, selectedIndex } = this.state;
        const { classes, options, variant, disabled, error, className } = this.props;

        return (
            <div {...(className ? { className } : {})}>
                <ButtonGroup
                    className={classes.buttonGroup}
                    variant={variant}
                    color="primary"
                    ref={this.anchorRef}
                >
                    <ConfirmationButton
                        className={classes.actionButton}
                        error={error || options[selectedIndex].error}
                        disabled={disabled || options[selectedIndex].disabled}
                        onClick={options[selectedIndex].callback}
                        showConfirmation={options[selectedIndex].showConfirmation}
                        confirmationTitle={options[selectedIndex].confirmationTitle}
                        confirmationText={options[selectedIndex].confirmationText}
                        confirmationStatus={options[selectedIndex].confirmationStatus}
                        confirmationConfirmText={options[selectedIndex].confirmationConfirmText}
                    >
                        {options[selectedIndex].label}
                    </ConfirmationButton>
                    <ConfirmationButton
                        className={classes.menuButton}
                        color="primary"
                        error={error}
                        disabled={disabled}
                        variant={variant}
                        size="small"
                        onClick={this.handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </ConfirmationButton>
                </ButtonGroup>
                <Popper
                    className={classes.popper}
                    open={open}
                    anchorEl={this.anchorRef.current}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                key={typeof option.label === 'string' ?
                                                    option.label
                                                    :
                                                    index
                                                }
                                                selected={index === selectedIndex}
                                                onClick={event => this.handleMenuItemClick(event, index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}


SplitButton.propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node,
        callback: PropTypes.func,
        showConfirmation: PropTypes.bool,
        confirmationTitle: PropTypes.string,
        confirmationText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
        ]),
        confirmationStatus: PropTypes.bool,
        confirmationConfirmText: PropTypes.node
    })).isRequired,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained'])
};

SplitButton.defaultProps = {
    variant: 'text'
};

export default withStyles(styles)(SplitButton);