import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import dotnetify from "dotnetify";
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Prompt,
    withRouter
} from "react-router-dom";
import { compose } from "redux";

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import StaffContainer from '.../components/features/staff/StaffContainer';
import { ALLOW_ILLEGAL_DATA } from '.../components/settings/SettingKeys';
import userManager from ".../utils/userManager";
import ReportContainer from '../reports/ReportContainer';
import ContextManager from "./ContextManager";
import DynamicForm from './DynamicForm';
import DynamicFormSection from './DynamicFormSection';
import FormControlBar from './FormControlBar';
import FormsIllegalDataDialog from "./FormsIllegalDataDialog";
import FormViewOnlyBar from './FormViewOnlyBar';
import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px`,
        width: '97%',
        height: `calc(100vh - 100px)`,
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    },
    cardBody: {
        padding: '150px'
    },
    EpicConfirmationDialogContent: {
        textAlign: 'left'
    },
    progressBar: {
        marginTop: `10px`,
        width: '100%'
    },
    backendActionBackdrop: {
        zIndex: '10000'
    },
    formName: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        paddingLeft: "30px",
        paddingTop: "5px"
    },
    patientInfo: {
        fontSize: '13px',
        fontWeight: 'normal',
        paddingLeft: "50px",
        paddingTop: "10px"
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    dialogPaper: {
        width: '100%'
    }
});

class FormsContainerSignalR extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            copilotFormData: {},
            progress: 0,
            illegalFieldsErrorMessageOpen: false,
            illegalFields: [],
            savedSnackbarOpen: false,
            unsavedChanges: false,
            lockStateTarget: null,
            moduleSecurity: {},
            isLockable: false,
            newEvent: this.props.match.params.recordId === '0',
            formSecurity: {},
            enableFormEdit: false,
            openStaffDialog: false,
            openPopupDialog: false,
            openUnconfirmedEpicDataPopup: false,
            popupSectionSchema: null,
            commandButtonSchema: null,
            openReportDialog: false,
            mappingElementsStatus: [],
            progressDone: false,
            setDefaultValueToAllFields: false
        };

        userManager.getUser().then(async (user) => {
            let headers = {
                Authorization: 'Bearer ' + user.access_token
            };
            // Connect this component to the back-end view model.
            this.vm = dotnetify.react.connect("FormVM", this, {
                headers: headers,
                vmArg: {
                    SsPatientId: this.props.patientInfo.ssPatientId,
                    EmrPatients: this.props.patientInfo.emrPatients,
                    FormName: this.props.formName,
                    EventId: this.props.recordId
                }
            });
            // Set up function to dispatch state to the back-end.
            this.dispatchState = state => this.vm.$dispatch(state);

            userManager.events.addUserLoaded(() => {
                userManager.getUser().then(async (user) => {
                    this.vm.$dispatch({
                        $headers: {
                            Authorization: 'Bearer ' + user.access_token
                        }
                    });
                });
            });
        });
    }

    componentWillUnmount() {
        userManager.events.removeUserLoaded();
        this.vm.$destroy();
    }

    updateEvent = (dataPoints) => {
        const { unsavedChanges } = this.state;

        this.dispatchState({
            UpdateEvent: {
                DataPoints: dataPoints
            }
        });
        if (!unsavedChanges) {
            this.setState({
                unsavedChanges: true
            });
        }
    };

    handleChange = (dataPoints) => {
        this.updateEvent(dataPoints);
    };

    handleBlur = (dataPoints) => {
        const { openStaffDialog } = this.state;
        if (openStaffDialog === true) {
            this.setState({
                openStaffDialog: false
            });
        }
        this.updateEvent(dataPoints);
    };

    handleRecordAdd = (tableName, parentKeyValue) => {
        const { unsavedChanges } = this.state;

        this.dispatchState({
            AddRecord: {
                TableName: tableName,
                ParentKeyValue: parentKeyValue
            }
        });
        if (!unsavedChanges) {
            this.setState({
                unsavedChanges: true
            });
        }
    }

    executeCommandButton = (commandButton) => {
        const { unsavedChanges } = this.state;
        const { formSchema } = this.props;

        if (commandButton.lookupName !== undefined && commandButton.lookupName !== '' && commandButton.lookupName !== null) {
            if (commandButton.lookupName === 'Staff') {
                this.setState({
                    openStaffDialog: true,
                    commandButtonSchema: commandButton
                });
            }
            else {
                alert('Please enter proper lookup name in CommandButton property.');
            }
        }
        else if (commandButton.popUpFormName !== undefined && commandButton.popUpFormName !== '' && commandButton.popUpFormName !== null) {
            this.setState({
                popupSectionSchema: formSchema.sections.find(s => s.sectionName === commandButton.popUpFormName)
            }, () => this.setState({ openPopupDialog: true }));
        }
        else {
            this.dispatchState({
                ExecuteScript: commandButton
            });
            if (!unsavedChanges) {
                this.setState({
                    unsavedChanges: true
                });
            }
        }
    }

    closeStaffLookup = () => {
        this.setState({
            openStaffDialog: false
        });
    }

    closePopupForm = () => {
        this.setState({
            openPopupDialog: false
        });
    }

    openReportPopup = () => {
        this.setState({
            openReportDialog: true
        });
    };

    closeSavePopup = () => {
        this.setState({
            openUnconfirmedEpicDataPopup: false
        });
    }

    openSavePopup = () => {
        this.setState({
            openUnconfirmedEpicDataPopup: true
        });
    }
    closeReportPopup = () => {
        this.setState({
            openReportDialog: false
        });
    }

    onRecordLocked = (isRecordLocked) => {
        if (isRecordLocked) {
            this.dispatchState({
                ToggleRecordLock: {}
            });
        }
    }

    handleRecordDelete = (tableName, recordKeys) => {
        const { unsavedChanges } = this.state;

        this.dispatchState({
            DeleteRecords: {
                TableName: tableName,
                Keys: recordKeys
            }
        });
        if (!unsavedChanges) {
            this.setState({
                unsavedChanges: true
            });
        }
    }

    handleSetRecordCurrency = (tableName, targetRecordKey) => {
        this.dispatchState({
            SetTableCurrency: {
                TableName: tableName,
                Key: targetRecordKey
            }
        });
    }

    clearEvent = () => {
        this.setState({
            unsavedChanges: false
        });
        this.props.history.goBack();
    };

    resetForm = (event) => {

    };

    saveForm = () => {
        const { settings } = this.props;
        const { copilotFormData } = this.state;

        let illegalFields = this.getIllegalFieldsList(copilotFormData);

        if (illegalFields.length === 0 || settings[ALLOW_ILLEGAL_DATA]) {
            this.setState({
                illegalFields: [],
                unsavedChanges: false,
                newEvent: false
            });
            this.dispatchState({
                Save: {}
            });
            this.showSavedSnackbar();
        } else {
            this.showIllegalFieldsErrorMessage(illegalFields);
        }
    };

    saveConfirmDataFieldList = () => {
        const { mappingElementsStatus } = this.state;
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let allMappedMissing = this.areAllMappedMissing(newMappingElementsStatus);
        let isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
        this.setState({
            illegalFields: [],
            unsavedChanges: false,
            newEvent: false
        });
        this.dispatchState({
            Save: {}
        });

        if (!isNoEmrOptionsForAllelements && !allMappedMissing) {
            let mappingElementsStatusToSave = [];
            newMappingElementsStatus.forEach(element => {
                let mappingElementStatus = {
                    section: element.section,
                    element: element.element,
                    elementDefaultValue: element.elementDefaultValue,
                    conflictStatus: element.conflictStatus,
                    mappedStatus: element.mappedStatus,
                    status: element.status,
                    remark: element.remark,
                    uniqueKey: element.uniqueKey,
                    apolloOption: element.apolloOption,
                    emrOptions: element.emrOptions
                };
                mappingElementsStatusToSave.push(mappingElementStatus);
            });
            this.dispatchState({
                DeleteFormCache: {}
            });
            const sectionNames = [...new Set(mappingElementsStatusToSave.map((item) => item.section))];
            let sectionSequence = 0;
            sectionNames.forEach(sectionName => {
                sectionSequence = sectionSequence + 1
                let sectionElements = mappingElementsStatusToSave.filter((e) => (e.section === sectionName))
                this.dispatchState({
                    SaveFormSectionInCache: {
                        FormName: this.props.formName,
                        SectionName: sectionName,
                        SectionElements: sectionElements,
                        SectionSequence: sectionSequence
                    }
                });
            });
            this.dispatchState({
                AuditLogTheConfirmedFields: {}
            });
        }
        this.showSavedSnackbar();
        this.setState({
            openUnconfirmedEpicDataPopup: false
        });
    };

    isNoEmrOptionsForEveryElement = (newMappingElementsStatus) => {
        let result = newMappingElementsStatus.every(element => {
            if (element.emrOptions.length === 0) {
                return true;
            }
        });
        return result;
    };

    isListHasUnconfirmedStatus = (newMappingElementsStatus) => {
        let unconfirmedMappingElements = newMappingElementsStatus.filter(element => element.status === "Unconfirmed");
        if (unconfirmedMappingElements.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    deleteRecord = () => {
        this.dispatchState({
            Delete: {}
        });
        this.props.history.push('/');
        this.props.history.push(`patient/${this.props.patientInfo.patientId}`);
    };

    toggleRecordLock = (targetState) => () => {
        this.setState({
            lockStateTarget: targetState
        });
        this.dispatchState({
            ToggleRecordLock: {}
        });
    };

    getIllegalFieldsList = (copilotFormData) => {
        let illegalFields = [];
        if ((copilotFormData ?? null) !== null) {
            copilotFormData.validationList.forEach((singleValidation) => {
                switch (singleValidation.severity) {
                    case 1:
                        illegalFields.push(singleValidation);
                        break;
                    default:
                        break;
                }
            });
        }
        return illegalFields;
    };

    showIllegalFieldsErrorMessage = (illegalFields) => {
        this.setState({
            illegalFields: illegalFields,
            illegalFieldsErrorMessageOpen: true
        });
    };

    handleIllegalFieldsErrorMessageClose = () => {
        this.setState({
            illegalFieldsErrorMessageOpen: false
        });
    };

    showSavedSnackbar = () => {
        this.setState({
            savedSnackbarOpen: true
        });
    };

    handleSavedSnackbarClose = () => {
        this.setState({
            savedSnackbarOpen: false
        });
    };

    executeWidgetAction = (widgetName, methodName, methodArguments) => () => {
        if (widgetName && methodName) {
            this.dispatchState({
                ExecuteWidgetAction: {
                    WidgetName: widgetName,
                    MethodName: methodName,
                    Arguments: methodArguments
                }
            });
        } else {
            console.error("missing WidgetName or MethodName");
        }
    }

    hideFormViewOnlyBar = () => {
        this.setState({
            enableFormEdit: true
        });
        this.dispatchState({
            enableFormEdit: true
        });
    };

    getFieldData = (itemSchema, formData, recordContext) => {
        if (formData && formData.tables && recordContext) {
            return {
                ...recordContext.getDataPoint(formData, itemSchema.tableName, itemSchema.fieldName)
            };
        } else {
            return {
                value: '',
                options: []
            };
        }
    };

    preInitializeCache = (section) => {
        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach((section) => {
                this.preInitializeCache(section);
            })
        }
        else {
            section.elements.forEach((element) => {
                this.addMappingElementStatus(element, "Unconfirmed", section.sectionName);
            })
        }
    }

    initializeCache = (section, copilotFormData, globalContext) => {
        const { copilotFormCacheData } = this.state;
        let formstatusCacheData = Array.isArray(copilotFormCacheData) ? [...copilotFormCacheData] : [];
        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach((section) => {
                this.initializeCache(section, copilotFormData, globalContext);
            })
        }
        else {
            section.elements.forEach((element) => {
                let dataPoint = this.getFieldData(element, copilotFormData, globalContext);
                if (formstatusCacheData.length > 0) {
                    //When cache data is comming from database.
                    this.updateMappingStatusAsPerCache(element, dataPoint, formstatusCacheData);

                } else {
                    //When cache data is not comming from database.
                    this.updateMappingStatusWithValue(element, dataPoint);
                }
            })
        }
    }

    areAllMappedMissing = (mappingElementsStatusArg) => {
        let newMappingElementsStatus = Array.isArray(mappingElementsStatusArg) ? [...mappingElementsStatusArg] : [];
        const result = newMappingElementsStatus.every(element => {
            if (element.mappedStatus === 'Mapped-Missing' || element.mappedStatus === 'Mapping-Error') {
                return true;
            }
        });
        return result;
    }

    addMappingElementStatus = (element, status, sectionName) => {
        const { mappingElementsStatus } = this.state;
        const { formMetaData } = this.props;
        let calculatedItemMetaData = formMetaData[element.metaDataKey];
        if (Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0) {
            let arrOfElementsInMappingElementsStatus = mappingElementsStatus.filter((elementInList) => (elementInList.element === element.metaDataKey))
            if (arrOfElementsInMappingElementsStatus.length === 0) {
                let mappingElementStatus = {
                    section: sectionName,
                    element: element.metaDataKey,
                    elementDefaultValue: '',
                    conflictStatus: 'Nonconflicting data',
                    mappedStatus: "",
                    status,
                    remark: 'Pre-Initialization',
                    uniqueKey: element.uniqueKey,
                    apolloOption: '',
                    emrOptions: [],
                    mappingErrorDesc: ''
                };
                mappingElementsStatus.push(mappingElementStatus);
            }
            this.setState({ mappingElementsStatus });
        }
    }

    updateMappingStatus = (metaDataKey, conflicting, status, remark) => {
        const { mappingElementsStatus } = this.state;
        if (mappingElementsStatus !== null) {
            let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
            let arrOfElementsInMappingElementsStatus = newMappingElementsStatus.filter((e) => (e.element === metaDataKey))
            if (Array.isArray(arrOfElementsInMappingElementsStatus) && arrOfElementsInMappingElementsStatus.length > 0) {
                arrOfElementsInMappingElementsStatus.forEach(dupElement => {
                    let elementIndex = newMappingElementsStatus.map(elementStatus => elementStatus.uniqueKey).indexOf(dupElement.uniqueKey);
                    let fieldElement;
                    if (elementIndex >= 0) {
                        fieldElement = newMappingElementsStatus.find(elementStatus => elementStatus.element === metaDataKey);
                    }

                    let mappingElementStatus = {
                        section: fieldElement !== undefined ? fieldElement.section : '',
                        element: metaDataKey,
                        elementDefaultValue: fieldElement !== undefined ? fieldElement.elementDefaultValue : '',
                        conflictStatus: conflicting ? "Conflicting data" : "Nonconflicting data",
                        mappedStatus: fieldElement !== undefined ? fieldElement.mappedStatus : '',
                        status,
                        remark: remark,
                        uniqueKey: dupElement.uniqueKey,
                        apolloOption: fieldElement !== undefined ? fieldElement.apolloOption : '',
                        emrOptions: dupElement.emrOptions,
                        mappingErrorDesc: dupElement.mappingErrorDesc
                    };
                    mappingElementsStatus.splice(elementIndex, 1, mappingElementStatus);
                    this.setState({
                        mappingElementsStatus
                    });
                });
            }
        }
    }

    updateMappingStatusWithValue = (element, dataPoint) => {
        const { mappingElementsStatus } = this.state;
        const { formMetaData } = this.props;
        let elementValue = dataPoint.value;
        let options = dataPoint.options;
        let calculatedItemMetaData = formMetaData[element.metaDataKey];
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let valueOptions = Array.isArray(options) ? [...options] : [];
        let mappingElementStatus;
        if (mappingElementsStatus !== null && (Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0)) {
            let arrOfElementsInMappingElementsStatus = newMappingElementsStatus.filter((e) => (e.element === element.metaDataKey))
            if (Array.isArray(arrOfElementsInMappingElementsStatus) && arrOfElementsInMappingElementsStatus.length > 0) {
                arrOfElementsInMappingElementsStatus.forEach(dupElement => {
                    let elementIndex = newMappingElementsStatus.map(elementStatus => elementStatus.uniqueKey).indexOf(dupElement.uniqueKey);

                    let tempEmrOptions = [];
                    if (valueOptions.length > 0) {
                        valueOptions.forEach(option => {
                            let tempEmrOption = {
                                value: option.value,
                                mapping: option?.mapping?.name,
                                sourceType: option?.mapping?.emrSource?.sourceType,
                                source: option?.mapping?.emrSource?.source,
                                emrOptionElements: []
                            };
                            let mappingElements = option?.mapping?.mappingElements;
                            if (mappingElements.length > 0) {
                                let valueOptionElements = [];
                                mappingElements.forEach(element => {
                                    let tempEmrOptionElement = {
                                        codeSystem: element.codeSystem,
                                        codeValue: element.codeValue,
                                        name: element.name
                                    };
                                    valueOptionElements.push(tempEmrOptionElement);
                                })
                                tempEmrOption.emrOptionElements = valueOptionElements;
                            }
                            tempEmrOptions.push(tempEmrOption);
                        });

                        let isElementValueNothing = false;
                        if ((elementValue === undefined || elementValue === null || elementValue === '')) {
                            isElementValueNothing = true;
                        }

                        let epicDataMatched = valueOptions.every(option => {
                            if (option === valueOptions[0]) {
                                return true;
                            }
                        });
                        let elementValueMatched = undefined;
                        if (!isElementValueNothing) {
                            elementValueMatched = valueOptions.find(option => option.value === elementValue);
                        }
                        if (elementValueMatched === undefined && calculatedItemMetaData.defaultInputType === 'CheckBox') {
                            switch (elementValue) {
                                case 1:
                                    elementValue = true;
                                    break;
                                case 0:
                                    elementValue = false;
                                    break;
                                default:
                                    elementValue = calculatedItemMetaData?.forceBooleanFalse ? false : null
                                    break;
                            }
                            elementValueMatched = valueOptions.find(option => option.value === elementValue);
                            isElementValueNothing = elementValue === null ? null : false;
                        }
                        let apolloSourceOption = elementValue + '|Apollo|Apollo Database';
                        mappingElementStatus = {
                            section: dupElement.section,
                            element: element.metaDataKey,
                            elementDefaultValue: elementValue,
                            conflictStatus: (elementValueMatched && epicDataMatched) || (isElementValueNothing && epicDataMatched) ? "Nonconflicting data" : "Conflicting data",
                            mappedStatus: "Mapped",
                            status: (elementValueMatched === undefined || isElementValueNothing || !epicDataMatched) ? "Unconfirmed" : "Confirmed",
                            remark: 'Initialization',
                            uniqueKey: dupElement.uniqueKey,
                            apolloOption: (elementValueMatched === undefined && !isElementValueNothing) ? apolloSourceOption : '',
                            emrOptions: tempEmrOptions,
                            mappingErrorDesc: ''
                        };
                    }
                    else {
                        mappingElementStatus = {
                            section: dupElement.section,
                            element: element.metaDataKey,
                            elementDefaultValue: elementValue,
                            conflictStatus: "",
                            mappedStatus: dataPoint.mappingExpressionError ? "Mapping-Error" : "Mapped-Missing",
                            status: "",
                            remark: 'Initialization',
                            uniqueKey: dupElement.uniqueKey,
                            apolloOption: '',
                            emrOptions: [],
                            mappingErrorDesc: dataPoint.mappingExpressionErrorDesc
                        };
                    }
                    mappingElementsStatus.splice(elementIndex, 1, mappingElementStatus);
                    this.setState({ mappingElementsStatus });
                });
            }
        }
    }

    updateMappingStatusAsPerCache = (element, dataPoint, formstatusCacheData) => {
        const { mappingElementsStatus } = this.state;
        const { formMetaData } = this.props;
        let elementValue = dataPoint.value;
        let options = dataPoint.options;
        let calculatedItemMetaData = formMetaData[element.metaDataKey];
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let valueOptions = Array.isArray(options) ? [...options] : [];
        let mappingElementStatus;
        if (mappingElementsStatus !== null && (Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0)) {
            let arrOfElementsInMappingElementsStatus = newMappingElementsStatus.filter((e) => (e.element === element.metaDataKey))
            if (Array.isArray(arrOfElementsInMappingElementsStatus) && arrOfElementsInMappingElementsStatus.length > 0) {
                arrOfElementsInMappingElementsStatus.forEach(dupElement => {
                    let elementIndex = newMappingElementsStatus.map(elementStatus => elementStatus.uniqueKey).indexOf(dupElement.uniqueKey);
                    let cachedElement = formstatusCacheData.find(cacheElementData => cacheElementData.uniqueKey === dupElement.uniqueKey);
                    if (cachedElement !== undefined) {
                        let isMappedMissingOrMappedError = false;
                        if ((cachedElement.mappedStatus === 'Mapped-Missing' || cachedElement.mappedStatus === 'Mapping-Error') && (valueOptions.length === 0)) {
                            isMappedMissingOrMappedError = true;
                        }
                        if (!isMappedMissingOrMappedError) {
                            //Create array of mapping values and elements from FHIR data which needs to compare with mapping data from cache.
                            let tempValueOptions = [];
                            if (valueOptions.length > 0) {
                                valueOptions.forEach(option => {
                                    let tempValueOption = {
                                        value: option.value,
                                        mapping: option?.mapping?.name,
                                        sourceType: option?.mapping?.emrSource?.sourceType,
                                        source: option?.mapping?.emrSource?.source,
                                        emrOptionElements: []
                                    };
                                    let mappingElements = option?.mapping?.mappingElements;
                                    if (mappingElements.length > 0) {
                                        let valueOptionElements = [];
                                        mappingElements.forEach(element => {
                                            let tempEmrOptionElement = {
                                                codeSystem: element.codeSystem,
                                                codeValue: element.codeValue,
                                                name: element.name
                                            };
                                            valueOptionElements.push(tempEmrOptionElement);
                                        })
                                        tempValueOption.emrOptionElements = valueOptionElements;
                                    }
                                    tempValueOptions.push(tempValueOption);
                                });
                            }
                            //Compare here recent FHIR data with mapping data from cache. if there is difference between two, then mark data as "Unconfirmed"
                            let hasEqualOptionsInCacheAndEpic = this.equalsCheck(cachedElement.emrOptions, tempValueOptions);

                            let cachedEmrOptions = Array.isArray(cachedElement.emrOptions) ? [...cachedElement.emrOptions] : [];
                            let isElementValueNothing = false;
                            if ((elementValue === undefined || elementValue === null || elementValue === '')) {
                                isElementValueNothing = true;
                            }

                            let epicDataMatched;
                            let elementValueMatched;
                            //If comparision is equal, then use data from cache, else use newly arrived FHIR data.
                            if (hasEqualOptionsInCacheAndEpic) {
                                epicDataMatched = cachedEmrOptions.every(option => {
                                    if (option === cachedEmrOptions[0]) {
                                        return true;
                                    }
                                });
                                elementValueMatched = undefined;
                                if (!isElementValueNothing) {
                                    elementValueMatched = cachedEmrOptions.find(option => option.value === elementValue);
                                }
                            } else {
                                epicDataMatched = tempValueOptions.every(option => {
                                    if (option === tempValueOptions[0]) {
                                        return true;
                                    }
                                });
                                elementValueMatched = undefined;
                                if (!isElementValueNothing) {
                                    elementValueMatched = tempValueOptions.find(option => option.value === elementValue);
                                }
                            }

                            if (elementValueMatched === undefined && calculatedItemMetaData.defaultInputType === 'CheckBox') {
                                switch (elementValue) {
                                    case 1:
                                        elementValue = true;
                                        break;
                                    case 0:
                                        elementValue = false;
                                        break;
                                    default:
                                        elementValue = calculatedItemMetaData?.forceBooleanFalse ? false : null
                                        break;
                                }
                                if (hasEqualOptionsInCacheAndEpic) {
                                    elementValueMatched = cachedEmrOptions.find(option => option.value === elementValue);
                                } else {
                                    elementValueMatched = tempValueOptions.find(option => option.value === elementValue);
                                }

                                isElementValueNothing = elementValue === null ? null : false;
                            }
                            let apolloSourceOption = elementValue + '|Apollo|Apollo Database';
                            mappingElementStatus = {
                                section: dupElement.section,
                                element: element.metaDataKey,
                                elementDefaultValue: elementValue,
                                conflictStatus: (elementValueMatched && epicDataMatched) || (isElementValueNothing && epicDataMatched) ? "Nonconflicting data" : "Conflicting data",
                                mappedStatus: "Mapped",
                                status: hasEqualOptionsInCacheAndEpic ? cachedElement !== undefined ? cachedElement.status : (elementValueMatched === undefined || isElementValueNothing || !epicDataMatched) ? "Unconfirmed" : "Confirmed" : "Unconfirmed",
                                remark: 'Initialization',
                                uniqueKey: dupElement.uniqueKey,
                                apolloOption: (elementValueMatched === undefined && !isElementValueNothing) ? apolloSourceOption : '',
                                emrOptions: hasEqualOptionsInCacheAndEpic && cachedElement !== undefined ? cachedEmrOptions : tempValueOptions,
                                mappingErrorDesc: ''
                            };
                            //If mapping error has been fixed or element got mappings, then set state of Confirm/UnConfirm as per element match with Epic data.
                            //Also set emrOptions with new Epic data which comes after fixing the mapping error or after getting new mappings.
                            if ((cachedElement.mappedStatus === 'Mapped-Missing' || cachedElement.mappedStatus === 'Mapping-Error') && (cachedElement.status === "")) {
                                mappingElementStatus.status = (isElementValueNothing || elementValueMatched === undefined || !epicDataMatched) ? "Unconfirmed" : "Confirmed";
                                mappingElementStatus.emrOptions = tempValueOptions;
                            }
                        }
                        else {
                            mappingElementStatus = {
                                section: dupElement.section,
                                element: element.metaDataKey,
                                elementDefaultValue: elementValue,
                                conflictStatus: "",
                                mappedStatus: cachedElement.mappedStatus,
                                status: "",
                                remark: 'Initialization',
                                uniqueKey: dupElement.uniqueKey,
                                apolloOption: '',
                                emrOptions: [],
                                mappingErrorDesc: dataPoint.mappingExpressionErrorDesc
                            };
                        }
                        mappingElementsStatus.splice(elementIndex, 1, mappingElementStatus);
                        this.setState({ mappingElementsStatus });
                    }
                });
            }
        }
    }

    equalsCheck = (a, b) => {
        return (
            (Array.isArray(a) && Array.isArray(b)) && (a.length === b.length) &&
            JSON.stringify(a) === JSON.stringify(b)
        );
    }

    render() {
        const { formSchema, formMetaData, recordId, sectionList, classes, settings, triggerScroll, patientInfo } = this.props;
        const {
            copilotFormData,
            progress,
            illegalFieldsErrorMessageOpen,
            illegalFields,
            savedSnackbarOpen,
            unsavedChanges,
            newEvent,
            moduleSecurity,
            isLockable,
            lockStateTarget,
            formSecurity,
            enableFormEdit,
            openStaffDialog,
            openPopupDialog,
            openUnconfirmedEpicDataPopup,
            commandButtonSchema,
            openReportDialog,
            mappingElementsStatus,
            progressDone,
            setDefaultValueToAllFields,
            copilotFormCacheData
        } = this.state;

        let globalContext = new ContextManager(copilotFormData?.globalContext);
        let ieMessage = window.IeVersion().IsIE ? "Warning, checking the don't let this page create more messages box will prevent you from navigating to other pages until you refresh the browser." : "";

        let message = `You have unsaved changes. If you select OK, you will lose your changes. Select Cancel to remain on the current page.\n\n${ieMessage}`;
        //This logic finds the head record associated with the form to determine if the record is locked or not. This is used to determine which text to display on the lock/unlock button
        let headRecord = {
            isLocked: false
        };
        let rootRecord = {
            isLocked: false
        };
        if (copilotFormData && copilotFormData?.tables && formSchema) {
            let headTable = copilotFormData.tables[formSchema.header.eventTable];
            headRecord = headTable?.records[recordId] ? headTable?.records[recordId] : headRecord;
            if (lockStateTarget !== null) {
                if (lockStateTarget === headRecord?.isLocked) {
                    this.setState({
                        lockStateTarget: null
                    });
                }
            }
            let rootTable = copilotFormData.tables[copilotFormData.tables.Demographics.tableName];
            rootRecord = rootTable?.records[globalContext.recordContext.Demographics.key] ? rootTable?.records[globalContext.recordContext.Demographics.key] : rootRecord;
            rootRecord.isLocked = headRecord?.isLocked;

            if (this.props.recordId === '0') {
                let newRecordValue = '0';
                for (let [value] of Object.entries(headTable.records)) {
                    newRecordValue = value;
                    if (value === '-1') {
                        break;
                    }
                }
                if (parseInt(newRecordValue) > 0) {
                    this.props.history.push('/');
                    this.props.history.push(`patient/${this.props.patientInfo.patientId}/forms/${formSchema.header.eventTable}/${newRecordValue}/${this.props.formName}`);
                    window.location.reload();
                }
            }
        }

        if (!formSchema?.sections.length > 0) {
            return <CircularProgress size={60} thickness={7} />;
        }

        //PreInitializeCache - Initial cache for storing the element value with status.
        if (progressDone !== true) {
            formSchema.sections.forEach((section) => {
                this.preInitializeCache(section);
            });

            this.setState({
                progressDone: true
            });
        }
        //InitializeCache - with element default values.
        if (progress === 100) {
            let fetchedCopilotFormCacheData;
            if (!setDefaultValueToAllFields) {
                fetchedCopilotFormCacheData = copilotFormCacheData;
                formSchema.sections.forEach((section) => {
                    this.initializeCache(section, copilotFormData, globalContext);
                });

                this.setState({
                    setDefaultValueToAllFields: true
                });
            }
        }

        let formControlBarComponents = [
            {
                type: 'button',
                label: 'Reports',
                onClick: this.openReportPopup,
                disabled: newEvent || progress !== 100 || headRecord.isLocked || formSecurity.viewOnly
            }
        ];
        if (moduleSecurity.allowDelete) {
            formControlBarComponents.push({
                type: 'button',
                label: 'Delete',
                showConfirmation: true,
                confirmationTitle: 'Delete Record?',
                confirmationText: [
                    'This will permanently delete this record.',
                    'Ensure this is the correct record and patient before confirming.'
                ],
                onClick: this.deleteRecord,
                disabled: newEvent || headRecord.isLocked
            });
        }
        if (isLockable && ((moduleSecurity.allowLock && !headRecord.isLocked) || (moduleSecurity.allowUnlock && headRecord.isLocked))) {
            formControlBarComponents.push({
                type: 'button',
                label: headRecord.isLocked ? 'Unlock' : 'Lock',
                showConfirmation: false,
                onClick: this.toggleRecordLock(!headRecord.isLocked),
                disabled: newEvent || progress !== 100
            });
        }
        formControlBarComponents.push({
            type: 'button',
            label: 'Save',
            showConfirmation: newEvent,
            confirmationTitle: 'Save?',
            confirmationText: [
                'Saving will create a new event record in the Apollo database.',
                'Do you want to proceed?'
            ],
            onClick: this.saveForm,
            confirmationStatus: true,
            disabled: headRecord.isLocked || ((formSecurity.viewOnly && (newEvent || enableFormEdit)) ? false : formSecurity.viewOnly) || progress !== 100
        });
        let formLoadingLable = '"' + formSchema.header.formDisplayName + '" form is loading. (' + progress + '%)'
        return (
            <React.Fragment>
                <Prompt
                    when={unsavedChanges}
                    message={message}
                />
                <GridContainer>
                    <GridItem xs={8}>
                        <div className={classes.patientInfo} style={{ textAlign: "left" }}>
                            {'\n'} MRN: {patientInfo.patientId} &nbsp;&nbsp;&nbsp;&nbsp; Name: {patientInfo.lastName},&nbsp; {patientInfo.firstName}&nbsp;&nbsp;&nbsp;&nbsp; DOB: {moment(patientInfo.dateOfBirth).format('MM/DD/YYYY')}
                        </div>
                    </GridItem>
                    <GridItem xs={4}>
                        <FormControlBar
                            components={formControlBarComponents}
                        />
                    </GridItem>
                    {
                        (formSecurity.viewOnly && !newEvent && !enableFormEdit) ?
                            (
                                <GridItem xs={12}>
                                    <FormViewOnlyBar
                                        formDisplayName={formSchema.header.formDisplayName}
                                        onEnableEditing={this.hideFormViewOnlyBar}
                                        moduleSecurity={moduleSecurity}
                                    />
                                </GridItem>
                            )
                            : null
                    }
                    <GridItem xs={12}>
                        {progress !== 100 || !copilotFormData ?
                            <>
                                <div className={classes.progressBar}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </div>
                                <div>
                                    <Card className={classes.paper}>
                                        <CardBody className={classes.cardBody}>
                                            <CircularProgress className={classes.title} size={100} thickness={7} color='secondary' />
                                            <br></br>
                                            <Typography align='center' variant='h7'>
                                                {formLoadingLable}
                                            </Typography>
                                        </CardBody>
                                    </Card>
                                </div>
                            </>
                            : null
                        }
                        {progress === 100 ?
                            <DynamicForm
                                formSchema={formSchema}
                                formMetaData={formMetaData}
                                sectionList={sectionList}
                                formData={copilotFormData}
                                recordContext={globalContext}
                                settings={settings}
                                onChange={this.handleChange}
                                executeCommandButton={this.executeCommandButton}
                                onBlur={this.handleBlur}
                                onAddRecord={this.handleRecordAdd}
                                onDeleteRecords={this.handleRecordDelete}
                                setRecordCurrency={this.handleSetRecordCurrency}
                                triggerWidgetCall={this.executeWidgetAction}
                                tabView={'true'}
                                triggerScroll={triggerScroll}
                                isLocked={headRecord.isLocked}
                                formSecurity={formSecurity}
                                newEvent={newEvent}
                                enableFormEdit={enableFormEdit}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={this.updateMappingStatus}
                                formLoadProgress={progress}
                            />
                            : null
                        }
                    </GridItem>
                    <FormsIllegalDataDialog
                        onClose={this.handleIllegalFieldsErrorMessageClose}
                        open={illegalFieldsErrorMessageOpen}
                        illegalFields={illegalFields}
                    />
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={savedSnackbarOpen}
                        onClose={this.handleSavedSnackbarClose}
                        message="Saved!"
                        autoHideDuration={3000}
                    />
                </GridContainer>
                <Backdrop className={classes.backendActionBackdrop} open={lockStateTarget !== null}>
                    <CircularProgress size={60} thickness={7} color='secondary' />
                </Backdrop>
                <Dialog open={openStaffDialog} onClose={this.closeStaffLookup} maxWidth="xl">
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeStaffLookup}
                    />
                    <DialogContent>
                        <StaffContainer
                            recordContext={globalContext}
                            onBlur={this.handleBlur}
                            itemSchema={commandButtonSchema}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog open={openPopupDialog} onClose={this.closePopupForm} fullWidth={true} maxWidth="xl">
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closePopupForm}
                    />
                    <DialogContent>
                        <DynamicFormSection
                            sectionSchema={this.state.popupSectionSchema}
                            formMetaData={formMetaData}
                            formData={copilotFormData}
                            recordContext={globalContext}
                            settings={settings}
                            executeCommandButton={this.executeCommandButton}
                            onAddRecord={this.handleRecordAdd}
                            onDeleteRecords={this.handleRecordDelete}
                            setRecordCurrency={this.handleSetRecordCurrency}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            triggerWidgetCall={this.executeWidgetAction}
                            tabView={'true'}
                            triggerScroll={triggerScroll}
                            isLocked={headRecord.isLocked}
                            formSecurity={formSecurity}
                            newEvent={newEvent}
                            enableFormEdit={enableFormEdit}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog open={openReportDialog} onClose={this.closeReportPopup} classes={{ paper: classes.dialogPaper }} maxWidth={'lg'} disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeReportPopup}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Reports'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <ReportContainer
                            eventTableName={formSchema.header.eventTable}
                            eventId={recordId}
                            formName={formSchema.header.formDisplayName}
                            onRecordLocked={this.onRecordLocked}
                        />
                    </DialogContent>
                    <MuiDialogActions>
                        <Button color="primary" variant='contained' onClick={this.closeReportPopup}>
                            Close
                        </Button>
                    </MuiDialogActions>
                </Dialog>
                <Dialog open={openUnconfirmedEpicDataPopup} onClose={this.closeSavePopup} classes={{ paper: classes.dialogPaper }} maxWidth={'md'} disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeSavePopup}
                    />
                    <DialogTitle className={classes.dialogTitle}>
                        {'Warning, Unconfirmed FHIR Data'}
                    </DialogTitle>
                    <DialogContent className={classes.EpicConfirmationDialogContent}>
                        {'There is Unconfirmed FHIR Data on this form. Fields with FHIR data must be manually confirmed before they can be saved. If you save the form now, no unconfirmed FHIR data will be saved to the database. Click Save to save fields without FHIR data and fields with confirmed FHIR data. Click cancel to return to the form.'}
                    </DialogContent>
                    <MuiDialogActions className={classes.dialogButtons}>
                        <Button variant='contained' color='primary' onClick={this.saveConfirmDataFieldList}>
                            {'Save'}
                        </Button>
                        <Button variant='contained' color='primary' onClick={this.closeSavePopup}>
                            {'Cancel'}
                        </Button>
                    </MuiDialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default compose(
    connect((state) => ({ patientInfo: state.demographics })),
    withRouter,
    withStyles(styles)
)(FormsContainerSignalR);