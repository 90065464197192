import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const styles = theme => ({
    formImageBox: {
        display: 'flex',
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`
        }
    },
    addImage: {
        marginRight: '0%',
        minWidth: '100px'
    },
    addButton: {
        whiteSpace: 'nowrap',
        minWidth: '150px'
    }
});

class ImageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onAddItem: props.onAddItem,
            base64imagestring: '',
            fileName: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.addImage = this.addImage.bind(this);
    }

    handleChange(event) {
        let file = event.target.files[0];

        if (file) {
            const maxAllowedSize = 1024 * 1024;
            if (file.size > maxAllowedSize) {
                alert("File must be less then or equal to 1mb.");
                event.target.value = '';
                return;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                this.setState({
                    base64imagestring: e.target.result,
                    fileName: file.name
                });
            };
        }
    }

    addImage() {
        if (this.state.base64imagestring) {
            let image = {
                base64ImageString: this.state.base64imagestring,
                $lumedxType: "Image"
            };
            this.state.onAddItem(image, null);
        }
    }

    chooseFile = () => {
        document.getElementById('imgAttachment').click();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.formImageBox}>
                <Button className={classes.addButton} color='primary' variant='outlined' id="copyBtn" onClick={this.chooseFile}>{'Browse File...'}</Button>
                <input type="file" accept="image/jpg, image/png, image/gif, image/jpeg" onChange={this.handleChange} style={{ display: 'none' }} id="imgAttachment" />
                <Button className={classes.addImage} color='primary' variant='outlined' onClick={this.addImage}>
                    {'UPLOAD'}
                </Button>
                <TextField value={this.state.fileName} disabled='true' fullWidth />
            </div>
        );
    }
}

export default withStyles(styles)(ImageSelector);