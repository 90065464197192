import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import modalStyle from '.../assets/jss/material-dashboard-pro-react/modalStyle';
import PatientWorklist from '.../components/patientSearch/PatientWorklist';
import { pushToError } from '.../utils/apiHelper';
import CreateNewPatient from './CreateNewPatient';

const styles = theme => ({
    ...modalStyle(theme),
    dialog: {
        width: '100vh',
        overflow: 'visible',
        '@media (min-width: 960px)': {
            width: '60vw'
        }
    },
    dialogContent: {
        height: '65vh',
        overflow: 'hidden',
        paddingTop: '0px'
    },
    patientList: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '6px',
        margin: theme.spacing(4),
        width: 'auto'
    },
    scrollbar: {
        height: 'inherit',
        '& .ps__rail-y': {
            top: '0px !important'
        }
    }
});

class PatientConflictResolver extends React.Component {
    constructor(props) {
        super(props);

        let queryIds = PatientConflictResolver.getQueryValues(props.location.search);
        let resolvedPatient = PatientConflictResolver.autoResolvePatient(props.conflictingPatients, queryIds);

        if (PatientConflictResolver.isPatientResolved(resolvedPatient, props.conflictingPatients)) {
            if (typeof props.onPatientSubmit === 'function') {
                props.onPatientSubmit(resolvedPatient);
            }
        }

        this.state = {
            resolvedPatient,
            createPatientOpen: false,
            conflictingPatientsString: JSON.stringify(props.conflictingPatients),
            searchString: props.location.search
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { conflictingPatients, location, onPatientSubmit } = nextProps;
        const { conflictingPatientsString, searchString } = prevState;
        if (JSON.stringify(conflictingPatients) !== conflictingPatientsString || location.search !== searchString) {
            let queryIds = PatientConflictResolver.getQueryValues(location.search);
            let resolvedPatient = PatientConflictResolver.autoResolvePatient(conflictingPatients, queryIds);

            if (PatientConflictResolver.isPatientResolved(resolvedPatient, conflictingPatients)) {
                if (typeof onPatientSubmit === 'function') {
                    onPatientSubmit(resolvedPatient);
                }
            }

            return ({
                resolvedPatient,
                conflictingPatientsString: JSON.stringify(conflictingPatients),
                searchString: location.search
            });
        }

        return null;
    }

    static autoResolvePatient(conflictingPatients, queryIds) {
        let resolvedPatient = {};

        Object.getOwnPropertyNames(conflictingPatients).forEach(source => {
            if (Array.isArray(conflictingPatients[source])) {
                if (conflictingPatients[source].length === 1) {
                    resolvedPatient[source] = conflictingPatients[source];
                } else {
                    let selectedId = '';
                    Object.getOwnPropertyNames(queryIds).forEach(idSource => {
                        if (idSource.toLowerCase() === source.toLowerCase()) {
                            selectedId = queryIds[idSource];
                        }
                    });
                    if (selectedId !== '') {
                        let resolvedPatientList = conflictingPatients[source].filter(patient => `${patient.uniqueSourceId}` === `${selectedId}`);
                        if (resolvedPatientList.length > 0) {
                            resolvedPatient[source] = resolvedPatientList;
                        }
                    }
                }
            }
        });

        return resolvedPatient;
    }

    static getQueryValues(queryString) {
        queryString = queryString.indexOf('?') === 0 ? queryString.slice(1) : queryString;
        let queryPairs = queryString.split('&');
        let queryValues = {};

        queryPairs.forEach(pair => {
            let [key, value] = pair.split('=');
            queryValues[key] = value;
        });

        return queryValues;
    }

    static isPatientResolved = (resolvedPatient, conflictingPatients) => {
        let resolved = true;

        Object.getOwnPropertyNames(conflictingPatients).forEach(source => {
            if (Array.isArray(conflictingPatients[source])) {
                if (conflictingPatients[source].length > 0) {
                    if (typeof resolvedPatient[source] === 'undefined') {
                        resolved = false;
                    }
                } else {
                    if (source.toLowerCase() === 'apollo') {
                        resolved = false;
                    }
                }
            }
        });

        return resolved;
    }

    handlePatientSelect = (source) => (selectedPatient) => {
        const { resolvedPatient } = this.state;
        this.setState({
            resolvedPatient: {
                ...resolvedPatient,
                [source]: [selectedPatient]
            }
        });
    }

    handleCreatePatient = (patient) => {
        const { onPatientCreation } = this.props;
        this.setState({
            createPatientOpen: false
        });
        onPatientCreation(patient);
    }

    getAgreeingPatient = () => {
        const { conflictingPatients } = this.props;
        let patient = {
            firstName: null,
            lastName: null,
            dateOfBirth: null
        };

        Object.getOwnPropertyNames(conflictingPatients).forEach(source => {
            if (Array.isArray(conflictingPatients[source])) {
                conflictingPatients[source].forEach(sourcePatient => {
                    patient.firstName = patient.firstName !== null && patient.firstName !== sourcePatient.firstName ? '' : sourcePatient.firstName;
                    patient.lastName = patient.lastName !== null && patient.lastName !== sourcePatient.lastName ? '' : sourcePatient.lastName;
                    patient.dateOfBirth = patient.dateOfBirth !== null && patient.dateOfBirth !== sourcePatient.dateOfBirth ? '' : sourcePatient.dateOfBirth;
                });
            }
        });

        return patient;
    }

    getSourceTitleName = (source) => {
        const titleNames = {
            apollo: 'Apollo Database',
            fhir: 'FHIR',
            genericsql: 'Generic SQL Database'
        };

        if (typeof titleNames[source.toLowerCase()] !== 'undefined') {
            return titleNames[source.toLowerCase()];
        } else {
            return source.toUpperCase();
        }
    }

    render() {
        const { classes, mrn, conflictingPatients, onPatientSubmit } = this.props;
        const { createPatientOpen, resolvedPatient } = this.state;

        return (
            <React.Fragment>
                <Dialog classes={{ paper: classes.dialog }} open maxWidth={false}>
                    <DialogTitle disableTypography className={classes.modalHeader}>
                        <h4 className={classes.modalTitle}>
                            {`Resolve Patient Conflicts for MRN ${mrn}`}
                        </h4>
                    </DialogTitle>
                    <DialogContent className={`${classes.modalBody} ${classes.dialogContent}`}>
                        <PerfectScrollbar
                            className={classes.scrollbar}
                            options={{
                                suppressScrollX: true,
                                suppressScrollY: false
                            }}
                        >
                            {Object.getOwnPropertyNames(conflictingPatients).map((source, index) =>
                                <PatientWorklist
                                    key={`${source} ${index}`}
                                    className={classes.patientList}
                                    patients={conflictingPatients[source]}
                                    selectedPatient={typeof resolvedPatient[source] !== 'undefined' ?
                                        resolvedPatient[source][0]
                                        :
                                        undefined
                                    }
                                    handlePatientSelect={this.handlePatientSelect(source)}
                                    handleCreatePatient={source.toLowerCase() === 'apollo' && Array.isArray(conflictingPatients[source]) && conflictingPatients[source].length === 0?
                                        () => this.setState({ createPatientOpen: true })
                                        :
                                        undefined
                                    }
                                    title={`Patients From ${this.getSourceTitleName(source)}`}
                                    showUniqueId
                                    collapsible
                                />
                            )}
                        </PerfectScrollbar>
                    </DialogContent>
                    <DialogActions>
                        <GridContainer spacing={4} justify='flex-end' alignItems='baseline'>
                            <GridItem>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={!PatientConflictResolver.isPatientResolved(resolvedPatient, conflictingPatients)}
                                    onClick={() => onPatientSubmit(resolvedPatient)}
                                >
                                    {'SUBMIT'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </DialogActions>
                </Dialog>
                <CreateNewPatient
                    mrn={mrn}
                    open={createPatientOpen}
                    handleClose={() => this.setState({ createPatientOpen: false })}
                    failureCallback={pushToError}
                    successfulCallback={this.handleCreatePatient}
                    currentPatient={this.getAgreeingPatient()}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(PatientConflictResolver));