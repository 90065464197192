import CircularProgress from '@material-ui/core/CircularProgress';
import React from "react";
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';

import { pushToError } from '.../utils/apiHelper';

class ViewRouteLoader extends React.Component {
    state = {
        isLoading: true,
        routes: []
    }

    componentDidMount() {
        this.updateRoutes();
    }

    updateRoutes = () => {
        const { views, basePath, patientInfo } = this.props;

        if (typeof views !== 'undefined') {
            Promise.resolve(views).then(foundViews => {
                let routes = [];
                let alreadyAtViewPath = matchPath(this.props.location.pathname, {
                    path: basePath,
                    exact: true
                }) === null;

                foundViews.forEach(view => {
                    if (!alreadyAtViewPath) {
                        alreadyAtViewPath = true;
                        this.props.history.replace(view.path.replace(':mrn', patientInfo.patientId));
                    }

                    routes.push({
                        path: view.path,
                        component: view.component
                    });
                });

                this.setState({
                    isLoading: false,
                    routes
                });
            }).catch(error => {
                pushToError(error, patientInfo !== null ? patientInfo.patientId : undefined);
            });
        }
    }

    render() {
        const { isLoading, routes } = this.state;

        if (isLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }

        return (
            <Switch>
                {routes.map(route => (
                    <Route key={route.path} path={route.path} render={route.component} />
                ))}
            </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
    patientInfo: state.demographics
});

export default withRouter(connect(mapStateToProps, null)(ViewRouteLoader));