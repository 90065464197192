import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxSwitch from '.../components/formComponents/LxSwitch';
import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    content: {
        minWidth: '100%'
    }
});

function SqlQueryEditor(props) {
    const [sqlQuery, setSqlQuery] = React.useState(props.value);

    let handleToggleChange = (propertyName) => (value) => {
        const { onChange } = props;
        let newSqlQuery = {
            ...sqlQuery
        };
        
        if (!newSqlQuery) {
            newSqlQuery = {
                [propertyName]: value === null ? false : value
            };
        }
        else {
            newSqlQuery[propertyName] = value === null ? false : value;
        }

        setSqlQuery(newSqlQuery);
        onChange(newSqlQuery);
    };

    let handleQueryChange = (propertyName) => (value) => {
        const { onChange } = props;  
        let newSqlQuery = {
            ...sqlQuery
        };

        if (!validateQuery(value)) {
            sqlQuery(newSqlQuery);
            alert("The query can not contain commands: CREATE, COMMIT, DROP, UPDATE, INSERT, ALTER, DELETE, ATTACH, DETACH.");
            return;
        }

        if (!newSqlQuery) {
            newSqlQuery = {
                [propertyName]: value
            };
        }
        else {
            newSqlQuery[propertyName] = value;
        }

        setSqlQuery(newSqlQuery);
        onChange(newSqlQuery);
    };

    let validateQuery = (query) => {
        let regex = /^(?!.*(CREATE|COMMIT|DROP|UPDATE|INSERT|ALTER|DELETE|ATTACH|DETACH)).*$/img;

        return regex.test(query);
    };

    const { classes, key, title } = props;
    return (
        <div className={classes.content}>
            <LxSwitch
                key={key}
                title={title}
                onChange={handleToggleChange("useSql")}
                value={sqlQuery?.useSql}
                forceBooleanFalse
            />
            <LxTextField
                disabled={!sqlQuery?.useSql}
                multiline
                onChange={handleQueryChange("query")}
                onBlur={handleQueryChange("query")}
                value={sqlQuery?.query}
                variant="outlined"
                fullWidth
            />
        </div>
    );
}

export default withStyles(styles)(SqlQueryEditor);