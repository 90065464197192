import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import DynamicFormSection from '.../components/features/forms/DynamicFormSection';

const styles = theme => ({
    dividerLineTitle: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px 0px`,
        height: '100%',
        width: '100%',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#bbbbbb',
        flexDirection: 'column'
    },
    dividerLine: {
        flex: '1 0 auto'
    },
    recordList: {
        overflow: 'auto'
    },
    recordListTitle: {
        margin: 'auto'
    },
    recordListTitleWithSwitcher: {
        marginTop: '40px'
    },
    buttonCenterer: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    verticalDividerDiv: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap'
    },
    recordPanelFlexContainer: {
        height: '100%',
        minWidth: '170px',
        display: 'flex',
        flexDirection: 'column'
    },
    recordListFlexItem: {
        overflow: 'auto'
    },
    scrollableGridItem: {
        height: '100%',
        flexGrow: '1',
        overflow: 'auto'
    },
    recordDeleteButton: {
        width: '20px',
        height: '20px',
        position: 'absolute',
        right: '1px',
        padding: '0px'
    }
});

class FormView extends React.Component {
    constructor(props) {
        super(props);
        this.scrollableDivRef = React.createRef(null);
    }

    handleCellBlur = (dataPoints) => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            onBlur(dataPoints);
        }
    }

    handleDeleteRow = (recordKey) => (event) => {
        const { itemSchema, onDeleteRecords } = this.props;
        let recordKeys = [];
        recordKeys.push(recordKey);
        onDeleteRecords(itemSchema.contextTableName, recordKeys);
        event.stopPropagation();
    }

    handleAddRecord = () => {
        const { itemSchema, onAddRecord, recordContext, formData } = this.props;

        if (formData?.tables)
        {
            let contextTable = formData.tables[itemSchema.contextTableName];
            let parentTableNode = recordContext.recordContext[contextTable.parentTableName];
            onAddRecord(itemSchema.contextTableName, parentTableNode.key);
        }
    }

    handleSetRecordCurrency = (recordKeyValue) => () => {
        const { itemSchema, setRecordCurrency } = this.props;

        if (typeof setRecordCurrency === 'function') {
            setRecordCurrency(itemSchema.contextTableName, recordKeyValue);
        }
    }

    renderRecordList = () => {
        const {  itemSchema, formData, recordContext } = this.props;

        let allBaseRecords = {};
        //Ensure Required Data Exists:
        if (recordContext?.recordContext && itemSchema.contextTableName && formData) {
            allBaseRecords = recordContext.getChildRecords(formData, itemSchema.contextTableName);
            if (allBaseRecords.length > 0) {
                return (
                    <div>
                        {allBaseRecords?.map((record) => this.renderRecordItem(record))}
                    </div>
                );
            } else {
                return null;
            }
        }
    }

    renderRecordItem = (record) => {
        const { classes, itemSchema, recordContext, disabled } = this.props;

        let contextTableNode = recordContext.recordContext[itemSchema.contextTableName];
        let selected = contextTableNode.key === record.keyValue;
        let recordIdentifierString = `${itemSchema.contextTableName}:${record.keyValue}`;
        if (itemSchema.recordIdentifierFieldName) {
            let identifierValue = record.fields[itemSchema.recordIdentifierFieldName]?.value;
            if (identifierValue) {
                recordIdentifierString = identifierValue;
            }
        }
        return (
            <ListItem button onClick={this.handleSetRecordCurrency(record.keyValue)} selected={selected} key={record.keyValue} disabled={disabled}>
                {recordIdentifierString}
                <IconButton className={classes.recordDeleteButton} onClick={this.handleDeleteRow(record.keyValue)}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>
        );
    }

    handleScrollTriggered = (cellRef) => {
        const { triggerScroll } = this.props;
        if (cellRef) {
            cellRef.scrollIntoView();
            triggerScroll(cellRef);
            if (this.scrollableDivRef.current && cellRef) {
                let containerRect = this.scrollableDivRef.current.getBoundingClientRect();
                let halfContainerHeight = containerRect.height / 2;
                let containerHalfwayHeight = containerRect.top + halfContainerHeight;
                let cellRect = cellRef.getBoundingClientRect();
                //If the element that is being scrolled into center is below halfway down the table, don't shift the scrollbar up a half page. This is because it must be towards the bottom of the content.
                if (cellRect.top <= containerHalfwayHeight) {
                    this.scrollableDivRef.current.scrollTop = this.scrollableDivRef.current.scrollTop - halfContainerHeight;
                }
            }
        }
    }

    render() {
        const { classes, itemSchema, formMetaData, formData, recordContext, settings, onAddRecord, onDeleteRecords, toggleAvailable, onBlur, setRecordCurrency, executeCommandButton, highlightedFieldKeyArray, highlightedRecordKey, clearHighlightedField, disabled } = this.props;
        
        return (
            <div className={classes.dividerLineTitle} >
                <div className={classes.verticalDividerDiv}>
                    <div className={classes.recordPanelFlexContainer} disabled={disabled}>
                        <div >
                            <Typography className={toggleAvailable ? classes.recordListTitleWithSwitcher : classes.recordListTitle} align={'center'} color='inherit' variant='h6'>
                                {itemSchema.displayName ? itemSchema.displayName : itemSchema.contextTableName}
                            </Typography>
                            <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
                        </div>
                        <div className={classes.recordListFlexItem} disabled={disabled}>
                            <List className={classes.recordList}>
                                {this.renderRecordList()}
                            </List>
                        </div>
                        <Button
                            onClick={this.handleAddRecord}
                            variant='outlined'
                            disabled={disabled}
                        >
                            Add New Record
                                <AddIcon />
                        </Button>
                    </div>
                    <div className={classes.scrollableGridItem} ref={this.scrollableDivRef}>
                        <DynamicFormSection
                            sectionSchema={itemSchema.formViewSchema}
                            formMetaData={formMetaData}
                            formData={formData}
                            recordContext={recordContext}
                            settings={settings}
                            onBlur={onBlur}
                            onAddRecord={onAddRecord}
                            onDeleteRecords={onDeleteRecords}
                            setRecordCurrency={setRecordCurrency}
                            executeCommandButton={executeCommandButton}
                            highlightedFieldKeyArray={highlightedFieldKeyArray}
                            highlightedRecordKey={highlightedRecordKey}
                            clearHighlightedField={clearHighlightedField}
                            triggerScroll={this.handleScrollTriggered}
                            disabled={disabled}
                            tabView
                        />
                    </div>
                </div>
                <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
            </div>
        );
    }
}

export default withStyles(styles)(FormView);