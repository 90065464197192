import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import DemographicsReducer from './DemographicsReducer';
import RecordReducer from './RecordReducer';
import STSSurgicalRiskReducer from './STSSurgicalRiskReducer';

const Reducer = combineReducers(
    {
        routing: routerReducer,
        stsSurgicalRisk: STSSurgicalRiskReducer,
        demographics: DemographicsReducer,
        recordInfo: RecordReducer
    }
);

export default Reducer;
