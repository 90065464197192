import Avatar from '@material-ui/core/Avatar';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Event from '@material-ui/icons/Event';
import moment  from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import regularFormsStyle from ".../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { chestPainEvalApi, pushToError } from '.../utils/apiHelper';

const styles = theme => ({
    ...regularFormsStyle,
    listItem: {
        margin: 'auto'
    },
    avatar: {
        margin: 'auto',
        backgroundColor: 'transparent'
    },
    eventIcon: {
        margin: 'auto'
    },
    listItemText: {
        margin: 'auto'
    }
});

class ChestPainEventList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            eventList: []
        };
    }

    componentDidMount() {
        const { patientId, ssPatientId } = this.props.patientInfo;
        chestPainEvalApi.getEvents(ssPatientId).then(eventList => {
            this.setState({
                isLoading: false,
                eventList: eventList.map(event => ({ ...event, eventDate: moment(event.eventDate).format('MM/DD/YYYY HH:mm') }))
            });
        }).catch((error) => pushToError(error, patientId));
    }

    render() {
        const { classes, handleEventSelect } = this.props;
        const { eventList, isLoading } = this.state;
        
        if (isLoading) {
            return <CircularProgress size={60} thickness={7} />;
        } else {
            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader icon>
                                <h4 className={classes.cardTitle}>Events</h4>
                            </CardHeader>
                            <CardBody>
                                <Divider />
                                {
                                    eventList.length === 0 ?
                                        <div>
                                            {'No Events Found'}
                                            <Divider />
                                        </div>
                                        :
                                        eventList.map((event, index) => {
                                            const eventDisplayDate =  event.eventDate ? moment(event.eventDate).format('MM/DD/YYYY HH:mm') : 'N/A';
                                            return (
                                                <React.Fragment key={index}>
                                                    <ListItem className={classes.listItem} button onClick={() => handleEventSelect(event.chestPainEventId)}>
                                                        <Avatar className={classes.avatar}>
                                                            <Event className={classes.eventIcon} color='secondary' />
                                                        </Avatar>
                                                        <ListItemText
                                                            className={classes.listItemText}
                                                            primary={
                                                                <GridContainer spacing={4}>
                                                                    <GridItem xs={6}>
                                                                        {eventDisplayDate}
                                                                    </GridItem>
                                                                </GridContainer>
                                                            }
                                                        />
                                                    </ListItem>

                                                    <Divider />
                                                </React.Fragment>
                                            );
                                        })
                                }
                                <Button variant='contained' color='primary' onClick={() => handleEventSelect(0)}>
                                    Create New Event
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default connect((state) => ({ patientInfo: state.demographics }))(withRouter(withStyles(styles)(ChestPainEventList)));