import withStyles from "@material-ui/core/styles/withStyles";
import cx from "classnames";
import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import sidebarStyle from "../../jss/material-dashboard-pro-react/components/sidebarStyle";

class SidebarBranding extends React.Component {

    render() {
        const { classes, logo, logoText, bgColor, rtlActive, miniActive, isIframe } = this.props;

        const logoNormal = cx({
            [classes.logoNormal]: true,
            [classes.logoNormalSidebarMini]: miniActive,
            [classes.logoNormalSidebarMiniRTL]: rtlActive && miniActive,
            [classes.logoNormalRTL]: rtlActive,
            [classes.disabledLinks]: isIframe
        });
        const logoMini = cx({
            [classes.logoMini]: true,
            [classes.logoMiniRTL]: rtlActive,
            [classes.disabledLinks]: isIframe
        });
        const logoClasses = cx({
            [classes.logo]: true,
            [classes.whiteAfter]: bgColor === "white"
        });

        return (
            <div className={logoClasses}>
                <NavLink to={'/'} className={logoMini}>
                    <img src={logo} alt="logo" className={classes.img} />
                </NavLink>
                <NavLink to={'/'} className={logoNormal}>
                    {logoText}
                </NavLink>
            </div>
        );
    }
}

export default withStyles(sidebarStyle)(withRouter(SidebarBranding));
