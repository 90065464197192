import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from '../PropertyEditor';

const styles = theme => ({

});

class SourceColumnPropertyEditor extends React.Component {
    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                title="Source Column Property Editor"
                properties={[
                    {
                        name: "fieldName",
                        label: "Source Field Name",
                        type: "text",
                        description: "The Source Field dictates what field from the Main Source Table will be used to populate this column"
                    },
                    {
                        name: "displayName",
                        label: "Display Name (optional)",
                        type: "text"
                    },
                    {
                        name: "targetTable",
                        label: "Target Table",
                        type: "text",
                        description: "When a row is selected, data will be pulled from the source field and will be output into the Target Field in the Target Table. If you only want this data to be displayed, but not output, leave the Target Table blank."
                    },
                    {
                        name: "targetField",
                        label: "Target Field",
                        type: "text",
                        description: "When a row is selected, data will be pulled from the source field and will be output into the Target Field in the Target Table. If you only want this data to be displayed, but not output, leave the Target Field blank."
                    },
                    {
                        name: "hidden",
                        label: "Hidden",
                        type: "checkBox",
                        description: "If this checkbox is true, the column will not be displayed to the user. This property is useful for populating fields in the backend without burdening the user with extra information."
                    }
                ]}
            />
        );
    }
}

export default withStyles(styles)(SourceColumnPropertyEditor);