import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import ReactTable from 'react-table';

const styles = theme => ({
    leftText: {
        textAlign: "left"
    },
    reactTable: {
        '& .rt-thead': {
            '& .rt-th': {
                margin: '0px',
                maxHeight: '30px',
                lineHeight: '1 !important',
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily
            },
            '& .rt-th:last-child': {
                textAlign: 'left'
            }
        },
        '& .rt-td': {
            fontSize: theme.typography.fontSize,
            margin: '0px',
            padding: '3px 5px',
            height: '24px',
            display: 'flex',

            flexDirection: 'column',
            flexWrap: 'wrap',
            '& div': {
                textAlign: 'center',
                width: 'auto',
                margin: 'auto',
                '& span': {
                    margin: '0px 8px'
                }
            }
        },
        '& .rt-tr': {
            minWidth: 'fit-content',
            textAlign: 'left'
        },
        '& .rt-table': {
            flex: '1 1 auto'
        }
    },
    helpIcon: {
        height: "16px",
        width: "16px",
        color: theme.palette.background.selected,
        background: theme.palette.text.secondary,
        borderRadius: '50%'
    },
    helpButton: {
        backgroundColor: theme.palette.background.selected,
        height: "18px !important",
        width: "18px",
        minWidth: '16px',
        padding: "0px !important",
        verticalAlign: "baseline",
        borderRadius: '50%',
        [`& ${theme.IeVersion.IsIE ? 'svg' : 'path'}`]: {
            transform: 'scale(1.3)',
            transformOrigin: '50% 50%'
        }
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    categorySelector: {
        minWidth: '125px'
    },
    definitionContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        overflow: 'auto',
        height: '400px',
        width: '600px',
        scrollX: 'auto',
    },
    definitionBody: {
        margin: '3px',
        whiteSpace: 'pre-wrap',
        textAlign: 'justify'
    },
    dialog: {
        minWidth: '40vw',
        minHeight: '20vh',
        maxWidth: '70vw',
        maxHeight: '90vh'
    }
});

class FieldInfoButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            selectedIndex: 0
        };
    }

    toggleDialog = () => {
        this.setState({
            open: !this.state.open,
            showFieldInfo: true
        });
    }

    setSelectedDefinition = (index) => () => {
        this.setState({
            selectedIndex: index,
            showFieldInfo: false
        });
    }

    setFieldInfoFlag = () => () => {
        this.setState({
            showFieldInfo: true
        });
    }

    renderDefinition = (sortedDescriptions) => {
        const { classes } = this.props;
        if (sortedDescriptions !== null) {
            return (
                <div className={classes.definitionContainer}>
                    <Typography variant='h6'>
                        Definition:
                    </Typography>
                    <Divider />
                    <Typography variant='body1' className={classes.definitionBody}>
                        {sortedDescriptions[this.state.selectedIndex]?.description}
                    </Typography>
                </div>);
        }
    }

    renderFieldInfo = () => {
        const { fieldInformation, classes } = this.props;
        return (
            <div className={classes.definitionContainer}>
                <Typography variant='body1'>
                    <ReactTable
                        className={`-striped -highlight ${classes.reactTable}`}
                        data={fieldInformation !== 'undefined' ? fieldInformation : []}
                        columns={[
                            {
                                title: 'key',
                                key: 'key',
                                id: 'key',
                                sortable: false,
                                width: 180,
                                accessor: item => item.key.toString()
                            },
                            {
                                title: 'value',
                                key: 'value',
                                id: 'value',
                                width: 600,
                                sortable: false,
                                accessor: item => item.value.toString()
                            }]}
                        searchKeys={['key', 'value']}
                        manual
                        defaultPageSize={fieldInformation !== 'undefined' ? fieldInformation.length : 0}
                        showPaginationBottom={false}
                    />
                </Typography>
            </div>);
    }

    render() {
        const { open, showFieldInfo } = this.state;
        const { classes, descriptions, className, fieldInfo } = this.props;

        let sortedDescriptions = null;
        if (descriptions) {
            sortedDescriptions = descriptions.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
        }

        // The className nonDraggableSection stops the field associated with the dialog form being dragged around by mistake
        return (
            <React.Fragment>
                <Button className={`${classes.helpButton} ${className}`} onClick={this.toggleDialog} variant='outlined' tabIndex="-1">
                    <HelpIcon className={classes.helpIcon} />
                </Button>
                <Dialog classes={{ paper: classes.dialog }} open={open} onClose={this.toggleDialog}>
                    <DialogContent className={classes.dialogContent}>
                        {typeof fieldInfo !== 'undefined' ?
                            (<Typography variant='body1' className={classes.leftText}>
                                <b>Field Information:</b>(View:{fieldInfo[2]})
                                <br />
                                <b>Full Name:</b>{fieldInfo[0]}.{fieldInfo[1]}
                                <br />
                                <b>Table Name:</b>{fieldInfo[0]}  &nbsp;  &nbsp; <b>Field Name:</b>{fieldInfo[1]}
                            </Typography>) : null}
                        <div className={classes.flexContainer}>
                            <List>
                                <ListItem
                                    button
                                    onClick={this.setFieldInfoFlag()} >
                                    <Typography variant='h6'>
                                        Field Information:
                                        <Divider />
                                    </Typography>
                                </ListItem>
                                {sortedDescriptions !== null ?
                                    <ListItem>
                                        <Typography variant='h6'>
                                            Category:
                                            <Divider />
                                        </Typography>
                                    </ListItem> : null}
                                {sortedDescriptions !== null ?
                                    sortedDescriptions.map((description, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                button
                                                onClick={this.setSelectedDefinition(index)}
                                            >
                                                <Typography variant='subtitle2'>
                                                    {description.category}
                                                </Typography>
                                            </ListItem>
                                        );
                                    }) : null}
                            </List>
                            <div >
                                {showFieldInfo ? this.renderFieldInfo() : this.renderDefinition(sortedDescriptions)}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={this.toggleDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(FieldInfoButton);