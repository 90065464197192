import forms from './Forms.jpg';
import ipccc from './IPCCC.jpg';
import launchpad from './Launchpad.jpg';
import hva from './power-bi-dashboard.png';
import risk from './Risk.jpg';
import sabermedics from './Sabermedics.jpg';
import surgeonScorecard from './SurgeonScorecard.jpg';
import trends from './Trends.jpg';
import webPacs from './WebPacs.jpg';

export default {
    ipccc,
    risk,
    surgeonScorecard,
    webPacs,
    sabermedics,
    launchpad,
    trends,
    forms,
    hva
};