import * as pbi from 'powerbi-client';
import PropTypes from 'prop-types';
import React from 'react';

const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory);

class PowerBI extends React.PureComponent {
    constructor(props) {
        super(props);

        this.rootElement = null;
        this.component = null;
        this.state = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: this.props.accessToken,
            embedUrl: this.props.embedUrl,
            id: this.props.id,
            permissions: pbi.models.Permissions.All,
            settings: this.props.settings
        };
    }

    componentDidMount() {
        this.updateState(this.props);
    }

    componentDidUpdate() {
        if (this.props.accessToken !== this.state.accessToken ||
            this.props.embedUrl !== this.state.embedUrl ||
            this.props.id !== this.state.id ||
            this.props.settings !== this.state.settings
        ) {
            let newState = {
                ...this.state,
                accessToken: this.props.accessToken,
                embedUrl: this.props.embedUrl,
                id: this.props.id,
                settings: this.props.settings
            };

            this.setState(newState);

            if (this.validateConfig(newState)) {
                this.loadAndRender(newState);
            }
        }
    }

    componentWillUnmount() {
        powerbi.reset(this.rootElement);
        this.component = null;
    }

    loadAndRender = (config) => {
        this.component = powerbi.load(this.rootElement, config);
        if (this.props.onEmbedded) {
            this.props.onEmbedded(this.component);
        }
        this.component.on('loaded', () => {
            this.component.render();
        });
        return this.component;
    }

    updateState(props) {
        const nextState = Object.assign({}, this.state, props, {
            settings: {
                filterPaneEnabled: this.props.filterPaneEnabled,
                navContentPaneEnabled: this.props.navContentPaneEnabled
            }
        });
        /**
         * This property must be removed from the state object so that it doesn't get used in the embedConfig.
         * This would be passed to `powerbi.embed(element, embedConfig)` and attempted to be sent over postMessage;
         * however, functions cannot be cloned and it will fail.
         */

        delete nextState.onEmbedded;
        this.setState(nextState);
    }

    validateConfig(config) {
        const errors = pbi.models.validateReportLoad(config);
        return (typeof errors === 'undefined');
    }

    handleRef = (el) => {
        this.rootElement = el;
    }

    render() {
        const styles = {
            width: this.props.width,
            height: this.props.height
        };
        return (
            <div className={this.props.embedClassName} ref={this.handleRef} style={styles} />
        );
    }
}

PowerBI.propTypes = {
    embedClassName: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    embedUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

PowerBI.defaultProps = {
    width: '100%',
    height: '100%',
    settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true
    },
    preload: {
        type: 'report',
        baseurl: 'https://app.powerbi.com/reportEmbed'
    }
};

export default PowerBI;