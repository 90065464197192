import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Settings from '@material-ui/icons/Settings';
import React from 'react';

const styles = theme => ({
    
});

class PropertyCog extends React.Component {
    state = {
        editorIsOpen: false
    }

    //This method is to stop the draggable grid from allowing dragging when the property dialogue is open.
    handleMouseDown = (event) => {
        event.stopPropagation();
    }

    handleOpen = () => {
        this.setState({
            editorIsOpen: true
        });
    }

    handleClose = () => {
        this.setState({
            editorIsOpen: false
        });
    }

    handleConfirm = (newItemSchema) => {
        const { onItemPropertiesChanged } = this.props;

        onItemPropertiesChanged(newItemSchema);
        this.handleClose();
    }

    render() {
        const { itemSchema, itemMetaData, formMetaData, determineEditorType, cogButtonCss } = this.props;
        const { editorIsOpen } = this.state;
        let PropertyEditor = null;
        if (typeof determineEditorType === "function") {
            PropertyEditor = determineEditorType(itemSchema);
        }
        if (PropertyEditor !== null) {
            return (
                <React.Fragment>
                    <IconButton className={cogButtonCss} onClick={this.handleOpen} onMouseDown={this.handleMouseDown}>
                        <Settings />
                    </IconButton>
                    <PropertyEditor 
                        isOpen={editorIsOpen}
                        itemSchema={itemSchema}
                        itemMetaData={itemMetaData}
                        formMetaData={formMetaData}
                        onConfirm={this.handleConfirm}
                        closeDialog={this.handleClose}
                        onMouseDown={this.handleMouseDown}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment />
            );
        }
    }
}

export default withStyles(styles)(PropertyCog);