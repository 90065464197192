import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Button } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Done from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    iconButton: {
        padding: "0px !important",
        height: '18px !important',
        borderRadius: "4px",
        minWidth: "24px"
    },
    container: {
        background: theme.palette.background.selected,
        height: "18px",
        position: 'relative',
        '& .icon': {
            height: '16px',
            width: '22px'
        },
        "& .agreeingButton": {
            backgroundColor: theme.palette.lxGreen.light,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.secondary
            }
        }
    },
    headerRow: {
        background: "rgb(225, 225, 225)"
    },
    elementsButton: {
        background: "rgba(0, 0, 0, 0.22)",
        padding: "0px",
        zIndex: "50000000"
    },
    tooltipOnTop: {
        zIndex: "52000000"
    },
    buttonCell: {
        padding: "0px !important",
        width: "21px"
    },
    elementCellOpened: {
        paddingLeft: "30px !important",
        paddingRight: "0px !important",
        paddingBottom: "8px !important",
        paddingTop: "0px !important"
    },
    elementCellClosed: {
        padding: "0px !important"
    },
    expandedElementsDiv: {
        height: "350px",
        width: (window.innerWidth * 0.5).toString() + 'px',
        overflow: "auto"
    }
});

class EmrOptionRow extends React.Component {
    state = {
        elementViewerOpen: false
    }

    handleValueSelected = () => () => {
        const { handleRowClick } = this.props;
        handleRowClick();
    }

    toggleElements = () => (event) => {
        const { elementViewerOpen } = this.state;
        event.stopPropagation();
        this.setState({
            elementViewerOpen: !elementViewerOpen
        });
    }

    render() {
        const { className, classes, option } = this.props;
        const { elementViewerOpen } = this.state;
        const { value, mapping } = option;

        return (
            /*TODO DateTime values don't always appear correctly in the front end*/
            <React.Fragment>
                <TableRow hover onClick={this.handleValueSelected()}>
                    <TableCell className={classes.valueCell}>{value !== null && typeof value !== 'undefined' ? value.toString() : 'N/A'}</TableCell>
                    <TableCell>{mapping.name}</TableCell>
                    <TableCell>{mapping.emrSource.sourceType}</TableCell>
                    <TableCell>{mapping.emrSource.source}</TableCell>
                    <TableCell>
                        <Tooltip placement='top'>                            
                            <ButtonGroup variant="outlined" className={`${classes.container} ${className}`}>
                                <Button className={`agreeingButton ${classes.iconButton}`} onClick={this.handleValueSelected()}>
                                    <Done className='icon' />
                                </Button>
                            </ButtonGroup>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.buttonCell}>
                        <IconButton onClick={this.toggleElements()} className={classes.elementsButton}>
                            {elementViewerOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} className={elementViewerOpen ? classes.elementCellOpened : classes.elementCellClosed}>
                        <Collapse in={elementViewerOpen}>
                            {this.renderExpandedElements(mapping)}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    renderExpandedElements = (mapping) => {
        //MappingElements is currently not camel case because the FormData endpoint in LCDS turned off the JSON converter's flag for converting to camel case.
        //Because MappingElements is a property of an EmrMappingDef in LumedxApplication, it is done in Pascal case as is standard for .net properties.
        const { classes } = this.props;
        const { mappingElements } = mapping;
        return (
            <div className={classes.expandedElementsDiv}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell>Code System</TableCell>
                            <TableCell align="right">Code Value</TableCell>
                            <TableCell align="right">Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mappingElements.map(this.renderElementRow)}
                    </TableBody>
                </Table>
            </div>
        );
    }

    renderElementRow = (element, index) => {
        if (element !== null) {
            return (
                <TableRow key={index}>
                    <TableCell>{element.codeSystem}</TableCell>
                    <TableCell align="right">{element.codeValue}</TableCell>
                    <TableCell align="right">{element.name}</TableCell>
                </TableRow>
            );
        }
    }
}

export default withStyles(styles)(EmrOptionRow);