import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    formLabelBox: {
        display: 'flex',
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`
        }
    },
    addContextTableNameField: {
        paddingRight: `${theme.spacing(3)}px`
    },
    addLabelButton: {
        whiteSpace: 'nowrap'
    }
});

class LabelSelector extends React.Component {
    state = {
        contextTableName: ''
    }

    handleAddItem = () => {
        const { onAddItem } = this.props;
        const { contextTableName } = this.state;

        if (typeof contextTableName  === 'string' && contextTableName !== '') {
            let newGrid = {
                $lumedxType: "Grid",
                contextTableName: contextTableName,
                defaultViewMode: "GridView",
                formViewSchema: { elements: []},
                subSections: []
            };
            onAddItem(newGrid, null);
        }
    }

    updateContextTableText = (contextTableName) => {
        this.setState({
            contextTableName
        });
    }

    render() {
        const { classes } = this.props;
        const { contextTableName } = this.state;

        return (
            <div className={classes.formLabelBox}>
                <LxTextField className={classes.addContextTableNameField} value={contextTableName} placeholder='Enter Context Table Name...' onChange={this.updateContextTableText}/>
                <Button className={classes.addLabelButton} color='primary' variant='outlined' onClick={this.handleAddItem}>
                    {'ADD GRID'}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(LabelSelector);