import ".../assets/scss/react-table.css";

import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
import React from 'react';
import {withRouter} from "react-router-dom";
import ReactTable from 'react-table';
import {compose} from "redux";

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import CardIcon from '.../assets/components/Card/CardIcon';
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import { cardTitle } from ".../assets/jss/material-dashboard-pro-react";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    worklistTable:{
        marginTop: "15px"
    },
    clickableRow: {
        cursor: 'pointer'
    }
};

const getColumns = (filterResults) => {
    let columns = [];
    filterResults.rows[0].fields.forEach(field =>{
        if(field.value !== filterResults.rows[0].keyColumnValue) {
            columns.push({
                Header: field.name,
                accessor: field.name
            });
        }
    });

    return columns;
};

const getData = (filterResults) => {
    let data = [];
    filterResults.rows.forEach(row =>{
        const record = {};
        row.fields.map(field => {
            if(field.value !== row.keyColumnValue) {
                record[field.name] = field.value;
            }
            return field;
        });
        record.eventNavigatorLink = `/worklist/eventNavigator/${row.tableName}/${row.keyColumnValue}`;
        data.push(record);
    });
    return data;
};

function WorklistContainer(props) {
    const {filterResults, classes} = props;

    return filterResults.rows.length === 0
        ? "No records found"
        : (
            <GridContainer justify={"center"} style={{maxHeight: '100%', overflow:'auto'}}>
                <GridItem xs={12}>
                    <Card className={classes.worklistTable}>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment/>
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{filterResults.filterName}</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData(filterResults)}
                                filterable
                                columns={getColumns(filterResults)}
                                defaultPageSize={25}
                                getTrProps={(state, rowInfo, column) => {
                                    return {
                                        className: classes.clickableRow,
                                        onClick: () => 
                                        {
                                            props.history.push(rowInfo.original.eventNavigatorLink);
                                        }
                                    };
                                }}
                                showPaginationTop
                                showPaginationBottom
                                className="-striped -highlight"
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
}

export default compose(
    withRouter,
    withStyles(styles)
)(WorklistContainer);