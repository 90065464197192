import 'react-table/react-table.css';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import treeTableHOC from "react-table/lib/hoc/treeTable";

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import { cardTitle } from '.../assets/jss/material-dashboard-pro-react';
import buttonsStyle from '.../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { pushToError } from '.../utils/apiHelper';
import IPCCCAddEditDialog from './IPCCCAddEditDialog';
import TextBox from './TextBox';

const TreeTable = treeTableHOC(ReactTable);

const styles = theme => ({
    ...buttonsStyle,
    reactTable: {
        '& .rt-thead': {
            '& .rt-th': {
                maxHeight: '30px',
                lineHeight: '1 !important',
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily
            },
            '& .rt-th:last-child': {
                textAlign: 'left'
            }
        },
        '& .rt-td': {
            fontSize: theme.typography.fontSize,
            margin: '0px',
            padding: '0px 5px',
            height: '24px',
            display: 'flex',
            flexDirection: 'column',
            '& div': {
                textAlign: 'left',
                margin: 'auto 8px',
                '& span': {
                    margin: '0px 8px'
                }
            }
        },
        '& .rt-table': {
            overflow: 'visible'
        },
        minHeight: '170px',
        border: 'none'
    },
    archiveDialog: {
        textAlign: 'left'
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    archiveButton: {
        width: '100%',
        height: '70%',
        padding: '0px',
        lineHeight: '.5',
        fontSize: '100%',
        margin: 'auto'
    },
    showArchive: {
        height: '24px'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardButtons: {
        flex: '0 0 auto',
        marginBottom: '8px'
    },
    cardTable: {
        flex: '1 0 auto',
        maxHeight: 'calc(100vh - 155px)',
        border: '1px solid rgba(0, 0, 0, .1)',
        '& .ps__rail-y': {
            top: '52px !important'
        },
        '& .ps__rail-x': {
            bottom: '0px !important'
        }
    }
});

class IPCCCTable extends React.Component {
    constructor(props) {
        super(props);

        this.columns = props.columns.map(column => ({
            ...column,
            Header: typeof column.Header !== 'string' ?
                column.Header
                :
                <Tooltip title={column.Header} placement='top'>
                    <span>{column.Header}</span>
                </Tooltip>
        }));

        let extraColumns = [
            {
                accessor: 'archived',
                filterable: false,
                resizable: false,
                sortable: false,
                width: 90,
                Cell: row => (
                    <Button variant='contained' className={props.classes.archiveButton} size='small' onClick={() => this.handleArchiveRow(row.original)} title="Archive">
                        {row.value ? 'Restore' : 'Archive'}
                    </Button>
                )
            }
        ];

        this.columns = extraColumns.concat(this.columns);

        this.state = {
            includedColumns: this.columns.map(col => typeof col.accessor === 'string' ? col.accessor : col.id),
            confirmDeleteOpen: false,
            showArchived: true,
            formOpen: false,
            confirmUnarchiveOpen: false,
            confirmArchiveOpen: false,
            archiveReasonErrorMessage: 'Archived Reason cannot be empty',
            rows: props.rows
        };
    }

    ArchiveButton = ({ original }) => {
        if (typeof original !== 'undefined') {
            return (
                <Button variant='contained' className={this.props.classes.archiveButton} size='small' onClick={() => this.handleArchiveRow(original)} title="Archive">
                    {original.archived ? 'Restore' : 'Archive'}
                </Button>
            );
        }
    }

    EditButton = ({ onExecute }) => (
        <IconButton variant="raised" color="primary" onClick={onExecute} title="Edit">
            <EditIcon />
        </IconButton>
    )


    AddButton = ({ onExecute }) => (
        <Button variant="contained" color="primary" onClick={onExecute} title="Add">
            Add
        </Button>
    )

    handleShowArchived = (event, value) => {
        this.setState({
            showArchived: value
        });
    }

    setRows = (newRows) => {
        this.setState({
            rows: newRows
        });
        if (typeof this.props.setPatientDiagnosis !== 'undefined') {
            this.props.setPatientDiagnosis(newRows);
        }
        if (typeof this.props.setPatientInterventions !== 'undefined') {
            this.props.setPatientInterventions(newRows);
        }
        if (typeof this.props.setFetalDiagnosis !== 'undefined') {
            this.props.setFetalDiagnosis(newRows);
        }
    }

    handleAddRow = () => {
        this.setState({ formOpen: true });
    }

    handleEditRow = (edits) => {
        if (edits && edits.length > 0 && edits[0] < this.props.rows.length) {
            this.setState({
                editRowId: edits[0],
                formOpen: true
            });
        }
    }

    handleArchiveRow = (row) => {
        let { ssDemoAepcdiagId, ssDemoAepcsurgId } = row;
        let rowIndex = typeof ssDemoAepcdiagId !== 'undefined'
            ? this.props.rows.findIndex(gridRow => gridRow.ssDemoAepcdiagId === ssDemoAepcdiagId)
            : this.props.rows.findIndex(gridRow => gridRow.ssDemoAepcsurgId === ssDemoAepcsurgId);
        if (row.archived) {
            this.setState({
                pendingUnarchiveRowId: rowIndex,
                confirmUnarchiveOpen: true
            });
        }
        else {
            this.setState({
                pendingArchiveRowId: rowIndex,
                archiveReasonErrorMessage: 'Archived Reason cannot be empty',
                archivedReason: '',
                confirmArchiveOpen: true
            });
        }
    }

    handleDeleteRow = (changes) => {
        if (Array.isArray(changes.deleted) && changes.deleted.length > 0) {
            this.setState({
                pendingDeleteRowId: changes.deleted[0],
                confirmDeleteOpen: true
            });
        }
    }

    closeForm = () => {
        this.setState({
            formOpen: false,
            archiveRowId: undefined,
            editRowId: undefined
        });
    }

    closeDeleteConfirm = () => {
        this.setState({
            pendingDeleteRowId: undefined,
            confirmDeleteOpen: false
        });
    }

    closeUnarchiveConfirm = () => {
        this.setState({
            pendingUnarchiveRowId: undefined,
            confirmUnarchiveOpen: false
        });
    }

    closeArchiveConfirm = () => {
        this.setState({
            pendingArchiveRowId: undefined,
            archivedReason: '',
            confirmArchiveOpen: false
        });
    }

    deleteRow = () => {
        const { pendingDeleteRowId } = this.state;
        if (typeof pendingDeleteRowId !== 'undefined' && pendingDeleteRowId < this.state.rows.length) {
            let newRow = Object.assign([], this.state.rows);
            let { ssDemoAepcdiagId, ssDemoAepcsurgId } = newRow[pendingDeleteRowId];
            let aepcId = typeof ssDemoAepcdiagId !== 'undefined' ? ssDemoAepcdiagId : ssDemoAepcsurgId;
            newRow.splice(pendingDeleteRowId, 1);
            this.props.deleteRowApi(aepcId).catch((error) => pushToError(error, this.props.match.params.mrn));
            this.setRows(newRow);
            this.closeDeleteConfirm();
        }
    }

    unarchiveRow = () => {
        const { pendingUnarchiveRowId } = this.state;

        if (typeof pendingUnarchiveRowId !== 'undefined' && pendingUnarchiveRowId < this.state.rows.length) {
            let newRows = Object.assign([], this.state.rows);
            let newRow = newRows[pendingUnarchiveRowId];

            newRow.archived = false;
            newRow.archivedDate = null;
            newRow.archivedReason = null;

            this.props.editRowApi(newRow).catch((error) => pushToError(error, this.props.match.params.mrn));
            this.setRows(newRows);
            this.closeUnarchiveConfirm();
        }
    }

    archiveRow = () => {
        const { pendingArchiveRowId } = this.state;
        if ((typeof pendingArchiveRowId !== 'undefined' && pendingArchiveRowId < this.state.rows.length)
            && (this.state.archiveReasonErrorMessage === '' || this.state.archiveReasonErrorMessage === null)) {
            let newRows = Object.assign([], this.state.rows);
            let newRow = newRows[pendingArchiveRowId];

            newRow.archived = true;
            newRow.archivedDate = new Date().toISOString();
            newRow.archivedReason = this.state.archivedReason;

            this.props.editRowApi(newRow).catch((error) => pushToError(error, this.props.match.params.mrn));
            this.setRows(newRows);
            this.closeArchiveConfirm();
        }
    }


    handleAddSubmit = (row) => {
        this.props.addRowApi(row)
            .then(res => {
                let comparisonKey = typeof this.props.setPatientDiagnosis !== 'undefined' || typeof this.props.setFetalDiagnosis !== 'undefined' ?
                    'ssDemoAepcdiagId' :
                    'ssDemoAepcsurgId';
                let updatedRow = {
                    ...row,
                    [comparisonKey]: res
                };
                let newRows = Object.assign([], this.state.rows).concat(updatedRow);
                this.setRows(newRows);
            }).catch((error) => pushToError(error, this.props.match.params.mrn));
        this.closeForm();
    }

    handleEditArchiveSubmit = (row) => {
        if (typeof this.state.archiveRowId !== 'undefined' || typeof this.state.editRowId !== 'undefined') {
            this.props.editRowApi(row).catch((error) => pushToError(error, this.props.match.params.mrn));

            let comparisonKey = typeof this.props.setPatientDiagnosis !== 'undefined' || typeof this.props.setFetalDiagnosis !== 'undefined' ?
                'ssDemoAepcdiagId' :
                'ssDemoAepcsurgId';

            let newRows = Object.assign([], this.state.rows);

            let oldRowIndex = newRows.findIndex(r => r[comparisonKey] === row[comparisonKey]);

            newRows[oldRowIndex] = row;
            this.setRows(newRows);
            this.closeForm();
        }
    }

    updateArchivedReason = (event) => {
        if (event.target.value === '' || event.target.value === null) {
            this.setState({
                archiveReasonErrorMessage: 'Archived Reason cannot be empty',
                archivedReason: event.target.value
            });
        } else {
            this.setState({
                archiveReasonErrorMessage: '',
                archivedReason: event.target.value
            });
        }
    }

    render() {

        const { className, classes, patient, codeLibrary, itemToString, getItems, pivotBy } = this.props;
        const { rows, includedColumns, confirmUnarchiveOpen, confirmArchiveOpen, archivedReason, formOpen, editRowId, archiveRowId, showArchived } = this.state;

        const filteredRows = showArchived ? rows : rows.filter(row => !row.archived);

        let defaultExpanded = {};
        pivotBy.forEach((pivot, index) => {
            const inner = defaultExpanded;
            let expanded = {};
            filteredRows.forEach((row, index) => expanded[index] = inner);
            defaultExpanded = expanded;
        });

        return (
            <React.Fragment>
                <Card className={className}>
                    <CardBody className={classes.cardBody}>
                        <GridContainer justify='space-between' alignItems='center' className={classes.cardButtons}>
                            <GridItem>
                                <Button variant='contained' color='primary' onClick={() => this.handleAddRow()}>
                                    {"New"}
                                </Button>
                            </GridItem>
                            <GridItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            checked={this.state.showArchived}
                                            onChange={this.handleShowArchived}
                                            className={classes.showArchive}
                                        />
                                    }
                                    label='Show Archived'
                                />
                            </GridItem>
                        </GridContainer>
                        <PerfectScrollbar className={classes.cardTable}>
                            <TreeTable
                                key={`IPCCC-${filteredRows.length}`}
                                data={filteredRows}
                                columns={this.columns}
                                showPaginationBottom={false}
                                defaultPageSize={
                                    filteredRows.length
                                }
                                className={"-striped -highlight " + classes.reactTable}
                                pivotBy={typeof pivotBy !== 'undefined' ? pivotBy : []}
                                collapseOnSortingChange={false}
                                collapseOnDataChange={false}
                                collapseOnPageChange={false}
                                defaultExpanded={defaultExpanded}
                                minRows={0}
                            />
                        </PerfectScrollbar>
                    </CardBody>
                </Card>
                <Dialog open={confirmUnarchiveOpen} className={classes.archiveDialog}>
                    <DialogTitle>{"Restore Diagnosis"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {"Are you sure you want to restore the archived entry?"}
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={this.closeUnarchiveConfirm} variant='contained' color='primary' autoFocus>Cancel</Button>
                            <Button onClick={this.unarchiveRow} variant='contained' color='primary'>Confirm</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={confirmArchiveOpen} className={classes.archiveDialog}>
                    <DialogTitle>{"Archive Diagnosis"}</DialogTitle>
                    <DialogContent>
                        <Typography varient='subheading'>
                            Archived Reason:
                            </Typography>
                        <TextBox
                            name='Archived reason'
                            defaultValue={archivedReason}
                            onChange={this.updateArchivedReason}
                            maxLength={50}
                            error={this.state.archiveReasonErrorMessage !== ''}
                            helperText={this.state.archiveReasonErrorMessage}
                        />
                        <DialogActions>
                            <Button onClick={this.closeArchiveConfirm} variant='contained' color='primary' autoFocus>Cancel</Button>
                            <Button onClick={this.archiveRow} variant='contained' color='primary'>Confirm</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <IPCCCAddEditDialog
                    handleClose={this.closeForm}
                    handleAddSubmit={this.handleAddSubmit}
                    handleEditArchiveSubmit={this.handleEditArchiveSubmit}
                    itemToString={itemToString}
                    codeLibrary={codeLibrary}
                    patient={patient}
                    rows={rows}
                    getItems={getItems}
                    open={formOpen}
                    archiveRowId={archiveRowId}
                    editRowId={editRowId}
                    includes={includedColumns}
                    fetalTab={typeof this.props.setFetalDiagnosis !== 'undefined'}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(IPCCCTable));