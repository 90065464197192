import { IconButton, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    horizontalFlexDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    navButtons: {
    },
    verticalFlexDiv: {
        display: 'flex',
        flexDirection: 'column'
    },
    perPageText: {
        color: '#0000006e',
        paddingTop: '3px',
        fontSize: '.75rem'
    },
    pageText: {
        color: '#0000006e',
        paddingTop: '3px',
        fontSize: '.75rem'
    },
    perPageSelect: {
        color: '#0000006e'
    }
}));

export default function CommpactTablePager(props) {
    const { page, 
        rowsPerPage, 
        rowsPerPageOptions, 
        count,
        onChangeRowsPerPage,
        onChangePage } = props;
    const classes = useStyles();

    let pageCount = rowsPerPage < count ? Math.ceil(count / rowsPerPage) : 1;
    
    let handePageChange = (targetPage) => () => {
        onChangePage(targetPage);
    };
    
    let handleRowsPerPageChange = (event) => {
        onChangeRowsPerPage(event.target.value);
    };
    
    if (page < 0 || page + 1 > pageCount) {
        onChangePage(0);
    }

    return (
        <div className={classes.horizontalFlexDiv}>
            <IconButton
                className={classes.navButtons}
                onClick={handePageChange(page > 0 ? page - 1 : page)}
                disabled={page === 0}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <div className={classes.verticalFlexDiv}>
                <Typography className={classes.pageText}>
                    {`page ${page + 1} of ${pageCount}`}
                </Typography>
                <div className={classes.horizontalFlexDiv}>
                    <Select
                        className={classes.perPageSelect}
                        value={rowsPerPage}
                        options={rowsPerPageOptions}
                        onChange={handleRowsPerPageChange}
                    >
                        {rowsPerPageOptions.map((option) =>
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        )}
                    </Select>
                    <Typography className={classes.perPageText}>
                        {'items per page'}
                    </Typography>
                </div>
            </div>
            <IconButton
                className={classes.navButtons}
                onClick={handePageChange(page + 1 < pageCount ? page + 1 : page)}
                disabled={page + 1 >= pageCount}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
        </div>
    );
}