import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Sabermedics from './SabermedicsLandingPage';

const SabermedicsRoutes = () => (
    <Switch>
        <Route exact path="/sabermedics" component={Sabermedics} />
        <Route exact path="/patient/:mrn/sabermedics" component={Sabermedics} />
    </Switch>
);

export default withRouter(SabermedicsRoutes);