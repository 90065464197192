import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';

import GridItem from '.../assets/components/Grid/GridItem';

const styles = theme => ({
    gridItem: {
        height: "18px",
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    }
});

const StyledErrorIcon = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main
    }
}))(HighlightOffIcon);

const StyledWarningIcon = withStyles((theme) => ({
    root: {
        color: theme.palette.warning.main
    }
}))(ReportProblemOutlinedIcon);

const StyledInfoIcon = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main
    }
}))(InfoOutlinedIcon);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);

function ValidationErrorIcon(props) {
    const { classes, error, validationErrorSeverity, helperText } = props;

    return (
        error && helperText ?
            <GridItem className={classes.gridItem}>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">
                                {helperText}
                            </Typography>
                        </React.Fragment>
                    }
                    placement={'top'}
                >
                    {validationErrorSeverity === 1 ?
                        <StyledErrorIcon />
                        : validationErrorSeverity === 2 ?
                            <StyledWarningIcon />
                            : <StyledInfoIcon />
                    }
                </HtmlTooltip>
            </GridItem>
            : null
    );
}

export default withStyles(styles)(ValidationErrorIcon);