import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';

const styles = theme => ({
    tabRoot: {
        width: '0px',
        minWidth: '100%'
    }
});

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, activePanelName, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== activePanelName}
            id={`scrollable-force-tabpanel-${value}`}
            aria-labelledby={`scrollable-force-tab-${value}`}
            {...other}
        >
            {value === activePanelName && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function DynamicFormTabsSection(props){
    const {section, renderSection, setCurrentSectionName, currentSectionName, sectionList, classes} = props;
    let sortedSubsections = section.subSections.sort((a, b) => a.sequence - b.sequence);

    const handleChange = (event, newValue) => {
        setCurrentSectionName(newValue);
    };

    const getActivePanelName = () => {
        let sectionListEntry = sectionList[currentSectionName];
        //is my section currently selected
        if(section.sectionName === currentSectionName){
            return section.subSections[0]?.sectionName;
        }
        //first potential child is the section list entry
        let myChildSectionListEntry = sectionListEntry;

        //if the sectionListEntry has a parent, and that parent is not me, walk parent tree until it finds me or the root
        while(myChildSectionListEntry.parentName && myChildSectionListEntry.parentName !== section.sectionName){
            myChildSectionListEntry = sectionList[myChildSectionListEntry.parentName];
        }

        //its one of my children
        if(section.subSections.some(s => s.sectionName === myChildSectionListEntry.name)){
            return myChildSectionListEntry.name;
        }
        //its one of my parents
        return section.subSections[0]?.sectionName;
    };

    let activePanelName = getActivePanelName();

    return(
        <React.Fragment>
            <Tabs
                value={activePanelName}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                aria-label="scrollable auto tabs example"
                classes={{root: classes.tabRoot}}
            >
                {sortedSubsections.map((subSection, index) => (
                    <Tab key={subSection.sectionName} label={subSection.sectionDisplayName} value={subSection.sectionName}{...a11yProps(index)} />
                ))}
            </Tabs>
            {sortedSubsections.map((subSection, index) => (
                <TabPanel key={subSection.sectionName} value={subSection.sectionName} activePanelName={activePanelName}>
                    {renderSection(subSection)}
                </TabPanel>
            ))}
    </React.Fragment>);
}

export default withStyles(styles)(DynamicFormTabsSection);