import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from './PropertyEditor';
import GridColumnOneToManyEditor from './SubEditors/GridColumnOneToManyEditor';
import SubSectionEditor from './SubEditors/SubSectionEditor';

const styles = theme => ({

});

const GRID_VIEW_STRING = "GridView";
const GRID_VIEW_PROP_NAME = "gridViewEnabled";
const FORM_VIEW_STRING = "FormView";
const FORM_VIEW_PROP_NAME = "formViewEnabled";
class GridPropertyEditor extends React.Component {
    state = {
        gridViewEnabled: this.props.itemSchema.gridViewEnabled,
        formViewEnabled: this.props.itemSchema.formViewEnabled
    }

    handleUpdatedPropertyBindings = (propertyName, value) => {
        if (propertyName === GRID_VIEW_PROP_NAME) {
            this.setState({
                gridViewEnabled: value
            });
        } else if (propertyName === FORM_VIEW_PROP_NAME) {
            this.setState({
                formViewEnabled: value
            });
        }
    }

    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;
        const { gridViewEnabled, formViewEnabled } = this.state;

        let defaultViewModeOptions = [];
        if (gridViewEnabled && formViewEnabled) {
            defaultViewModeOptions = [GRID_VIEW_STRING, FORM_VIEW_STRING];
        }

        if (!gridViewEnabled && formViewEnabled) {
            itemSchema.defaultViewMode = FORM_VIEW_STRING;
        } else if (gridViewEnabled && !formViewEnabled) {
            itemSchema.defaultViewMode = GRID_VIEW_STRING;
        }

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                handleUpdatedPropertyBindings={this.handleUpdatedPropertyBindings}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                title="Grid Property Editor"
                properties={[
                    {
                        name: "contextTableName",
                        label: "Main Table Name",
                        type: "text",
                        description: "This field refers to the table that will serve as the primary source table for the grid. One row in this table will be considered one row in the grid."
                    },
                    {
                        name: "displayName",
                        label: "Display Name",
                        type: "text"
                    },
                    {
                        name: "tabIndex",
                        label: "Tab sequence",
                        type: "int"
                    },
                    {
                        name: "defaultViewMode",
                        label: "Default View Mode",
                        type: "radioGroup",
                        options: defaultViewModeOptions,
                        hidden: !gridViewEnabled || !formViewEnabled
                    },
                    {
                        name: GRID_VIEW_PROP_NAME,
                        label: "Grid View Enabled?",
                        type: "checkBox"
                    },
                    {
                        name: "columns",
                        CustomRender: GridColumnOneToManyEditor,
                        hidden: !gridViewEnabled
                    },
                    {
                        name: FORM_VIEW_PROP_NAME,
                        label: "Form View Enabled?",
                        type: "checkBox"
                    },
                    {
                        name: "formViewSchema",
                        CustomRender: SubSectionEditor,
                        customProps: {
                            contextTableName: itemSchema.contextTableName
                        },
                        hidden: !formViewEnabled
                    },
                    {
                        name: "recordIdentifierFieldName",
                        label: "Record Identifier Field Name",
                        type: "text",
                        hidden: !formViewEnabled,
                        description: "The Record Identifier Field Name is the field that will be used to identify records from the record list. The value found from the field entered here will be what the user sees to represent each record."
                    }
                ]}
            />
        );
    }
}

export default withStyles(styles)(GridPropertyEditor);