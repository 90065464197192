import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';

const styles = (theme) => ({
    button: {
        padding: `0px ${theme.spacing(4)}px 0px ${theme.spacing(2)}px`,
        borderWidth: '1px',
        margin: '0px'
    },
    buttonLabel: {
        width: '100%'
    },
    icon: {
        transform: 'rotate(180deg) scale(.75)',
        height: '20px'
    },
    dialogContent: {
        textAlign: 'justify'
    },
    dialogActions: {
        justifyContent: 'center'
    }
});

class Disclaimer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    onClick = () => {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const { className, classes } = this.props;
        const { open } = this.state;

        return (
            <React.Fragment>
                <Button
                    className={`${className} ${classes.button}`}
                    classes={{ label: classes.buttonLabel }}
                    fullWidth
                    onClick={this.onClick}
                >
                    <GridContainer justify='center'>
                        <GridItem>
                            <InfoIcon className={classes.icon} />
                        </GridItem>
                        <GridItem>
                            <Typography align='left' variant='body1'>
                                {"DISCLAIMER"}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </Button>
                <Dialog
                    open={open}
                    onClose={this.onClick}
                >
                    <DialogTitle>{"Disclaimer"}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            {`
                                Medical Co-Pilot is a software device intended to be used by medical professionals to help identify patients at risk for specific events using statistical models.
                                There are inherent limitations to statistical risk-adjusted models, and the user should always use their best medical judgement when reviewing a patient's potential risks.
                                There are factors which may impact an individual patient which are not included in a risk model, which could increase or decrease a patient's risk of an adverse outcome.
                                All calculated risks should be supplemented by the professional judgement of the patients’ healthcare provider.
                            `}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button variant='contained' color='primary' onClick={this.onClick}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Disclaimer);