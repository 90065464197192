import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';

import CoefficientItem from './CoefficientItem.js';

const styles = theme => ({
    containerList: {
        margin: '-12px 0px -4px 0px'
    },
    showHideButton: {
        padding: '4px'
    }
});

class Coefficients extends React.Component {
    state = {
        showCoefficients: false
    }

    getCoefficientItems = (coefficients, isLoading) => {
        let filteredList = coefficients.filter(coefficient => coefficient.value !== 0);
        let sortedList = filteredList.sort((a, b) => a.value === b.value ? 0 : a.value > b.value ? -1 : 1);
        return sortedList.map((coefficient, index) =>
            <CoefficientItem coefficient={coefficient} key={index} index={index} isLoading={isLoading} />);
    }

    onToggleCoefficients = () => this.setState({ showCoefficients: !this.state.showCoefficients });

    render() {
        const { coefficients, isLoading } = this.props;
        const { showCoefficients } = this.state;
        return (
            <List dense className={this.props.classes.containerList}>
                <ListItem className={this.props.classes.showHideButton} button onClick={this.onToggleCoefficients}>
                    <ListItemText primary={showCoefficients ? "Hide Coefficients" : "Show Coefficients"} />
                    {showCoefficients ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={showCoefficients}>
                    <List className={`coefficientList`} dense disablePadding>
                        {this.getCoefficientItems(coefficients, isLoading)}
                    </List>
                </Collapse>
            </List>
        );
    }
}


export default withStyles(styles)(Coefficients);