import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
    imageContainer: {
        width: '100%',
        height: '100%'
    },
    image: {
        flex: 1,
        maxWidth: '100%',
        maxHeight: '100%',
        resizeMode: 'contain'
    }
});

class ImageElement extends React.Component {

    render() {
        const { classes, itemSchema } = this.props;

        return (
            <div className={classes.imageContainer}>
                <img className={classes.image} src={itemSchema.base64ImageString} />
            </div>
        );
    }
}

export default withStyles(styles)(ImageElement);