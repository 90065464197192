import React from 'react';

const ConflictingConfirmedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 70.391 30">
      <g id="medical_co_pilot_pill" data-name="medical co pilot pill" transform="translate(0.5 0.5)">
        <path id="Path_2385" data-name="Path 2385" d="M16.43.5v29H44.8a6.85,6.85,0,0,0,6.582-7.107V7.607A6.85,6.85,0,0,0,44.8.5Z" transform="translate(18.009 -0.5)" fill="#63bc43" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_2386" data-name="Path 2386" d="M35.453.5v29H7.082A6.85,6.85,0,0,1,.5,22.393V7.607A6.85,6.85,0,0,1,7.082.5Z" transform="translate(-0.5 -0.5)" fill="#d3d3d3" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="bullet-conflicting" transform="translate(5.476 2.499)">
          <path id="Path_2401" data-name="Path 2401" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2402" data-name="Path 2402" d="M4.2,12.5a1.5,1.5,0,1,0,0,3A1.538,1.538,0,0,0,5.7,14,1.473,1.473,0,0,0,4.2,12.5Zm0,6a1.5,1.5,0,0,0,0,3A1.538,1.538,0,0,0,5.7,20,1.473,1.473,0,0,0,4.2,18.5Zm3,2.5h14V19H7.2v2Zm0-6h14V13H7.2v2ZM16,9.5l-1.2,1.2L8,3.9,9.2,2.7ZM14.9,2.8,16,4,9.2,10.8,8.1,9.6Z" />
        </g>
        <g id="bullet-conflicting-2" data-name="bullet-conflicting" transform="translate(5.476 2.65)">
          <path id="Path_2401-2" data-name="Path 2401" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2402-2" data-name="Path 2402" d="M4.2,12.5a1.5,1.5,0,1,0,0,3A1.538,1.538,0,0,0,5.7,14,1.473,1.473,0,0,0,4.2,12.5Zm0,6a1.5,1.5,0,0,0,0,3A1.538,1.538,0,0,0,5.7,20,1.473,1.473,0,0,0,4.2,18.5Zm3,2.5h14V19H7.2v2Zm0-6h14V13H7.2v2ZM16,9.5l-1.2,1.2L8,3.9,9.2,2.7ZM14.9,2.8,16,4,9.2,10.8,8.1,9.6Z" />
        </g>
        <g id="Checkmark" transform="translate(40.392 3)">
          <path id="Path_2377" data-name="Path 2377" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2378" data-name="Path 2378" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" />
        </g>
      </g>
    </svg>
  )
}

const ConflictingUnconfirmedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 70.391 30">
      <g id="medical_co_pilot_pill" data-name="medical co pilot pill" transform="translate(0.5 0.5)">
        <path id="Path_2386" data-name="Path 2386" d="M35.453.5v29H7.082A6.85,6.85,0,0,1,.5,22.393V7.607A6.85,6.85,0,0,1,7.082.5Z" transform="translate(-0.5 -0.5)" fill="#d3d3d3" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="bullet-conflicting" transform="translate(5.476 2.65)">
          <path id="Path_2401" data-name="Path 2401" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2402" data-name="Path 2402" d="M4.2,12.5a1.5,1.5,0,1,0,0,3A1.538,1.538,0,0,0,5.7,14,1.473,1.473,0,0,0,4.2,12.5Zm0,6a1.5,1.5,0,0,0,0,3A1.538,1.538,0,0,0,5.7,20,1.473,1.473,0,0,0,4.2,18.5Zm3,2.5h14V19H7.2v2Zm0-6h14V13H7.2v2ZM16,9.5l-1.2,1.2L8,3.9,9.2,2.7ZM14.9,2.8,16,4,9.2,10.8,8.1,9.6Z" />
        </g>
        <g id="bullet-conflicting-2" data-name="bullet-conflicting" transform="translate(5.476 2.499)">
          <path id="Path_2401-2" data-name="Path 2401" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2402-2" data-name="Path 2402" d="M4.2,12.5a1.5,1.5,0,1,0,0,3A1.538,1.538,0,0,0,5.7,14,1.473,1.473,0,0,0,4.2,12.5Zm0,6a1.5,1.5,0,0,0,0,3A1.538,1.538,0,0,0,5.7,20,1.473,1.473,0,0,0,4.2,18.5Zm3,2.5h14V19H7.2v2Zm0-6h14V13H7.2v2ZM16,9.5l-1.2,1.2L8,3.9,9.2,2.7ZM14.9,2.8,16,4,9.2,10.8,8.1,9.6Z" />
        </g>
        <path id="Path_2405" data-name="Path 2405" d="M16.43.5v29H44.8a6.85,6.85,0,0,0,6.582-7.107V7.607A6.85,6.85,0,0,0,44.8.5Z" transform="translate(18.009 -0.5)" fill="#ffbf00" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="exclamation" transform="translate(40.392 3)">
          <path id="Path_2379" data-name="Path 2379" d="M0,0H24V24H0Z" fill="none" />
          <circle id="Ellipse_1" data-name="Ellipse 1" cx="2" cy="2" r="2" transform="translate(10 17)" fill="#333" />
          <path id="Path_2380" data-name="Path 2380" d="M10,3h4V15H10Z" fill="#333" />
        </g>
      </g>
    </svg>
  )
}

const MappedMissingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 70.391 30">
      <g id="medical_co_pilot_pill" data-name="medical co pilot pill" transform="translate(0.5 0.5)">
        <path id="Path_2385" data-name="Path 2385" d="M16.43.5v29H44.8a6.85,6.85,0,0,0,6.582-7.107V7.607A6.85,6.85,0,0,0,44.8.5Z" transform="translate(18.009 -0.5)" fill="#ffbf00" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_2386" data-name="Path 2386" d="M35.453.5v29H7.082A6.85,6.85,0,0,1,.5,22.393V7.607A6.85,6.85,0,0,1,7.082.5Z" transform="translate(-0.5 -0.5)" fill="#d3d3d3" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="link_black_24dp_copy" data-name="link_black_24dp copy" transform="translate(6.476 3.65)">
          <path id="Path_2369" data-name="Path 2369" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2370" data-name="Path 2370" d="M3.9,12A3.116,3.116,0,0,1,7,8.9h4V7H7A5,5,0,0,0,7,17h4V15.1H7A3.116,3.116,0,0,1,3.9,12ZM8,13h8V11H8Zm9-6H13V8.9h4a3.1,3.1,0,1,1,0,6.2H13V17h4A5,5,0,0,0,17,7Z" />
        </g>
        <g id="question_mark_black_24dp" transform="translate(40.392 3)">
          <g id="Group_122" data-name="Group 122">
            <rect id="Rectangle_1012" data-name="Rectangle 1012" width="24" height="24" fill="none" />
          </g>
          <g id="Group_123" data-name="Group 123">
            <path id="Path_2381" data-name="Path 2381" d="M11.07,12.85c.77-1.39,2.25-2.21,3.11-3.44A2.356,2.356,0,0,0,12,5.71,3.04,3.04,0,0,0,9.13,8.05L6.54,6.96A5.659,5.659,0,0,1,11.99,3a5.37,5.37,0,0,1,4.78,2.41,4.806,4.806,0,0,1,.03,4.9c-1.2,1.77-2.35,2.31-2.97,3.45-.25.46-.35.76-.35,2.24H10.59A6.045,6.045,0,0,1,11.07,12.85ZM14,20a2,2,0,1,1-2-2A2.006,2.006,0,0,1,14,20Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

const MappingErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 70.391 30">
      <g id="medical_co_pilot_pill" data-name="medical co pilot pill" transform="translate(0.5 0.5)">
        <path id="Path_2385" data-name="Path 2385" d="M16.43.5v29H44.8a6.85,6.85,0,0,0,6.582-7.107V7.607A6.85,6.85,0,0,0,44.8.5Z" transform="translate(18.009 -0.5)" fill="#dd5852" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_2386" data-name="Path 2386" d="M35.453.5v29H7.082A6.85,6.85,0,0,1,.5,22.393V7.607A6.85,6.85,0,0,1,7.082.5Z" transform="translate(-0.5 -0.5)" fill="#d3d3d3" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="mapping_error_pt_2" data-name="mapping error pt 2" transform="translate(5.392 3)">
          <path id="Path_2382" data-name="Path 2382" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2383" data-name="Path 2383" d="M17,7H13V8.9h4A3.116,3.116,0,0,1,20.1,12a3.212,3.212,0,0,1-2.3,3l1.5,1.5a5.036,5.036,0,0,0,2.8-4.4A5.121,5.121,0,0,0,17,7Zm-1,4H13.8l2,2H16ZM2,4.3,5.1,7.4A4.985,4.985,0,0,0,7,17h4V15.1H7a3.108,3.108,0,0,1-.3-6.2l2,2.1H8v2h2.7L13,15.3V17h1.7l4,4L20,19.7,3.3,3Z" />
          <path id="Path_2384" data-name="Path 2384" d="M0,24V0" fill="none" />
        </g>
        <g id="ic_clear_black_24px" transform="translate(40.392 3)" clip-path="url(#clip-path)">
          <path id="Path_2125" data-name="Path 2125" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" />
          <path id="Path_2126" data-name="Path 2126" d="M0,0H24V24H0Z" fill="none" />
        </g>
      </g>
    </svg>
  )
}

const NonConflictingConfirmedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 70.391 30">
      <g id="medical_co_pilot_pill" data-name="medical co pilot pill" transform="translate(0.5 0.5)">
        <path id="Path_2386" data-name="Path 2386" d="M35.453.5v29H7.082A6.85,6.85,0,0,1,.5,22.393V7.607A6.85,6.85,0,0,1,7.082.5Z" transform="translate(-0.5 -0.5)" fill="#d3d3d3" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="bullet-nonconflicting" transform="translate(7.976 4.649)">
          <path id="bullet-nonconflicting-2" data-name="bullet-nonconflicting" d="M4.7,12.4a1.5,1.5,0,1,0,0,3,1.538,1.538,0,0,0,1.5-1.5A1.473,1.473,0,0,0,4.7,12.4Zm0,6a1.5,1.5,0,0,0,0,3,1.538,1.538,0,0,0,1.5-1.5A1.473,1.473,0,0,0,4.7,18.4Zm3,2.5h14v-2H7.7v2Zm0-6h14v-2H7.7v2ZM9.4,5.1,7.7,6.7l4.2,4.2,6.7-6.7L16.9,2.5l-5,5Z" transform="translate(-3.2 -2.5)" fill="#010101" />
        </g>
        <path id="Path_2406" data-name="Path 2406" d="M16.43.5v29H44.8a6.85,6.85,0,0,0,6.582-7.107V7.607A6.85,6.85,0,0,0,44.8.5Z" transform="translate(18.009 -0.5)" fill="#63bc43" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="Checkmark" transform="translate(40.392 3)">
          <path id="Path_2377" data-name="Path 2377" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_2378" data-name="Path 2378" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" />
        </g>
      </g>
    </svg>
  )
}

const NonConflictingUnconfirmedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 70.391 30">
      <g id="medical_co_pilot_pill" data-name="medical co pilot pill" transform="translate(0.5 0.5)">
        <path id="Path_2385" data-name="Path 2385" d="M16.43.5v29H44.8a6.85,6.85,0,0,0,6.582-7.107V7.607A6.85,6.85,0,0,0,44.8.5Z" transform="translate(18.009 -0.5)" fill="#ffbf00" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_2386" data-name="Path 2386" d="M35.453.5v29H7.082A6.85,6.85,0,0,1,.5,22.393V7.607A6.85,6.85,0,0,1,7.082.5Z" transform="translate(-0.5 -0.5)" fill="#d3d3d3" stroke="#707070" stroke-miterlimit="10" stroke-width="1" />
        <g id="bullet-nonconflicting" transform="translate(7.976 4.649)">
          <path id="bullet-nonconflicting-2" data-name="bullet-nonconflicting" d="M4.7,12.4a1.5,1.5,0,1,0,0,3,1.538,1.538,0,0,0,1.5-1.5A1.473,1.473,0,0,0,4.7,12.4Zm0,6a1.5,1.5,0,0,0,0,3,1.538,1.538,0,0,0,1.5-1.5A1.473,1.473,0,0,0,4.7,18.4Zm3,2.5h14v-2H7.7v2Zm0-6h14v-2H7.7v2ZM9.4,5.1,7.7,6.7l4.2,4.2,6.7-6.7L16.9,2.5l-5,5Z" transform="translate(-3.2 -2.5)" fill="#010101" />
        </g>
        <g id="exclamation" transform="translate(40.392 3)">
          <path id="Path_2379" data-name="Path 2379" d="M0,0H24V24H0Z" fill="none" />
          <circle id="Ellipse_1" data-name="Ellipse 1" cx="2" cy="2" r="2" transform="translate(10 17)" fill="#333" />
          <path id="Path_2380" data-name="Path 2380" d="M10,3h4V15H10Z" fill="#333" />
        </g>
      </g>
    </svg>
  )
}

export { ConflictingConfirmedIcon, ConflictingUnconfirmedIcon, MappedMissingIcon, MappingErrorIcon, NonConflictingConfirmedIcon, NonConflictingUnconfirmedIcon };