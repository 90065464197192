import TextField from '@material-ui/core/TextField';
import React from 'react';

export default class TextBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: props.defaultValue,
            textField: typeof props.defaultValue !== 'undefined' && props.defaultValue !== null ? props.defaultValue : ''
        };
        this.maxLength = typeof props.maxLength !== 'undefined' ? props.maxLength : undefined;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.defaultValue !== state.defaultValue) {
            return ({
                defaultValue: props.defaultValue,
                textField: typeof props.defaultValue !== 'undefined' && props.defaultValue !== null ? props.defaultValue : ''
            });
        }

        return null;
    }

    handleOnChange = (event) => {
        this.setState({
            textField: typeof this.maxLength === 'undefined' ? event.target.value : event.target.value.substring(0, this.maxLength)
        });
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    render() {
        const { defaultValue, value, onChange, maxLength, ...others } = this.props;
        return <TextField {...others} onChange={this.handleOnChange} value={this.state.textField} />;
    }
}