import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    formLabelBox: {
        display: 'flex',
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`
        }
    },
    addLabelTextField: {
        paddingRight: `${theme.spacing(3)}px`
    },
    addLabelButton: {
        whiteSpace: 'nowrap'
    }
});

class LabelSelector extends React.Component {
    state = {
        labelText: ''
    }

    updateLabelText = (labelText) => {
        this.setState({
            labelText
        });
    }

    addLabel = () => {
        const { onAddItem } = this.props;
        const { labelText } = this.state;
        let labelElement = {
            text: labelText,
            $lumedxType: "Label"
        };

        if (typeof labelText === 'string' && labelText !== '') {
            onAddItem(labelElement, null);
            this.setState({
                labelText: ''
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { labelText } = this.state;

        return (
            <div className={classes.formLabelBox}>
                <LxTextField className={classes.addLabelTextField} value={labelText} placeholder='Enter Label Text...' onChange={this.updateLabelText}/>
                <Button className={classes.addLabelButton} color='primary' variant='outlined' onClick={this.addLabel}>
                    {'ADD LABEL'}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(LabelSelector);