import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ReactDOM from 'react-dom';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { imagesApi } from '.../utils/apiHelper';
import ImageLoader from '.../utils/ImageLoader';
import mediaQueries from '.../utils/mediaQueries';

const styles = theme => ({
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        zIndex: '998'
    },
    portraitMobileSidebar: {
        height: '15vh',
        overflow: 'hidden'
    },
    horizontalMobileSidebar: {
        height: '80vh',
        overflow: 'hidden'
    },
    sectionTitle: {
        margin: '10px',
        textAlign: 'center'
    },
    thumbnailList: {
        height: '85vh',
        overflowY: 'auto',
        padding: '0px'
    },
    portraitMobileThumbnailList: {
        height: '100%',
        margin: 'auto',
        overflowX: 'scroll'
    },
    horizontalMobileThumbnailList: {
        height: '100%',
        margin: 'auto',
        overflowY: 'scroll',
        zIndex: '0'
    },
    selectedListItem: {
        backgroundColor: `${theme.palette.primary.main} !important`
    }
});

class Thumbnails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };

        this.refs = props.studies.map(() => null);
    }

    componentDidMount = () => window.addEventListener('resize', this.handleWindowResize)

    componentDidUpdate(prevProps) {
        if (this.props.studies.length !== this.refs.length) {
            this.refs = this.props.studies.map(() => null);
        }
        if (prevProps.selectedThumbnail !== this.props.selectedThumbnail) {
            let ref = this.refs[this.props.selectedThumbnail];
            if (typeof ref !== 'undefined' && ref !== null) {
                ref.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.handleWindowResize)

    handleWindowResize = () => this.setState({ width: window.innerWidth })

    setRef = (ref, index) => {
        if (ref !== null && this.refs.length > index) {
            this.refs[index] = ReactDOM.findDOMNode(ref);
        }
    }

    render() {
        const { width } = this.state;
        const { studies, selectedThumbnail, classes, onThumbnailSelect } = this.props;

        const mobileLayout = width <= mediaQueries.breakpointLargePixels;

        if (mobileLayout && window.innerWidth < window.innerHeight) { // Mobile layout Portrait
            return (
                <Paper className={classes.portraitMobileSidebar}>
                    <GridContainer className={classes.portraitMobileThumbnailList} wrap='nowrap' spacing={4}>
                        {studies.map((image, index) => (
                            <GridItem
                                key={image.ssdicomimageId}
                                className={selectedThumbnail === index ? classes.selectedListItem : ''}
                                onClick={() => onThumbnailSelect(index)}
                                ref={ref => this.setRef(ref, index)}
                            >
                                <ImageLoader key={image.ssdicomimageId + '-ImageLoader'} fit='height' imagePromise={imagesApi.getJpeg(image.ssdicomimageId)} />
                            </GridItem>
                        ))}
                    </GridContainer>
                </Paper>
            );
        } else if (mobileLayout) { // Mobile layout Horizontal
            return (
                <Paper className={classes.horizontalMobileSidebar}>
                    <List disablePadding className={classes.horizontalMobileThumbnailList} defaultValue={selectedThumbnail}>
                        {studies.map((image, index) => (
                            <ListItem
                                key={image.ssdicomimageId}
                                button
                                divider
                                value={index}
                                disableGutters
                                className={selectedThumbnail === index ? classes.selectedListItem : ''}
                                onClick={() => onThumbnailSelect(index)}
                                ref={ref => this.setRef(ref, index)}
                            >
                                <ImageLoader key={image.ssdicomimageId + '-ImageLoader'} fit='width' imagePromise={imagesApi.getJpeg(image.ssdicomimageId)} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            );
        }

        return (
            <Paper className={classes.sideBar} >
                <Typography className={classes.sectionTitle} variant="subtitle1">
                    {`THUMBNAILS (${studies.length})`}
                </Typography>
                <Divider />
                <List className={classes.thumbnailList} defaultValue={selectedThumbnail}>
                    {studies.map((image, index) => (
                        <ListItem
                            key={image.ssdicomimageId}
                            button
                            divider
                            value={index}
                            className={selectedThumbnail === index ? classes.selectedListItem : ''}
                            onClick={() => onThumbnailSelect(index)}
                        >
                            <ImageLoader key={image.ssdicomimageId + '-ImageLoader'} imagePromise={imagesApi.getJpeg(image.ssdicomimageId)} />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        );
    }
}

export default withStyles(styles)(Thumbnails);