import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import CompactTablePager from '.../components/features/widgets/CompactTablePager';
import CollapseWrapper from '../forms/FormSidePanel/CollapseWrapper';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxSelectField from '.../components/formComponents/LxSelectField';

const styles = theme => ({
    truncatedText: {
        width: '220px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '.75rem !important'
    },
    gridContainer: {
        height: 'inherit'
    },
    compactDescriptionCell: {
        padding: '0px !important'
    },
    buttonContainer: {
        marginBottom: '5px'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
});

class FhirWidget extends React.Component {
    state = {
        fullView: false,
        page: 0,
        rowsPerPage: 10,
        filterOption: '',
        conflictingStatus: '',
        mappedStatus: '',
        totalCount: 0
    }

    componentDidUpdate = () => {
        this.props.onResize();
    }

    handleNavigateToValidation = (targetFhirField) => () => {
        const { onNavigateToFhirField, formData, recordContext, formSchema } = this.props;
        let targetTable = formData.tables[targetFhirField.element.split('.')[0]];
        let eventTableName = formSchema.header.eventTable;
        let eventTableKey = recordContext.getRecord(formData, eventTableName).keyValue;

        if (targetTable) {
            let targetFieldInfo = targetTable.includedFieldInfos[targetFhirField.element.split('.')[1]];
            if (targetFieldInfo) {
                if (Array.isArray(targetFieldInfo.formLocations) && targetFieldInfo.formLocations.length > 0) {
                    //Currently this always navigates to the first element listed in the collection, in the future we may want to add more intelligence or user input to this decision.
                    let targetFormLocation = targetFieldInfo.formLocations[0];
                    if (targetFormLocation) {
                        onNavigateToFhirField(targetFormLocation.sectionName, targetFormLocation.navigationKey, targetFhirField.element.split('.')[0], eventTableKey);
                    }
                }
            }
        }
    }

    renderTableRow = (singleFhirElement) => {
        const { classes } = this.props;
        return (
            <Tooltip
                key={`${singleFhirElement.element}:${singleFhirElement.status}`}
                title={singleFhirElement.element}
            >
                <TableRow
                    onClick={this.handleNavigateToValidation(singleFhirElement)}
                    hover
                    style={{ cursor: 'pointer' }}
                >
                    <TableCell className={classes.compactDescriptionCell}>
                        <Typography className={classes.truncatedText}>
                            {this.renderMessage(singleFhirElement)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </Tooltip>
        );
    }

    isNoEmrOptionsForEveryElement = (newMappingElementsStatus) => {
        let result = newMappingElementsStatus.every(element => {
            if (element.emrOptions.length === 0) {
                return true;
            }
        });
        return result;
    };

    renderMessage = (singleFhirElement) => {
        const { mappingElementsStatus } = this.props;
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
        let message = '';
        switch (singleFhirElement.mappedStatus) {
            case "Mapped-Missing": message = (singleFhirElement.element.split('.')[1] + ' data is missing');
                break;
            case "Mapping-Error":
                if (isNoEmrOptionsForAllelements) {
                    message = (singleFhirElement.element.split('.')[1] + ' data is missing');
                } else {
                    message = (singleFhirElement.element.split('.')[1] + ' has mapping error');
                }
                break;
            default: message = (singleFhirElement.element.split('.')[1] + ' is ' + singleFhirElement.status);
                break;
        }
        return message;
    }


    renderTableBody = (tempFilterOption, tempConflictingStatus, tempMappedStatus) => {
        const { mappingElementsStatus } = this.props;
        const { page, rowsPerPage } = this.state;

        if (mappingElementsStatus) {
            let fhirSubPage;
            if (tempFilterOption !== '') {
                fhirSubPage = mappingElementsStatus
                    .filter((element) => (element.remark !== 'Pre-Initialization') && (element.status === tempFilterOption))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
            else if (tempConflictingStatus !== '') {
                fhirSubPage = mappingElementsStatus
                    .filter((element) => (element.remark !== 'Pre-Initialization') && (element.conflictStatus === tempConflictingStatus))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
            else if (tempMappedStatus !== '') {
                let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
                let isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
                if (isNoEmrOptionsForAllelements && tempMappedStatus === 'Mapping-Error') {
                    fhirSubPage = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.mappedStatus === ''))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
                } else if (isNoEmrOptionsForAllelements && tempMappedStatus === 'Mapped-Missing') {
                    fhirSubPage = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.mappedStatus === 'Mapped-Missing' || element.mappedStatus === 'Mapping-Error'))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
                } else {
                    fhirSubPage = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.mappedStatus === tempMappedStatus))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
                }
            }
            else {
                fhirSubPage = mappingElementsStatus
                    .filter((element) => (element.remark !== 'Pre-Initialization'))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
            if (fhirSubPage.length !== 0) {
                return (
                    <TableBody>
                        {fhirSubPage.map((element) => this.renderTableRow(element))}
                    </TableBody>
                );
            }
        }
    }

    onFilterOptionChange = (selectedFilterOption) => {
        const { mappingElementsStatus } = this.props;
        let tempFilterOption = '', tempConflictingStatus = '', tempMappedStatus = '';

        this.setState({
            page: 0,
            filterOption: '',
            conflictingStatus: '',
            mappedStatus: '',
            totalCount: 0
        }, () => {
            let fhirDataElements;
            switch (selectedFilterOption) {
                case "Unconfirmed data": tempFilterOption = 'Unconfirmed';
                    fhirDataElements = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.status === tempFilterOption));
                    break;
                case "Confirmed data": tempFilterOption = 'Confirmed';
                    fhirDataElements = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.status === tempFilterOption));
                    break;
                case "Conflicting data": tempConflictingStatus = 'Conflicting data';
                    fhirDataElements = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.conflictStatus === tempConflictingStatus));
                    break;
                case "Nonconflicting data": tempConflictingStatus = 'Nonconflicting data';
                    fhirDataElements = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.conflictStatus === tempConflictingStatus));
                    break;
                case "Error": tempMappedStatus = 'Mapping-Error';
                    var newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
                    var isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
                    if (isNoEmrOptionsForAllelements) {
                        tempMappedStatus = '';
                    }
                    fhirDataElements = mappingElementsStatus
                        .filter((element) => (element.remark !== 'Pre-Initialization') && (element.mappedStatus === tempMappedStatus));

                    tempMappedStatus = 'Mapping-Error';
                    break;
                case "Missing data": tempMappedStatus = 'Mapped-Missing';
                    var newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
                    var isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
                    if (isNoEmrOptionsForAllelements) {
                        fhirDataElements = mappingElementsStatus
                            .filter((element) => (element.remark !== 'Pre-Initialization') && (element.mappedStatus === 'Mapped-Missing' || element.mappedStatus === 'Mapping-Error'));
                        break;
                    } else {
                        fhirDataElements = mappingElementsStatus
                            .filter((element) => (element.remark !== 'Pre-Initialization') && (element.mappedStatus === tempMappedStatus));
                        break;
                    }
            }

            if (fhirDataElements !== undefined) {
                this.setState({ totalCount: fhirDataElements.length })
            }

            this.setState({
                filterOption: tempFilterOption,
                conflictingStatus: tempConflictingStatus,
                mappedStatus: tempMappedStatus
            }, () => {
                console.log(this.state.totalCount, 'totalCount');
            });
            this.renderTableBody(tempFilterOption, tempConflictingStatus, tempMappedStatus);
        });
    }

    renderFilterOption = () => {
        const { classes } = this.props;
        const filterList = ['All', 'Unconfirmed data', 'Confirmed data', 'Conflicting data', 'Nonconflicting data', 'Error', 'Missing data'];

        return (
            <div className={classes.buttonContainer}>
                <GridContainer spacing={4} className={classes.gridContainer}>
                    <GridItem xs={12}>
                        <LxSelectField
                            title=' Filter By'
                            classes={{
                                dropdownRoot: classes.dropdownRoot,
                                dropdownPaper: classes.dropdownPaper
                            }}
                            defaultValue='All'
                            options={filterList}
                            nullable={false}
                            onChange={this.onFilterOptionChange}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    render() {
        const { mappingElementsStatus } = this.props;
        const { page, rowsPerPage, totalCount, filterOption, conflictingStatus, mappedStatus } = this.state;

        if (mappingElementsStatus) {
            let tempCount = 0;
            if (totalCount > 0 || (filterOption !== '' || conflictingStatus !== '' || mappedStatus !== '')) {
                tempCount = totalCount
            }
            else {
                tempCount = mappingElementsStatus
                    .filter((element) => (element.remark !== 'Pre-Initialization')).length
            }
            return (
                <div>
                    {this.renderFilterOption()}
                    <Table size={'small'}>
                        {this.renderTableBody(this.state.filterOption, this.state.conflictingStatus, this.state.mappedStatus)}
                    </Table>
                    <CompactTablePager
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        count={tempCount}
                        onChangePage={(newPage) => this.setState({ page: newPage })}
                        onChangeRowsPerPage={(newRowsPerPage) => this.setState({ rowsPerPage: newRowsPerPage })}
                    />
                </div>
            );
        } else {
            return (
                <React.Fragment />
            );
        }
    }
}
export default withStyles(styles)(CollapseWrapper(FhirWidget));