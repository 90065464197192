const LOAD = 'LOAD';

const STSSurgicalRiskReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD:
            return {
                data: action.data,
                guid: action.guid
            };
        default:
            return state;
    }
};


// Simulates data loaded into this reducer from somewhere
export const load = (data, guid) => ({ type: LOAD, data, guid });

export default STSSurgicalRiskReducer;