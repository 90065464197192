import React, {useState, useEffect} from 'react';
import { worklistApi, pushToError } from '.../utils/apiHelper';
import CircularProgress from "@material-ui/core/CircularProgress";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import WorklistContainer from ".../components/patientSearch/WorklistContainer";

function WorklistRoutesContainer(props){
    const {match} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [filterResults, setFilterResults] = useState(null);

    useEffect(() => {
        worklistApi.getWorklistFilterResults(match.params.filterNamespace, match.params.filterName).then(filterResult => {
            setFilterResults(filterResult);
            setIsLoading(false);
        }).catch(error => pushToError(error));
    }, [match.params.filterNamespace, match.params.filterName]);

    return(
        isLoading
            ? <CircularProgress size={60} thickness={7} />
            : <WorklistContainer filterResults={filterResults}/>
    );
}

export default compose(
    withRouter
)(WorklistRoutesContainer);