import { UserManager } from 'oidc-client';

const userManagerConfig = {
    client_id: 'js',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.pathname ? `${window.location.pathname.replace(/\/$/, '')}` : ''}${window.location.port ? `:${window.location.port}` : ''}/#/callback#`,
    response_type: 'id_token token',
    scope: 'openid profile coreapi lcds hva emrapi lumedxprofile',
    authority: typeof window.env.IDP_ROUTE !== 'undefined' ? window.env.IDP_ROUTE : null,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.pathname ? `${window.location.pathname.replace(/\/$/, '')}` : ''}${window.location.port ? `:${window.location.port}` : ''}/#/silent_renew#`,
    automaticSilentRenew: true,
    silentRequestTimeout: 30000,
    filterProtocolClaims: true,
    loadUserInfo: true
};

const userManager = new UserManager(userManagerConfig);

userManager.events.addSilentRenewError((error) => {
    console.warn("Silent Renew", error);
});

export default userManager;