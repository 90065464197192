import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    title: {
        flex: '0 1 auto',
        margin: 'auto',
        fontSize: '30px'
    },
    button: {
        flex: '0 1 auto',
        margin: '8px auto 8px auto'
    }
});

class StudyWorklist extends React.Component {
    onStudySelect = (studyId) => {
        this.props.history.push(`${this.props.location.pathname}/${studyId}`);
    }

    render() {
        const { classes } = this.props;

        let studyId = window.env.API_ROUTE === 'https://mcdemo.westus.cloudapp.azure.com/medicalcopilotapi/api' ? 53 : 7;

        return (
            <div className={classes.list}>
                <div className={classes.title}>
                    Please Select a Study:
                </div>
                <Button  color="primary" variant="contained" onClick={() => this.onStudySelect(studyId)} className={classes.button}>
                    Study ID: {studyId}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(StudyWorklist));