import React, {useState, useEffect} from 'react';
import { worklistApi, pushToError } from '.../utils/apiHelper';
import CircularProgress from "@material-ui/core/CircularProgress";
import {compose} from "redux";
import {withRouter, Redirect} from "react-router-dom";


function WorklistRoutesContainer(props){
    const {match} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [filterRecord, setFilterRecord] = useState(null);

    useEffect(() => {
        worklistApi.getFilterRecordDto(match.params.tableName, match.params.keyValue).then(result => {
            setFilterRecord(result);
            setIsLoading(false);
        }).catch(error => pushToError(error));
    }, [match.params.tableName, match.params.keyValue]);

    return(
        isLoading
            ? <CircularProgress size={60} thickness={7} />
            : match.params.keyValue === 0
                ? <Redirect to={`/patient/${filterRecord.mrn}/forms/${match.params.tableName}/${match.params.keyValue}/${filterRecord.newRecordDefaultFormName}`}/>
                : <Redirect to={`/patient/${filterRecord.mrn}/forms/${match.params.tableName}/${match.params.keyValue}/${filterRecord.defaultFormName}`}/>
    );
}

export default compose(
    withRouter
)(WorklistRoutesContainer);