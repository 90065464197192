import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { pushToError, selectionSetApi } from '.../utils/apiHelper';
import SelectionSetSelector from './SelectionSetSelector';
import SelectionSetElementSelector from './SelectionSetElementSelector';
import SelectionSetEditor from './SelectionSetEditor';
import SelectionSetElementEditor from './SelectionSetElementEditor';

const styles = theme => ({
    selectionSetCard: {
        height: '60%',
        display: 'block'
    },
    selectionSetElementCard: {
        height: '40%',
        display: 'block'
    },
    gridContainer: {
        height: 'inherit'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class SelectionSetContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectionSetList: null,
            selectionSetElementList: null
        };
    }
    componentDidMount() {
        this.getSelectionSets();
    }

    getSelectionSets() {
        this.setState({
            isLoading: true
        });
        selectionSetApi.getSelectionSets().then(selectionSetList => {
            this.setState({
                selectionSetList,
                isLoading: false
            });
        }).catch(error => pushToError(error));
        console.log("List Of selection sets : " + this.state.selectionSetList);
    }

    getSelectionSetElements = (selectionSet) => {
        this.setState({
            isLoadingElements: true
        });
        selectionSetApi.getSelectionSetElements(selectionSet).then(selectionSetElementList => {
            this.setState({
                selectionSetElementList,
                isLoadingElements: false
            });
        }).catch(error => pushToError(error));
    }

    delete = (sS_Selection_Sets_ID) => {
        if (sS_Selection_Sets_ID !== null) {
            this.setState({
                isLoading: true
            });
            selectionSetApi.deleteSelectionSet(sS_Selection_Sets_ID).then(() => {
                this.showDeletedSnackbar();
                this.getSelectionSets();
            }).catch(error => pushToError(error));
        }
    }

    deleteSelectionSetElement = (SSSelectionSetID) => {
        if (SSSelectionSetID !== null) {
            this.setState({
                isLoadingElements: true
            });
            selectionSetApi.deleteSelectionSetElement(SSSelectionSetID).then(() => {
                this.showElementDeletedSnackbar();
                this.getSelectionSetElements(this.state.selectionSetElementDef.selectionSet);
            }).catch(error => pushToError(error));
        }
    }

    handleSelectionSetEdit = (selectionSetDef) => {
        console.log(selectionSetDef);
        this.setState({
            selectionSetDef,
            openDialog: true
        });        
    }

    handleSelectionSetElementEdit = (selectionSetElementDef) => {
        console.log(selectionSetElementDef);
        this.setState({
            selectionSetElementDef,
            openDialogElement: true
        });
    }

    handleSelectionSetElementSelect = (selectionSetElementDef) => {
        console.log(selectionSetElementDef);
        this.setState({
            selectionSetElementDef
        });
    }

    handleSelectionSetSelect = (selectionSetDef) => {
        console.log(selectionSetDef);
        this.setState({
            selectionSetDef,
            isLoadingElements: true
        });
        this.getSelectionSetElements(selectionSetDef.selectionSet);
    }

    openSelectionSetEditor = () => {
        let selectionSetDetails = {
            selectionSetId: 0,
            selectionSet: '',
            formLock: false,
            userLock: false,
            clinicalDoc: false,
            attributes: 0
        };

        this.setState({
            selectionSetDef: selectionSetDetails,
            openDialog: true
        });
    }

    openSelectionSetElementEditor = () => {
        let selectionSetElementDetails = {
            selectionSetElementId: 0,
            selectionSet: this.state.selectionSetDef.selectionSet,
            elementText: '',
            elementOrder: 0
        };

        this.setState({
            selectionSetElementDef: selectionSetElementDetails,
            openDialogElement: true
        });
    }

    closeSelectionSetEditor = () => {
        this.setState({
            openDialog: false
        });
    }

    closeSelectionSetElementEditor = () => {
        this.setState({
            openDialogElement: false
        });
    }

    changeSelectionSet = (newSelectionSet) => {
        this.setState({
            selectionSetDef: newSelectionSet
        });
    }

    changeSelectionSetElement = (newSelectionSetElement) => {
        this.setState({
            selectionSetElementDef: newSelectionSetElement
        });
    }

    showSavedSnackbar = () => {
        this.setState({
            savedSnackbarOpen: true
        });
    };

    showElementSavedSnackbar = () => {
        this.setState({
            savedElementSnackbarOpen: true
        });
    };

    handleSavedSnackbarClose = () => {
        this.setState({
            savedSnackbarOpen: false
        });
    };

    handleElementSavedSnackbarClose = () => {
        this.setState({
            savedElementSnackbarOpen: false
        });
    };

    showDeletedSnackbar = () => {
        this.setState({
            deletedSnackbarOpen: true
        });
    };

    showElementDeletedSnackbar = () => {
        this.setState({
            deletedElementSnackbarOpen: true
        });
    };

    handleDeletedSnackbarClose = () => {
        this.setState({
            deletedSnackbarOpen: false
        });
    };

    handleDeletedElementSnackbarClose = () => {
        this.setState({
            deletedElementSnackbarOpen: false
        });
    };

    saveSelectionSet = () => {
        if (this.state.selectionSetDef !== null) {
            selectionSetApi.saveSelectionSet(this.state.selectionSetDef).then(() => {
                this.showSavedSnackbar();
                this.getSelectionSets();
                this.closeSelectionSetEditor();
            }).catch(error => pushToError(error));
        }
    }

    saveSelectionSetElement = () => {
        if (this.state.selectionSetElementDef !== null) {
            selectionSetApi.saveSelectionSetElements(this.state.selectionSetElementDef).then(() => {
                this.showElementSavedSnackbar();
                this.getSelectionSetElements(this.state.selectionSetElementDef.selectionSet);
                this.closeSelectionSetElementEditor();
            }).catch(error => pushToError(error));
        }
    }

    render() {
        const { classes } = this.props;
        const { selectionSetList,
            selectionSetDef,
            selectionSetElementDef,
            selectionSetElementList,
            openDialog,
            openDialogElement,
            isLoading,
            isLoadingElements,
            savedSnackbarOpen,
            savedElementSnackbarOpen,
            deletedSnackbarOpen,
            deletedElementSnackbarOpen } = this.state;

        return (
            <React.Fragment>
                <GridContainer spacing={4} className={classes.gridContainer}>
                    <GridItem xs={12} style={{ height: '60%' }}>
                        <SelectionSetSelector
                            onSelectionSetSelect={this.handleSelectionSetSelect}
                            onSelectionSetEdit={this.handleSelectionSetEdit}
                            onDeleteSelectionSet={this.delete}
                            onSelectionSetEditorOpen={this.openSelectionSetEditor}
                            selectionSetList={selectionSetList}
                            isLoading={isLoading}
                        />
                    </GridItem>
                    <GridItem xs={12} style={{ height: '40%' }}>
                        <SelectionSetElementSelector
                            onSelectionSetElementSelect={this.handleSelectionSetElementSelect}
                            onSelectionSetElementEdit={this.handleSelectionSetElementEdit}
                            onDeleteSelectionSetElement={this.deleteSelectionSetElement}
                            onSelectionSetElementEditorOpen={this.openSelectionSetElementEditor}
                            selectionSetElementList={selectionSetElementList}
                            isLoading={isLoadingElements}
                        />
                    </GridItem>
                </GridContainer>

                <Dialog open={openDialog} maxWidth="lg" onClose={this.closeSelectionSetEditor}>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeSelectionSetEditor}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Selection Set Editor'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <SelectionSetEditor
                            populateSelectionSet={selectionSetDef}
                            onSelectionSetChange={this.changeSelectionSet}
                            onSaveSelectionSet={this.saveSelectionSet}
                        />
                    </DialogContent>
                    <MuiDialogActions>
                        <Button color="primary" variant='contained' onClick={this.saveSelectionSet}>
                            Save
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                <Dialog open={openDialogElement} maxWidth="lg" onClose={this.closeSelectionSetElementEditor}>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeSelectionSetElementEditor}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Selection Set Element Editor'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <SelectionSetElementEditor
                            populateSelectionSetElement={selectionSetElementDef}
                            onSelectionSetElementChange={this.changeSelectionSetElement}
                            onSaveSelectionSetElement={this.saveSelectionSetElement}
                        />
                    </DialogContent>
                    <MuiDialogActions>
                        <Button color="primary" variant='contained' onClick={this.saveSelectionSetElement}>
                            Save
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={savedSnackbarOpen}
                    onClose={this.handleSavedSnackbarClose}
                    message="Selection Set Saved!"
                    autoHideDuration={3000}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={deletedSnackbarOpen}
                    onClose={this.handleDeletedSnackbarClose}
                    message="Selection Set Deleted!"
                    autoHideDuration={3000}
                />

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={savedElementSnackbarOpen}
                    onClose={this.handleElementSavedSnackbarClose}
                    message="Selection Set Element Saved!"
                    autoHideDuration={3000}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={deletedElementSnackbarOpen}
                    onClose={this.handleElementDeletedSnackbarClose}
                    message="Selection Set Element Deleted!"
                    autoHideDuration={3000}
                />
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(SelectionSetContainer);