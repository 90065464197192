import { powerbiApi } from '.../utils/apiHelper';
import { CheckAuthorization } from '.../utils/AuthorizationFilter';

const GetMiniString = (inputString) => {
    return inputString.split(' ').map(word => {
        if (typeof word === 'string' && word[0].toLowerCase() !== word[0].toUpperCase()) {
            return word[0].toUpperCase();
        } else {
            return null;
        }
    }).join('');
};

const GetHvaViews = (mrn) => new Promise((resolve, reject) => {
    let mrnProvided = typeof mrn !== 'undefined';

    CheckAuthorization('HVA:User').then(hva => {
        if (hva) {
            let views = [];

            powerbiApi.getUserModules().then(userModules => {
                userModules.forEach(module => {
                    let lowerModuleName = module.productName.toLowerCase();
                    let modulePath = mrnProvided ? `/patient/${mrn}/hva/${lowerModuleName}` : `/hva/${lowerModuleName}`;

                    views.push({
                        path: modulePath,
                        name: module.productName,
                        mini: GetMiniString(module.productName),
                        link: false,
                        views: module.features.map(feature => {
                            let lowerFeatureName = feature.featureName.toLowerCase();
                            let featurePath = mrnProvided ? `/patient/${mrn}/hva/${lowerModuleName}/${lowerFeatureName}` : `/hva/${lowerModuleName}/${lowerFeatureName}`;
                            return {
                                path: featurePath,
                                name: feature.properties.displayName,
                                mini: GetMiniString(feature.properties.displayName),
                                link: true
                            };
                        })
                    });
                });
                resolve(views);
            }).catch(err => reject(err));
        } else {
            resolve([]);
        }
    });
});

export default GetHvaViews;