import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCheckbox from '.../components/formComponents/LxCheckbox';
import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 50px)',
        width: '100%'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        display: 'flex'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class SelectionSetElementEditor extends React.Component {

    renderSelectionSetElementEditor = () => {
        const { classes, populateSelectionSetElement } = this.props;

        return (
            <GridContainer className={classes.flex} spacing={5}>
                <GridItem xs={12} >
                    <LxTextField
                        align='center'
                        title='Element Text'
                        value={populateSelectionSetElement?.elementText}
                        max={255}
                        onChange={this.onSelectionSetElementTextChange}
                    />
                </GridItem>                
                <GridItem xs={12}>
                <LxTextField
                        align='center'
                        title='Order'
                        value={populateSelectionSetElement?.elementOrder}
                        max={255}
                        onChange={this.onSelectionSetElementOrderChange}
                    />                    
                </GridItem>                
            </GridContainer>
        );
    }

    onSelectionSetElementTextChange = (newSelectionSetElementText) => {
        this.setState({
            newSelectionSetElementText
        });

        const { populateSelectionSetElement, onSelectionSetElementChange } = this.props;
        if (populateSelectionSetElement?.elementText !== newSelectionSetElementText) {
            let newSelectionSetElements = {
                ...populateSelectionSetElement,
                elementText: newSelectionSetElementText
            };
            onSelectionSetElementChange(newSelectionSetElements, populateSelectionSetElement.elementText);
        }
    }

    onSelectionSetElementOrderChange = (newSelectionSetElementOrder) => {
        this.setState({
            newSelectionSetElementOrder
        });

        const { populateSelectionSetElement, onSelectionSetElementChange } = this.props;
        if (populateSelectionSetElement?.elementOrder !== newSelectionSetElementOrder) {
            let newSelectionSetElements = {
                ...populateSelectionSetElement,
                elementOrder: newSelectionSetElementOrder
            };
            onSelectionSetElementChange(newSelectionSetElements, populateSelectionSetElement.elementOrder);
        }
    }    

    render() {
        const { classes } = this.props;
        return (
            <>
                <Card className={classes.card}>
                    <CardBody>
                        {this.renderSelectionSetElementEditor()}
                    </CardBody>
                </Card>
            </>
        );
    }
}
export default withStyles(styles)(SelectionSetElementEditor);