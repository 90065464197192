import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PermIdentity from '@material-ui/icons/PermIdentity';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    listItem: {
        margin: 'auto'
    },
    avatar: {
        margin: 'auto',
        backgroundColor: 'transparent'
    },
    textContainer: {
        flex: '1 1 auto',
        margin: 'auto',
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column'
    }
});

const dateLongFormat = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
};

const PatientWorklistItem = ({ classes, patient = {}, selected, handlePatientSelect, showUniqueId }) => {
    let birthDate = new Date(patient.dateOfBirth);

    return (
        <div>
            <Divider />
            <ListItem className={classes.listItem} selected={selected} button onClick={() => handlePatientSelect(patient)}>
                <Avatar className={classes.avatar}>
                    <PermIdentity color='secondary' />
                </Avatar>
                <div className={classes.textContainer}>
                    <Typography variant='body1' color='textPrimary'>
                        {`Name: ${patient.lastName}, ${patient.firstName}`}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                        {`DOB: ${birthDate.toString() !== 'Invalid Date' ?
                            birthDate.toLocaleDateString('en-US', dateLongFormat)
                            :
                            patient.dateOfBirth}`
                        }
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                        {`MRN: ${patient.mrn}`}
                    </Typography>
                    {showUniqueId ?
                        <Typography variant='body2' color='textSecondary'>
                            {`UniqueID: ${patient.uniqueSourceId}`}
                        </Typography>
                        :
                        null
                    }
                </div>
            </ListItem>
        </div>
    );
};

PatientWorklistItem.propTypes = {
    patient: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string,
        mrn: PropTypes.string.isRequired,
        uniqueSourceId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]).isRequired
    }).isRequired,
    handlePatientSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    showUniqueId: PropTypes.bool
};

PatientWorklistItem.defaultProps = {
    selected: false,
    showUniqueId: false
};

export default withStyles(styles)(PatientWorklistItem);