import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";

import ValidationWidget from ".../components/features/widgets/ValidationWidget";
import FhirWidget from ".../components/features/widgets/FhirWidget";
import WrappedSectionList from "./WrappedSectionList";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    panelWrapper: {
        position: 'fixed',
        width: '275px',
        //TODO this 65px buffer is a manual number to account for the height of the back/save buttons. Ultimately a better solution should be found.
        height: `calc(100% - ${theme.topBarOffset}px - 65px)`,
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    listHeader: {
        marginLeft: '15px'
    },
    widgetGrid: {
        marginTop: '15px',
        width: '250px'
    }
});

const ROW_HEIGHT = 5;
const SECTION_LIST_NAME = 'SectionList';
const VALIDATION_PANEL_NAME = 'ValidationPanel';
const FHIR_PANEL_NAME = 'FHIRPanel';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DynamicFormSidePanel extends React.Component {
    constructor(props) {
        super(props);
        let itemOrder = [];
        //let itemOrder = MOCK_WIDGET_OBJECT.sort(this.sortSectionsBySchema).map((widget) => widget.name);
        //Adding Hardcoded items to the order array
        itemOrder.splice(0, 0, SECTION_LIST_NAME);
        itemOrder.splice(0, 0, FHIR_PANEL_NAME);
        itemOrder.splice(0, 0, VALIDATION_PANEL_NAME);

        this.state = {
            itemOrder,
            itemHeights: {}
        };
    }

    updateWidgetLayout = (layout) => {
        const { itemOrder } = this.state;
        let newItemOrder = [...itemOrder];
        newItemOrder.sort((a, b) => {
            let aLayout = layout.find(itemLayout => itemLayout.i === a);
            let bLayout = layout.find(itemLayout => itemLayout.i === b);
            return aLayout.y - bLayout.y;
        });

        this.setState({
            itemOrder: newItemOrder
        });
    }

    sortSectionsBySchema = (a, b) => a.sequence - b.sequence

    setItemHeight = (itemName) => (newHeight) => {
        const { itemHeights } = this.state;
        itemHeights[itemName] = newHeight;
        this.setState({
            itemHeights
        });
    }

    renderSectionList = () => {
        const { sectionListProps } = this.props;
        const { itemHeights } = this.state;
        if (sectionListProps) {
            const { sectionList, onSectionSelect, currentSectionName } = sectionListProps;
            return (
                <div key={SECTION_LIST_NAME}>
                    <WrappedSectionList
                        uniqueName={SECTION_LIST_NAME}
                        displayName={"Sections"}
                        defaultOpen
                        onClick={onSectionSelect}
                        sections={sectionList}
                        currentSectionName={currentSectionName}
                        currentHeight={itemHeights[SECTION_LIST_NAME]}
                        setHeight={this.setItemHeight(SECTION_LIST_NAME)}
                    />
                </div>
            );
        } else {
            return (
                <div key={SECTION_LIST_NAME} className={`nonDraggableSection`} />
            );
        }
    }

    renderFhirWidget = () => {
        const { mappingElementsStatus, onFieldNavigation, onUpdateMappingStatus, recordContext, formSchema, formData } = this.props;
        const { itemHeights } = this.state;
        if (mappingElementsStatus.length > 0) {
            return (
                <div key={FHIR_PANEL_NAME}>
                    <FhirWidget
                        uniqueName={FHIR_PANEL_NAME}
                        displayName={"FHIR Integration"}
                        defaultOpen
                        currentHeight={itemHeights[FHIR_PANEL_NAME]}
                        setHeight={this.setItemHeight(FHIR_PANEL_NAME)}
                        onNavigateToFhirField={onFieldNavigation}
                        formData={formData}
                        mappingElementsStatus={mappingElementsStatus}
                        onUpdateMappingStatus={onUpdateMappingStatus}
                        recordContext={recordContext}
                        formSchema={formSchema}
                    />
                </div>
            );
        } else {
            return (
                <div key={FHIR_PANEL_NAME} className={`nonDraggableSection`} />
            );
        }
    }

    // renderWidgets = () => {
    //     const { triggerWidgetCall, formData } = this.props;
    //     const { itemHeights } = this.state;
    //     return (
    //         MOCK_WIDGET_OBJECT.map((widget) => (
    //             //This div needs to be here because for some reason the ResponsiveReactGridLayout isn't dragable if widgetWrapper is the first layer under it.
    //             <div key={widget.name}>
    //                 <WidgetWrapper
    //                     widget={widget}
    //                     triggerWidgetCall={triggerWidgetCall}
    //                     formData={formData}
    //                     currentHeight={itemHeights[widget.name]}
    //                     setHeight={this.setItemHeight(widget.name)}
    //                 />
    //             </div>
    //         ))
    //     );
    // }

    render() {
        const { formData, classes, onFieldNavigation, mappingElementsStatus, onUpdateMappingStatus, recordContext, formSchema } = this.props;
        const { itemOrder, itemHeights } = this.state;

        let layouts = {
            xs: itemOrder.map((itemName, index) => {
                return ({
                    i: itemName,
                    x: 0,
                    y: index,
                    w: 1,
                    h: itemHeights[itemName] ? itemHeights[itemName] / ROW_HEIGHT + 2 : 0,
                    isResizable: false
                });
            })
        };

        return (
            <div className={classes.panelWrapper}>
                <ResponsiveReactGridLayout
                    className={classes.widgetGrid}
                    breakpoints={{ xs: 0 }}
                    cols={{ xs: 1 }}
                    layouts={layouts}
                    rowHeight={ROW_HEIGHT}
                    margin={[0, 0]}
                    containerPadding={[0, 0]}
                    onLayoutChange={this.updateWidgetLayout}
                    draggableCancel='.nonDraggableSection'
                    compactType={"vertical"}
                >
                    {this.renderSectionList()}
                    <div key={VALIDATION_PANEL_NAME}>
                        <ValidationWidget
                            uniqueName={VALIDATION_PANEL_NAME}
                            displayName={"Validation"}
                            defaultOpen
                            currentHeight={itemHeights[VALIDATION_PANEL_NAME]}
                            setHeight={this.setItemHeight(VALIDATION_PANEL_NAME)}
                            onNavigateToValidation={onFieldNavigation}
                            formData={formData}
                        />
                    </div>
                    {this.renderFhirWidget()}
                </ResponsiveReactGridLayout>
            </div>
        );
    }
}

export default withStyles(styles)(DynamicFormSidePanel);