import { Button, Typography } from '@material-ui/core';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme =>({
    cardHeader: {
        padding: '2px'
    },
    card: {
        marginTop: `10px`,
        backgroundColor: 'yellow'
    },
    avatar: {
        marginTop: '1px',
        marginLeft: '5px',
        width: '25px',
        height: '25px'
    },
    buttonStyle: {
        border: `1px solid ${theme.palette.lxGreen.main}`,
        backgroundColor: theme.palette.lxGreen.light,
        color: 'white',
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '2px',
        marginRight: '15px',
        verticalAlign: 'middle'
    },    
    bodyText: {
        marginTop: '1px',
        marginLeft: '2px',
        textAlign: 'left'
    }
});

class FormViewOnlyBar extends React.Component {
    render() {
        const { formDisplayName, onEnableEditing, classes, moduleSecurity } = this.props;
        
        return (
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    avatar={
                        <Avatar className={classes.avatar}>
                            i
                        </Avatar>
                    }
                    action={
                        <Button className={classes.buttonStyle} onClick={onEnableEditing} disabled={!moduleSecurity.allowEdit}>
                            <Typography variant='body2'>
                                Enable Editing
                            </Typography>
                        </Button>
                    }
                    subheader={
                        <Typography variant="body1" className={classes.bodyText}>
                            <b>View Only</b>: Form '{formDisplayName}' is view only by default. Click Enable Editing to allow editing.
                        </Typography>
                    }
                />
            </Card>
        );
    }
}
export default withStyles(styles)(FormViewOnlyBar);