import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxCombobox from '.../components/formComponents/LxCombobox';

const styles = theme => ({
    formFieldBox: {
        display: 'flex',
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`
        }
    },
    addFieldButton: {
        whiteSpace: 'nowrap'
    }
});

class FieldSelector extends React.Component {
    state = {
        selectedFormItem: null
    }

    downshiftRef = null

    getDownshiftRef = (ref) => this.downshiftRef = ref   

    formItemToString = (formItem) => (
        formItem ?
            `${formItem.displayName} ${formItem.fieldName} ${formItem.tableName} ${formItem.inputType} ${formItem.description}`
            :
            ''
    )
 
    formItemToDisplayString = (formItem) => (
        formItem ?
            `${formItem.displayName}: ${formItem.tableName}.${formItem.fieldName}`
            :
            ''
    )

    updateFormSelection = (formItem) => {
        this.setState({
            selectedFormItem: formItem
        });
    }

    addFormSelection = () => {
        const { onAddItem } = this.props;
        const { selectedFormItem } = this.state;
        
        if (selectedFormItem) {
            let newItem = {
                tableName: selectedFormItem.tableName,
                fieldName: selectedFormItem.fieldName,
                metaDataKey: selectedFormItem.uniqueKey,
                $lumedxType: "ApolloField"
            };
            onAddItem(newItem, selectedFormItem);
            this.setState({
                selectedFormItem: null
            });
            if (this.downshiftRef !== null) {
                this.downshiftRef.clearSelection();
            }
        }
    }

    render() {
        const { classes, formItemsLoading, formItems } = this.props;
        return (
            <div className={classes.formFieldBox}>
                <LxCombobox
                    disabled={!formItemsLoading && formItems === null}
                    error={!formItemsLoading && formItems === null}
                    helperText={!formItemsLoading && formItems === null ?
                        'An Event Table must be selected before adding fields'
                        :
                        ''
                    }
                    items={formItems !== null ? formItems : []}
                    itemsLoading={formItemsLoading}
                    placeholder='Search Valid Database Fields...'
                    itemToString={this.formItemToString}
                    inputDisplayTemplate={this.formItemToDisplayString}
                    onChange={this.updateFormSelection}
                    columns={[
                        {
                            title: 'Display Name',
                            key: 'displayName'
                        },
                        {
                            title: 'Field Name',
                            key: 'fieldName'
                        },
                        {
                            title: 'Table Name',
                            key: 'tableName'
                        }
                    ]}
                    fitInputWidth
                    downshiftRef={this.getDownshiftRef}
                    //The keepInputOnExit is what fixed the double click issue. 
                    //if this property is false/undefined the menu will reset itself to an unfiltered version, which the second click will select
                    keepInputOnExit
                />
                <Button className={classes.addFieldButton} color='primary' variant='outlined' onClick={this.addFormSelection}>
                    {'ADD FIELD'}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(FieldSelector);