import { IconButton, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';

const cssStyles = {
    widgetFlexContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    minimizeIconButton: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        right: '5px',
        top: '2px'
    },
    headerDiv: {
        height: '28px',
        cursor: 'move'
    },
    headerText: {
        textAlign: 'left',
        marginLeft: '15px'
    }
};

export default function CollapseWrapper(WrappedComponent) {
    return class extends React.Component {
        state = {
            open: this.props.defaultOpen
        }

        componentDidMount = () => {
            this.recalculateHeight(this.props.defaultOpen);
        }

        componentDidUpdate = () => {
            const { open } = this.state;
            this.recalculateHeight(open);
        }

        handleResize = () => {
            const { open } = this.state;
            this.recalculateHeight(open);
        }

        toggleOpen = () => {
            const { open } = this.state;
            this.setState({
                open: !open
            });
            this.recalculateHeight(!open);
        }

        recalculateHeight = (optimisticOpen) => {
            const { currentHeight, setHeight } = this.props;
            if (this.contentRef && this.headerRef) {
                let newHeight;
                if (optimisticOpen) {
                    newHeight = this.contentRef.clientHeight + this.headerRef.clientHeight;
                } else if (!optimisticOpen) {
                    newHeight = this.headerRef.clientHeight;
                }
                if (newHeight !== currentHeight) {
                    setHeight(newHeight);
                }
            }
        }

        render() {
            const { displayName, uniqueName } = this.props;
            const { open } = this.state;

            return (
                <Paper key={uniqueName} >
                    <div style={cssStyles.widgetFlexContainer}>
                        <div style={cssStyles.headerDiv} ref={(headerRef) => { this.headerRef = headerRef; }}>
                            <Typography style={cssStyles.headerText} variant={'subtitle1'}>
                                {displayName}
                            </Typography>
                            <IconButton style={cssStyles.minimizeIconButton} onClick={this.toggleOpen}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </div>
                        <Collapse in={open} id={`collapsePanel-${uniqueName}`}>
                            {/* If you don't wrap the rendered widget in a div, you can in some circumstances end up with an infinite loop of resizing. */}
                            <div className={`nonDraggableSection`} ref={(contentRef) => { this.contentRef = contentRef; }}>
                                <WrappedComponent onResize={this.handleResize} {...this.props}/>
                            </div>
                        </Collapse>
                    </div>
                </Paper>
            );
        }
    };
}