import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pause from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';
import SkipNext from '@material-ui/icons/SkipNext';
import SkipPrevious from '@material-ui/icons/SkipPrevious';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import * as wado from 'cornerstone-wado-image-loader';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import * as dicomParser from 'dicom-parser';
import Hammer from 'hammerjs';
import React from 'react';
import ReactDOM from 'react-dom';

import mediaQueries from '.../utils/mediaQueries';
import userManager from '.../utils/userManager';

const styles = theme => ({
    hiddenContainer: {
        height: '100%',
        width: '100%',
        margin: 'auto'
    },
    imageWrapper: {
        height: '100%',
        width: '100%',
        margin: 'auto',
        background: 'black',
        position: 'relative'
    },
    managementBar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    managementBarMobileHorizontal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: `${theme.spacing(1)}px`
    },
    managementButton: {
        flex: '0 0 auto',
        marginBottom: `${theme.spacing(1)}px`
    },
    managementButtonMobile: {
        flex: '0 0 auto',
        width: '90px',
        margin: `${theme.spacing(1)}px 0px`
    },
    dicomImage: {
        height: '100%',
        width: '100%',
        '& .magnifyTool': {
            border: '4px white solid',
            boxShadow: '2px 2px 10px #1e1e1e',
            borderRadius: '50%',
            display: 'none',
            cursor: 'none',
            zIndex: '100'
        }
    },
    controlBar: {
        width: '100%',
        height: '56px',
        display: 'flex',
        alignItems: 'stretch',
        margin: 'auto',
        marginTop: '0px'
    },
    controlBarMobileHorizontal: {
        width: '100%',
        height: '28px',
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        margin: 'auto',
        marginTop: '0px'
    },
    lowerControlMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    playProgressMobile: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column'
    },
    playButton: {
        flex: '0 1 auto',
        margin: '8px',
        marginLeft: '0px'
    },
    playSlider: {
        flex: '1 0 auto',
        margin: '8px'
    },
    playFPS: {
        flex: '0 1 auto',
        margin: '8px',
        marginRight: '0px',
        marginTop: '16px'
    },
    playButtonMobile: {
        flex: '0 1 auto',
        marginLeft: '0px'
    },
    playSliderMobile: {
        flex: '1 1 auto'
    },
    playFPSMobile: {
        flex: '0 0 auto',
        margin: 'auto',
        marginLeft: `${theme.spacing(1)}px`,
        marginRight: '0px'
    },
    infoBar: {
        width: '100%',
        height: '35px',
        background: 'black',
        color: 'white',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    infoItem: {
        margin: '0px 8px 0px 8px'
    },
    error: {
        color: 'red'
    },
    circularProgress: {
        margin: 'auto'
    },
    cornerstone: {
        height: '80%'
    },
    cornerstoneMobilePortrait: {
        height: '40vh'
    },
    cornerstoneMobileHorizontal: {
        height: '75vh',
        display: 'flex'
    }
});

class Cornerstone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageUrl: props.imageInfo.dicomUrl,
            isLoading: true,
            width: window.innerWidth,
            playing: false
        };

        // Hosting these script files ourselves requires creating virtual directories on IIS which can be hard to switch to from localhost:3000 so,
        // using the unpkg cdn network to supply the files for now. If any workstation doesn't have internet this may need to be changed.
        ///
        // let workerRequest = new Request(window.env.BASE_ROUTE + '/ImageLoaderScripts/cornerstoneWADOImageLoaderWebWorker.min.js', { mode: 'no-cors' });
        // let codecRequest = new Request(window.env.BASE_ROUTE + '/ImageLoaderScripts/cornerstoneWADOImageLoaderCodecs.min.js', { mode: 'no-cors' });

        let workerRequest = new Request('https://unpkg.com/cornerstone-wado-image-loader@2.1.4/dist/cornerstoneWADOImageLoaderWebWorker.min.js');
        let codecRequest = new Request('https://unpkg.com/cornerstone-wado-image-loader@2.1.4/dist/cornerstoneWADOImageLoaderCodecs.min.js');

        Promise.all([
            fetch(workerRequest),
            fetch(codecRequest),
            userManager.getUser()
        ]).then(async ([workerResponse, codecResponse, user]) => {
            if (!workerResponse.ok || !codecResponse.ok) {
                throw new Error('Could not retrieve WebWorker and Codec scripts.');
            }

            let workerBlob = await workerResponse.blob();
            let codecBlob = await codecResponse.blob();
            return ([workerBlob, codecBlob, user]);
        }).then(([workerBlob, codecBlob, user]) => {
            this.toolsLoaded = false;
            wado.external.cornerstone = cornerstone;
            wado.external.dicomParser = dicomParser;
            wado.external.cornerstoneMath = cornerstoneMath;
            wado.configure({
                beforeSend: (xhr) => {
                    xhr.setRequestHeader('Authorization', `bearer ${user.access_token}`);
                },
                userWebWorkers: true
            });

            // URL.createObjectURL isn't supposed to be supported in IE11 but, it works for now. May work for js files less than 4GB.
            // If this needs to work in IE11 later we may need to come up with a new way to provide these files.
            let workerUrl = URL.createObjectURL(workerBlob);
            let codecUrl = URL.createObjectURL(codecBlob);
            try {
                wado.webWorkerManager.initialize({
                    maxWebWorkers: undefined,
                    startWebWorkersOnDemand: false,
                    webWorkerPath: workerUrl,
                    webWorkerTaskPaths: [],
                    taskConfiguration: {
                        decodeTask: {
                            loadCodecsOnStartup: false,
                            initializeCodecsOnStartup: false,
                            codecsPath: codecUrl
                        }
                    }
                });
                cornerstoneTools.external.cornerstone = cornerstone;
                cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
                cornerstoneTools.external.Hammer = Hammer;
                cornerstoneTools.zoom.setConfiguration({
                    minScale: 0.25,
                    maxScale: 20.0,
                    preventZoomingOutsideImage: false
                });
                cornerstoneTools.magnify.setConfiguration({
                    magnifySize: 300,
                    magnificationLevel: 2.5
                });

                this.loadImageStack(this.state.imageUrl);

                this.lastFrameTime = Date.now();
            } catch (error) {
                console.warn(error.message);
            }
        }).catch(error => this.setState({ error: true, errorMsg: error.message }));
    }

    componentDidMount() {
        window.addEventListener('resize', this.resetViewport);
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageInfo.dicomUrl !== this.props.imageInfo.dicomUrl) {
            this.setState({ error: false, errorMsg: '', isLoading: true, imageStack: undefined });

            if (this.state.playing) {
                this.togglePlaying();
            }

            this.loadImageStack(this.props.imageInfo.dicomUrl);
        } else if (prevProps.tool !== this.props.tool) {
            this.loadTool(this.props.tool);
        }
    }

    componentWillUnmount() {
        if (typeof this.element !== 'undefined') {
            this.element.removeEventListener('cornerstoneimagerendered', this.handleFrameLoad);
            this.resizeSensor.detach(this.resetViewport);
        }
        window.removeEventListener('resize', this.resetViewport);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => this.setState({ width: window.innerWidth });

    loadTool = (newTool) => {
        if (typeof this.element !== 'undefined' && this.element !== null) {
            const { activeTool } = this.state;
            if (typeof activeTool !== 'undefined') {
                cornerstoneTools[activeTool].deactivate(this.element, 1);
            }
            if (typeof newTool !== 'undefined') {
                cornerstoneTools[newTool].enable(this.element);
                cornerstoneTools[newTool].activate(this.element, 1);
                this.setState({ activeTool: newTool });
            }
        }
    }

    bruteForceDataSetTags = (dataSet) => {
        let hexLtrs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

        let tagValues = {};

        let startTime = Date.now();

        for (let a = 0; a < 16; a++) {
            for (let b = 0; b < 16; b++) {
                for (let c = 0; c < 16; c++) {
                    for (let d = 0; d < 16; d++) {
                        for (let e = 0; e < 16; e++) {
                            for (let f = 0; f < 16; f++) {
                                for (let g = 0; g < 16; g++) {
                                    for (let h = 0; h < 16; h++) {
                                        let timeSinceStart = ((Date.now() - startTime) / 1000);

                                        if (timeSinceStart > 10) {
                                            break;
                                        }

                                        let tag = `x${hexLtrs[a]}${hexLtrs[b]}${hexLtrs[c]}${hexLtrs[d]}${hexLtrs[e]}${hexLtrs[f]}${hexLtrs[g]}${hexLtrs[h]}`;
                                        let data = dataSet.string(tag);

                                        if (data) {
                                            tagValues[tag] = data;
                                            console.log(`Found tag ${tag}`);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        console.log(JSON.stringify(tagValues, null, 2));
    }

    loadImageStack = (imageUrl) => {
        // if (this.state.playing) this.togglePlaying();
        wado.wadouri.dataSetCacheManager.load(imageUrl).then((dataSet) => {
            let frameCount = dataSet.intString('x00280008');
            if (!frameCount) {
                frameCount = 1;
            }

            let frameRate = 1000 / dataSet.intString('x00181063');
            if (!frameRate) {
                frameRate = 31;
            }

            let imageUID = dataSet.string('x00080018');
            if (!imageUID) {
                imageUID = 'Image UID Not found';
            }

            let patientID = dataSet.string('x00100020');
            if (!patientID) {
                patientID = 'Patient ID Not found';
            }

            let imageIds = [];
            let imageIdRoot = 'wadouri:' + imageUrl;
            for (let i = 0; i < frameCount; i++) {
                let imageFrame = imageIdRoot + "?frame=" + i;
                imageIds.push(imageFrame);
                if (typeof this.props.preCacheImages !== 'undefined' && this.props.preCacheImages === true) cornerstone.loadAndCacheImage(imageFrame);
            }
            let imageStack = {
                currentImageIdIndex: 0,
                imageIds: imageIds
            };

            this.setState({
                frameCount,
                frameRate,
                imageUID,
                patientID,
                imageStack,
                imageUrl,
                fps: 0,
                isLoading: false
            });

            if (typeof this.element !== 'undefined') {
                this.toolsLoaded = false;
                this.loadAndViewImage(imageStack);
            }
        }).catch(error => this.setState({ error: true, errorMsg: error.message }));
    }

    loadAndViewImage = (imageStack) => {
        imageStack = typeof imageStack === 'undefined' ? this.state.imageStack : imageStack;
        const { tool } = this.props;
        try {
            cornerstone.getEnabledElement(this.element);
        } catch (error) {
            cornerstone.enable(this.element, { renderer: 'webgl' });
            this.toolsLoaded = false;
        }
        return cornerstone.loadAndCacheImage(imageStack.imageIds[0]).then((image) => {
            cornerstone.displayImage(this.element, image);
            cornerstone.reset(this.element);
            if (this.toolsLoaded === false) {
                cornerstoneTools.mouseInput.enable(this.element);
                cornerstoneTools.mouseWheelInput.enable(this.element);
                cornerstoneTools.zoomWheel.activate(this.element);
                this.loadTool(tool);
                cornerstoneTools.addStackStateManager(this.element, ['stack', 'playClip']);
                cornerstoneTools.addToolState(this.element, 'stack', imageStack);
                this.toolsLoaded = true;
                if (this.wasPlaying) {
                    this.wasPlaying = false;
                    this.togglePlaying();
                }
            }
        }).catch(error => this.setState({ error: true, errorMsg: error.message }));
    }

    handleFrameLoad = (event) => {
        let currentTime = Date.now();
        let timeDiff = currentTime - this.lastFrameTime;
        let fps = this.state.playing ? 1000 / timeDiff : 0;

        // console.log(fps.toFixed(1));

        this.lastFrameTime = currentTime;
        this.setState({
            fps
        });
    }

    setContainerElement = (ref) => {
        if (ref !== null && typeof ref !== 'undefined' && typeof this.containerElement === 'undefined' && typeof this.element !== 'undefined') {
            this.containerElement = ReactDOM.findDOMNode(ref);
            this.resizeSensor = new ResizeSensor(this.containerElement, this.resetViewport);
        }
    }

    setElement = (ref) => {
        if (ref !== null && typeof ref !== 'undefined') {
            let newElement = ReactDOM.findDOMNode(ref);
            if (typeof this.element === 'undefined') newElement.addEventListener('cornerstoneimagerendered', this.handleFrameLoad);
            if (typeof this.element !== 'undefined' && this.element !== newElement) {
                this.element.removeEventListener('cornerstoneimagerendered', this.handleFrameLoad);
                newElement.addEventListener('cornerstoneimagerendered', this.handleFrameLoad);
                if (this.state.playing) {
                    this.wasPlaying = true;
                    this.togglePlaying();
                }
                cornerstone.disable(this.element);
            }
            this.element = newElement;
            if (typeof this.state.imageStack !== 'undefined') {
                this.loadAndViewImage();
            }
        }
    }

    handleMouseInput = (event) => {
        event.preventDefault();
        return true;
    }

    togglePlaying = () => {
        if (typeof this.element !== 'undefined' && this.element !== null) {
            if (this.state.playing) {
                cornerstoneTools.stopClip(this.element);
            } else {
                cornerstoneTools.playClip(this.element, this.state.frameRate);
            }
            this.setState({
                playing: !this.state.playing,
                fps: 0
            });
            this.lastFrameTime = Date.now();
        }
    }

    resetImage = () => {
        if (typeof this.element !== 'undefined' && this.element !== null) {
            cornerstoneTools.clearToolState(this.element, this.state.activeTool);
            cornerstone.updateImage(this.element);
        }
    }

    resetViewport = () => {
        if (!this.state.isLoading && typeof this.element !== 'undefined' && typeof this.state.imageStack !== 'undefined') {
            this.loadAndViewImage();
        }
    }

    skipToFrame = (frameIndex) => {
        if (typeof this.element !== 'undefined') {
            var stackToolDataSource = cornerstoneTools.getToolState(this.element, 'stack');
            if (stackToolDataSource === undefined) {
                return;
            }
            var stackData = stackToolDataSource.data[0];

            // Switch images, if necessary
            if (frameIndex !== stackData.currentImageIdIndex && stackData.imageIds[frameIndex] !== undefined) {
                cornerstone.loadAndCacheImage(stackData.imageIds[frameIndex]).then((image) => {
                    stackData.currentImageIdIndex = frameIndex;
                    cornerstone.displayImage(this.element, image);
                }).catch(error => this.setState({ error: true, errorMsg: error.message }));
            }
        }
    }

    handleFrameSlider = (event, value) => this.skipToFrame(value)

    render() {
        const { frameRate, frameCount, fps, imageUID, patientID, error, errorMsg, isLoading, width, imageStack } = this.state;
        const { classes, className, handlePrevThumbnail, handleNextThumbnail } = this.props;

        const mobileLayout = width <= mediaQueries.breakpointLargePixels;
        const currentFrame = typeof imageStack !== 'undefined' ? imageStack.currentImageIdIndex : undefined;

        if (error === true) {
            return (
                <Typography className={`${className} ${classes.circularProgress}`}>
                    {errorMsg}
                </Typography>
            );
        }

        if (mobileLayout && window.innerWidth < window.innerHeight) { // Mobile layout Portrait
            return (
                <div className={`${className} ${classes.cornerstoneMobilePortrait}`} ref={this.setContainerElement}>
                    <div className={classes.managementBar}>
                        <Button variant='contained' color='primary' onClick={handlePrevThumbnail} className={classes.managementButtonMobile}>
                            <SkipPrevious />
                        </Button>
                        <Button variant='contained' color='primary' onClick={handleNextThumbnail} className={classes.managementButtonMobile}>
                            <SkipNext />
                        </Button>
                    </div>
                    <Hidden implementation='js' xsUp={!isLoading}>
                        <CircularProgress className={className} size={60} thickness={7} />
                    </Hidden>
                    <Hidden implementation='css' xsUp={isLoading} className={classes.hiddenContainer}>
                        <div
                            className={classes.imageWrapper}
                            onContextMenu={this.handleMouseInput}
                            unselectable='on'
                            onSelect={this.handleMouseInput}
                            onMouseDown={this.handleMouseInput}
                        >
                            <div
                                ref={this.setElement}
                                className={`cornerstone-canvas ${classes.dicomImage}`}
                            />
                        </div>
                        {frameCount < 2 ? <div className={classes.controlBar} /> :
                            <div className={classes.controlBar}>
                                <div className={classes.playProgressMobile}>
                                    <div className={classes.playSliderMobile}>
                                        {typeof currentFrame !== 'undefined' ?
                                            <Slider step={1} min={0} max={frameCount - 1} value={currentFrame} onChange={this.handleFrameSlider}
                                                classes={{
                                                    thumb: classes.disableTransition,
                                                    trackBefore: classes.disableTransition,
                                                    trackAfter: classes.disableTransition
                                                }}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                    <div className={classes.lowerControlMobile}>
                                        <div className={classes.playButtonMobile}>
                                            <Button variant='contained' color='primary' onClick={this.togglePlaying}>
                                                {this.state.playing ? <Pause /> : <PlayArrow />}
                                            </Button>
                                        </div>
                                        <div className={`${classes.playFPSMobile} ${this.state.playing && Math.abs(fps - frameRate) > 3 ? classes.error : ''}`}>
                                            {typeof currentFrame !== 'undefined' ?
                                                (`${`000000${currentFrame + 1}`.substr(-(frameCount.toString().length))} / ${frameCount} FPS: ${fps.toFixed(1)} / ${frameRate.toFixed(1)}`)
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Hidden>
                </div>
            );
        } else if (mobileLayout) { // Mobile layout Horizontal
            return (
                <div className={`${className} ${classes.cornerstoneMobileHorizontal}`} ref={this.setContainerElement}>
                    <Hidden implementation='js' xsUp={!isLoading}>
                        <div className={classes.hiddenContainer}>
                            <CircularProgress className={className} size={60} thickness={7} />
                        </div>
                    </Hidden>
                    <Hidden implementation='css' xsUp={isLoading} className={classes.hiddenContainer}>
                        <div
                            className={classes.imageWrapper}
                            onContextMenu={this.handleMouseInput}
                            unselectable='on'
                            onSelect={this.handleMouseInput}
                            onMouseDown={this.handleMouseInput}
                        >
                            <div
                                ref={this.setElement}
                                className={`cornerstone-canvas ${classes.dicomImage}`}
                            />
                        </div>
                        {frameCount < 2 ? <div className={classes.controlBarMobileHorizontal} /> :
                            <div className={classes.controlBarMobileHorizontal}>
                                {typeof currentFrame !== 'undefined' ?
                                    <Slider step={1} min={0} max={frameCount - 1} value={currentFrame} onChange={this.handleFrameSlider}
                                        classes={{
                                            thumb: classes.disableTransition,
                                            trackBefore: classes.disableTransition,
                                            trackAfter: classes.disableTransition
                                        }}
                                    />
                                    :
                                    null
                                }
                                {typeof currentFrame !== 'undefined' ?
                                    <div className={`${classes.playFPSMobile} ${this.state.playing && Math.abs(fps - frameRate) > 3 ? classes.error : ''}`}>
                                        {typeof currentFrame !== 'undefined' ?
                                            (`FPS: ${fps.toFixed(1)} / ${frameRate.toFixed(1)}`)
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        }
                    </Hidden>
                    <div className={classes.managementBarMobileHorizontal}>
                        <Button variant='contained' color='primary' onClick={handlePrevThumbnail}>
                            <SkipPrevious />
                        </Button>
                        {frameCount < 2 ? null :
                            <Button variant='contained' color='primary' onClick={this.togglePlaying}>
                                {this.state.playing ? <Pause /> : <PlayArrow />}
                            </Button>
                        }
                        <Button variant='contained' color='primary' onClick={handleNextThumbnail}>
                            <SkipNext />
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className={`${className} ${classes.cornerstone}`} ref={this.setContainerElement}>
                <Hidden implementation='js' xsUp={!isLoading}>
                    <CircularProgress className={className} size={60} thickness={7} />
                </Hidden>
                <Hidden implementation='css' xsUp={isLoading} className={classes.hiddenContainer}>
                    <div className={classes.managementBar}>
                        <Button variant='contained' color='primary' onClick={this.resetImage} className={classes.managementButton}>
                            {`Reset Tool`}
                        </Button>
                    </div>
                    <div
                        className={classes.imageWrapper}
                        onContextMenu={this.handleMouseInput}
                        unselectable='on'
                        onSelect={this.handleMouseInput}
                        onMouseDown={this.handleMouseInput}
                    >
                        <div
                            ref={this.setElement}
                            className={`cornerstone-canvas ${classes.dicomImage}`}
                        />
                    </div>
                    <div className={classes.infoBar}>
                        <div className={classes.infoItem}>
                            Patient ID: {patientID}
                        </div>
                        <div className={classes.infoItem}>
                            Image UID: {imageUID}
                        </div>
                    </div>
                    {frameCount < 2 ? <div className={classes.controlBar} /> :
                        <div className={classes.controlBar}>
                            <div className={classes.playButton}>
                                <Button variant='contained' color='primary' onClick={this.togglePlaying}>
                                    {this.state.playing ? <Pause /> : <PlayArrow />}
                                </Button>
                            </div>
                            <div className={classes.playSlider}>
                                {typeof currentFrame !== 'undefined' ?
                                    <Slider step={1} min={0} max={frameCount - 1} value={currentFrame} onChange={this.handleFrameSlider} />
                                    :
                                    null
                                }
                            </div>
                            <div className={`${classes.playFPS} ${this.state.playing && Math.abs(fps - frameRate) > 3 ? classes.error : ''}`}>
                                {typeof currentFrame !== 'undefined' ?
                                    (`${`000000${currentFrame + 1}`.substr(-(frameCount.toString().length))} / ${frameCount} FPS: ${fps.toFixed(1)} / ${frameRate.toFixed(1)}`)
                                    :
                                    null
                                }
                            </div>
                        </div>
                    }
                </Hidden>
            </div>
        );
    }
}

export default withStyles(styles)(Cornerstone);