import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from ".../assets/components/Grid/GridItem";

const styles = theme => ({
    cardBody: {
        paddingTop: '0px'
    },
    riskFactor: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex',
        marginTop: `${theme.spacing(1)}px`
    },
    buttonGroup: {
        position: "relative",
        marginBottom: `${theme.spacing(1)}px`,
        display: "inline-block",
        verticalAlign: "right"
    }
});

class TimiRiskForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentRiskFactorScores: this.props.configuration.riskFactors.map(riskFactor => riskFactor.criteria[0].value),
            selectedButtons: this.props.configuration.riskFactors.map(riskFactor => 0)
        };

        this.state = this.calculateRiskScore(this.state.currentRiskFactorScores[0], 0, 0);
    }

    calculateRiskScore = (score, criteriaId, factorId) => {
        const { configuration, updateRiskScore } = this.props;

        let newSelectedButton = [
            ...this.state.selectedButtons
        ];
        newSelectedButton[factorId] = criteriaId;

        let newScore = [
            ...this.state.currentRiskFactorScores
        ];
        newScore[factorId] = score;

        let totalRiskScore = 0;
        newScore.forEach(score => totalRiskScore += score);
        if (typeof updateRiskScore !== 'undefined') {
            updateRiskScore(totalRiskScore);
        }

        let mortality = "";
        if (totalRiskScore < 0 || totalRiskScore >= configuration.riskOfMortality.length) {
            mortality = `Invalid risk score. Risk score can be between 0 and ${configuration.riskOfMortality.length - 1}`;
        } else {
            mortality = configuration.riskOfMortality[totalRiskScore] + "% risk";
        }

        return ({
            totalRiskScore: totalRiskScore,
            mortality: mortality,
            currentRiskFactorScores: newScore,
            selectedButtons: newSelectedButton
        });
    };

    renderRiskFactor = (riskFactor, riskFactorIndex) => {
        const { classes } = this.props;
        return (
            <div key={riskFactor.name} >
                <Divider />
                <div className={classes.riskFactor}>{riskFactor.name}</div>
                <div className={classes.buttonGroup}>
                    {riskFactor.criteria.map((criteria, criteriaIndex) =>
                        (
                            <Button
                                key={`${riskFactor.name}-${criteria.name}`}
                                variant='contained'
                                color={this.state.selectedButtons[riskFactorIndex] === criteriaIndex ? "primary" : "default"}
                                onClick={() => this.setState(this.calculateRiskScore(criteria.value, criteriaIndex, riskFactorIndex))}
                            >
                                {criteria.name} {criteria.value}
                            </Button>
                        ))
                    }
                </div>
            </div>);
    };

    render() {
        const { configuration, classes } = this.props;
        return (
            <GridContainer justify="center" style={{maxHeight: '100%', overflow:'auto'}}>
                <GridItem xs={12} lg={6}>
                    <Card>
                        <CardHeader>{this.state.totalRiskScore} Point(s): {this.state.mortality}</CardHeader>
                        <CardBody className={classes.cardBody}>
                            {(configuration.riskFactors.length > 0) ? configuration.riskFactors.map(this.renderRiskFactor) : 'No Risk factor found!'}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(TimiRiskForm);