import AssignmentIcon from '@material-ui/icons/Assignment';
import BugReport from '@material-ui/icons/BugReport';
import CachedIcon from '@material-ui/icons/Cached';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import React from 'react';

import images from './images';

const FeatureInformation = {
    webpacs: {
        displayName: 'webpacs',
        path: 'webpacs',
        description: 'View patient images from anywhere at any time.',
        image: images.webPacs
    },
    stsrisk: {
        displayName: 'risk',
        path: 'risk',
        description: 'Calculate a patient’s STS standard risk for 7 different procedure types and 9 different endpoints.  Uses the official STS risk coefficients to calculate logistic regression probabilities.',
        image: images.risk
    },
    ipccc: {
        displayName: 'ipccc',
        path: 'ipccc',
        description: 'Use International Pediatric and Congenital Cardiac Code to easily name and classify pediatric and congenital cardiac disease for individual patients.',
        image: images.ipccc
    },
    sabermedics: {
        displayName: 'sabermedics',
        path: 'sabermedics',
        description: 'Compare surgeon OE Ratios and their ranking to peers for the 9 STS endpoints.',
        image: images.sabermedics
    },
    trends: {
        displayName: 'trends',
        path: 'trends',
        description: 'Analyze patient measured Echocardiography values over time or compare against the entire patient population.',
        image: images.trends
    },
    forms: {
        displayName: 'forms',
        path: 'forms',
        description: 'Use custom forms to manage patient data.',
        image: images.forms
    },
    hva: {
        displayName: 'hva',
        path: 'hva',
        description: 'Sophisticated business and clinical intelligence reports to help understand your performance, identify problems, and take action.',
        image: images.hva
    },
    formsEditor: {
        displayName: 'form editor',
        path: 'formeditor',
        description: 'Edit custom forms for managing patient data.',
        icon: <AssignmentIcon />
    },
    emrMappingManager: {
        displayName: 'emr mapping manager',
        path: 'emrmappingmanager',
        description: 'View mappings for fields, add mappings to fields, create new mappings and, edit codes associated with mappings.',
        icon: <ImportExportIcon />
    },
    debug: {
        displayName: 'debug info',
        path: 'debug',
        description: 'Check debug information about the UI',
        icon: <BugReport />
    },
    resetAppContext: {
        displayName: 'Refresh Cached System Data',
        path: 'resetappcontext',
        description: 'Refresh cached information about system data to see changes made in the backend reflected in the UI.',
        icon: <CachedIcon />
    },
    staffManager: {
        displayName: 'Staff Manager',
        path: 'staffmanager',
        description: 'Manage staff in clinical workflow.',
        icon: <PersonIcon />
    },
    selectionSetManager: {
        displayName: 'Selection Set Manager',
        path: 'selectionSetManager',
        description: 'Manage selection set and selection set elements in clinical workflow.',
        icon: <PlaylistAddCheckIcon />
    },
    lookupTableManager: {
        displayName: 'Lookup Table Manager',
        path: 'lookupTableManager',
        description: 'Manage Lookup Tables in clinical workflow.',
        icon: <ListIcon />
    }
};

export default FeatureInformation;