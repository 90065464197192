import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import DeleteIcon from '@material-ui/icons/DeleteForever';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';
import LxSelectField from '.../components/formComponents/LxSelectField';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block'
    },
    cardBody: {
        height: 'calc(100% - 50px)'
    },
    divider: {
        marginTop: '15px'
    },
    cardFooter: {
        display: 'flex',
        bottom: '0px',
        width: '100%',
        alignitems: 'right',
        justifycontent: 'space-between'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    addRole: {
        flex: '1 0 auto'
    },
    table: {
        flex: '1 0 auto',
        height: '300px'
    }
});

class StaffRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changedRole: '',
            staffRoleDef: null,
            staffRoles: this.props.staffRoles,
            validNewStaffRole: true
        };
    }

    componentDidMount() {
        const { staffRoles } = this.props;

        this.setState({
            staffRoles: staffRoles
        });
    }

    componentWillUnmount() {
        const { onStaffRolesChange } = this.props;
        const { staffRoles } = this.state;

        if (typeof onStaffRolesChange === 'function') {
            onStaffRolesChange(staffRoles);
        }
    }

    onStaffRoleChange = (changedRole) => {
        this.setState({
            newChangedRole: changedRole
        });
    }

    onStaffRoleAdd = (staffId, newRole) => {
        let newStaffRoleList = Array.isArray(this.state.staffRoles) ? [...this.state.staffRoles] : [];

        //Set Staff role ids for newly adding staff roles with 0 or -1,-2 numbers etc.
        let roleId = 0;
        if (newStaffRoleList.length > 0) {
            let lastElement = newStaffRoleList[newStaffRoleList.length - 1];
            if (lastElement.staffRoleId <= 0) {
                roleId = lastElement.staffRoleId - 1;
            }
        }

        let roleDef = {
            staffRoleId: roleId,
            staffId: staffId,
            role: newRole,
            destinationTable: '',
            destinationField: '',
            facility: ''
        };

        //Add New Staff Role.
        let staffRole = roleDef;
        newStaffRoleList.push({
            ...staffRole
        });

        this.setState({
            staffRoles: newStaffRoleList
        });
    }

    deleteStaffRole = (roleId) => {
        if (this.state.staffRoles !== null) {
            let newStaffRoleList = Array.isArray(this.state.staffRoles) ? [...this.state.staffRoles] : [];
            let staffRoleIdIndex = newStaffRoleList.map(staffRole => staffRole.staffRoleId).indexOf(roleId);
            newStaffRoleList.splice(staffRoleIdIndex, 1);

            this.setState({
                staffRoles: newStaffRoleList
            });
        }
    }

    isValidStaffRole = (staffRole) => {
        const { staffRoles } = this.state;
        let validStaffRole = true;

        if (Array.isArray(staffRoles)) {
            staffRoles.forEach(role => {
                if (role.role === staffRole) {
                    validStaffRole = false;
                }
            });
        }

        return validStaffRole;
    }

    render() {
        const { classes, staffId, personnelRoles } = this.props;
        const { newChangedRole } = this.state;
        //let validNewStaffRole = this.isValidStaffRole(newChangedRole);

        return (
            <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                    <LxSearchableTable
                        className={classes.table}
                        items={this.state.staffRoles !== null ? this.state.staffRoles : []}
                        columns={[
                            {
                                title: 'Staff Role Id',
                                key: 'staffRoleId',
                                show: false
                            },
                            {
                                title: 'Staff Id',
                                key: 'staffId',
                                show: false
                            },
                            {
                                title: 'Staff Roles',
                                key: 'role',
                                width: 350
                            },
                            {
                                id: 'delete',
                                width: 65,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Delete',
                                key: (item) => (
                                    <ConfirmationButton
                                        className={classes.button}
                                        showConfirmation
                                        confirmationTitle='Permanently Delete Staff Role?'
                                        confirmationText={[
                                            'Are you sure you want to remove this staff role?',
                                            'You will not be able to get it back.'
                                        ]}
                                        confirmationConfirmText='Delete'
                                        onClick={() => this.deleteStaffRole(item.staffRoleId)}
                                    >
                                        <DeleteIcon />
                                    </ConfirmationButton>
                                )
                            }
                        ]}
                        searchKeys={[
                            'role'
                        ]}

                        title={'Available Roles'}
                    />
                    <Divider />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                    <LxSelectField
                        title={'Staff Role'}
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={newChangedRole}
                        nullable={false}
                        onChange={this.onStaffRoleChange}
                        options={personnelRoles}
                    />
                    <Button
                        className={classes.addRole}
                        variant='contained'
                        color='primary'
                        onClick={() => this.onStaffRoleAdd(staffId, newChangedRole)}
                    >
                        {'Add Role'}
                    </Button>

                </CardFooter>
            </Card>
        );
    }
}

export default withStyles(styles)(StaffRoles);