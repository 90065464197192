import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTable from 'react-table';

import LxTextField from "../formComponents/LxTextField";

const styles = theme => ({
    reactTable: {
        '& .rt-thead': {
            '& .rt-th': {
                margin: '0px',
                maxHeight: '30px',
                lineHeight: '1 !important',
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily
            },
            '& .rt-th:last-child': {
                textAlign: 'left'
            }
        },
        '& .rt-td': {
            fontSize: theme.typography.fontSize,
            margin: '0px',
            padding: '3px 5px',
            height: '24px',
            display: 'flex',
            flexDirection: 'column',
            '& div': {
                textAlign: 'center',
                width: 'inherit',
                margin: 'auto',
                '& span': {
                    margin: '0px 8px'
                }
            }
        },
        '& .rt-tr': {
            minWidth: 'fit-content',
            textAlign: 'left'
        },
        '& .rt-table': {
            flex: '1 1 auto',
            overflow: 'hidden'
        },
        '& .pagination-bottom': {
            flex: '0 0 auto',
            '& .-previous': {
                flex: '0 0 65px',
                '& button': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                    padding: '0px'
                }
            },
            '& .-center': {
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily,
                '& .-pageInfo': {
                    marginTop: '0px',
                    marginBottom: '0px',
                    '& .-pageJump': {
                        '& input': {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            height: '18px',
                            backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#D2D2D2, #D2D2D2)`
                        }
                    }
                },
                '& .-pageSizeOptions': {
                    marginTop: '0px',
                    marginBottom: '0px',
                    '& select': {
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        height: '18px',
                        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#D2D2D2, #D2D2D2)`
                    }
                }
            },
            '& .-next': {
                flex: '0 0 65px',
                '& button': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                    padding: '0px'
                }
            },
            '& .-pagination': {
                boxShadow: 'none'
            }
        },
        minHeight: '170px',
        flex: '1 1 auto'
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    table: {
        '& .ps__rail-x': {
            position: 'relative'
        }
    },
    tableBody: {
        maxHeight: 'calc(100vh - 80px)',
        display: 'flex',
        '& .ps__rail-y': {
            top: '24px !important'
        },
        '& .rt-tr-group': {
            flex: '0 0 auto'
        }
    },
    selectedRow: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: theme.palette.primary.contrastText
    },
    clickableRow: {
        cursor: 'pointer'
    },
    tableLoadingContainer: {
        transform: 'translateY(-52%) !important'
    },
    tableLoadingIcon: {
        transform: 'none',
        transition: 'none',
        color: theme.palette.primary.main
    }
});

class LxSearchableTable extends React.Component {
    state = {
        filteredItems: this.props.items,
        items: this.props.items,
        page: 0,
        scrollTop: 0,
        scrollLeft: 0,
        searchValue: ''
    }

    scrollXRef = null
    scrollYRef = null

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.items) !== JSON.stringify(state.items)) {
            return ({
                filteredItems: matchSorter(props.items, state.searchValue.replace(/\s/g, '').replace(/_/g, ''), { keys: props.searchKeys }),
                items: props.items,
                page: 0,
                ...(typeof props.selectedItem === 'undefined' || props.selectedItem === null ?
                    {
                        scrollTop: 0,
                        scrollLeft: 0
                    }
                    :
                    {}
                )
            });
        }
        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.page !== this.state.page) {
            return true;
        } else if (nextState.scrollTop !== this.state.scrollTop) {
            if (nextState.scrollTop === 0) {
                return true;
            } else {
                return false;
            }
        } else if (nextState.scrollLeft !== this.state.scrollLeft) {
            if (nextState.scrollLeft === 0) {
                return true;
            } else {
                return false;
            }
        } else if (nextState.searchValue !== this.state.searchValue) {
            return false;
        } else {
            return true;
        }
    }

    handleSearchValueChange = (searchValue) => {
        const { items, searchKeys } = this.props;

        this.setState({
            searchValue,
            page: 0,
            scrollTop: 0,
            scrollLeft: 0
        });

        window.LxSearchWorker(items, searchValue.replace(/\s/g, '').replace(/_/g, ''), searchKeys).then(filteredItems => {
            //Ensure it only updates filteredItems if the searchTerm is still the current searchTerm
            if (this.state.searchValue === searchValue) {
                this.setState({
                    filteredItems
                });
            }
        });
    }

    onClick = (state, rowInfo, column, instance) => {
        const { onRowClick } = this.props;
        return ({
            onClick: (e, handleOriginal) => {
                if (typeof onRowClick === 'function'
                    && typeof rowInfo !== 'undefined'
                    && !e.defaultPrevented
                ) {
                    onRowClick(rowInfo.original);
                }
                if (handleOriginal) {
                    handleOriginal();
                }
            }
        });
    }

    getTrProps = (state, rowInfo, column) => {
        const { selectedItem, itemToString, onRowClick, classes } = this.props;

        let className = classnames({
            [classes.selectedRow]: typeof rowInfo !== 'undefined'
                && typeof selectedItem !== 'undefined'
                && selectedItem !== null
                && itemToString(selectedItem) === itemToString(rowInfo.original),
            [classes.clickableRow]: typeof onRowClick === 'function'
        });

        return ({
            className
        });
    }

    handlePageChange = (page) => {
        this.setState({
            page,
            scrollTop: 0,
            scrollLeft: 0
        });
    }

    handlePageSizeChange = () => {
        this.setState({
            page: 0,
            scrollTop: 0,
            scrollLeft: 0
        });
    }

    handleScrollY = (container) => {
        this.setState({
            scrollTop: container.scrollTop
        });
    }

    handleScrollX = (container) => {
        this.setState({
            scrollLeft: container.scrollLeft
        });
    }

    setScrollXRef = (ref) => this.scrollXRef = ref

    setScrollYRef = (ref) => this.scrollYRef = ref

    mapColumns = (columns) => {
        return columns.map(column => {
            const { title, key, ...otherOptions } = column;
            // Just to allow the same title, key object we use in other places, allows a normal react-table column object also
            return ({
                Header: title,
                accessor: key,
                ...otherOptions
            });
        });
    }

    tableRenderer = (tableState) => {
        const { classes } = this.props;
        const { scrollLeft } = this.state;

        if (this.scrollXRef !== null && scrollLeft === 0) {
            this.scrollXRef._container.scrollLeft = scrollLeft;
        }

        return (
            <PerfectScrollbar
                ref={this.setScrollXRef}
                className={`rt-table ${classes.table} ${tableState.className}`}
                style={tableState.style}
                onScrollX={this.handleScrollX}
                options={{
                    suppressScrollX: false,
                    suppressScrollY: true
                }}
            >
                {tableState.children}
            </PerfectScrollbar>
        );
    }

    tableBodyRenderer = (tableBodyState) => {
        const { classes } = this.props;
        const { scrollTop } = this.state;

        if (this.scrollYRef !== null && scrollTop === 0) {
            this.scrollYRef._container.scrollTop = scrollTop;
        }

        return (
            <PerfectScrollbar
                ref={this.setScrollYRef}
                className={`rt-tbody ${classes.tableBody} ${tableBodyState.className}`}
                style={tableBodyState.style}
                onScrollY={this.handleScrollY}
                options={{
                    suppressScrollX: true,
                    suppressScrollY: false,
                    wheelSpeed: .25
                }}
            >
                {tableBodyState.children}
            </PerfectScrollbar>
        );
    }

    tableLoadingRenderer = (tableLoadingState) => {
        const { classes } = this.props;

        return (
            <div className={`-loading${tableLoadingState.loading ? ' -active' : ''}`}>
                <div className={`rt-noData ${classes.tableLoadingContainer}`}>
                    <CircularProgress className={`-loading-inner ${classes.tableLoadingIcon}`} size={60} thickness={7} />
                </div>
            </div>
        );
    }

    render() {
        const { filteredItems, page } = this.state;
        const { columns, title, placeholder, label, isLoading, classes, className } = this.props;

        return (
            <div className={`${className ? className + ' ' : ''}${classes.container}`}>
                <LxTextField
                    onChange={this.handleSearchValueChange}
                    title={typeof title !== 'undefined' ? title : ''}
                    placeholder={typeof placeholder !== 'undefined' ? placeholder : 'Search...'}
                    label={typeof label !== 'undefined' ? label : ''}
                />
                <ReactTable
                    className={`-striped -highlight ${classes.reactTable}`}
                    page={page}
                    data={typeof filteredItems === 'undefined' || filteredItems === null ? [] : filteredItems}
                    columns={this.mapColumns(columns)}
                    pageSizeOptions={[10, 25, 50, 100, 500]}
                    defaultPageSize={100}
                    TbodyComponent={this.tableBodyRenderer}
                    TableComponent={this.tableRenderer}
                    getTdProps={this.onClick}
                    getTrProps={this.getTrProps}
                    onPageChange={this.handlePageChange}
                    onPageSizeChange={this.handlePageSizeChange}
                    LoadingComponent={this.tableLoadingRenderer}
                    loading={isLoading}
                    minRows={0}
                />
            </div>
        );
    }
}

LxSearchableTable.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    isLoading: PropTypes.bool,
    onRowClick: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.object),
    selectedItem: PropTypes.object,
    itemToString: PropTypes.func,
    searchKeys: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object
    ])).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        key: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func
        ]),
        Cell: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        Header: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        Footer: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        Filter: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func
        ]),
        Aggregated: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        Pivot: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        PivotValue: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        Expander: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
            PropTypes.func
        ]),
        accessor: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func
        ]),
        id: PropTypes.string,
        sortable: PropTypes.bool,
        resizable: PropTypes.bool,
        filterable: PropTypes.bool,
        show: PropTypes.bool,
        width: PropTypes.number,
        minWidth: PropTypes.number,
        maxWidth: PropTypes.number,
        pivot: PropTypes.bool,
        expander: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.object,
        headerClassName: PropTypes.string,
        headerStyle: PropTypes.object,
        getHeaderProps: PropTypes.func,
        columns: PropTypes.object,
        footerClassName: PropTypes.string,
        footerStyle: PropTypes.object,
        getFooterProps: PropTypes.func,
        filterMethod: PropTypes.func,
        filterAll: PropTypes.bool
    })).isRequired
};

LxSearchableTable.defaultProps = {
    itemToString: JSON.stringify
};

export default withStyles(styles)(LxSearchableTable);