import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import OneToManyEditor from './OneToManyEditor';
import SourceColumnPropertyEditor from './SourceColumnPropertyEditor.js';

const styles = theme => ({

});

class SourceColumnOneToManyEditor extends React.Component {
    createNewSourceColumn = () => {
        return {};
    }

    determineEditorType = () => {return SourceColumnPropertyEditor;}

    itemToDisplayString = (item) => {
        if (item.displayName){
            return item.displayName;
        } else if (item.fieldName) {
            return item.fieldName;
        } else{
            return "New Column"; 
        }
    }

    render() {
        const { value, onChange } = this.props;

        return (
            <OneToManyEditor
                items={value ? value : []}
                displayName={"Source Columns"}
                itemToDisplayString={this.itemToDisplayString}
                onChange={onChange}
                newItem={this.createNewSourceColumn()}
                determineEditorType={this.determineEditorType}
            />
        );
    }
}

export default withStyles(styles)(SourceColumnOneToManyEditor);