import Divider from '@material-ui/core/Divider';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";

import sidebarStyle from "../../jss/material-dashboard-pro-react/components/sidebarStyle";
import SidebarBranding from './SidebarBranding';
import SidebarLinkItem from './SidebarLinkItem';
import SidebarUserItem from './SidebarUserItem';

class Sidebar extends React.Component {
    state = {
        miniActive: true,
        isIframe: window.self !== window.top || window.CefSharp || this.props.isManagedWindow
    }

    onMouseOver = () => {
        this.setState({ miniActive: false });
    }

    onMouseOut = () => {
        this.setState({ miniActive: true });
    }

    render() {
        const { classes, logo, image, logoText, routes, bgColor, rtlActive, onLogoutButtonClicked, adminToolsInfo } = this.props;
        const { isIframe } = this.state;

        const user = (
            <SidebarUserItem
                bgColor={bgColor}
                rtlActive={rtlActive}
                miniActive={this.props.miniActive && this.state.miniActive}
                onLogoutButtonClicked={onLogoutButtonClicked}
            />
        );

        var links = routes.map((route, key) =>
            <SidebarLinkItem
                route={route}
                rtlActive={rtlActive}
                miniActive={this.props.miniActive && this.state.miniActive}
                color={this.props.color}
                key={`${route.name}-${key}`}
            />
        );

        var brand = (
            <SidebarBranding
                logo={logo}
                logoText={logoText}
                bgColor={bgColor}
                rtlActive={rtlActive}
                miniActive={this.props.miniActive && this.state.miniActive}
                isIframe={isIframe}
            />
        );

        const drawerPaper = cx({
            [classes.drawerPaper]: true,
            [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
            [classes.drawerPaperRTL]: rtlActive
        });

        const sidebarWrapper = cx({
            [classes.sidebarWrapper]: true,
            [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive
        });

        return (
            <div ref="mainPanel">
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <div className={sidebarWrapper}>
                            <Divider className={classes.divider} />
                            {user}
                            <Divider className={classes.divider} />
                            <PerfectScrollbar
                                className={classes.perfectScrollbar}
                                options={{
                                    suppressScrollX: true,
                                    suppressScrollY: false
                                }}
                            >
                                {links}
                            </PerfectScrollbar>
                            <Divider className={classes.divider} />
                            {typeof adminToolsInfo !== 'undefined' && adminToolsInfo !== null ?
                                <div className={classes.adminTools}>
                                    <SidebarLinkItem
                                        route={adminToolsInfo.route}
                                        rtlActive={rtlActive}
                                        miniActive={this.props.miniActive && this.state.miniActive}
                                        color={this.props.color}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                        {image !== undefined ?
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                            :
                            null
                        }
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer
                        onMouseOver={this.onMouseOver}
                        onMouseOut={this.onMouseOut}
                        anchor={"left"}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                    >
                        {brand}
                        <div className={sidebarWrapper}>
                            <Divider className={classes.divider} />
                            {user}
                            <Divider className={classes.divider} />
                            <PerfectScrollbar
                                className={classes.perfectScrollbar}
                                options={{
                                    suppressScrollX: true,
                                    suppressScrollY: false
                                }}
                            >
                                {links}
                            </PerfectScrollbar>
                            {typeof adminToolsInfo !== 'undefined' && adminToolsInfo !== null ?
                                <div className={classes.adminTools}>
                                    <Divider className={classes.divider} />
                                    <SidebarLinkItem
                                        route={adminToolsInfo.route}
                                        rtlActive={rtlActive}
                                        miniActive={this.props.miniActive && this.state.miniActive}
                                        color={this.props.color}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                        {image !== undefined ?
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                            :
                            null
                        }
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose"
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(withRouter(Sidebar));
