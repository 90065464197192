import 'react-table/react-table.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from 'react';
import ReactTable from 'react-table';

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import { cardTitle } from ".../assets/jss/material-dashboard-pro-react";
import { pushToError, sabermedicsApi } from '.../utils/apiHelper';
import OeRatioFilters from './OeRatioFilters';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};
class PhysicianRanking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterLoading: true,
            resultLoading: true

        };
    }
    componentDidMount() {
        sabermedicsApi.getOeRatioFilterOptions().then((filters) => {
            if (filters.numberOfMonths.length === 0 || filters.procedures.length === 0) {
                throw new Error("Sabermedics was missing filter options");
            }

            sabermedicsApi.getPhysicianRankings(filters.procedures[0].id, filters.numberOfMonths[0]).then(ranking => {
                this.setState({
                    ranking,
                    filters,
                    filterLoading: false,
                    resultLoading: false,
                    selected: {
                        procedure: filters.procedures[0].id,
                        month: filters.numberOfMonths[0]
                    }
                });
            }).catch(pushToError);
        }).catch(pushToError);
    }

    onFilterChange = (selected) => {
        this.setState({
            selected,
            resultLoading: true
        });
        sabermedicsApi.getPhysicianRankings(selected.procedure, selected.month).then(ranking => {
            this.setState({
                ranking,
                resultLoading: false
            });
        }).catch(pushToError);
    }


    render() {
        const { classes } = this.props;
        const { ranking, filterLoading, resultLoading, filters, selected } = this.state;
        return (
            filterLoading ?
                <CircularProgress size={60} thickness={7} />
                :
                <div> {/* filling the table cells*/}
                    {resultLoading ?
                        <CircularProgress size={60} thickness={7} />
                        :
                        <GridContainer>
                            <GridItem xs={12}>
                                <Card>
                                    <CardHeader color="primary" icon>
                                        <h4 className={classes.cardIconTitle}>Physician Ranking</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <OeRatioFilters selected={selected} filterOptions={filters} onChange={this.onFilterChange} />
                                        <ReactTable
                                            data={ranking}
                                            filterable
                                            columns={[
                                                {
                                                    Header:
                                                        <Typography align='center' variant="subtitle1">
                                                            Physician
                                                        </Typography>,
                                                    columns: [
                                                        {
                                                            Header: "",
                                                            accessor: "physician",
                                                            sortable: false
                                                        }
                                                    ]
                                                },
                                                {
                                                    Header:
                                                        <Typography align='center' variant="subtitle1">
                                                            Facility
                                                        </Typography>,
                                                    columns: [
                                                        {
                                                            Header: "",
                                                            accessor: "facility",
                                                            sortable: false
                                                        }
                                                    ]
                                                },
                                                {
                                                    Header:
                                                        <Typography align='center' variant="subtitle1">
                                                            OE Ratio
                                                        </Typography>,
                                                    columns: [
                                                        {
                                                            Header: "Long LOS",
                                                            accessor: "longLengthOfStay",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "Short LOS",
                                                            accessor: "shortLengthOfStay",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "DSW Infect",
                                                            accessor: "dswInfection",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "Morality/Morbidity",
                                                            accessor: "morbidityOrMortality",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "Mortality",
                                                            accessor: "mortality",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "Renal Fail",
                                                            accessor: "renalFailure",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "Permanent Stroke",
                                                            accessor: "permanentStroke",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        },
                                                        {
                                                            Header: "Prolonged Vent",
                                                            accessor: "prolongedVentilation",
                                                            filterable: false,
                                                            Cell: row => (
                                                                <span>
                                                                    <span style={{
                                                                        color: row.value > 2 ? '#ff2e00'
                                                                            : row.value < 1 ? '#57d500'
                                                                                : '#ffbf00',
                                                                        transition: 'all .3s ease'
                                                                    }}
                                                                    >
                                                                        &#x25cf;
                                                                    </span> {Math.round(row.value * 100) / 100} </span>)
                                                        }
                                                    ]
                                                }
                                            ]}
                                            showPaginationTop
                                            showPaginationBottom={false}
                                            defaultPageSize={25}
                                            className="-striped -highlight"
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    }
                    <br />
                </div>
        );
    }
}

export default withStyles(styles)(PhysicianRanking);
