class ContextManager {
    constructor (incomingRecordContext) {
        if (incomingRecordContext && Object.getOwnPropertyNames(incomingRecordContext).length > 0) {
            this.recordContext = incomingRecordContext;
        }
    }
    
    recordContext = null
    validRecordContext = false;

    getDataPoint = (formData, tableName, fieldName) => {
        if (this.recordContext && formData?.tables) {
            let currentTableNode = this.recordContext[tableName];
            if (currentTableNode?.hasCurrentRecord) {
                return formData.tables[tableName].records[currentTableNode.key].fields[fieldName];
            }
        }
        return null;
    }

    getRecord = (formData, tableName) => {
        if (this.recordContext && formData?.tables) {
            let currentTableNode = this.recordContext[tableName];
            if (currentTableNode?.hasCurrentRecord) {
                return formData.tables[tableName].records[currentTableNode.key];
            }
        }
        return null;
    }

    getDataPoints = (formData, tableName, fieldName) => {
        let retval = [];
        let currentRecords = formData.tables[tableName].records;
        currentRecords.forEach(record => {
            retval.push(record.fields[fieldName]);
        });
        return retval;
    }

    getChildRecords = (formData, childTableName) => {
        let validRecords = [];
        if (this.recordContext && formData?.tables) {
            let childTable = formData.tables[childTableName];
            if(childTable){
                let parentNode = this.recordContext[childTable.parentTableName];
                if (parentNode) {
                    let parentKeyValue = parentNode.key;
                    Object.getOwnPropertyNames(childTable.records).forEach(recordKey => {
                        let record = childTable.records[recordKey];
                        if (record.parentKeyValue === parentKeyValue) {
                            validRecords.push(record);
                        }
                    });
                }
            }
        }
        return validRecords;
    }

    getDataRecords = (formData, tableName) => {
        if (formData?.tables) {
            return formData.tables[tableName].records;
        }
        return {};
    }

    getRelatedRecord = (targetTableName, originalRecord, formData) => {
        if (formData?.tables) {
            //Check Original Table
            if (targetTableName === originalRecord.tableName) {
                return originalRecord;
            }
            //Check if related parent
            var relatedParentRecord = this.getRelatedParentRecord(targetTableName, originalRecord, formData);
            if (relatedParentRecord) {
                return relatedParentRecord;
            }
            //Check for related children
            var relatedChildRecord = this.getRelatedChildRecord(targetTableName, originalRecord, formData);
            if (relatedChildRecord) {
                return relatedChildRecord;
            }
        }
    }

    getRelatedParentRecord = (targetTableName, callingRecord, formData) => {
        var childTable = formData.tables[callingRecord.tableName];
        if (childTable.parentTableName === null) {
            //indicates the root record was found but no match was made.
            return null;
        }
        var parentTable = formData.tables[childTable.parentTableName];
        var relatedRecord = parentTable.records[callingRecord.parentKeyValue];
        if (parentTable.tableName === targetTableName) {
            return relatedRecord;
        } else {
            parentTable.childTableNames.forEach(childTableName => {
                if (childTableName !== callingRecord.tableName) {
                    let childRecord = this.getRelatedChildRecord(targetTableName, relatedRecord, formData);
                    if (childRecord !== null) {
                        return childRecord;
                    }
                }
            });
            return this.getRelatedParentRecord(targetTableName, relatedRecord, formData);
        }
    }

    getRelatedChildRecord = (targetTableName, callingRecord, formData) => {
        let retval = null;
        var parentTable = formData.tables[callingRecord.tableName];
        parentTable.childTableNames.forEach(childTableName => {
            var childTable = formData.tables[childTableName];
            if (childTable) {
                var relatedRecord = childTable.records[callingRecord.keyValue];
                //TODO There is no way to have a 1 to 1 child table related to the context table in the grid if it has no current records. We won't know when to add a new record.
                if (childTable.isOneToOne && relatedRecord) {
                    if (targetTableName === childTableName && childTable.isOneToOne) {
                        retval = relatedRecord;
                    } else {
                        let subRecord = this.getRelatedChildRecord(targetTableName, relatedRecord, formData);
                        if (subRecord) {
                            retval = subRecord;
                        }
                    }
                }
            }
        });
        return retval;
    }
} 

export default ContextManager;