import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import LxTextField from '.../components/formComponents/LxTextField';

const styles = theme => ({
    commandButtonSelector: {
        display: 'flex',
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`
        }
    },
    addScriptButton: {
        whiteSpace: 'nowrap',
        minWidth: '250px'
    },
    inputField: {
        marginRight: '8px'
    }
});

class CommandButtonSelector extends React.Component {
    state = {
        displayName: null
    }

    updateDisplayName = (newValue) => {
        this.setState({
            displayName: newValue
        });
    }

    addFormSelection = () => {
        const { onAddItem } = this.props;
        const { displayName } = this.state;

        if (displayName)
        {
            let newItem = {
                displayName: displayName,
                $lumedxType: "CommandButton"
            };

            onAddItem(newItem);
            this.setState({
                displayName: null
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { displayName } = this.state;
        return (
            <div className={classes.commandButtonSelector}>
                <LxTextField className={classes.inputField} value={displayName} title={"Display Name"} placeholder='Enter Display Name...' onChange={this.updateDisplayName}/>
                <Button className={classes.addScriptButton} color='primary' variant='outlined' onClick={this.addFormSelection}>
                    {'ADD COMMAND BUTTON'}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(CommandButtonSelector);