import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import LxCheckbox from '.../components/formComponents/LxCheckbox';
import LxCombobox from '.../components/formComponents/LxCombobox';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';

const styles = theme => ({
    card: {
        height: '100%'
    },
    title: {
        paddingBottom: '10px'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column'
    },
    combobox: {
        flex: '0 1 auto',
        height: 'auto'
    },
    divider: {
        marginTop: '20px',
        marginBottom: '10px'
    },
    table: {
        flex: '1 0 auto',
        height: '0px' // height doesn't act right if it's not set, flex ends up changing it
    },
    checkboxCell: {
        padding: '0px !important'
    },
    checkbox: {
        height: '24px',
        '& svg': {
            backgroundColor: '#fff',
            borderRadius: '4px'
        }
    }
});

class EmrMappingFieldSelector extends React.Component {
    formToString = (form) => (
        form ?
            `${form.formDisplayName} ${form.viewName} ${form.eventTable} ${form.viewName} ${form.formName}`
            :
            ''
    )

    formToDisplayString = (form) => (
        form ?
            `${form.formDisplayName}${form.eventTable ? ` - ${form.eventTable}` : ''}${form.viewName ? ` - ${form.viewName}` : ''}`
            :
            ''
    )

    eventTableToString = (eventTable) => eventTable ? eventTable.eventTable : ''

    viewToString = (view) => (
        view ?
            `${view.displayName} ${view.viewName} ${view.viewName} ${view.tableName}`
            :
            ''
    )

    viewToDisplayString = (view) => (
        view ?
            `${view.viewName} - ${view.tableName}`
            :
            ''
    )

    onFormSelect = (form) => {
        const { onFieldFilterChange, onFieldSelect } = this.props;

        if (form === null) {
            this.clearSelections();
        } else {
            let eventTable = {
                eventTable: form.eventTable
            };

            let view = {
                viewName: form.viewName,
                tableName: form.eventTable
            };

            onFieldFilterChange(eventTable, view, form);
        }
        onFieldSelect(null);
    }

    onEventTableSelect = (eventTable) => {
        const { onFieldFilterChange, onFieldSelect } = this.props;

        if (eventTable === null) {
            this.clearSelections();
        } else {
            onFieldFilterChange(eventTable, null, null);
        }
        onFieldSelect(null);
    }

    onViewSelect = (view) => {
        const { onFieldFilterChange, onFieldSelect, form, eventTable } = this.props;

        if (view === null) {
            if (form === null) {
                onFieldFilterChange(eventTable, null, null);
            } else {
                this.clearSelections();
            }
        } else {
            onFieldFilterChange({ eventTable: view.tableName }, view, null);
        }
        onFieldSelect(null);
    }

    clearSelections = () => {
        const { onFieldFilterChange, onFieldSelect } = this.props;

        onFieldFilterChange(null, null, null);
        onFieldSelect(null);
    }

    render() {
        const {
            classes,
            form,
            eventTable,
            view,
            formListLoading,
            formList,
            eventTableListLoading,
            eventTableList,
            viewListLoading,
            viewList,
            fieldList,
            fieldListLoading,
            selectedField,
            onFieldSelect
        } = this.props;

        let filteredForms = formList === null ?
            []
            :
            formList.filter(form =>
                (eventTable === null && view === null)
                || (view === null && eventTable !== null && eventTable.eventTable === form.eventTable)
                || (view !== null && view.viewName === form.viewName && eventTable !== null && eventTable.eventTable === form.eventTable));

        let filteredViews = viewList === null ?
            []
            :
            viewList.filter(view => eventTable === null || view.tableName === eventTable.eventTable);

        let columns = [
            {
                id: 'mapped',
                width: 75,
                resizable: false,
                className: classes.checkboxCell,
                title: 'Mapped',
                key: (item) => (
                    <LxCheckbox align='top' className={classes.checkbox} value={item.emrMappings?.length > 0} />
                )
            }
        ];

        if (form !== null) {
            columns.push({
                title: 'Section',
                key: 'sectionDisplayName'
            });
        }

        columns.push(
            {
                title: 'Label',
                key: 'displayName'
            },
            {
                title: 'Table Name',
                key: 'tableName'
            },
            {
                title: 'Field Name',
                key: 'fieldName'
            },
            {
                title: 'Field Type',
                key: 'inputType'
            }
        );

        return (
            <Card className={classes.card}>
                <CardHeader>
                    <Typography className={classes.title} align='center' variant='h6'>
                        {'MAPPING MANAGER'}
                    </Typography>
                    <Divider />
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <LxCombobox
                        key={`filteredForms-${filteredForms.length}`}
                        className={classes.combobox}
                        selectedItem={form}
                        items={filteredForms}
                        itemsLoading={formListLoading}
                        placeholder='Search Forms...'
                        itemToString={this.formToString}
                        inputDisplayTemplate={this.formToDisplayString}
                        onChange={this.onFormSelect}
                        columns={[
                            {
                                title: 'Form Display Name',
                                key: 'formDisplayName'
                            },
                            {
                                title: 'Form Unique Name',
                                key: 'formName'
                            },
                            {
                                title: 'View Name',
                                key: 'viewName'
                            },
                            {
                                title: 'Event Table',
                                key: 'eventTable'
                            }
                        ]}
                        fitInputWidth
                        keepInputOnExit
                    />
                    <LxCombobox
                        className={classes.combobox}
                        selectedItem={eventTable}
                        items={eventTableList}
                        itemsLoading={eventTableListLoading}
                        placeholder='Search Event Tables...'
                        itemToString={this.eventTableToString}
                        inputDisplayTemplate={this.eventTableToString}
                        onChange={this.onEventTableSelect}
                        columns={[
                            {
                                title: 'Event Table',
                                key: 'eventTable'
                            }
                        ]}
                        fitInputWidth
                        keepInputOnExit
                    />
                    <LxCombobox
                        key={`filteredViews-${filteredViews.length}`}
                        className={classes.combobox}
                        selectedItem={view}
                        items={filteredViews}
                        itemsLoading={viewListLoading}
                        placeholder='Search Views...'
                        itemToString={this.viewToString}
                        inputDisplayTemplate={this.viewToDisplayString}
                        onChange={this.onViewSelect}
                        columns={[
                            {
                                title: 'View Name',
                                key: 'viewName'
                            },
                            {
                                title: 'Event Table',
                                key: 'tableName'
                            }
                        ]}
                        fitInputWidth
                        keepInputOnExit
                    />
                    <Divider className={classes.divider} />
                    <LxSearchableTable
                        className={classes.table}
                        title='Form Fields'
                        items={fieldList}
                        itemToString={item => `${item.tableName}:${item.fieldName}`}
                        selectedItem={selectedField}
                        onRowClick={onFieldSelect}
                        searchKeys={[
                            'sectionDisplayName',
                            'tableName',
                            'displayName',
                            'fieldName',
                            'inputType'
                        ]}
                        columns={columns}
                        isLoading={fieldListLoading}
                    />
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(EmrMappingFieldSelector);