import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PropertyEditor from './PropertyEditor';
import QueryParametersOneToManyEditor from './SubEditors/QueryParametersOneToManyEditor';
import SourceColumnOneToManyEditor from './SubEditors/SourceColumnOneToManyEditor';
import SqlQueryEditor from './SubEditors/SqlQueryEditor';
import WhereCriteriaOneToManyEditor from './SubEditors/WhereCriteriaOneToManyEditor';

const styles = theme => ({

});

class MultiColumnPropertyEditor extends React.Component {
    state = {
        sqlQueryEnabled: this.props.itemSchema ? this.props.itemSchema["sqlQuery"]?.useSql : false
    }

    handleUpdatedPropertyBindings = (propertyName, value) => {
        if (propertyName === "sqlQuery") {
            this.setState({
                sqlQueryEnabled: value.useSql
            });
        }
    }

    render() {
        const { classes, isOpen, onConfirm, closeDialog, itemSchema } = this.props;

        return (
            <PropertyEditor
                classes={classes}
                isOpen={isOpen}
                closeDialog={closeDialog}
                onConfirm={onConfirm}
                itemSchema={itemSchema}
                handleUpdatedPropertyBindings={this.handleUpdatedPropertyBindings}
                title="Multi-Column Combo Property Editor"
                properties={[
                    {
                        name: "tableName",
                        label: "Table Name",
                        type: "text"
                    },
                    {
                        name: "fieldName",
                        label: "Field Name",
                        type: "text"
                    },
                    {
                        name: "displayName",
                        label: "Display Name",
                        type: "text"
                    },
                    {
                        name: "tabIndex",
                        label: "Tab sequence",
                        type: "int"
                    },
                    {
                        name: "mainSourceTableName",
                        label: "Source Table Name",
                        type: "text",
                        hidden: this.state.sqlQueryEnabled,
                        description: "This field refers to the table that data will be pulled from to populate the dropdown. The specific columns are configured in the SourceColumns collection."
                    },
                    {
                        name: "sourceColumns",
                        CustomRender: SourceColumnOneToManyEditor
                    },
                    {
                        name: "whereCriteria",
                        hidden: this.state.sqlQueryEnabled,
                        CustomRender: WhereCriteriaOneToManyEditor
                    },
                    {
                        name: "sqlQuery",
                        label: "Use Sql Query",
                        CustomRender: SqlQueryEditor
                    },
                    {
                        name: "parameters",
                        hidden: !this.state.sqlQueryEnabled,
                        CustomRender: QueryParametersOneToManyEditor
                    }
                ]}
            />
        );
    }
}

export default withStyles(styles)(MultiColumnPropertyEditor);