import React from 'react';

import OneToManyEditor from './OneToManyEditor';
import QueryParameterSubEditor from './QueryParameterSubEditor.js';

const itemToDisplayString = (item) => {
    return item.placeholder ? item.placeholder : "New Parameter";
};

function QueryParameterOneToManyEditor(props) {
    const { value, onChange } = props;

    let createNewCriteria = () => { return {}; };

    let determineEditorType = () => { return QueryParameterSubEditor; };

    return (
        <OneToManyEditor
            items={value ? value : []}
            displayName={"Dynamic Query Parameters"}
            itemToDisplayString={itemToDisplayString}
            onChange={onChange}
            newItem={createNewCriteria()}
            determineEditorType={determineEditorType}
        />
    );
}

export default (QueryParameterOneToManyEditor);