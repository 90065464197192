import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    formControlLabel: {
        margin: '0px',
        cursor: 'default'
    },
    leftAlign: {
        flexDirection: 'row'
    },
    rightAlign: {
        flexDirection: 'row-reverse'
    },
    topAlign: {
        flexDirection: 'column-reverse'
    },
    bottomAlign: {
        flexDirection: 'column'
    },
    iconButton: {
        width: '24px',
        height: '24px',
        padding: '0px'
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    colorTextMain: {
        color: theme.palette.text.primary
    },
    gridItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    inputGridItem: {
        width: '100%'
    }
});

class LxSwitch extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            focus: props.modified ? props.modified : props.autoFocus,
            internalValue: this.props.defaultValue
        };
    }

    convertToValidValue = (value) => {
        const { forceBooleanFalse } = this.props;
        let retval;
        if (!forceBooleanFalse && value === null) {
            retval = null;
        } else {
            retval = !!value;
        }
        return retval;
    }

    getNextThreeStateValue = (value) => {
        switch(value){
            case true:
                return null;
            case false:
                return true;
            case null:
                return false;
            default:
                return false;
        }
    };

    handleChange = (event) => {
        const { onChange, onBlur, value, threeState } = this.props;
        const { internalValue } = this.state;
        let currentValue = value;

        if (typeof value === 'undefined') {
            currentValue = this.convertToValidValue(internalValue);
        }
        const nextValue = threeState
            ? this.getNextThreeStateValue(currentValue)
            : event.target.checked;
        this.setState({
            internalValue: nextValue
        });

        if (typeof onChange === 'function') {
                onChange(nextValue);
        }
        if (typeof onBlur === 'function') {
                onBlur(nextValue);
        }
    };

    // This class allows us to see validation errors from the backend, this would only really happen when the database is in a bad state.
    // We probably need to make the backend handle some cleaning of the data before it's sent forward for instances like this.
    render() {
        const {
            className,
            classes,
            error,
            disabled,
            title,
            descriptions,
            value,
            valueOptions,
            hasMappings,
            onBlur,
            helperText,
            align,
            tabIndex,
            validationErrorSeverity,
            fieldInformation,
            fieldInfo,
            inputOnly, metaDataKey, mappingElementsStatus, onUpdateMappingStatus, fhirPopupSelector
        } = this.props;
        const { internalValue } = this.state;
        let alignToLower = align.toLowerCase();
        let convertedControlledValue = this.convertToValidValue(value);
        let convertedInternalValue = this.convertToValidValue(internalValue);
        let formControlLabel = cx({
            [classes.formControlLabel]: true,
            [classes.colorTextMain]: !error && !disabled,
            [classes.leftAlign]: alignToLower === 'left',
            [classes.rightAlign]: alignToLower === 'right',
            [classes.topAlign]: alignToLower === 'top',
            [classes.bottomAlign]: alignToLower === 'bottom'
        });

        return (
            <FormControl className={className} disabled={disabled}>
                <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                    <GridItem className={classes.gridItem}>
                        <GridContainer wrap="nowrap" alignItems="center">
                            <GridItem className={classes.inputGridItem}>
                                <FormControlLabel
                                    className={formControlLabel}
                                    label={
                                        typeof title === 'string' ?
                                            <Typography align={alignToLower === "right" || alignToLower === "left" ? alignToLower : "center"} color='inherit' variant='body2'>
                                                {title}
                                            </Typography>
                                            :
                                            undefined
                                    }
                                    control={
                                        <Switch
                                            checked={typeof value === 'undefined' ? !!convertedInternalValue : !!convertedControlledValue}
                                            //indeterminate={typeof value === 'undefined' ? convertedInternalValue === null : convertedControlledValue === null}
                                            onChange={this.handleChange}
                                            color='primary'
                                            inputProps={{ tabIndex, autoFocus: this.state.focus }}
                                        />
                                    }
                                />
                            </GridItem>
                            {inputOnly ?
                                <GridItem>
                                    <ValidationErrorIcon
                                        error={error}
                                        validationErrorSeverity={validationErrorSeverity}
                                        helperText={helperText}
                                    />
                                </GridItem>
                                :
                                null
                            }
                        </GridContainer>
                    </GridItem>
                    {typeof description === 'string' || (Array.isArray(valueOptions) && valueOptions.length > 0) ?
                        <GridItem className={classes.gridItem}>
                            <FieldHeader
                                value={typeof value === 'undefined' ? convertedInternalValue : convertedControlledValue}
                                descriptions={descriptions}
                                valueOptions={valueOptions}
                                onValueSelect={onBlur}
                                hasMappings={hasMappings}
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                                metaDataKey={metaDataKey}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={onUpdateMappingStatus}
                                fhirPopupSelector={fhirPopupSelector}
                                fieldInformation={fieldInformation}
                                fieldInfo={fieldInfo}
                            />
                        </GridItem>
                        :
                        null
                    }
                </GridContainer>
                {error && helperText  && validationErrorSeverity === 1?
                    <FormHelperText error={false} className={classes.colorError}>{helperText}</FormHelperText>
                    :
                    null
                }
            </FormControl>
        );
    }
}

LxSwitch.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    threeState: PropTypes.bool,
    forceBooleanFalse: PropTypes.bool
};

LxSwitch.defaultProps = {
    disabled: false,
    error: false,
    align: 'left',
    threeState: true,
    forceBooleanFalse: false
};

export default withStyles(styles)(LxSwitch);