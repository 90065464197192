import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    wordWrapFix: {
        width: '100%' //WorkWrapFix
    },
    radioGroup: {
        width: '100%', //WordWrapFix
        textAlign: 'left',
        paddingLeft: '10px'
    },
    radioRoot: {
        padding: '1px 9px'
    },
    formControlLabel: {
        cursor: 'default',
        margin: '0px',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start'
    },
    leftFieldHeader: {
        marginTop: '2px'
    },
    topFieldHeader: {
        marginBottom: '2px'
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    colorTextMain: {
        color: theme.palette.text.primary
    },
    gridItem: {
        width: '100%',
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    inputGridItem: {
        width: '100%'
    }
});

function LxRadioGroup(props) {
    const {
        classes,
        value,
        defaultValue,
        onChange,
        onBlur,
        borderStyle,
        error,
        disabled,
        title,
        titleTooltip,
        descriptions,
        valueOptions,
        helperText,
        hasMappings,
        options,
        row,
        allowDeselect,
        uniqueKey,
        tabIndex,
        autoFocus,
        forcedFocus,
        validationErrorSeverity,
        labelPosition,
        fieldInformation,
        fieldInfo,
        inputOnly, metaDataKey, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress, fhirPopupSelector
    } = props;
    const [internalValue, setInternalValue] = React.useState(defaultValue);    
    const useInternalValue = typeof value === 'undefined';
    const calculatedValue = useInternalValue ? internalValue : value;
    let formLabelClick = 0;
    let handleChange = (value) => {
        if (useInternalValue) {
            setInternalValue(value);
        }
        if (!disabled) { //TODO Check if this is still needed. Fix for disabled not working correctly on <Radio />
            if (typeof onChange === 'function') {
                onChange(value);
            }
            if (typeof onBlur === 'function') {
                onBlur(value);
            }
        }
    };

    let handleLabelClick = (incomingValue) => (event) => {
        //verify that only one time label/control will get clicked and data will get updated
        if (formLabelClick === 1) {
            if (event.type === 'click' && event.clientX > 0 && event.clientY > 0) { // This is a real click.
                if (allowDeselect && incomingValue === calculatedValue) {
                    incomingValue = null;
                }
            }

            handleChange(incomingValue);
            formLabelClick = 0;
        }
        formLabelClick = 1;
    };

    return (
        <FormControl disabled={disabled} style={borderStyle}>  
            <GridContainer
                direction={labelPosition?.toLowerCase() === "left" ? "row" : "column"}
                justify={"space-between"}
                alignItems={labelPosition?.toLowerCase() === "left" ? "center" : "flex-start"}
            >
                {!inputOnly ?
                    <GridItem className={classes.wordWrapFix}>
                        <div className={labelPosition?.toLowerCase() === "left" ? classes.leftFieldHeader : classes.topFieldHeader}>
                            <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                                <GridItem className={classes.gridItem}>
                                    <FormLabel>
                                        <Tooltip title={titleTooltip} placement="top">
                                            <Typography align="left" color='inherit' variant='body2'>
                                                {title}
                                            </Typography>
                                        </Tooltip>
                                    </FormLabel>
                                </GridItem>
                                <GridItem>
                                    <FieldHeader
                                        value={calculatedValue}
                                        descriptions={descriptions}
                                        valueOptions={valueOptions}
                                        onValueSelect={handleChange}
                                        hasMappings={hasMappings}
                                        error={error}
                                        validationErrorSeverity={validationErrorSeverity}
                                        helperText={helperText}
                                        metaDataKey={metaDataKey}
                                        mappingElementsStatus={mappingElementsStatus}
                                        onUpdateMappingStatus={onUpdateMappingStatus}
                                        defaultValue={defaultValue}    
                                        formLoadProgress={formLoadProgress}
                                        fhirPopupSelector={fhirPopupSelector}    
                                        fieldInformation={fieldInformation}
                                        fieldInfo={fieldInfo}
                                    />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GridItem>
                    :
                    null
                }
                <GridItem className={classes.inputGridItem}>
                    <GridContainer wrap="nowrap" alignItems="center">
                        <GridItem className={classes.inputGridItem}>
                            <RadioGroup
                                className={classes.radioGroup}
                                row={row}
                                value={calculatedValue}
                            >
                                {options.map((option, index) => {
                                    let optionValue = option.value ?? option;
                                    return (
                                        <FormControlLabel
                                            key={optionValue}
                                            checked={optionValue === calculatedValue}
                                            className={classes.colorTextMain}
                                            label={
                                                <Typography color='inherit' variant='body2'>
                                                    {typeof option.label !== 'undefined' ? option.label : optionValue}
                                                </Typography>
                                            }
                                            onClick={handleLabelClick(optionValue)}
                                            control={
                                                <Radio
                                                    onClick={handleLabelClick(optionValue)}
                                                    name={uniqueKey}
                                                    inputProps={{
                                                        tabIndex,
                                                        autoFocus: autoFocus && (optionValue === calculatedValue || index === 0)
                                                    }}
                                                    inputRef={inputRef => {
                                                        if (forcedFocus && inputRef && (calculatedValue ? optionValue === calculatedValue : index === 0)) {
                                                            inputRef.focus();
                                                        }
                                                    }}
                                                    classes={{ root: classes.radioRoot }}
                                                    color='primary'
                                                />
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </GridItem>
                        {inputOnly ?
                            <GridItem>
                                <ValidationErrorIcon
                                    error={error}
                                    validationErrorSeverity={validationErrorSeverity}
                                    helperText={helperText}
                                />
                            </GridItem>
                            :
                            null
                        }
                    </GridContainer>
                    {error && helperText && validationErrorSeverity === 1 ?
                        <FormHelperText error={false} className={validationErrorSeverity === 1 ? classes.colorError : classes.colorWarning}>{helperText}</FormHelperText>
                        :
                        null
                    }
                </GridItem>
            </GridContainer>
        </FormControl>
    );
}

LxRadioGroup.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.string),
    row: PropTypes.bool,
    allowDeselect: PropTypes.bool
};

LxRadioGroup.defaultProps = {
    disabled: false,
    error: false,
    options: [],
    row: true,
    allowDeselect: false
};

export default withStyles(styles)(LxRadioGroup);