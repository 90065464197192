import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { StyleRoot } from 'radium';
import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import PatientContext from '.../components/patientInfo/PatientContext';
import RecordContext from ".../components/patientInfo/RecordContext";
import LoginManager from './components/login/LoginManager';
import Main from './components/main/Main';
import store from './store';
import { theme } from './utils/styles/theme';


class App extends Component {
    render() {
        return (
            <StyleRoot>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <HashRouter>
                                <LoginManager>
                                    <PatientContext>
                                        <RecordContext>
                                            <Main />
                                        </RecordContext>
                                    </PatientContext>
                                </LoginManager>
                            </HashRouter>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Provider>
            </StyleRoot>
        );
    }
}

// Toggle between these two exports to turn react-hot-loader on and off
export default hot(module)(App);
// export default App;