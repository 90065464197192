import { worklistApi } from '.../utils/apiHelper';

const GetMiniString = inputString => {
    return inputString.split(' ').map(word => {
        if (typeof word === 'string' && word[0]?.toLowerCase() !== word[0]?.toUpperCase()) {
            return word[0]?.toUpperCase();
        } else {
            return null;
        }
    }).join('');
};

const GetInnerWorklistViews = (description, worklistFilters) =>{
    let views = [];
    worklistFilters.forEach(worklistFilter => {
        views.push({
            path: `/worklist/${description}/${worklistFilter.namespace}/${worklistFilter.name}`,
            name: worklistFilter.name,
            mini: GetMiniString(worklistFilter.name),
            link: true
        });
    });
    return views;
};

const GetWorklistViews = () => new Promise((resolve, reject) => {
    let views = [];

    worklistApi.getWorklists().then(worklists => {
        worklists.forEach(worklist => {
                views.push({
                    path: `/worklist/${worklist.description}`,
                    name: worklist.description,
                    mini: GetMiniString(worklist.description),
                    link: false,
                    views: GetInnerWorklistViews(worklist.description, worklist.worklistFilters)
                });
            });
        resolve(views);
    }).catch(reject);
});

export default GetWorklistViews;
