import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#337ab7',
            main: '#337ab7',
            dark: '#337ab7',
            contrastText: '#fafafa'
        },
        secondary: {
            light: '#094B83',
            main: '#094B83',
            dark: '#094B83',
            contrastText: '#202020'
        },
        error: {
            light: '#ff847c',
            main: '#f44336',
            dark: '#c12014',
            contrastText: '#fff'
        },
        warning: {
            light: '#ffae42',
            main: '#E29804',
            dark: '#c19400',
            contrastText: '#fff'
        },
        text: {
            primary: '#2f2f2f',
            secondary: '#505050',
            contrast: '#fafafa'
        },
        background: {
            paper: '#fff',
            default: '#eeeeee',
            selected: '#d3d3d3'
        },
        lxBlue: {
            light: '#337ab7',
            main: '#337ab7',
            dark: '#337ab7',
            contrastText: '#202020'
        },
        lxGreen: {
            light: '#6baf91',
            main: '#198c5a',
            dark: '#087948',
            contrastText: '#fafafa'
        }
    },
    typography: {
        fontSize: 12,
        fontFamily: "Verdana"
    },
    spacing: 4,
    topBarOffset: 5,
    IeVersion: window.IeVersion(),
    props: {
        MuiDialog: {
            style: {
                zIndex: 2000
            }
        },
        MuiSlider: {
            style: { transition: 'none' }
        },
        MuiFormControl: {
            fullWidth: true
        },
        MuiCheckbox: {
            style: {
                transform: 'scale(.85)'
            }
        },
        MuiRadio: {
            style: {
                transform: 'scale(.85)'
            }
        },
        MuiButton: {
            style: {
                height: '30px',
                minHeight: '16px',
                padding: '4px 12px'
            }
        }
    }
});