import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxCombobox from '.../components/formComponents/LxCombobox';

const styles = theme => ({
    header: {
        margin: `${theme.spacing(2)}px 0px`
    },
    lxComboboxMenuAnchor: {
        height: '300px'
    }
});

class YAxisSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: true
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.selectedChart) !== JSON.stringify(this.props.selectedChart)) {
            this.isValidated(this.props.selectedChart);
        }
    }

    isValidated = (chart) => {
        const checkedChart = typeof chart !== 'undefined' ? chart : this.props.selectedChart;
        let validated = typeof checkedChart !== 'undefined' && checkedChart.ssCopilotChartDataSets.length > 0;

        if (validated) {
            validated = checkedChart.chartType === 'scatter' ? checkedChart.ssCopilotChartDataSets.length === 1 : validated;
            for (let dataset of checkedChart.ssCopilotChartDataSets) {
                if (!validated || dataset.yAxisTableName === '' || dataset.yAxisFieldName === '' || dataset.axisLeftRight === '') {
                    validated = false;
                    break;
                }
            }
        }

        this.setState({ validated });
        return validated;
    }

    handleNewYAxis = () => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: [
                ...selectedChart.ssCopilotChartDataSets,
                {
                    axisLeftRight: '',
                    units: '',
                    yAxisTableName: '',
                    yAxisFieldName: ''
                }
            ]
        }, selectedChartId);
    }

    handleAxisDelete = (index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        newDataSets.splice(index, 1);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    handleYAxisTableName = (value, index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        let newData = newDataSets[index];
        newData.yAxisTableName = value;
        newDataSets.splice(index, 1, newData);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    handleYAxisFieldName = (value, index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        let newData = newDataSets[index];
        newData.yAxisFieldName = value;
        newDataSets.splice(index, 1, newData);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    handleYAxisUnits = (value, index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        let newData = newDataSets[index];
        newData.units = value;
        newDataSets.splice(index, 1, newData);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    handleYAxisLeftRight = (value, index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        let newData = newDataSets[index];
        newData.axisLeftRight = value;
        newDataSets.splice(index, 1, newData);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    handleYAxisMeasurementLabel = (value, index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        let newData = newDataSets[index];
        newData.measurementLabel = value;
        newDataSets.splice(index, 1, newData);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    handleItemSelect = (item, index) => {
        const { setSelectedChart, selectedChart, selectedChartId } = this.props;
        let newDataSets = JSON.parse(JSON.stringify(selectedChart.ssCopilotChartDataSets));
        let newData = newDataSets[index];

        newData.yAxisTableName = item !== null && typeof item !== 'undefined' ? item.table : '';
        newData.yAxisFieldName = item !== null && typeof item !== 'undefined' ? item.field : '';
        newData.units = item !== null && typeof item !== 'undefined' ? item.units : '';
        newData.measurementLabel = item !== null && typeof item !== 'undefined' ? item.label : '';

        newDataSets.splice(index, 1, newData);
        setSelectedChart({
            ...selectedChart,
            ssCopilotChartDataSets: newDataSets
        }, selectedChartId);
    }

    getSelectedMeasurement = (measurements, key) => {
        const { selectedChart } = this.props;
        const dataset = selectedChart.ssCopilotChartDataSets[key];

        for (let i = 0; i < measurements.length; i++){
            if (measurements[i].table === dataset.yAxisTableName && measurements[i].field === dataset.yAxisFieldName){
                return measurements[i];
            }
        }
    }

    render() {
        const { selectedChart, classes, measurements, getMeasurementItemToString } = this.props;
        const { validated } = this.state;

        const validChart = typeof selectedChart !== 'undefined';

        return (!validChart ? null :
            <div>
                {selectedChart.chartType === 'scatter' ?
                    <GridContainer className={classes.header} justify='space-between' alignItems='baseline'>
                        <GridItem>
                            <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                                {selectedChart.ssCopilotChartDataSets.length > 1 ?
                                    'Scatter plots can only have one Y axis, please remove unneeded axes'
                                    :
                                    'Select a Y axis option from the search box'
                                }
                            </Typography>
                        </GridItem>
                    </GridContainer>
                    :
                    <GridContainer className={classes.header} justify='space-between' alignItems='baseline'>
                        <GridItem>
                            <Typography variant='subtitle1' align='left' color={validated ? 'textPrimary' : 'error'}>
                                {'Select a Y axis option from the search box or create a new one'}
                            </Typography>
                        </GridItem>
                        <GridItem>
                            <Button variant='contained' color='primary' onClick={this.handleNewYAxis}>
                                {'New Y Axis'}
                            </Button>
                        </GridItem>
                    </GridContainer>
                }
                <List>
                    <Divider />
                    {selectedChart.ssCopilotChartDataSets.map((dataset, key) => (
                        <div key={key}>
                            <ListItem>
                                <GridContainer spacing={4} justify='flex-start' alignContent='center'>
                                    {/*
                                        <GridItem sm={6}>
                                            <TextField
                                                helperText='Y Axis Table Name'
                                                placeholder='Y Axis Table Name'
                                                fullWidth
                                                error={!validated && dataset.yAxisTableName === ''}
                                                value={dataset.yAxisTableName}
                                                onChange={(event) => this.handleYAxisTableName(event.target.value, key)}
                                            />
                                        </GridItem>
                                        <GridItem sm={6}>
                                            <TextField
                                                helperText='Y Axis Field Name'
                                                placeholder='Y Axis Field Name'
                                                fullWidth
                                                error={!validated && dataset.yAxisFieldName === ''}
                                                value={dataset.yAxisFieldName}
                                                onChange={(event) => this.handleYAxisFieldName(event.target.value, key)}
                                            />
                                        </GridItem>
                                        <GridItem sm={12}>
                                            <TextField
                                                helperText='Y Axis Units'
                                                placeholder='Y Axis Units'
                                                fullWidth
                                                value={dataset.units}
                                                onChange={(event) => this.handleYAxisUnits(event.target.value, key)}
                                            />
                                        </GridItem>
                                    */}
                                    <GridItem sm={selectedChart.ssCopilotChartDataSets.length <= 1 ? 12 : 11}>
                                        <LxCombobox
                                            key={`${selectedChart.title}-${key}`}
                                            items={measurements}
                                            placeholder={"Start typing to search measurement definitions..."}
                                            itemToString={getMeasurementItemToString}
                                            inputDisplayTemplate={item => `${item.label} ${item.units}`}
                                            selectedItem={measurements ? this.getSelectedMeasurement(measurements, key) : ''}
                                            onChange={(item) => this.handleItemSelect(item, key)}
                                            columns={["Label", "Units", "Table", "Field"]}
                                            searchKeys={['name', 'label', 'units', 'table', 'field']}
                                            classes={{ menuAnchor: classes.lxComboboxMenuAnchor }}
                                            fitTableWidth
                                        />
                                    </GridItem>
                                    <GridItem sm={3}>
                                        <FormControl fullWidth error={!validated && dataset.axisLeftRight === ''}>
                                            <FormLabel>{'Axis location'}</FormLabel>
                                            <GridContainer spacing={4}>
                                                <GridItem sm={6}>
                                                    <FormControlLabel
                                                        label='Left'
                                                        control={
                                                            <Radio
                                                                color='primary'
                                                                checked={dataset.axisLeftRight === 'left'}
                                                                onClick={() => this.handleYAxisLeftRight('left', key)}
                                                            />
                                                        }
                                                    />
                                                </GridItem>
                                                <GridItem sm={6}>
                                                    <FormControlLabel
                                                        label='Right'
                                                        control={
                                                            <Radio
                                                                color='primary'
                                                                checked={dataset.axisLeftRight === 'right'}
                                                                onClick={() => this.handleYAxisLeftRight('right', key)}
                                                            />
                                                        }
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                                {selectedChart.ssCopilotChartDataSets.length <= 1 ?
                                    null
                                    :
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.handleAxisDelete(key)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                            <Divider />
                        </div>
                    ))}
                </List>
            </div>
        );
    }
}

YAxisSelection.propTypes = {
    selectedChart: PropTypes.object,
    selectedChartId: PropTypes.number,
    setSelectedChart: PropTypes.func.isRequired
};

export default withStyles(styles)(YAxisSelection);