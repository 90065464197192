import { pushToError, settingsApi } from '.../utils/apiHelper';
import { settingArgsToPath } from './SettingRequestIdentifiers';

const SETTINGS_KEY = 'settingCache';



export const getSettingsPromise = (settingsIdentifiers) => {
    let missingIdentifiers = [];
    let result = {};
    let settingsCacheString = sessionStorage.getItem(SETTINGS_KEY);
    let settingsCache = JSON.parse(settingsCacheString);
    if (!settingsCache) {
        settingsCache = {};
        settingsCacheString = JSON.stringify(settingsCache);
        sessionStorage.setItem(SETTINGS_KEY, settingsCacheString);
    }

    //Build missingIdentifiers collection to send to API
    //TODO add null checks within this logic that parses settingsIdentifiers. The object must be structured in a specific way or it will fail.
    let isMissingSetting = false;
    settingsIdentifiers.forEach((settingGroup) => {
        let missingSettingGroupInfo = {
            ...settingGroup,
            keyValueInfos: []
        };
        missingIdentifiers.push(missingSettingGroupInfo);
        settingGroup.keyValueInfos.forEach((keyValueInfo) => {
            let targetKey = settingArgsToPath(settingGroup.pathArgs, keyValueInfo.keyName);
            let targetValue = settingsCache[targetKey];
            if (!targetValue) {
                missingSettingGroupInfo.keyValueInfos.push(keyValueInfo);
                isMissingSetting = true;
            } else {
                result[targetKey] = targetValue;
            }
        });
    });

    return new Promise(
        (resolve, reject) => {
            if (isMissingSetting) {
                //Make an API call for all missing settings and put their values in the result & settingsCache
                settingsApi.getSettings(missingIdentifiers).then(
                    (settingsResults) => {
                        Object.getOwnPropertyNames(settingsResults).forEach((settingValueName) => {
                            let value = settingsResults[settingValueName];
                            settingsCache[settingValueName] = value;
                            result[settingValueName] = value;
                        });
                        settingsCacheString = JSON.stringify(settingsCache);
                        sessionStorage.setItem(SETTINGS_KEY, settingsCacheString);
                        resolve(result);
                    }
                ).catch(error => pushToError(error));
            } else {
                //If there are no settings outside the cache immediately resolve the promise & return the result, no API call needed
                resolve(result);
            }
        }
    );
};

export default getSettingsPromise;