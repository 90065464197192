import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import Disclaimer from './Disclaimer';

const styles = theme => ({
    title: {
        marginBottom: `${theme.spacing(2)}px`
    },
    content: {
        margin: `${theme.spacing(2)}px`
    },
    scoreHeader: {
        marginBottom: `${theme.spacing(2)}px`,
        marginTop: `0px`
    },
    troponinDivider: {
        margin: `${theme.spacing(2)}px 0px`
    },
    legendTitle: {
        marginTop: `${theme.spacing(2)}px`
    },
    legendValues: {
        margin: `${theme.spacing(1)}px ${theme.spacing(6)}px`
    },
    mainDot: {
        height: '25px',
        width: '25px',
        borderRadius: '50%',
        display: 'inline-block'
    },
    dot: {
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        display: 'inline-block'
    },
    abnormal: {
        backgroundColor: 'rgb(209, 51, 43)'
    },
    indeterminate: {
        backgroundColor: 'rgb(243, 190, 85)'
    },
    ruledOut: {
        backgroundColor: 'rgb(146, 208, 80)'
    },
    noAcuteInjury: {
        backgroundColor: 'rgb(214, 214, 214)'
    },
    cardHeader: {
        paddingBottom: '0px'
    },
    cardBodyTall: {
        minHeight: '725px'
    },
    cardBodyShort: {
        minHeight: '400px'
    },
    stickyContainer: {
        width: '300px'
    },
    gridItem:{
        paddingRight: '0px'
    },
    gridItemHeader:{
        paddingRight: '0px',
        paddingBottom: '0px !important'
    }
});

class ChestPainResult extends React.Component {
    constructor(props) {
        super(props);

        this.tabs = [
            { resultKey: 'initialHourResult', formKey: 'initialHourEval' },
            { resultKey: 'oneHourResult', formKey: 'oneHourEval' },
            { resultKey: 'threeHourResult', formKey: 'threeHourEval' }
        ];
    }

    constructScore = (keyName, keyValue, header = false) => (
        <GridContainer className={header ? this.props.classes.scoreHeader : ''}>
            <GridItem xs={6}>
                {this.constructKeyName(keyName, header)}
            </GridItem>
            <GridItem xs={6}>
                <Typography align="right" color="inherit" variant={header ? this.props.reportResultKey || this.props.reportFormKey ? 'subtitle1' : "h5" : "body2"}>
                    {keyValue === null || keyValue.toString() === '-1' || keyValue.toString() === 'Unknown (-1)' ? '' : keyValue}
                </Typography>
            </GridItem>
        </GridContainer>
    );

    renderInitialInterpretation = (results, classes) => {
        return <GridContainer justify={'space-between'}>
                <GridItem className={classes.gridItem}>
                    <div className={`${classes.dot} ${this.getDotColor(results.initialHourResult.interpretation.toLowerCase(), classes)}`} />
                </GridItem>
                <GridItem className={classes.gridItem}>
                    <Typography align="right" color="inherit" variant="body1">
                        {results.initialHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.initialHourResult.interpretation}
                    </Typography>
                </GridItem>
            </GridContainer>;
    };
    renderOneHourInterpretation = (results, classes) => {
        return ((results.oneHourResult.interpretation.toLowerCase() !== 'error') || (results.initialHourResult.interpretation.toLowerCase() === 'indeterminate'))
            ?
            <GridContainer justify={'space-between'}>
                <GridItem className={classes.gridItem}>
                    <div className={`${classes.dot} ${this.getDotColor(results.oneHourResult.interpretation.toLowerCase(), classes)}`} />
                </GridItem>
                <GridItem className={classes.gridItem}>
                    <Typography align="right" color="inherit" variant="body1">
                        {results.oneHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.oneHourResult.interpretation}
                    </Typography>
                </GridItem>
            </GridContainer>
            : <div/>;
    };

    renderThreeHourInterpretation = (results, classes) => {
        return ((results.threeHourResult.interpretation.toLowerCase() !== 'error') || (results.oneHourResult.interpretation.toLowerCase() === 'indeterminate'))
            ?
            <GridContainer justify={'space-between'}>
                <GridItem className={classes.gridItem}>
                    <div className={`${classes.dot} ${this.getDotColor(results.threeHourResult.interpretation.toLowerCase(), classes)}`} />
                </GridItem>
                <GridItem className={classes.gridItem}>
                    <Typography align="right" color="inherit" variant="body1">
                        {results.threeHourResult.interpretation.toLowerCase() === 'error' ? 'Incomplete' : results.threeHourResult.interpretation}
                    </Typography>
                </GridItem>
            </GridContainer>
            : <div/>;
    };

    constructTroponin = (keyName, result, results, classes, renderInterpretationFunction) => (
        <div style={this.isActiveTabKeyName(keyName) ? {border: "1px solid black"} : {}}>
            <GridContainer>
                <GridItem xs={4}>
                    {this.constructKeyName(keyName)}
                </GridItem>
                <GridItem xs={5}>
                    {renderInterpretationFunction(results, classes)}
                </GridItem>
                <GridItem xs={3}>
                    <Typography align="right" color="inherit" variant={"body2"}>
                        {result.troponin === null || result.troponin.toString() === '-1' || result.troponin.toString() === 'Unknown (-1)' ? '' : result.troponinValue}
                    </Typography>
                </GridItem>
            </GridContainer>
        </div>
    );

    constructInterpretationAndRecommendation = (tab, reportResultKey, results, resultKey, classes) => {
        return reportResultKey
            ?
            this.renderReportInterpretationAndRecommendation(tab, reportResultKey, results, resultKey, classes)
            :
            this.renderInterpretationAndRecommendation(tab, reportResultKey, results, resultKey, classes);
    };

    renderInterpretationAndRecommendation = (tab, reportResultKey, results, resultKey, classes) => (
            <React.Fragment>
                <React.Fragment>
                    <Typography align='left' color='inherit' variant={reportResultKey ? 'subtitle1' : 'h5'} className={classes.title}>
                        {'Interpretation'}
                    </Typography>
                    <Typography align="left" color="inherit" variant="body2" className={classes.content}>
                        {results[resultKey].interpretation.toLowerCase() === 'error' ? '' : results[resultKey].interpretationMessage}
                    </Typography>
                </React.Fragment>

                <React.Fragment>
                    <Typography align='left' color='inherit' variant={reportResultKey ? 'subtitle1' : 'h5'} className={classes.title}>
                        {'Recommendation'}
                    </Typography>
                    <Typography align="left" color="inherit" variant="body2" className={classes.content}>
                        {results[resultKey].interpretation.toLowerCase() === 'error' ? 'Incomplete' : results[resultKey].recommendation}
                    </Typography>
                </React.Fragment>
            </React.Fragment>
    );

    renderReportInterpretationAndRecommendation = (tab, reportResultKey, results, resultKey, classes) => {
        switch (resultKey) {
            case "threeHourResult":
                return results.threeHourResult.interpretation.toLowerCase() !== 'error'
                    ? this.renderInterpretationAndRecommendation(tab, reportResultKey, results, resultKey, classes)
                    : <div/>;
            case "oneHourResult":
                return results.threeHourResult.interpretation.toLowerCase() === 'error' && results.oneHourResult.interpretation.toLowerCase() !== 'error'
                    ? this.renderInterpretationAndRecommendation(tab, reportResultKey, results, resultKey, classes)
                    : <div/>;
            case "initialHourResult":
                return results.threeHourResult.interpretation.toLowerCase() === 'error'
                && results.oneHourResult.interpretation.toLowerCase() === 'error'
                && results.initialHourResult.interpretation.toLowerCase() !== 'error'
                    ? this.renderInterpretationAndRecommendation(tab, reportResultKey, results, resultKey, classes)
                    : <div/>;
            default:
                return <div/>;
        }
    };

    isActiveTab = (index) => {
        return this.props.tab === index && !this.props.reportResultKey;
    };

    isActiveTabKeyName = (keyName) => {
        switch (keyName) {
            case 'Zero Hour':
                return this.props.tab === 0 && !this.props.reportResultKey;
            case 'One Hour':
                return this.props.tab === 1 && !this.props.reportResultKey;
            case 'Three Hour':
                return this.props.tab === 2 && !this.props.reportResultKey;
            default:
                return false;
        }
    };

    getTypographyElement = (keyName, index) => (
        <Typography align="left" color="inherit" variant={this.isActiveTab(index) ?  "subtitle2" : "body2"}>
            {keyName}
        </Typography>
    );

    constructKeyName = (keyName, header) => {
        switch (keyName) {
            case 'Zero Hour':
                return this.getTypographyElement(keyName, 0);
            case 'One Hour':
                return this.getTypographyElement(keyName, 1);
            case 'Three Hour':
                return this.getTypographyElement(keyName, 2);
            default:
                return  (
                <Typography align="left" color="inherit" variant={header ? this.props.reportResultKey || this.props.reportFormKey ? 'subtitle1' : "h5" : "body2"}>
                    {keyName}
                </Typography>
                );
        }
    };

    getResultHeaderElement = (text) => (
        <Typography align="left" color="inherit" variant='body1'>
            {text}
        </Typography>
    );

    getResultHeader = () => {
        return this.getResultHeaderElement(this.getHourLabel(this.props.tab, true));
    };

    getHourLabel = (tab, upper) => {
        switch (tab) {
            case 0:
                return upper ? 'ZERO HOUR' : 'Zero Hour';
            case 1:
                return upper ? 'ONE HOUR': 'One Hour';
            case 2:
                return upper ? 'THREE HOUR': 'Three Hour';
            default:
                return '';
        }
    };

    getDotColor = (interpretation, classes) => {
        switch (interpretation.toLowerCase()) {
            case 'abnormal':
            case 'error':
                return classes.abnormal;
            case 'indeterminate':
                return classes.indeterminate;
            case 'ruled out':
                return classes.ruledOut;
            default:
                return classes.noAcuteInjury;
        }
    };

    render() {
        const { className, classes, resultLoading, results, resultKey, formData, tab, reportResultKey, reportFormKey, convertToDisplayDate, convertToDisplayTime } = this.props;

        let formKey = typeof tab !== 'undefined' ? this.tabs[tab].formKey : reportFormKey;
        let dotColor;

        switch (results[resultKey].interpretation.toLowerCase()) {
            case 'abnormal':
            case 'error':
                dotColor = classes.abnormal;
                break;
            case 'indeterminate':
                dotColor = classes.indeterminate;
                break;
            case 'ruled out':
                dotColor = classes.ruledOut;
                break;
            default:
                dotColor = classes.noAcuteInjury;
        }

        return (
            <Card className={`${className} ${classes.card}`}>
                {reportResultKey ?
                    null
                    :
                    <CardHeader className={classes.cardHeader}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Disclaimer />
                            </GridItem>
                        </GridContainer>
                        <Divider />
                    </CardHeader>
                }
                <CardBody className={reportResultKey ? classes.cardBodyShort : classes.cardBodyTall}>
                    <div>
                        {resultLoading ?
                            <CircularProgress size={60} thickness={7} />
                            :
                            <React.Fragment>

                                <React.Fragment>
                                    {this.constructScore('HEART Score', `${results.heartScore.heartScoreInterpretation} (${results.heartScore.heartScore})`, true)}
                                    {this.constructScore('Age', results.heartScore.ageScore)}
                                    {this.constructScore('Symptoms', results.heartScore.symptomsScore)}
                                    {this.constructScore('ECG', results.heartScore.ecgScore)}
                                    {this.constructScore('Risk Factors', results.heartScore.riskFactorsScore)}
                                    {this.constructScore('Troponin', results.heartScore.troponinScore)}
                                </React.Fragment>

                                {reportResultKey ?
                                    <React.Fragment>
                                        <Divider className={classes.troponinDivider} />

                                        <Typography align='left' color='inherit' variant={reportResultKey ? 'body1' : 'h5'} className={classes.title}>
                                            {'hsTn & ECG SUMMARY'}
                                        </Typography>

                                        <Typography align="right" color="inherit" variant="subtitle2">
                                            {results[resultKey].interpretation.toLowerCase() === 'error' ? 'Incomplete' : results[resultKey].interpretation}
                                        </Typography>
                                        {this.constructScore('Troponin', results[resultKey].troponinValue)}
                                        {this.constructScore('Troponin Lab Date', convertToDisplayDate(results[resultKey].testDate))}
                                        {this.constructScore('Troponin Lab Time', convertToDisplayTime(results[resultKey].testTime))}
                                        {this.constructScore('ECG', formData[formKey].ecg)}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Typography align="center" color="inherit" variant={"h5"} className={classes.legendTitle}>
                                            {'Legend'}
                                        </Typography>
                                        <div className={classes.legendValues}>
                                            {this.constructScore('Low Risk', '0-3 Points')}
                                            {this.constructScore('Med Risk', '4-6 Points')}
                                            {this.constructScore('High Risk', '7-10 Points')}
                                        </div>

                                        <Divider className={classes.troponinDivider} />
                                        <GridContainer alignItems='flex-start' className={classes.title} spacing={2} >
                                            <GridItem className={classes.gridItemHeader} xs={12}>
                                                <Typography align='left' color='inherit' variant={reportResultKey ? 'subtitle1' : 'h5'} className={classes.title}>
                                                    {'hsTn'}
                                                </Typography>
                                            </GridItem>
                                            <GridItem className={classes.gridItem}>
                                                <div className={`${classes.mainDot} ${dotColor}`} />
                                            </GridItem>
                                            <GridItem className={classes.gridItem}>
                                                <Typography align="left" color="inherit" variant="h6">
                                                    {results[resultKey].interpretation.toLowerCase() === 'error' ? 'Incomplete' : results[resultKey].interpretation}
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>

                                    </React.Fragment>
                                }

                                <Divider className={classes.troponinDivider} />

                                {this.constructInterpretationAndRecommendation(this.props.tab, reportResultKey, results, resultKey, classes)}
                            </React.Fragment>
                        }
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(ChestPainResult);