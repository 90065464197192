export class SettingRequestIdentifiers {
    constructor() {
        this.settingGroups = [];
    }

    addSettingToRequest = (settingString, keyType = 0, dataType = 'string', defaultValue, createIfMissing = false) => {
        let settingsArgs = settingPathToArgs(settingString);
        let settingPathArgs = settingsArgs.pathArgs;
        let targetIdentifier = getSettingGroupKey(settingPathArgs, keyType);
        let targetGroup;
        this.settingGroups.forEach(currentGroup => {
            if (currentGroup.identifier === targetIdentifier){
                targetGroup = currentGroup;
            }
        });
        if (!targetGroup) {
            targetGroup = new SettingGroup(settingPathArgs, keyType);
            this.settingGroups.push(targetGroup);
        }
        targetGroup.addKeyValueInfo(settingsArgs.keyValueName, dataType, defaultValue, createIfMissing);
    }
}

class SettingGroup {
    constructor(incomingPathArgs, incomingKeyType) {
        this.pathArgs = incomingPathArgs ? incomingPathArgs : [];
        this.keyType = incomingKeyType ? incomingKeyType : 0;
        this.keyValueInfos = [];
        this.identifier = getSettingGroupKey(incomingPathArgs, incomingKeyType);
    }

    addKeyValueInfo = (incomingKeyName, dataType, defaultValue, createIfMissing) => {
        let targetSetting;
        let existingSettingFound = false;
        this.keyValueInfos.forEach(currentSetting => {
            if (currentSetting.keyName === incomingKeyName) {
                currentSetting.dataTypeString = dataType;
                currentSetting.defaultValue = defaultValue;
                currentSetting.createIfMissing = createIfMissing;
                targetSetting = currentSetting;
                existingSettingFound = true;
            }
        });
        if (!existingSettingFound) {
            targetSetting = new KeyValueInfo(incomingKeyName, dataType, defaultValue, createIfMissing);
            this.keyValueInfos.push(targetSetting);
        }
        return targetSetting;
    }
}

class KeyValueInfo {
    constructor(incomingKeyName, dataType, defaultValue, createIfMissing) {
        this.keyName = incomingKeyName ? incomingKeyName : '';
        this.dataTypeString = dataType ? dataType : 'string';
        this.defaultValue = defaultValue == null || typeof(defaultValue) === 'undefined' ? '' : defaultValue;
        this.createIfMissing = createIfMissing ?? false;
    }
}

export default SettingRequestIdentifiers;

export const settingArgsToPath = (pathArgs, keyValueName) => {
    let joinedArgs = pathArgs.join("\\");
    let retval = keyValueName ? joinedArgs + '\\' + keyValueName : joinedArgs;
    return retval;
};

export const settingPathToArgs = (path) => {
    let pathArgs = path.split("\\");
    let keyValueName = pathArgs.pop();
    return {
        pathArgs,
        keyValueName
    };
};

//This class matches up with an enum in Lumedx.Application called SettingKeyType in the Lumedx.Settings namespace.
export const SettingKeyTypes = {
    application: 0,
    user: 1,
    workstation: 2,
    userWorkstation: 3,
    dynamicData: 4
};

//This class matches up with an object of constant strings in MedicalCopilot.LCDS.ApplicationSettings called DataTypeStrings
export const DataTypeStrings = {
    string: 'string',
    boolean: 'boolean',
    int: 'int',
    douuble: 'double'
};

const getSettingGroupKey = (settingArgs, keyType) => {
    return settingArgsToPath(settingArgs, keyType);
};