import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
    divider: {
        margin: `${theme.spacing(3)}px 0px`,
        backgroundColor: `rgba(0, 0, 0, 0.42)`
    },
    buttonGroup: {
        width: '100%',
        marginTop: `${theme.spacing(3)}px`
    },
    tabButton: {
        width: '100%'
    },
    selectedTab: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: theme.palette.primary.contrastText,
        '& :hover': {
            borderColor: `${theme.palette.primary.contrastText} !important`
        }
    }
});

class ItemSelectorContainer extends React.Component {
    state = {
        currTabIndex: 0
    }

    setCurrTab = (currTabIndex) => () => {
        this.setState({
            currTabIndex
        });
    }

    render() {
        const { className, classes, onAddItem, tabs } = this.props;
        const { currTabIndex } = this.state;

        return (
            <div {...(className ? { className } : {})}>
                <Divider className={classes.divider} />
                {tabs.map((tab, index) => (
                    <Hidden key={index} implementation='css' xsUp={index !== currTabIndex}>
                        <tab.component onAddItem={onAddItem} {...tab.props} />
                    </Hidden>
                ))}
                <ButtonGroup
                    className={classes.buttonGroup}
                    variant='outlined'
                    color='primary'
                >
                    {tabs.map((tab, index) => (
                        <Button
                            key={index}
                            className={`${classes.tabButton} ${index === currTabIndex ? classes.selectedTab : ''}`}
                            onClick={this.setCurrTab(index)}
                        >
                            {tab.text}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
        );
    }
}

ItemSelectorContainer.propTypes = {
    onAddItem: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        component: PropTypes.elementType.isRequired,
        props: PropTypes.object
    })).isRequired
};

export default withStyles(styles)(ItemSelectorContainer);