import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import ResultItem from './ResultItem.js';

const styles = (theme) => ({
    list: {
        padding: '0px'
    }
});

const Result = (props) => {
    const { riskIndex, result, onRiskChange, classes, isLoading } = props;

    return (
        <List className={classes.list} defaultValue={riskIndex}>
            {result.map((risk, index) => <ResultItem isLoading={isLoading} risk={risk} key={index} index={index} riskIndex={riskIndex} onRiskChange={onRiskChange} />)}
        </List>
    );
};

export default withStyles(styles)(Result);