export default function getColor (value) {
    if (value > 0 && value <=0.2) {
        return '#C04640';
    }
    if (value > 0.2 && value <=0.4) {
        return '#C0392B';
    }
    if (value > 0.4 && value <=0.6) {
        return '#A93226';
    }
    if (value > 0.6 && value <=0.8) {
        return '#922B21';
    }
    if (value > 0.8 && value <=1.0) {
        return '#7B241C';
    }
    if (value > 1.0) {
        return '#641E16';
    }

    if (value < 0 && value >= -0.2) {
        return '#3DA06A';
    }
    if (value < -0.2 && value >= -0.4) {
        return '#27AE60';
    }
    if (value < -0.4 && value >= -0.6) {
        return '#229954';
    }
    if (value < -0.6 && value >= -0.8) {
        return '#1E8449';
    }
    if (value < -0.8 && value >= -1.0) {
        return '#196F3D';
    }
    if (value < -1.0 ) {
        return '#145A32';
    }
    return '#009688';
}